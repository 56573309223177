import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { UsersFilter } from "./users-filter/UsersFilter";
import { UsersTable } from "./users-table/UsersTable";
import { UsersGrouping } from "./users-grouping/UsersGrouping";
import { useUsersUIContext } from "./UsersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function UsersCard() {
    const intl = useIntl();
    const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
        ids: usersUIContext.ids,
        newUserButtonClick: usersUIContext.newUserButtonClick,
    };
  }, [usersUIContext]);

  return (
    <Card>
        <CardHeader title={intl.formatMessage({ id: "AUTH.USER.LIST.CASHIER" } )} >
            <CardHeaderToolbar>
                  <CheckPermissions
                      userFunctionalities={[UserFunctionalityE.UrediBlagajniskeUporabnike]}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={usersUIProps.newUserButtonClick}
                    >
                        <FormattedMessage id="AUTH.USER.NEW" />
                    </button>
                </CheckPermissions>
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UsersFilter />
        {usersUIProps.ids.length > 0 && <UsersGrouping />}
        <UsersTable />
      </CardBody>
    </Card>
  );
}
