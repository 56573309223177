import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import DateRange2 from '../../../../../components/DateRange2';
import MultiSelectCashier from '../../../../../components/MultiSelectCashier';
import moment from 'moment';
import { useCookies } from "react-cookie";

const prepareFilter = (queryParams, values, fromDt, toDt, selectedCashiers) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };

    newQueryParams.startDate = moment(fromDt).format("YYYY-MM-DD");
    newQueryParams.endDate = moment(toDt).format("YYYY-MM-DD");
    newQueryParams.query = searchText;

    if ((queryParams.documentType == 1) ||
        (queryParams.documentType == 2) ||
        (queryParams.documentType == 3) ||
        (queryParams.documentType == 4) ||
        (queryParams.documentType == 5) ||
        (queryParams.documentType == 6) ||
        (queryParams.documentType == 9) ||
        (queryParams.documentType == 10) ||
        (queryParams.documentType == 11)) {
        var ids = "";
        for (var index = 0; index < selectedCashiers.length; index++) {
            if (ids != "") {
                ids += ",";
            }
            ids += selectedCashiers[index];
        }
        newQueryParams.cashierIds = ids;
    }
  return newQueryParams;
};

export function DocumentsFilter({ listLoading }) {
    const intl = useIntl();
    const [cookies, setCookie] = useCookies();

    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { cashiers } = currentState;
    const { user } = useSelector(state => state.auth);

  // Office users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        queryParams: documentsUIContext.queryParams,
        setQueryParams: documentsUIContext.setQueryParams,
        documentType: documentsUIContext.documentType
    };
    }, [documentsUIContext]);

    const [selectedCashiers, setSelectedCashiers] = useState(cookies['filter_dt_' + documentsUIProps.documentType + '_' + user.id] != undefined ? getSelectCashiers(cookies['filter_dt_' + documentsUIProps.documentType + '_' + user.id]) : []);

  // queryParams, setQueryParams,
    const applyFilter = (values) => {
      const newQueryParams = prepareFilter(documentsUIProps.queryParams, values, startDate, endDate, selectedCashiers);
      if (!isEqual(newQueryParams, documentsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      documentsUIProps.setQueryParams(newQueryParams);
    }
    };

    function getSelectCashiers(selectCashiers) {
        if (cashiers == undefined || cashiers == null) {
            return [];
        }
        var list = [];
        for (var index = 0; index < selectCashiers.length; index++) {
            for (var x = 0; x < cashiers.length; x++) {
                if (cashiers[x].id == selectCashiers[index] || selectCashiers[index] < 0) {
                    list.push(selectCashiers[index]);
                    break;
                }
            }

        }
        return list;
    }


  return (
    <>
      <Formik
        initialValues={{
          type: "",
            searchText: "",
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD")
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
            {
                ((documentsUIProps.documentType == 1) ||
                    (documentsUIProps.documentType == 2) ||
                    (documentsUIProps.documentType == 3) ||
                    (documentsUIProps.documentType == 4) ||
                    (documentsUIProps.documentType == 5) ||
                    (documentsUIProps.documentType == 6) ||
                    (documentsUIProps.documentType == 9) ||
                    (documentsUIProps.documentType == 10) ||
                    (documentsUIProps.documentType == 11))
                && (
                <div className="col-lg-4">
                    <MultiSelectCashier
                        value={selectedCashiers}
                            onChange={(val) => {
                            var current = new Date();
                            var nextYear = new Date();
                            nextYear.setFullYear(current.getFullYear() + 5);

                            setCookie('filter_dt_' + documentsUIProps.documentType + '_' + user.id, val, {
                                path: '/',
                                expires: nextYear
                            });
                            setSelectedCashiers(val);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                        <b><FormattedMessage id="AUTH.GENERAL.CHOOSE" /></b> <FormattedMessage id="AUTH.GENERAL.FISCAL_CASHIER2" />
                    </small>
                </div>
            )}

              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.SEARCH"
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                </small>
            </div>
            <div className="col-lg-4">
                <DateRange2
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                        handleSubmit();
                    }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.DATES" />
                </small>
            </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
