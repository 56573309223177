import React from "react";
import { Route } from "react-router-dom";
import { OrganizationsLoadingDialog } from "./organizations-loading-dialog/OrganizationsLoadingDialog";
import { OrganizationEditDialog } from "./organization-edit-dialog/OrganizationEditDialog";
import { OrganizationsUIProvider } from "./OrganizationsUIContext";
import { OrganizationsCard } from "./OrganizationsCard";

export function OrganizationsPage({ history }) {
  const organizationsUIEvents = {
    openEditOrganizationDialog: (id) => {
        history.push(`/admin/organizations/${id}/edit`);
    },
  }

  return (
      <OrganizationsUIProvider organizationsUIEvents={organizationsUIEvents}>
        <OrganizationsLoadingDialog />

          <Route path="/admin/organizations/:id/edit">
        {({ history, match }) => (
            <OrganizationEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/admin/organizations");
            }}
          />
        )}
      </Route>
   
          
          
      <OrganizationsCard />
      </OrganizationsUIProvider>
  );
}
