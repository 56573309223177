import * as requestFromServer from "./yourProfileCrud";
import { yourProfileSlice, callTypes } from "./yourProfileSlice";

const { actions } = yourProfileSlice;

export const fetchProfile = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getProfile()
        .then(response => {
            const profile = response.data.model;
            dispatch(actions.profileFetched({ profileForEdit: profile }));
        })
        .catch(error => {
            error.clientMessage = "Can't find profile";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const updateProfile = profile => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateProfile(profile)
        .then(() => {
            dispatch(actions.profileUpdated({ profile }));
        })
        .catch(error => {
            error.clientMessage = "Can't update profile";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};
