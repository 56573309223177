import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/userRoles/userRolesActions";
import { UserRoleEditDialogHeader } from "./UserRoleEditDialogHeader";
import { UserRoleEditForm } from "./UserRoleEditForm";
import { useUserRolesUIContext } from "../UserRolesUIContext";

export function UserRoleEditDialog({ type, id, show, onHide }) {
  // UserRoles UI Context
    const userRolesUIContext = useUserRolesUIContext();
    const userRolesUIProps = useMemo(() => {
    return {
        initUserRole: userRolesUIContext.initUserRole,
    };
    }, [userRolesUIContext]);

  // UserRoles Redux state
    const dispatch = useDispatch();
    const { actionsLoading, userRoleForEdit, userFunctionalities } = useSelector(
    (state) => ({
            actionsLoading: state.userRoles.actionsLoading,
            userRoleForEdit: state.userRoles.userRoleForEdit,
            userFunctionalities: filterUserRoles(state.userRoles.userFunctionalities, type)
    }),
    shallowEqual
  );

  useEffect(() => {
      // server call for getting UserRole by id
      dispatch(actions.fetchUserRole(id));
      
      if (!userFunctionalities) {
          dispatch(actions.fetchUserFunctionalities(type));
      }
  }, [id, dispatch, type]);

    function filterUserRoles(userFunctionalities, type) {
        if (userFunctionalities != undefined && userFunctionalities != null) {
            var res = [];
            for (var index = 0; index < userFunctionalities.length; index++) {
                var el = userFunctionalities[index];
                if (el.type == type) {
                    res.push(el);
                }
            }
            return res;
        }
        return undefined;
    }

  // server request for saving userRole
    const saveUserRole = (userRole) => {
    if (!id) {
      // server request for creating userRole
        dispatch(actions.createUserRole(userRole)).then(() => onHide());
    } else {
      // server request for updating userRole
        dispatch(actions.updateUserRole(userRole)).then(() => onHide());
    }
  };
    
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      >
          <UserRoleEditDialogHeader id={id} />
          <UserRoleEditForm
              type={type}
              userFunctionalities={userFunctionalities}
              saveUserRole={saveUserRole}
        actionsLoading={actionsLoading}
              userRole={userRoleForEdit || userRolesUIProps.initUserRole}
        onHide={onHide}
      />
    </Modal>
  );
}
