/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourProfile/yourProfileAction";
import { updateSignatureProfile } from "../../_redux/yourProfile/yourProfileCrud";


export function YourProfileForm() {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [signatureUniqueId, setSignatureUniqueId] = useState("");
    const [removeSignature, setRemoveSignature] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { profile, error } = useSelector(
        (state) => ({
            profile: state.profile.profileForEdit,
            error: state.profile.error
        }),
        shallowEqual
    );
    // CodeLists Redux state

    useEffect(() => {
    }, [user, profile]);

    // Methods
    const saveProfile = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);

        var newSignatureUniqueId = values.signatureUniqueId;
        if (removeSignature == true) {
            newSignatureUniqueId = "";
        } else if (signatureUniqueId != "" && newSignatureUniqueId != signatureUniqueId) {
            newSignatureUniqueId = signatureUniqueId;
        }

        var obj = {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            signatureUniqueId: newSignatureUniqueId
        };

        dispatch(actions.updateProfile(obj)).then(() => {
            setIsError(error != null);
            setIsSuccess(error == null);
            setLoading(false);
            setSubmitting(false);
            setRemoveSignature(false);
            setSignatureUniqueId("");
        });
    };

    const getSignature = () => {
        if (removeSignature) {
            return "none"
        }
        return "url(/api/profile/signature?uniqueId=" + signatureUniqueId + "&" + new Date().getTime() + ")";
    };
    const onClickRemoveSignature = () => {
        setSignatureUniqueId("");
        setRemoveSignature(true);
    };

    const onChangeSignature = event => {
        const formData = new FormData();
        formData.append(
            "signature",
            event.target.files[0],
            event.target.files[0].name
        );
        updateSignatureProfile(formData).then((req) => {
            if (!req.data.didError) {
                setSignatureUniqueId(req.data.model);
                setRemoveSignature(false);
            } else {
                setSignatureUniqueId("");
            }
        }).catch(() => {
            setSignatureUniqueId("");
        });
    }

    // UI Helpers
    const initialValues = {
        firstName: profile != undefined ? profile.firstName : "",
        lastName: profile != undefined ? profile.lastName : "",
        phone: profile != undefined ? profile.phone : "",
        email: profile != undefined ? profile.email : "",
        signatureUniqueId: profile != undefined ? profile.signatureUniqueId : ""
    };

    const Schema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' })),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveProfile(values, setStatus, setSubmitting);
            resetForm(initialValues);
            setSignatureUniqueId("");
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit}  onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="AUTH.GENERAL.YOUR_PROFIL" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="AUTH.GENERAL.YOUR_PROFIL_2" />
          </span>
                </div>
                <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting || (formik.touched && !formik.isValid)
                        }
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
            {formik.isSubmitting}
                    </button>
                    <button
                        className="btn btn-secondary"
                        type="reset"
                    >
                        <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_SAVED_PROFILE" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_CHANGE_PASSWORD" />

              </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.FULLNAME" />
            </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.FULLNAME" })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "firstName"
                                )}`}
                                name="firstName"
                                {...formik.getFieldProps("firstName")}
                            />
                            {formik.touched.firstName &&
                                formik.errors.firstName ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.firstName}
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.LASTNAME" />
            </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.LASTNAME" })}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "lastName"
                                )}`}
                                name="lastName"
                                {...formik.getFieldProps("lastName")}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="invalid-feedback">{formik.errors.lastName}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.PHONE" />
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.PHONE" })}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "phone"
                                )}`}
                                name="phone"
                                {...formik.getFieldProps("phone")}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="invalid-feedback">
                                    {formik.errors.phone}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.EMAIL" />
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="email"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.EMAIL" })}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "email"
                                )}`}
                                name="email"
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="invalid-feedback">
                                    {formik.errors.email}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                            <FormattedMessage id="AUTH.INPUT.SIGNATURE" />
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <div
                                className="image-input image-input-outline"
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/users/blank.png"
                                    )}`,
                                }}
                            >
                                <div
                                    className="image-input-wrapper"
                                    style={{ backgroundImage: `${getSignature()}` }}
                                />
                                <label
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                    <input
                                        type="file"
                                        onChange={onChangeSignature}
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </label>
                                <span
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="cancel"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                <span
                                    onClick={onClickRemoveSignature}
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            </div>
                            <span className="form-text text-muted">
                                <FormattedMessage id="IMAGE.ALLOWED_FILES" />
                            </span>
                        </div>
                    </div>



                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}

