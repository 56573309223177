import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AutocompleteItemView from "../../../../../components/AutocompleteItemView"
import AutocompleteItemViewGoods from "../../../../../components/AutocompleteItemViewGoods"
import SelectUnit from "../../../../../components/SelectUnit"
import SelectVat from "../../../../../components/SelectVat"
import { decimal, onlyPositiveDecimal } from "../../../../../util/Util";
import { shallowEqual, useSelector } from "react-redux";
import { getStockByItemId } from "../../../_redux/documents/documentsCrud";
import { Field } from "formik";
import {
    Input,
} from "../../../../../../_metronic/_partials/controls";

export function DocumentPositionGrid({
    warehouse,
    readOnly,
    documentType,
    documentPositions,
    gridPositionColumnsVisible,
    gridPositionColumnsTranslate,
    formFieldsVisible,
    saveDocumentPositions,
    getDiscount,
    recalculate,
    save
}) {
    const [dpList, setDpList] = useState(documentPositions == undefined ? [] : cloneDocumentPositions(documentPositions));
    const [amountForPaymentBase, setAmountForPaymentBase] = useState(0);
    const [amountDiscount, setAmountDiscount] = useState(0);
    const [amountForPayment, setAmountForPayment] = useState(0);
    const [amountForVats, setAmountForVats] = useState([]);
    const [init, setInit] = useState(true);

    const intl = useIntl();

    useEffect(() => {
        calculateTotals();
    }, [recalculate]);

    useEffect(() => {
        setDpList(documentPositions == undefined ? [] : cloneDocumentPositions(documentPositions));
    }, [documentPositions]);

    useEffect(() => {
        calculateTotals();
    }, [dpList]);

    useEffect(() => {
        if (init) {
            setInit(false);
            return;
        }
        var documentPositions = [];
        for (var index = 0; index < dpList.length; index++) {
            var row = dpList[index];
            if ((row.item != null || (row.isCustomItem == true && row.itemTitle != null && row.itemTitle != undefined && row.itemTitle != "")) && (row.quantity != 0 || documentType == 13)) {
                documentPositions.push(row);
            }
        }
        saveDocumentPositions(documentPositions);
    }, [save]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { vatRates } = currentState;

    function cloneDocumentPositions(documentPositions) {
        var list = [];
        for (var x = 0; x < documentPositions.length; x++) {
            var dp = { ...documentPositions[x] };
            if (dp.item != null && dp.item.itemTypeId == 1) {
                dp["grossWeightPerUnit"] = dp.item.grossWeightPerUnit;
            }
            list.push(dp);
        }
        return list;
    }

    function addRows() {
        var documentPostions = dpList.slice();
        for (var x = 0; x < 5; x++) {
            documentPostions.push({
                item: null,
                vatRateId: 1,
                quantity: 0,
                discount: 0,
                itemAmountWithoutVat: 0,
                totalAmountWithoutVat: 0,
                totalAmountWithVat: 0,
                grossWeight: 0,
                isCustomItem: false,
                itemTitle: "",
                unitId: null,
                length: 0,
                radius: 0,
                cubic: 0
            });
        }
        setDpList(documentPostions);
    }

    function setColumnValue(index, columnName, value) {
        if (dpList.length <= index) {
            return;
        }

        var documentPostions = dpList.slice();

        documentPostions[index][columnName] = value;
        switch (columnName) {
            case "item":
                if (value != null && value.itemTypeId == 1 && gridPositionColumnsVisible.grossWeight) {
                    documentPostions[index]["grossWeightPerUnit"] = value.grossWeightPerUnit;
                }
                if (value != null && value.itemTypeId == 1 && gridPositionColumnsVisible.stock) {
                    getStockByItemId(value.id, warehouse)
                        .then(({ data }) => {
                            if (!data.didError) {
                                documentPostions[index]["stockQuantity"] = data.model;
                            } else {
                                documentPostions[index]["stockQuantity"] = undefined;
                            }
                            setDpList(documentPostions);
                        })
                        .catch((e) => {
                            documentPostions[index]["stockQuantity"] = undefined;
                            setDpList(documentPostions);
                        });
                    return;
                }
                if (value != null && gridPositionColumnsVisible.vat) {
                    documentPostions[index]["vatRateId"] = value.vatRateId;
                }
                if (value != null && gridPositionColumnsVisible.itemAmountWithoutVat) {
                    switch (documentType) {
                        case 12:
                        case 18:
                            documentPostions[index]["itemAmountWithoutVat"] = value.purchaseAmountWithoutVat ?? 0;
                            break;
                        default:
                            documentPostions[index]["itemAmountWithoutVat"] = value.priceWithoutVat ?? 0;
                            break;
                    }
                }
                break;
            case "grossWeight":
                if (documentPostions[index]["grossWeightPerUnit"] != undefined &&
                    documentPostions[index]["grossWeightPerUnit"] != null) {
                    var val = parseFloat(value);
                    var grossWeightPerUnit = parseFloat(documentPostions[index]["grossWeightPerUnit"]);
                    var quantity = val / grossWeightPerUnit;
                    documentPostions[index]["quantity"] = quantity;
                }
            case "length":
                if (documentPostions[index]["radius"] != undefined &&
                    documentPostions[index]["radius"] != null &&
                    documentPostions[index]["cubic"] != undefined &&
                    documentPostions[index]["cubic"] != null) {
                    var val = parseFloat(value);
                    var radius = parseFloat(documentPostions[index]["radius"]);
                    var cubic = parseFloat(documentPostions[index]["cubic"]);
                    var quantity = ((3.14 * ((radius/ 100) * (radius / 100))) * val) * cubic;
                    documentPostions[index]["quantity"] = quantity;
                }
                break;
            case "radius":
                if (documentPostions[index]["length"] != undefined &&
                    documentPostions[index]["length"] != null &&
                    documentPostions[index]["cubic"] != undefined &&
                    documentPostions[index]["cubic"] != null) {
                    var val = parseFloat(value);
                    var length = parseFloat(documentPostions[index]["length"]);
                    var cubic = parseFloat(documentPostions[index]["cubic"]);
                    var quantity = ((3.14 * ((val / 100) * (val / 100))) * length) * cubic;
                    documentPostions[index]["quantity"] = quantity;
                }
                break;
            case "cubic":
                if (documentPostions[index]["length"] != undefined &&
                    documentPostions[index]["length"] != null &&
                    documentPostions[index]["radius"] != undefined &&
                    documentPostions[index]["radius"] != null) {
                    var val = parseFloat(value);
                    var length = parseFloat(documentPostions[index]["length"]);
                    var radius = parseFloat(documentPostions[index]["radius"]);
                    var quantity = ((3.14 * ((radius / 100) * (radius / 100))) * length) * val;
                    documentPostions[index]["quantity"] = quantity;
                }
                break;
        }
        calculatePostionPrice(documentPostions[index], columnName);
        setDpList(documentPostions);
    }

    function getColumnValue(index, columnName) {
        if (dpList.length <= index) {
            return "";
        }
        return dpList[index][columnName];
    }

    function calculatePostionPrice(documentPosition, columnName)
    {
        switch (columnName) {
            case "quantity":
                var itemAmountWithoutVat = parseFloat(documentPosition.itemAmountWithoutVat.toString().replace(',', '.'));
                var quantity = parseFloat(documentPosition.quantity.toString().replace(',', '.'));
                var discount = parseFloat(documentPosition.discount.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));
                    
                var valWithoutVat1 = itemAmountWithoutVat * quantity * ((parseFloat('100') - discount) / parseFloat('100'));
                documentPosition.totalAmountWithoutVat = valWithoutVat1;
                documentPosition.totalAmountWithVat = ((vatProcent + parseFloat('100')) / parseFloat('100')) * valWithoutVat1;
                break;
            case "discount":
                var itemAmountWithoutVat = parseFloat(documentPosition.itemAmountWithoutVat.toString().replace(',', '.'));
                var quantity = parseFloat(documentPosition.quantity.toString().replace(',', '.'));
                var discount = parseFloat(documentPosition.discount.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));

                var valWithoutVat2 = itemAmountWithoutVat * quantity * ((parseFloat('100') - discount) / parseFloat('100'));
                documentPosition.totalAmountWithoutVat = valWithoutVat2;
                documentPosition.totalAmountWithVat = ((vatProcent + parseFloat('100')) / parseFloat('100')) * valWithoutVat2;
                break;
            case "vatRateId":
                var totalAmountWithoutVat = parseFloat(documentPosition.totalAmountWithoutVat.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));
                documentPosition.totalAmountWithVat = ((vatProcent + parseFloat('100')) / parseFloat('100')) * totalAmountWithoutVat;
                break;
            case "itemAmountWithoutVat":
                var itemAmountWithoutVat = parseFloat(documentPosition.itemAmountWithoutVat.toString().replace(',', '.'));
                var quantity = parseFloat(documentPosition.quantity.toString().replace(',', '.'));
                var discount = parseFloat(documentPosition.discount.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));

                var valWithoutVat = itemAmountWithoutVat * quantity * ((parseFloat('100') - discount) / parseFloat('100'));
                documentPosition.totalAmountWithoutVat = valWithoutVat;
                documentPosition.totalAmountWithVat = ((vatProcent + parseFloat('100')) / parseFloat('100')) * valWithoutVat;
                break;
            case "totalAmountWithoutVat":
                var quantity = parseFloat(documentPosition.quantity.toString().replace(',', '.'));
                var itemAmountWithoutVat = parseFloat(documentPosition.itemAmountWithoutVat.toString().replace(',', '.'));
                var totalAmountWithoutVat = parseFloat(documentPosition.totalAmountWithoutVat.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));
                var discount = parseFloat(documentPosition.discount.toString().replace(',', '.'));

                if (quantity > 0) {
                    documentPosition.itemAmountWithoutVat = totalAmountWithoutVat / quantity;
                    var valWithoutVat3 = documentPosition.itemAmountWithoutVat * quantity * ((parseFloat('100') - discount) / parseFloat('100'));
                    //documentPosition.totalAmountWithoutVat = valWithoutVat3;
                    documentPosition.totalAmountWithVat = ((vatProcent + parseFloat('100')) / parseFloat('100')) * valWithoutVat3;
                }
                else {
                    documentPosition.itemAmountWithoutVat = 0;
                    documentPosition.totalAmountWithoutVat = 0;
                    documentPosition.totalAmountWithVat = 0;
                }
                break;
            case "totalAmountWithVat":
                var quantity = parseFloat(documentPosition.quantity.toString().replace(',', '.'));
                var totalAmountWithVat = parseFloat(documentPosition.totalAmountWithVat.toString().replace(',', '.'));
                var vatProcent = parseFloat(vatRates.find(el => el.id == documentPosition.vatRateId).procent.toString().replace(',', '.'));
                var itemAmountWithoutVat = parseFloat(documentPosition.itemAmountWithoutVat.toString().replace(',', '.'));
                var discount = parseFloat(documentPosition.discount.toString().replace(',', '.'));

                if (quantity > 0) {
                    var val = (totalAmountWithVat * parseFloat('100')) / (parseFloat('100') + vatProcent);
                    documentPosition.itemAmountWithoutVat = val / quantity;
                    var valWithoutVat4 = documentPosition.itemAmountWithoutVat * quantity * ((parseFloat('100') - discount) / parseFloat('100'));
                    documentPosition.totalAmountWithoutVat = valWithoutVat4;
                    //documentPosition.totalAmountWithVat = ((vatProcent + 100) / 100) * valWithoutVat4;
                }
                else {
                    documentPosition.itemAmountWithoutVat = 0;
                    documentPosition.totalAmountWithoutVat = 0;
                    documentPosition.totalAmountWithVat = 0;
                }
                break;
        }
        calculateTotals();
    }

    function calculateTotals() {
        var totalPriceWithoutVat = 0;
        var totalPriceWithVat = 0;
        var totalAmountDiscount = 0;
        var vatsAmount = [];

        for (var index in vatRates) {
            var vatRate = vatRates[index];
            vatsAmount[vatRate.id] = 0;
        }

        var iDiscountProcent = 0;
        var discount = getDiscount();
        
        if (discount.discountProcent != '') {
            iDiscountProcent = parseFloat(discount.discountProcent.toString().replace(',', '.'));
        }
        if (discount.discountAmount != '') {
            var iDiscountAmount = parseFloat(discount.discountAmount.toString().replace(',', '.'));
            var tmpTotalPriceWithVat = 0;

            for (var index = 0; index < dpList.length; index++) {
                var row = dpList[index];
                if (row.item != null ||
                    (row.isCustomItem == true && row.itemTitle != null && row.itemTitle != undefined && row.itemTitle != "")) {
                    tmpTotalPriceWithVat += row.totalAmountWithVat;
                }
            }
            iDiscountProcent = parseFloat((parseFloat('100') - (((tmpTotalPriceWithVat - iDiscountAmount) / tmpTotalPriceWithVat) * parseFloat('100'))).toFixed(8));
        }

        for (var index = 0; index < dpList.length; index++) {
            var row = dpList[index];
            if (row.item != null ||
               (row.isCustomItem == true && row.itemTitle != null && row.itemTitle != undefined && row.itemTitle != "")) {
                var vatProcent = parseFloat(vatRates.find(el => el.id == row.vatRateId).procent.toString().replace(',', '.'));

                row.totalAmountWithoutVat = row.totalAmountWithVat / ((parseFloat('100') + vatProcent) / parseFloat('100'));

                var totalVat = (row.totalAmountWithVat - row.totalAmountWithoutVat) * ((parseFloat('100') - iDiscountProcent) / parseFloat('100'));
                vatsAmount[row.vatRateId] += totalVat;

                var itemPriceWithoutVat = row.totalAmountWithoutVat * ((parseFloat('100') - iDiscountProcent) / parseFloat('100'));
                var itemPriceWithVat = itemPriceWithoutVat * ((parseFloat('100') + vatProcent) / parseFloat('100'));
                var itemAmountDiscount = ((row.itemAmountWithoutVat * row.quantity) - row.totalAmountWithoutVat) + (row.totalAmountWithoutVat - itemPriceWithoutVat);
                totalPriceWithoutVat += itemPriceWithoutVat;
                totalPriceWithVat += itemPriceWithVat;
                totalAmountDiscount += itemAmountDiscount;
            }
        }

        setAmountForPaymentBase(totalPriceWithoutVat);
        setAmountDiscount(totalAmountDiscount);
        setAmountForPayment(totalPriceWithVat);

        var tmpAmountForVats = [];
        for (var index in vatRates) {
            var vatRate = vatRates[index];
            if (vatsAmount[vatRate.id] != 0) {
                tmpAmountForVats.push({
                    id: vatRate.id,
                    procent: vatRate.procent,
                    amount: vatsAmount[vatRate.id]
                });
            }
        }
        setAmountForVats(tmpAmountForVats);
    }
    
    return (
        <>
            <div className="form-group row">
                <div className="col-lg-12">
                    <h4><FormattedMessage id="AUTH.GENERAL.POSITIONS" /></h4>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                {gridPositionColumnsVisible.isCustomItem && (
                                    <th scope="col" width="5%" >{gridPositionColumnsTranslate.isCustomItem}</th>
                                )}
                                {gridPositionColumnsVisible.itemGoods && (
                                    <th scope="col" width="30%" >{gridPositionColumnsTranslate.item}</th>
                                )}
                                {gridPositionColumnsVisible.itemAll && (
                                    <th scope="col" width="30%">{gridPositionColumnsTranslate.item}</th>
                                )}
                                {gridPositionColumnsVisible.vat && (
                                    <th scope="col">{gridPositionColumnsTranslate.vat}</th>
                                )}
                                {gridPositionColumnsVisible.cubic && (
                                    <>
                                        <th scope="col">{gridPositionColumnsTranslate.length}</th>
                                        <th scope="col">{gridPositionColumnsTranslate.radius}</th>
                                        <th scope="col">{gridPositionColumnsTranslate.cubic}</th>
                                        <th scope="col">{gridPositionColumnsTranslate.quantity}</th>
                                    </>
                                )}
                                {!gridPositionColumnsVisible.cubic && gridPositionColumnsVisible.quantity && (
                                    <th scope="col">{gridPositionColumnsTranslate.quantity}</th>
                                )}
                                {gridPositionColumnsVisible.discount && (
                                    <th scope="col">{gridPositionColumnsTranslate.discount}</th>
                                )}
                                {gridPositionColumnsVisible.itemAmountWithoutVat && (
                                    <th scope="col">{gridPositionColumnsTranslate.itemAmountWithoutVat}</th>
                                )}
                                {gridPositionColumnsVisible.totalAmountWithoutVat && (
                                    <th scope="col">{gridPositionColumnsTranslate.totalAmountWithoutVat}</th>
                                )}
                                {gridPositionColumnsVisible.totalAmountWithVat && (
                                    <th scope="col">{gridPositionColumnsTranslate.totalAmountWithVat}</th>
                                )}
                                {gridPositionColumnsVisible.grossWeight && (
                                    <th scope="col">{gridPositionColumnsTranslate.grossWeight}</th>
                                )}
                                {gridPositionColumnsVisible.stock && (
                                    <th scope="col">{gridPositionColumnsTranslate.stock}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {dpList.map((dp, i) => {

                                return (
                                    <tr key={i}>
                                        <th scope="row">{i + 1}</th>
                                        {gridPositionColumnsVisible.isCustomItem && (
                                            <td>
                                                {getColumnValue(i, "isCustomItem")}
                                                <Field
                                                    disabled={readOnly}
                                                    className="PrivateSwitchBase-input-93"
                                                    type="checkbox"
                                                    name="isCustomItem"
                                                    checked={getColumnValue(i, "isCustomItem")}
                                                    onChange={(el) => {
                                                        setColumnValue(i, "isCustomItem", !getColumnValue(i, "isCustomItem"));
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.itemGoods && (
                                            <td>
                                                {!getColumnValue(i, "isCustomItem") && (
                                                    <AutocompleteItemViewGoods
                                                        readOnly={readOnly}
                                                        name="item"
                                                        value={getColumnValue(i, "item")}
                                                        onChange={(val) => {
                                                            setColumnValue(i, "item", val);
                                                        }}
                                                    />
                                                )}
                                                {getColumnValue(i, "isCustomItem") && (
                                                    <div className="row no-gutters">
                                                        <div className="col-md-8">
                                                            <Field
                                                                disabled={readOnly}
                                                                name="itemTitle"
                                                                component={Input}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ITEM_TITLE' })}
                                                                label=""
                                                                customFeedbackLabel=" "
                                                                value={getColumnValue(i, "itemTitle")}
                                                                onChange={(el) => {
                                                                    setColumnValue(i, "itemTitle", el.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <SelectUnit
                                                                readOnly={readOnly}
                                                                name="unitId"
                                                                value={getColumnValue(i, "unitId")}
                                                                onChange={(val) => {
                                                                    setColumnValue(i, "unitId", val);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.itemAll && (
                                            <td>
                                                {!getColumnValue(i, "isCustomItem") && (
                                                    <AutocompleteItemView
                                                        readOnly={readOnly}
                                                        name="item"
                                                        value={getColumnValue(i, "item")}
                                                        onChange={(val) => {
                                                            setColumnValue(i, "item", val);
                                                        }}
                                                    />
                                                )}
                                                
                                                {getColumnValue(i, "isCustomItem") && (
                                                    <div className="row no-gutters">
                                                        <div className="col-md-8">
                                                            <Field
                                                                disabled={readOnly}
                                                                name="itemTitle"
                                                                component={Input}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ITEM_TITLE' })}
                                                                label=""
                                                                customFeedbackLabel=" "
                                                                value={getColumnValue(i, "itemTitle")}
                                                                onChange={(el) => {
                                                                    setColumnValue(i, "itemTitle", el.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <SelectUnit
                                                                readOnly={readOnly}
                                                                name="unitId"
                                                                value={getColumnValue(i, "unitId")}
                                                                onChange={(val) => {
                                                                    setColumnValue(i, "unitId", val);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.vat && (
                                            <td>
                                                <SelectVat
                                                    readOnly={readOnly}
                                                    name="vatRateId"
                                                    value={getColumnValue(i, "vatRateId")}
                                                    onChange={(val) => {
                                                        setColumnValue(i, "vatRateId", val);
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.cubic && (
                                            <>
                                                <td>
                                                    <input
                                                        placeholder={gridPositionColumnsTranslate.length}
                                                        disabled={readOnly}
                                                        type="number"
                                                        onKeyPress={onlyPositiveDecimal}
                                                        className={`form-control`}
                                                        name="length"
                                                        value={getColumnValue(i, "length")}
                                                        onChange={(event) => {
                                                            if (event.target.value >= 0) {
                                                                setColumnValue(i, "length", event.target.value);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value == "") {
                                                                setColumnValue(i, "length", 0);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        placeholder={gridPositionColumnsTranslate.radius}
                                                        disabled={readOnly}
                                                        type="number"
                                                        onKeyPress={onlyPositiveDecimal}
                                                        className={`form-control`}
                                                        name="radius"
                                                        value={getColumnValue(i, "radius")}
                                                        onChange={(event) => {
                                                            if (event.target.value >= 0) {
                                                                setColumnValue(i, "radius", event.target.value);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value == "") {
                                                                setColumnValue(i, "radius", 0);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        placeholder={gridPositionColumnsTranslate.cubic}
                                                        disabled={readOnly}
                                                        type="number"
                                                        onKeyPress={onlyPositiveDecimal}
                                                        className={`form-control`}
                                                        name="cubic"
                                                        value={getColumnValue(i, "cubic")}
                                                        onChange={(event) => {
                                                            if (event.target.value >= 0) {
                                                                setColumnValue(i, "cubic", event.target.value);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value == "") {
                                                                setColumnValue(i, "cubic", 0);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        placeholder={gridPositionColumnsTranslate.quantity}
                                                        disabled={readOnly}
                                                        type="number"
                                                        onKeyPress={onlyPositiveDecimal}
                                                        className={`form-control`}
                                                        name="quantity"
                                                        value={getColumnValue(i, "quantity")}
                                                        onChange={(event) => {
                                                            if (event.target.value >= 0) {
                                                                setColumnValue(i, "quantity", event.target.value);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value == "") {
                                                                setColumnValue(i, "quantity", 0);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </>
                                        )}
                                        {!gridPositionColumnsVisible.cubic && gridPositionColumnsVisible.quantity && (
                                            <>
                                                <td>
                                                    <input
                                                        placeholder={gridPositionColumnsTranslate.quantity}
                                                        disabled={readOnly}
                                                        type="number"
                                                        onKeyPress={onlyPositiveDecimal}
                                                        className={`form-control`}
                                                        name="quantity"
                                                        value={getColumnValue(i, "quantity")}
                                                        onChange={(event) => {
                                                            if (event.target.value >= 0) {
                                                                setColumnValue(i, "quantity", event.target.value);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value == "") {
                                                                setColumnValue(i, "quantity", 0);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </>
                                        )}
                                        {gridPositionColumnsVisible.discount && (
                                            <td>
                                                <input
                                                    placeholder={gridPositionColumnsTranslate.discount}
                                                    type="number"
                                                    disabled={readOnly}
                                                    onKeyPress={onlyPositiveDecimal}
                                                    className={`form-control`}
                                                    name="discount"
                                                    value={getColumnValue(i, "discount")}
                                                    onChange={(event) => {
                                                        if (event.target.value >= 0) {
                                                            setColumnValue(i, "discount", event.target.value);
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (event.target.value == "") {
                                                            setColumnValue(i, "discount", 0);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.itemAmountWithoutVat && (
                                            <td>
                                                <input
                                                    placeholder={gridPositionColumnsTranslate.itemAmountWithoutVat}
                                                    type="number"
                                                    disabled={readOnly}
                                                    onKeyPress={onlyPositiveDecimal}
                                                    className={`form-control`}
                                                    name="itemAmountWithoutVat"
                                                    value={getColumnValue(i, "itemAmountWithoutVat")}
                                                    onChange={(event) => {
                                                        if (event.target.value >= 0) {
                                                            setColumnValue(i, "itemAmountWithoutVat", event.target.value);
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (event.target.value == "") {
                                                            setColumnValue(i, "itemAmountWithoutVat", 0);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.totalAmountWithoutVat && (
                                            <td>
                                                <input
                                                    placeholder={gridPositionColumnsTranslate.totalAmountWithoutVat}
                                                    type="number"
                                                    disabled={readOnly}
                                                    onKeyPress={onlyPositiveDecimal}
                                                    className={`form-control`}
                                                    name="totalAmountWithoutVat"
                                                    value={getColumnValue(i, "totalAmountWithoutVat")}
                                                    onChange={(event) => {
                                                        if (event.target.value >= 0) {
                                                            setColumnValue(i, "totalAmountWithoutVat", event.target.value);
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (event.target.value == "") {
                                                            setColumnValue(i, "totalAmountWithoutVat", 0);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.totalAmountWithVat && (
                                            <td>
                                                <input
                                                    placeholder={gridPositionColumnsTranslate.totalAmountWithVat}
                                                    type="number"
                                                    disabled={readOnly}
                                                    onKeyPress={onlyPositiveDecimal}
                                                    className={`form-control`}
                                                    name="totalAmountWithVat"
                                                    value={getColumnValue(i, "totalAmountWithVat")}
                                                    onChange={(event) => {
                                                        if (event.target.value >= 0) {
                                                            setColumnValue(i, "totalAmountWithVat", event.target.value);
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (event.target.value == "") {
                                                            setColumnValue(i, "totalAmountWithVat", 0);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.grossWeight && (
                                            <td>
                                                <input
                                                    placeholder={gridPositionColumnsTranslate.grossWeight}
                                                    type="number"
                                                    disabled={readOnly}
                                                    onKeyPress={onlyPositiveDecimal}
                                                    className={`form-control`}
                                                    name="grossWeight"
                                                    onChange={(event) => {
                                                        if (event.target.value >= 0) {
                                                            setColumnValue(i, "grossWeight", event.target.value);
                                                        }
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {gridPositionColumnsVisible.stock && (
                                            <td>{dp.stockQuantity == undefined ? intl.formatMessage({ id: "NO_DATA" }) : dp.stockQuantity.toFixed(2).replace(".", ",")}</td>
                                        )}
                                    </tr>)
                            })}
                    
                    
                        </tbody>
                
                    </table>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-lg-8">
                    {!readOnly && (
                        <button
                            type="button"
                            onClick={() => addRows()}
                            className="btn btn-danger btn-elevate"
                        >
                            <FormattedMessage id="AUTH.GENERAL.ADD_ROWS" />
                        </button>

                    )}
                </div>
                <div className="col-lg-4">
                    <table className="table invoice-total">
                        <tbody>
                            {formFieldsVisible.amountForPaymentBase && (
                                <tr>
                                    <td>
                                        <strong><FormattedMessage id="AUTH.GENERAL.SUM_AMOUNT_WITHOUT_VAT" /> <span className="valuta">(EUR)</span> :</strong>
                                    </td>
                                    <td>{amountForPaymentBase.toFixed(2).replace(".", ",")}</td>
                                </tr>
                            )}
                            {formFieldsVisible.amountDiscount && (
                                <tr>
                                    <td>
                                        <strong><FormattedMessage id="AUTH.GENERAL.SUM_AMOUNT_DISCOUNT" /> <span className="valuta">(EUR)</span> :</strong>
                                    </td>
                                    <td>{amountDiscount.toFixed(2).replace(".", ",")}</td>
                                </tr>    
                            )}
                            {formFieldsVisible.amountVat && (
                                <>
                                    {amountForVats.map((vat, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <strong><FormattedMessage id="AUTH.GENERAL.VAT" /> {vat.procent.toFixed(2).replace(".", ",")}% <span className="valuta">(EUR)</span> :</strong>
                                                </td>
                                                <td>{vat.amount.toFixed(2).replace(".", ",")}</td>
                                            </tr>
                                        );
                                    })}
                                </>)}
                            {formFieldsVisible.amountForPayment && (
                                <tr>
                                    <td>
                                        <strong><FormattedMessage id="AUTH.GENERAL.SUM_AMOUNT_WITH_VAT" /> <span className="valuta">(EUR)</span> :</strong>
                                    </td>
                                    <td>{amountForPayment.toFixed(2).replace(".", ",")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            

        </>
    );
}