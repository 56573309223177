// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export function UserRoleEditForm({
    type,
    userFunctionalities,
    saveUserRole,
    userRole,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    
    var ufList = [];
    switch (type) {
        case 1:
            ufList = [UserFunctionalityE.UrediVlogeZaBlagajniskePravice];
            break;
        case 2:
            ufList = [UserFunctionalityE.UrediVlogeZaPisarniskePravice];
            break;
    }

    var readOnly = !hasRoleForFunctionality(user, ufList);

    function createRows(arr, size) {
        var res = arr.reduce((acc, curr, i) => {
            if (!(i % size)) {    // if index is 0 or can be divided by the `size`...
                acc.push(arr.slice(i, i + size));   // ..push a chunk of the original array to the accumulator
            }
            return acc;
        }, []);
        return res;
    }

    function createModules(arr) {
        var res = [];

        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];

            var module = null;
            for (var i2 = 0; i2 < res.length; i2++) {
                var el2 = res[i2];
                if (el2.id == el.applicationModule) {
                    module = el2;
                    break;
                }
            }

            if (module == null) {
                module = {};
                module.id = el.applicationModule;
                module.list = [];
                res.push(module);
            }
            module.list.push(el);
        }

        return res;
    }

    // Validation schema
    const UserRoleEditSchema = Yup.object().shape({
        title: Yup.string()
            .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_1_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
    });


  return (
    <>
      <Formik
        enableReinitialize={true}
            initialValues={userRole}
            validationSchema={UserRoleEditSchema}
            onSubmit={(values) => {
                saveUserRole(values);
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
                )}
                {!actionsLoading && (
                    <Form className="form form-label-right">
                        <div className="form-group row">

                            {/* First Name */}
                            <div className="col-lg-6">
                                <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="title"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>

                        </div>
                        <FieldArray
                            name="userRoleUserFunctionalities"
                            render={arrayHelpers => (
                                <>
                                    {userFunctionalities && userFunctionalities.length > 0 ? (
                                        createModules(userFunctionalities).map((module, rowIndex1) =>
                                            <div key={rowIndex1}>
                                                <h3>{intl.formatMessage({ id: 'SETTING.ROLES.MODULE.' + module.id })}</h3>
                                                <hr />
                                                {
                                                    createRows(module.list, 3).map((urRows, rowIndex) =>
                                                        <div className="form-group row" key={rowIndex1 + '_' + rowIndex}>
                                                            {
                                                                urRows.map((userRoleRow, index) => (
                                                                    <div className="col-lg-4" key={(rowIndex * 3) + index}>
                                                                        <label>{userRoleRow.title}
                                                                            <br />
                                                                            <Field
                                                                                disabled={readOnly}
                                                                                className="PrivateSwitchBase-input-93"
                                                                                type="checkbox"
                                                                                name="enabledUserFunctionalities"
                                                                                value={`${userRoleRow.id}`}
                                                                            />

                                                                        </label>

                                                                    </div>
                                                                ))
                                                            }

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )

                                    ) : (<></>)}



                                </>
                            )}
                        />
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
                <> </>
            {!actionsLoading && (
                <CheckPermissions
                    userFunctionalities={ufList}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
            </Modal.Footer>
          </>
        )}
          </Formik>
    </>
  );
}
