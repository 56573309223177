import { createSlice } from "@reduxjs/toolkit";

const initialOrganizationsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    officeUserForEdit: undefined,
    error: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const organizationsSlice = createSlice({
    name: "organizations",
    initialState: initialOrganizationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getOrganizationById
        organizationFetched: (state, action) => {
            state.actionsLoading = false;
            state.organizationForEdit = action.payload.organizationForEdit;
            state.error = null;
        },
        // findOrganizations
        organizationsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // update organization
        organizationUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.organization.id) {
                    return action.payload.organization;
                }
                return entity;
            });
        }
    }
});
