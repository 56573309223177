// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { CheckPermissions } from "../../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../../enum/userFunctionalityE"
import { FormattedMessage, useIntl } from "react-intl";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
    { user, openEditCashierDialog, openDeleteCashierDialog, registerDialog, unregisterDialog, openConnectDialog, openDeconnectDialog}
) {
  return (
      <CheckPermissions
          userFunctionalities={[UserFunctionalityE.UrediDavcneBlagajne]}
      >
          {(row.isRegistered && user.organizationNotSentToFurs == false) && (
              <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => unregisterDialog(row.id)}
              >
                  <FormattedMessage id="UNREGISTER" />
              </button>
          )}

          {(!row.isRegistered && user.organizationNotSentToFurs == false) && (
              <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => registerDialog(row.id)}
              >
                  <FormattedMessage id="REGISTER" />
              </button>
          )}
          

          
          {!row.isRegistered && (
              <>
                  <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => openEditCashierDialog(row.id)}
                  >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                          />
                      </span>
                  </a>
                  <> </>
                <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.UrediDavcneBlagajne]}>
                  <a
                      className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                      onClick={() => openDeleteCashierDialog(row.id)}
                  >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                      </span>
                  </a>
                  
                  </CheckPermissions>
                </>
          )}

          {(row.isRegistered || user.organizationNotSentToFurs == true) && row.deviceKey == null && (
              <>

                  <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => openConnectDialog(row.id)}
                  >
                      <FormattedMessage id="CONNECT_DEVICE" />
                  </button>
                </>
          )}

          {(row.isRegistered || user.organizationNotSentToFurs == true) && row.deviceKey != null && (
              <>

                  <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => openDeconnectDialog(row.id)}
                  >
                      <FormattedMessage id="DECONNECT_DEVICE" />
                  </button>
              </>
          )}
        
      </CheckPermissions>
  );
}
