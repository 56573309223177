import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./OfficeUsersUIHelpers";

const OfficeUsersUIContext = createContext();

export function useOfficeUsersUIContext() {
    return useContext(OfficeUsersUIContext);
}

export const OfficeUsersUIConsumer = OfficeUsersUIContext.Consumer;

export function OfficeUsersUIProvider({officeUsersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOfficeUser = {
    id: undefined,
    userName : "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    taxNumber: "",
    foreignOperator: false,
    userRoleId: -1,
    password: "",
    changepassword: ""
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initOfficeUser,
      newOfficeUserButtonClick: officeUsersUIEvents.newOfficeUserButtonClick,
      openEditOfficeUserDialog: officeUsersUIEvents.openEditOfficeUserDialog,
      openDeleteOfficeUserDialog: officeUsersUIEvents.openDeleteOfficeUserDialog,
      openDeleteOfficeUsersDialog: officeUsersUIEvents.openDeleteOfficeUsersDialog
  };

    return <OfficeUsersUIContext.Provider value={value}>{children}</OfficeUsersUIContext.Provider>;
}