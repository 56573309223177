import React, { useMemo } from "react";
import { useUserRolesUIContext } from "../UserRolesUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function UserRolesGrouping() {
  const intl = useIntl();

  // UserRoles UI Context
    const userRolesUIContext = useUserRolesUIContext();
    const userRolesUIProps = useMemo(() => {
    return {
        ids: userRolesUIContext.ids,
        setIds: userRolesUIContext.setIds,
        openDeleteUserRolesDialog: userRolesUIContext.openDeleteUserRolesDialog
    };
    }, [userRolesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{userRolesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                    onClick={userRolesUIProps.openDeleteUserRolesDialog}
              >
                <i className="fa fa-trash"></i><FormattedMessage id="DELETE.ALL" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
