import {createSlice} from "@reduxjs/toolkit";

const initialCodeListsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  codeListForEdit: undefined,
    userFunctionalities: undefined,
    lastError: null,
    deleteResult : null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const codeListsSlice = createSlice({
    name: "codeLists",
    initialState: initialCodeListsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
        state.error = null;
        state.deleteResult = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCodeListById
    codeListFetched: (state, action) => {
        state.actionsLoading = false;
        state.codeListForEdit = action.payload.codeListForEdit;
        state.error = null;
    },
    // codeListSchemasFetched
    codeListSchemasFetched: (state, action) => {
        state.actionsLoading = false;
        state.codelistSchemas = action.payload.codelistSchemas;
        state.error = null;
    },
    // findCodeLists
    codeListsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCodeList
    codeListCreated: (state, action) => {
      state.actionsLoading = false;
        state.error = null;
      state.entities.push(action.payload.model);
    },
    // updateCodeList
    codeListUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.codeList.id) {
              return action.payload.codeList;
        }
        return entity;
      });
      }
  }
});
