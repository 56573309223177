import * as requestFromServer from "./cacheCrud";
import { cacheSlice, callTypes } from "./cacheSlice";

const { actions } = cacheSlice;


export const fetchInit = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getInit()
        .then(response => {
            const { model } = response.data;
            dispatch(actions.allVatRatesFetched(model.vatRates ));
            dispatch(actions.allItemTypesFetched(model.itemTypes));
            dispatch(actions.allUnitsFetched(model.units));
            dispatch(actions.allCashiersFetched(model.cashiers));
            dispatch(actions.allOfficeReportsFetched(model.officeReports));
            dispatch(actions.initStepTutorialsFetched(model.initStepTutorial));
            dispatch(actions.allDocumentTypesFetched(model.documentTypes));
        })
        .catch(error => {
            error.clientMessage = "Can't load init data";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
