import React, { useEffect } from "react";
import { TodayTrafficWidget} from "../dashboards/TodayTrafficWidget";
import { LastMonthTrafficWidget } from "../dashboards/LastMonthTrafficWidget";
import { Last12MonthsTrafficWidget } from "../dashboards/Last12MonthsTrafficWidget";
import { SalesByYearsWidget } from "../dashboards/SalesByYearsWidget";
import { SalesPurchaseWidget } from "../dashboards/SalesPurchaseWidget";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../app/enum/userFunctionalityE"
import * as actions from "../../_redux/dashboards/dashboardActions"
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { BrowserView, MobileView } from 'react-device-detect';

export function Dashboard() {
    const dispatch = useDispatch();

    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );

    useEffect(() => {
        if (currentState.vatRates != null && currentState.vatRates != undefined) {
            Promise.all([
                dispatch(actions.fetchTrafficLast12Months()),
                dispatch(actions.fetchTrafficCurrentMonth()),
                dispatch(actions.fetchSalesByYears()),
                dispatch(actions.fetchTrafficToday()),
                dispatch(actions.fetchSalesPurchaseByYears(new Date().getFullYear()))
            ]);
        }
    }, [currentState.vatRates]);

    return <>
        <div className="row">
            <CheckPermissions
                userFunctionalities={[UserFunctionalityE.PregledPrometaZaDanes]}
            >
                <div className="col-lg-6 col-xxl-4">
                    <TodayTrafficWidget className="card-stretch card-stretch-half gutter-b" />
                </div>
            </CheckPermissions>
            <CheckPermissions
                userFunctionalities={[UserFunctionalityE.PregledPrometaZaTekociMesec]}
            >
                <div className="col-lg-6 col-xxl-4">
                    <LastMonthTrafficWidget className="card-stretch card-stretch-half gutter-b" />
                </div>
            </CheckPermissions>
            <CheckPermissions
                userFunctionalities={[UserFunctionalityE.PregledPrometaVZadnjih12MesecihBrezDdv]}
            >
                <div className="col-lg-6 col-xxl-4">
                    <Last12MonthsTrafficWidget className="card-stretch card-stretch-half gutter-b" />
                </div>
            </CheckPermissions>
        </div>
        <BrowserView>
            <div className="row">
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.PregledProdajePoLetih]}
                >
                    <div className="col-lg-6 col-xxl-6">
                        <SalesByYearsWidget className="card-stretch card-stretch-half gutter-b" />
                    </div>
                </CheckPermissions>
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.PregledProdajeNabavePoLetih]}
                >
                    <div className="col-lg-6 col-xxl-6">
                        <SalesPurchaseWidget className="card-stretch card-stretch-half gutter-b" />
                    </div>
                </CheckPermissions>
            </div>
        </BrowserView>
    </>;
}
