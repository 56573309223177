import axios from "axios";

export function checkIfCashierCustomerNameIsFree(applicationCustomerId, customerName) {
    applicationCustomerId = (applicationCustomerId == undefined || applicationCustomerId == null) ? -1 : applicationCustomerId;
    return axios.post("/api/customer/CheckIfCashierCustomerNameIsFree", { applicationCustomerId, customerName });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
    return axios.post("/api/customer/find", queryParams );
}

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
    return axios.post("/api/customer", customer );
}

export function getCustomerById(customerId) {
    return axios.get("/api/customer/" + customerId );
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
    return axios.put("/api/customer/" + customer.id, customer);
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
    return axios.delete("/api/customer/" + customerId );
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
    return axios.post("/api/customer/deleteCustomers", { ids });
}

export function findByTaxCode(taxcode) {
    return axios.post("/api/customer/FindByTaxCode", { taxcode });
}

export function checkIfCustomerYetExist(id, taxNumber) {
    return axios.post("/api/customer/checkIfCustomerYetExist", {
        id: id == undefined ? -1 : id,
        taxNumber: taxNumber
    });
}