import * as requestFromServer from "./userRolesCrud";
import { userRolesSlice, callTypes } from "./userRolesSlice";

const { actions } = userRolesSlice;

export const fetchUserRoles = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
      .findUserRoles(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
        dispatch(actions.userRolesFetched({ totalCount, entities }));
    })
    .catch(error => {
        error.clientMessage = "Can't find userRoles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserRole = id => dispatch => {
  if (!id) {
      return dispatch(actions.userRoleFetched({ userRoleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .getUserRoleById(id)
      .then(response => {
          const userRole = response.data.model;
          dispatch(actions.userRoleFetched({ userRoleForEdit: userRole }));
    })
    .catch(error => {
        error.clientMessage = "Can't find userRole";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteUserRole = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .deleteUserRole(id)
      .then(response => {
          var { model } = response.data;
          dispatch(actions.userRoleDeleted({ model, id }));
    })
    .catch(error => {
        error.clientMessage = "Can't delete userRole";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUserRole = userRoleForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .createUserRole(userRoleForCreation)
      .then(response => {
          const { model } = response.data;
        dispatch(actions.userRoleCreated({ model }));
    })
    .catch(error => {
        error.clientMessage = "Can't create userRole";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateUserRole = userRole => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .updateUserRole(userRole)
    .then(() => {
        dispatch(actions.userRoleUpdated({ userRole }));
    })
    .catch(error => {
        error.clientMessage = "Can't update userRole";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUserFunctionalities = type => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getAllUserFunctionalities(type)
        .then(response => {
            const userFunctionalities = response.data.entities;
            dispatch(actions.userFunctionalitiesFetched({ userFunctionalities: userFunctionalities }));
        })
        .catch(error => {
            error.clientMessage = "Can't get user functionalities";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
