import axios from "axios";

export function login(username, password) {
    return axios.post("/api/account/login", { username, password });
}

export function logot() {
    return axios.get("/api/account/logout");
}

export function register(firstname, lastname, taxcode, phone, email, username, password) {
    return axios.post("/api/account/register", { firstname, lastname, taxcode, phone, email, username, password });
}

export function requestPassword(email) {
    return axios.post("/api/account/forgot-password", { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
    return axios.get("/api/account/me");
}

export function findByTaxCode(taxcode) {
    return axios.post("/api/customer/FindByTaxCode", { taxcode });
}

export function checkIfUserNameIsFree(userName) {
    return axios.post("/api/account/CheckIfUserNameIsFree", { userName });
}

export function createAccountExistTaxNumber(taxcode) {
    return axios.post("/api/account/CreateAccountExistTaxNumber", { taxcode });
}

export function forgotPassword(userName) {
    return axios.post("/api/account/forgotPassword", { userName });
}

export function resetPassword(userId, code, username, password) {
    return axios.post("/api/account/resetPassword", { userId, code, username, password });
}
