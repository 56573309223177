import {createSlice} from "@reduxjs/toolkit";

const initialOfficeUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  officeUserForEdit: undefined,
  error: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const officeUsersSlice = createSlice({
  name: "officeUsers",
    initialState: initialOfficeUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUserById
    officeUserFetched: (state, action) => {
      state.actionsLoading = false;
        state.officeUserForEdit = action.payload.officeUserForEdit;
      state.error = null;
    },
    // findUsers
    officeUsersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUser
    officeUserCreated: (state, action) => {
        state.actionsLoading = false;
      state.error = null;
        state.entities.push(action.payload.model);
        state.totalCount++;

    },
    // updateUser
    officeUserUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.officeUser.id) {
            return action.payload.officeUser;
        }
        return entity;
      });
    },
    // deleteUser
    officeUserDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUsers
    officeUsersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // usersUpdateState
    officeUsersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // findUserRoles
    userRolesFetched: (state, action) => {
        const {  entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.userRoles = entities;
    },
  }
});
