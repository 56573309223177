import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import { initialFilter } from "./CodeListsUIHelpers";

const CodeListsUIContext = createContext();

export function useCodeListsUIContext() {
    return useContext(CodeListsUIContext);
}

export const CodeListsUIConsumer = CodeListsUIContext.Consumer;

export function CodeListsUIProvider({  codeListsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initCodeList = {
        id: undefined,
        code: "",
        name: "",
        description: "",
        active: true,
        codelistSchemaId: -1
    };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initCodeList,
      newCodeListButtonClick: codeListsUIEvents.newCodeListButtonClick,
      openEditCodeListDialog: codeListsUIEvents.openEditCodeListDialog
  };

    return <CodeListsUIContext.Provider value={value}>{children}</CodeListsUIContext.Provider>;
}