// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect } from 'react';

export default function InputCount(props) {
    const [value, setValue] = useState(props.value);

    const setNewValue = value => {
        if (props.disabled == true) {
            return;
        }
        if ((props.maxValue == undefined || props.maxValue >= value) &&
            (props.minValue == undefined || props.minValue <= value)) {
            setValue(value);
            if (props.onChange != undefined) {
                props.onChange(this, value);
            }
        }
    };

    return (
        <>
            <a
                href="#"
                className={props.disabled == true ? "btn btn-xs btn-icon mr-2" : "btn btn-xs btn-light-success btn-icon mr-2"}
                onClick={() => { setNewValue(props.value - 1); }}
            >
                <i className="ki ki-minus icon-xs"></i>
            </a>

            <span className="font-weight-bold mr-2 text-dark-75 font-size-3">
                {props.value}
                            </span>
            <a href="#"
                className={props.disabled == true ? "btn btn-xs btn-icon mr-2" : "btn btn-xs btn-light-success btn-icon mr-2"}
                onClick={() => { setNewValue(props.value+1); }}
            >
                <i className="ki ki-plus icon-xs"></i>
            </a>
        </>
    );
}
