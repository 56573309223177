import React, { useMemo } from "react";
import { useCustomersUIContext } from "../CustomersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"

export function CustomersGrouping() {
  const intl = useIntl();

  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
        ids: customersUIContext.ids,
        setIds: customersUIContext.setIds,
        openCustomerTraffictDialog: customersUIContext.openCustomerTraffictDialog,
        openCustomerTakeoversDialog: customersUIContext.openCustomerTakeoversDialog
    };
  }, [customersUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{customersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
                {customersUIProps.ids.length == 1 &&
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.PregledPrometaStrank]}>
                    <button
                        type="button"
                        className="btn btn-light-primary font-weight-bolder font-size-sm"
                        onClick={customersUIProps.openCustomerTraffictDialog}
                    >
                        <i className="fa fa-print"></i><FormattedMessage id="CUSTOMER.TRAFFIC" />
                    </button>
                </CheckPermissions>
                }
                {customersUIProps.ids.length == 1 &&
                    <>
                        &nbsp;
                        <CheckPermissions
                            userFunctionalities={[UserFunctionalityE.PregledSeznamovPrevzemov]}>
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bolder font-size-sm"
                                onClick={customersUIProps.openCustomerTakeoversDialog}
                            >
                                <i className="fa fa-print"></i><FormattedMessage id="CUSTOMER.TAKEOVERS" />
                            </button>
                        </CheckPermissions>
                    </>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
