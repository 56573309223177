import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ItemGroupsUIHelpers";

const ItemGroupsUIContext = createContext();

export function useItemGroupsUIContext() {
    return useContext(ItemGroupsUIContext);
}

export const ItemGroupsUIConsumer = ItemGroupsUIContext.Consumer;

export function ItemGroupsUIProvider({itemGroupsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);


    const initItemGroup = {
        id: undefined,
        title: "",
        background: "#96BAF6",
        foreground: "#EFDCDC",
        fontSize: 14,
        order: -1,
        active: true,
        appearInCashier: true
    };
  
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initItemGroup,
      newItemGroupButtonClick: itemGroupsUIEvents.newItemGroupButtonClick,
      openEditItemGroupDialog: itemGroupsUIEvents.openEditItemGroupDialog,
      openDeleteItemGroupDialog: itemGroupsUIEvents.openDeleteItemGroupDialog,
      openDeleteItemGroupsDialog: itemGroupsUIEvents.openDeleteItemGroupsDialog,
      openUpItemGroup: itemGroupsUIEvents.openUpItemGroup,
      openDownItemGroup: itemGroupsUIEvents.openDownItemGroup
  };

    return <ItemGroupsUIContext.Provider value={value}>{children}</ItemGroupsUIContext.Provider>;
}