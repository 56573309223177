import axios from "axios";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCodeLists(queryParams) {
    return axios.post("/api/codeList/find", queryParams );
}

// CREATE =>  POST: add a new user to the server
export function createCodeList(codeList) {
    return axios.post("/api/codeList", codeList );
}

export function getCodeListById(codeListId) {
    return axios.get("/api/codeList/" + codeListId );
}

// UPDATE => PUT: update the user on the server
export function updateCodeList(codeList) {
    return axios.put("/api/codeList/" + codeList.id, codeList);
}

export function getCodeListSchemas() {
    return axios.get("/api/codelistSchema");
}

