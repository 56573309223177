import React, { useMemo } from "react";
import { useItemGroupsUIContext } from "../ItemGroupsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function ItemGroupsGrouping() {
  const intl = useIntl();

  // ItemGroups UI Context
  const itemGroupsUIContext = useItemGroupsUIContext();
  const itemGroupsUIProps = useMemo(() => {
    return {
        ids: itemGroupsUIContext.ids,
        setIds: itemGroupsUIContext.setIds,
        openDeleteItemGroupsDialog: itemGroupsUIContext.openDeleteItemGroupsDialog
    };
  }, [itemGroupsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{itemGroupsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={itemGroupsUIProps.openDeleteItemGroupsDialog}
              >
                <i className="fa fa-trash"></i><FormattedMessage id="DELETE.ALL" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
