/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AutocompletePost from '../../../../components/AutocompletePost'

export default function CompanyPayment(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { organization, error } = useSelector(
        (state) => ({
            organization: state.organization.organizationForEdit,
            error: state.organization.error
        }),
        shallowEqual
    );
    const [invoiceSendToMail, setInvoiceSendToMail] = useState([0]);
    const [post, setPost] = useState(organization != undefined && organization.invoicePostCode != null ?
        { code: organization.invoicePostCode, title: organization.invoicePostTitle } : null);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediVasePodjetje]);

    useEffect(() => {
        setPost(organization != undefined && organization.invoicePostCode != null ?
            { code: organization.invoicePostCode, title: organization.invoicePostTitle } : null);
        setInvoiceSendToMail(organization != undefined && organization.invoiceSendToMail == true ? [1] : [0]);
    }, [organization]);
    // Methods
    const saveOrganization = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);

        var obj = {
            address: values.address,
            appModulePurchase: values.appModulePurchase,
            appModuleSales: values.appModuleSales,
            description: values.description,
            id: values.id,
            name: values.name,
            postCode: values.postCode,
            postTitle: values.postTitle,
            registrationNumber: values.registrationNumber,
            taxDuty: values.taxDuty,
            taxNumber: values.taxNumber,
            town: values.town,
            bankName: values.bankName,
            bankIban: values.bankIban,
            bankBic: values.bankBic,
            invoiceSendToMail: invoiceSendToMail[0] == 1,
            invoiceEmail: values.invoiceEmail,
            invoiceAddress: values.invoiceAddress,
            invoicePostCode: post.code,
            invoicePostTitle: post.title,
            logoUniqueId: values.logoUniqueId,
            officeUserCount: values.officeUserCount,
            cashierUserCount: values.cashierUserCount,
            cashierCount: values.cashierCount,
            minOfficeUserCount: values.minOfficeUserCount,
            minCashierUserCount: values.minCashierUserCount,
            minCashierCount: values.minCashierCount,
            deactiveReason: values.deactiveReason,
            deactiveDate: values.deactiveDate,
            confirmed: values.confirmed,
            contractExpirationDate: values.contractExpirationDate
        };
          
        dispatch(actions.updateOrganization(obj)).then(() => {
            setIsError(error != null);
            setIsSuccess(error == null);
            setLoading(false);
            setSubmitting(false);
        });
    };
    // UI Helpers
    const initialValues = {
        address: organization != undefined && organization != null ? organization.address : "",
        appModulePurchase: organization && organization != null != undefined ? organization.appModulePurchase : false,
        appModuleSales: organization && organization != null != undefined ? organization.appModuleSales : false,
        description: organization && organization != null != undefined ? organization.description : "",
        id: organization && organization != null != undefined ? organization.id : -1,
        name: organization && organization != null != undefined ? organization.name : "",
        postCode: organization && organization != null != undefined ? organization.postCode : 0,
        postTitle: organization && organization != null != undefined ? organization.postTitle : "",
        registrationNumber: organization && organization != null != undefined ? organization.registrationNumber : "",
        taxDuty: organization != undefined && organization != null ? organization.taxDuty : false,
        taxNumber: organization != undefined && organization != null ? organization.taxNumber : "",
        town: organization != undefined && organization != null ? organization.town : "",
        bankName: organization != undefined && organization != null && organization.bankName != null ? organization.bankName : "",
        bankIban: organization != undefined && organization != null && organization.bankIban != null ? organization.bankIban : "",
        bankBic: organization != undefined && organization != null && organization.bankBic != null ? organization.bankBic : "",
        invoiceSendToMail: organization && organization != null != undefined ? organization.invoiceSendToMail : false,
        invoiceEmail: organization && organization != null != undefined && organization.invoiceEmail != null ? organization.invoiceEmail : "",
        invoiceAddress: organization && organization != null != undefined && organization.invoiceAddress != null ? organization.invoiceAddress : "",
        invoicePostCode: organization && organization != null != undefined && organization.invoicePostCode != null ? organization.invoicePostCode : 0,
        invoicePostTitle: organization && organization != null != undefined && organization.invoicePostTitle != null ? organization.invoicePostTitle : "",
        logoUniqueId: organization && organization != null != undefined ? organization.logoUniqueId : "",
        officeUserCount: organization != undefined && organization != null ? organization.officeUserCount : 1,
        cashierUserCount: organization != undefined && organization != null ? organization.cashierUserCount : 1,
        cashierCount: organization != undefined && organization != null ? organization.cashierCount : 1,
        minOfficeUserCount: organization != undefined && organization != null ? organization.minOfficeUserCount : 1,
        minCashierUserCount: organization != undefined && organization != null ? organization.minCashierUserCount : 1,
        minCashierCount: organization != undefined && organization != null ? organization.minCashierCount : 1,
        deactiveReason: organization != undefined && organization != null && organization.deactiveReason != null ? organization.deactiveReason : "",
        deactiveDate: organization != undefined && organization != null && organization.deactiveDate != null ? organization.deactiveDate : "",
        contractExpirationDate: organization != undefined && organization != null && organization.contractExpirationDate != null ? organization.contractExpirationDate : null,
        confirmed: organization != undefined && organization != null ? organization.confirmed : false
    };

    const Schema = Yup.object().shape({
        invoiceEmail: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                if (invoiceSendToMail[0] == 1) {
                    return value != undefined && value != null && value.length > 0;
                }
                return true;
            }),
        invoiceAddress: Yup.string()
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                if (invoiceSendToMail[0] == 0) {
                    return value != undefined && value != null && value.length > 0;
                }
                return true;
            })
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    function handleChangeInvoiceSendToMail(event, value) {
        setInvoiceSendToMail([parseInt(event.currentTarget.value)]);
        document.getElementById("frmCompanyPayment").reset();
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveOrganization(values, setStatus, setSubmitting);
        },
        onReset: () => {
            setIsError(false);
            setIsSuccess(false);
            setPost(organization != undefined && organization.invoicePostCode != null ?
                { code: organization.invoicePostCode, title: organization.invoicePostTitle } : null);
        }
    });

    return (
        <form id="frmCompanyPayment" className="card card-custom" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="COMPANY.NAVIGATION.PAY" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="COMPANY.NAVIGATION.PAY_2" />
          </span>
                </div>
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || post == null || (formik.touched && !formik.isValid) 
                            }
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
                {formik.isSubmitting}
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="reset"
                        >
                            <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                        </button>
                    </div>
                </CheckPermissions>

            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_PAYMENT" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_PAYMENT" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <div className="col-lg-3 col-xl-3">
                        </div>
                        <div className="col-lg-6 col-xl-6">

                            <ToggleButtonGroup
                                type="checkbox"
                                value={invoiceSendToMail}
                                onChange={handleChangeInvoiceSendToMail}
                            >
                                <ToggleButton value={1}><FormattedMessage id="AUTH.GENERAL.SEND_BY_MAIL" /></ToggleButton>
                                <ToggleButton value={0}><FormattedMessage id="AUTH.GENERAL.SEND_BY_POST" /></ToggleButton>
                            </ToggleButtonGroup>
                            
                        </div>
                        <div className="col-lg-3 col-xl-3">
                        </div>
                    </div>
                    {invoiceSendToMail[0] == 1 && (
                        <>
                            <div className="form-group row">
                                <h4><FormattedMessage id="AUTH.GENERAL.PAYMENT_EMAIL_TITLE" /></h4>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.GENERAL.PAYMENT_EMAIL" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        disabled={readOnly}
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PAYMENT_EMAIL' })}
                                        className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                            "invoiceEmail"
                                        )}`}
                                        name="invoiceEmail"
                                        {...formik.getFieldProps("invoiceEmail")}
                                    />
                                    {formik.touched.invoiceEmail &&
                                        formik.errors.invoiceEmail ? (
                                            <div className="invalid-feedback">
                                                {formik.errors.invoiceEmail}
                                            </div>
                                        ) : null}
                                </div>
                            </div> 
                        </>
                        )}

                    {invoiceSendToMail[0] == 0 && (
                        <>
                            <div className="form-group row">
                                <h4><FormattedMessage id="AUTH.GENERAL.PAYMENT_ADDRESS_TITLE" /></h4>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.GENERAL.PAYMENT_POST_ADDRES" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        disabled={readOnly}
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PAYMENT_POST_ADDRES' })}
                                        className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                            "invoiceAddress"
                                        )}`}
                                        name="invoiceAddress"
                                        {...formik.getFieldProps("invoiceAddress")}
                                    />
                                    {formik.touched.invoiceAddress &&
                                        formik.errors.invoiceAddress ? (
                                            <div className="invalid-feedback">
                                                {formik.errors.invoiceAddress}
                                            </div>
                                        ) : null}
                                </div>
                            </div>


                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.GENERAL.POST" />
                                </label>

                                <div className="col-lg-9 col-xl-6">

                                    <AutocompletePost
                                        name="invoicePostCode"
                                        value={post}
                                        onChange={(val) => { setPost(val); }}
                                    />

                                </div>
                            </div>

                        </>                        
                        )}
                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}

