import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual, isFunction } from "lodash";
import moment from 'moment'
import { useCookies } from "react-cookie";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Formik } from "formik";
import AutocompleteWarehouse from '../../../../components/AutocompleteWarehouse';
import PdfViewer from "../../../../components/PdfViewer"

export function ReportStockItemsPage({ history }) {
    const intl = useIntl();
    const { user } = useSelector(state => state.auth);
    const [cookies, setCookie] = useCookies();
    const [warehouse, setWarehouse] = useState(cookies['filter_dt_reportStockItemsPage' + '_' + user.id] != undefined ? cookies['filter_dt_reportStockItemsPage' + '_' + user.id] : []);

    const [itemText, setItemText] = useState("");
    const [itemText1, setItemText1] = useState("");
    const [intervalItemText, setIntervalItemText] = useState(-1);

    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

    const applyFilter = (values) => {



    };

  return (
      <>
          <Card>
              <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.REPORT_STOCK_ITEMS" })} >
                  <CardHeaderToolbar>
                  </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                  <Formik
                      initialValues={{
                          status: "",
                          type: "",
                          supplierText: "",
                      }}
                      onSubmit={(values) => {
                          applyFilter(values);
                      }}
                  >
                      {({
                          values,
                          handleSubmit,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                      }) => (
                              <form onSubmit={handleSubmit} className="form form-label-right">
                              <div className="form-group row">
                                  <div className="col-lg-4">
                                      <AutocompleteWarehouse
                                          name="warehouseId"
                                          value={warehouse}
                                          onChange={(val) => {
                                              var current = new Date();
                                              var nextYear = new Date();
                                              nextYear.setFullYear(current.getFullYear() + 5);

                                              setCookie('filter_dt_reportStockItemsPage' + '_' + user.id, val, {
                                                  path: '/',
                                                  expires: nextYear
                                              });
                                              setWarehouse(val);
                                              handleSubmit();
                                          }}
                                      />
                                      <small className="form-text text-muted">
                                          <b><FormattedMessage id="AUTH.GENERAL.CHOOSE" /></b> <FormattedMessage id="AUTH.GENERAL.WAREHOUSE_SMALL" />
                                      </small>
                                  </div>
                                  <div className="col-lg-4">
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="searchText"
                                          placeholder={intl.formatMessage({
                                              id: "AUTH.GENERAL.ITEM"
                                          })}
                                          onBlur={handleBlur}
                                          value={itemText1}
                                          onChange={(e) => {
                                              var value = e.target.value;
                                              setItemText1(e.target.value);
                                              if (intervalItemText > 0) {

                                                  clearTimeout(intervalItemText);
                                              }
                                              setIntervalItemText(setTimeout(function () {
                                                  setItemText(value);
                                              }, 600));
                                          }}
                                      />
                                      <small className="form-text text-muted">
                                          <b><FormattedMessage id="AUTH.GENERAL.INSERT" /></b> <FormattedMessage id="AUTH.GENERAL.ITEM2" />
                                      </small>
                                  </div>
                                  <div className="col-lg-4">
                                          <input
                                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PRICE_DATE' })}
                                              type="date"
                                              value={endDate}
                                              className={`form-control`}
                                              name="endDate"
                                              onChange={(event, val) => {
                                                  setEndDate(event.target.value);
                                                  handleSubmit();
                                              }}
                                          />
                                          <small className="form-text text-muted">
                                              <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.DATES" />
                                          </small>
                                      </div>

                                  </div>
                              </form>
                          )}
                  </Formik>

                  <PdfViewer
                      url={'../../../API/OfficeReport/ReportStockItems?end=' + endDate + '&id=' + (warehouse != null && warehouse != undefined && warehouse.id != null && warehouse.id != undefined ? warehouse.id : -1) + '&item=' + itemText }
                  />

              </CardBody>
          </Card>
          </>
  );
}
