// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";

export function ActiveColumnFormatter(
  cellContent,
  row,
  rowIndex,
) {

  return (
      <>
          {cellContent &&
              <span className="label label-lg label-light-success label-inline"><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" /></span>}

          {!cellContent &&
              <span className="label label-lg label-light-danger label-inline"><FormattedMessage id="ECOMMERCE.COMMON.INACTIVE" /></span>}
          
    </>
  );
}
