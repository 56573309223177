import React from "react";
import { Route } from "react-router-dom";
import { ActiveUsersLoadingDialog } from "./activeUsers-loading-dialog/ActiveUsersLoadingDialog";
import { ActiveUserEditDialog } from "./activeUser-edit-dialog/ActiveUserEditDialog";
import { ActiveUsersUIProvider } from "./ActiveUsersUIContext";
import { ActiveUsersCard } from "./ActiveUsersCard";

export function ActiveUsersPage({ history }) {
  const activeUsersUIEvents = {
    openEditActiveUserDialog: (id) => {
          history.push(`/admin/active_users/${id}/connect`);
    },
  }
  return (
      <ActiveUsersUIProvider activeUsersUIEvents={activeUsersUIEvents}>
        <ActiveUsersLoadingDialog />

          <Route path="/admin/active_users/:id/connect">
        {({ history, match }) => (

            <ActiveUserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/admin/active_users");
            }}
          />
        )}
      </Route>
   
          
          
      <ActiveUsersCard />
      </ActiveUsersUIProvider>
  );
}
