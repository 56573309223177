import React, { useState } from "react";
import { Route } from "react-router-dom";
import { ItemsLoadingDialog } from "./items-loading-dialog/ItemsLoadingDialog";
import { ItemEditDialog } from "./item-edit-dialog/ItemEditDialog";
import { ItemDeleteDialog } from "./item-delete-dialog/ItemDeleteDialog";
import { ItemsDeleteDialog } from "./items-delete-dialog/ItemsDeleteDialog";
import { ItemsUIProvider } from "./ItemsUIContext";
import { ItemsCard } from "./ItemsCard";
import { ItemStockMovementPrintDialog } from "./item-stock-movement-print-dialog/ItemStockMovementPrintDialog";

export function ItemsPage({ history }) {

  const itemsUIEvents = {
    newItemButtonClick: () => {
        history.push("/items/new");
         
    },
    openEditItemDialog: (id) => {
        history.push(`/items/${id}/edit`);
    },
    openDeleteItemDialog: (id) => {
        history.push(`/items/${id}/delete`);
    },
      openItemStockMovementDialog: () => {
          history.push(`/items/printItemStockMovement`);
      }
  }

  return (
      <ItemsUIProvider itemsUIEvents={itemsUIEvents}>
        <ItemsLoadingDialog />
          <Route path="/items/new">
        {({ history, match }) => (
           <ItemEditDialog
            show={match != null}
            onHide={() => {
                history.push("/items");
            }}
          />
        )}
      </Route>
          <Route path="/items/:id/edit">
        {({ history, match }) => (
            <ItemEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/items");
            }}
          />
        )}
      </Route>
          <Route path="/items/printItemStockMovement">
              {({ history, match }) => (
                  <ItemStockMovementPrintDialog
                      show={match != null}
                      onHide={() => {
                          history.push("/items");
                      }}
                  />
              )}
          </Route>
          <Route path="/items/:id/delete">
        {({ history, match }) => (
            <ItemDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/items");
            }}
          />
        )}
      </Route>
          
      <ItemsCard />
      </ItemsUIProvider>
  );
}
