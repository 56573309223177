// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InputCount from '../../../../../components/InputCount'
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export function ActiveUserEditForm({
    saveReasonLogin,
    reason,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    
    useEffect(() => {
    
    }, [user]);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.AktivniUporabniki]);

    // Validation schema
    const ActiveUserSchema = Yup.object().shape({
        reason: Yup.string()
            .max(500, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_500_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
    });

  return (
    <>
      <Formik
        enableReinitialize={true}
              initialValues={reason}
              validateOnChange={false}
              validationSchema={ActiveUserSchema}
        onSubmit={(values) => {
            
            saveReasonLogin(values);
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                
                <div className="form-group row">
                    <div className="col-lg-12">
                        <label><FormattedMessage id="ECOMMERCE.COMMON.REASON" /></label>
                        <br />
                        <Field
                            disabled={readOnly}
                            style={{ width: "100%" }}
                            name="reason"
                            type="text"
                            component={ Input}
                            placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.REASON' })}
                            label=""
                            customFeedbackLabel=" "

                        />
                    </div>
                </div>          

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
                <> </>
                <CheckPermissions
                    activeUserFunctionalities={[UserFunctionalityE.Organizacije]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                    </button>
                </CheckPermissions>
            </Modal.Footer>
          </>
        )}
          </Formik>
    </>
  );
}
