// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import { shallowEqual, useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import { useTheme } from '@material-ui/core/styles';
import { useIntl } from "react-intl";

function getStyles(option, value, theme) {
    return {
        fontWeight:
            value.indexOf(option.id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultiSelectCashier(props) {
    const theme = useTheme();
    const intl = useIntl();

    function getCashierById(id) {
        var tmpCashiers = getCashiers();
        for (var index = 0; index < tmpCashiers.length; index++) {
            var el = tmpCashiers[index];
            if (el.id == id) {
                return el;
            }
        }
        return tmpCashiers[0];
    }

    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { cashiers } = currentState;

    function getCashiers() {
        var list = [];

        list.push({
            id: -1,
            businessPremiseTitle: intl.formatMessage({ id: 'AUTH.INPUT.WITHOUT_CASHIER' })
        });

        if (cashiers != undefined && cashiers != null) {
            for (var x = 0; x < cashiers.length; x++) {
                list.push(cashiers[x]);
            }
        }

        return list;
    }

    return (
        <>
            <Select
                multiple
                value={props.value}
                onChange={(event) => { props.onChange(event.target.value); }}
                className={`form-control form-control-solid }`}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                    <div>
                        {selected.map(value => (
                            <Chip key={value} label={getCashierById(value).businessPremiseTitle}  />
                        ))}
                    </div>
                )}
            >
                {getCashiers().map(option => (
                    <MenuItem key={option.id} value={option.id} style={getStyles(option, props.value, theme)}>
                        {option.businessPremiseTitle}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
