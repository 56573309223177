import * as requestFromServer from "./officeUsersCrud";
import { officeUsersSlice, callTypes } from "./officeUsersSlice";

const { actions } = officeUsersSlice;

export const fetchOfficeUsers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
      .findOfficeUsers(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.officeUsersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find office users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOfficeUser = id => dispatch => {
  if (!id) {
      return dispatch(actions.officeUserFetched({ officeUserForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOfficeUserById(id)
      .then(response => {
          const officeUser = response.data.model;
          if (officeUser.taxNumber == null) {
              officeUser.taxNumber = "";
          }
          officeUser.changepassword = "";
          dispatch(actions.officeUserFetched({ officeUserForEdit: officeUser }));
    })
    .catch(error => {
      error.clientMessage = "Can't find office user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOfficeUser = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .deleteOfficeUser(id)
    .then(response => {
        dispatch(actions.officeUserDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete office user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOfficeUser = officeUserForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .createOfficeUser(officeUserForCreation)
      .then(response => {
        const { model } = response.data;
          dispatch(actions.officeUserCreated({ model }));
    })
    .catch(error => {
      error.clientMessage = "Can't create office user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOfficeUser = officeUser => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .updateOfficeUser(officeUser)
    .then(() => {
        dispatch(actions.officeUserUpdated({ officeUser }));
    })
    .catch(error => {
      error.clientMessage = "Can't update office user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOfficeUsers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOfficeUsers(ids)
    .then(() => {
        dispatch(actions.officeUsersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete office users";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOfficeUserRoles = type => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getOfficeUserRoles(type)
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.userRolesFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find userRoles";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
