import React, {useEffect, Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { logot } from "../_redux/authCrud";
import { shallowEqual, useSelector } from "react-redux";


function Relogin(props) {
    const { hasAuthToken } = props;
    const { token } = useSelector(
        (state) => ({
            token: state.activeUsers.token
        }),
        shallowEqual
    );

    useEffect(() => {
        props.logout();
        logot().then(() => {
            props.login(token);
        });
    });

    return (<>
        {hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/dashboard" />}
    </>);
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Relogin);
