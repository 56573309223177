import * as requestFromServer from "./exportCrud";
import { exportSlice, callTypes } from "./exportSlice";
import moment from 'moment';

const { actions } = exportSlice;

export const fetchLastExportData = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getLastExportData()
        .then(response => {
            const exportData = response.data.model;
            var date = new Date();
            exportData.toDate = moment(date).format("YYYY-MM-DD");
            exportData.fromDate = moment(date.setMonth(date.getMonth() - 1)).format("YYYY-MM-DD");
            dispatch(actions.lastExportDataFetched({ exportDataForEdit: exportData }));
        })
        .catch(error => {
            error.clientMessage = "Can't find last export data";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchExperts = queryParams => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findExports(queryParams)
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.expertsFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find experts";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};