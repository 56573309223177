import React, { useEffect, useState, useCallback } from "react";
import { Route, useLocation } from "react-router-dom";
import { DocumentsLoadingDialog } from "../../../Document/pages/documents/documents-loading-dialog/DocumentsLoadingDialog";
import { DocumentEditDialog } from "../../../Document/pages/documents/document-edit-dialog/DocumentEditDialog";
import { DocumentHistoryDialog } from "../../../Document/pages/documents/document-history-dialog/DocumentHistoryDialog";
import { DocumentDeleteDialog } from "../../../Document/pages/documents/document-delete-dialog/DocumentDeleteDialog";
import { DocumentsPrintDialog } from "../../../Document/pages/documents/documents-print-dialog/DocumentsPrintDialog";
import { DocumentsPrintStornoDialog } from "../../../Document/pages/documents/documents-print-storno-dialog/DocumentsPrintStornoDialog";
import { DocumentsCard } from "../../../Document/pages/documents/DocumentsCard";
import { DocumentsUIProvider } from "../../../Document/pages/documents/DocumentsUIContext";
import { shallowEqual, useSelector } from "react-redux";
import InfoPopup from "../../../Helpers/InfoPopup"
import { useIntl } from "react-intl";
import { isEqual, isFunction } from "lodash";
import moment from 'moment'
import { hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"
import { useCookies } from "react-cookie";

export function CreditsPage({ history }) {
    const location = useLocation();
    const intl = useIntl();
    const [cookies, setCookie] = useCookies();
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const { currentState } = useSelector(
        (state) => ({ currentState: state.documents }),
        shallowEqual
    );

    useEffect(() => {
        if (location.state != undefined && location.state.ids != undefined && location.state.ids.length > 0) {
            setTimeout(function () {
                history.push(`/sales/credits/send`, location.state);
            }, 0);
        }
    }, []);

  const takeoversUIEvents = {
        newDocumentButtonClick: () => {
            history.push("/sales/credits/new");
        },
        openEditDocumentDialog: (id) => {
            history.push(`/sales/credits/${id}/edit`);
        },
        openDeleteDocumentDialog: (id) => {
            history.push(`/sales/credits/${id}/delete`);
        },
        printDocumentsDialog: () => {
            history.push(`/sales/credits/printCredits`);
      },
      printDocumentDialog: (id) => {
          history.push(`/sales/credits/${id}/printCredit`);
      },
          printStornoDocumentsDialog: (id) => {
              history.push(`/sales/credits/${id}/printStornoCredits/`);
          },
          copyDocumentButtonClick: (id) => {
              history.push(`/sales/credits/${id}/copy`);
      },
      historyDocumentDialog: (id) => {
          history.push(`/sales/credits/${id}/history`);
      }
    }

    const documentType = 5;
    const { user } = useSelector(state => state.auth);

    const initialFilter = {
        documentType: documentType,
        query: "",
        sortOrder: "desc", // asc||desc
        sortField: "id",
        pageNumber: 1,
        pageSize: 25,
        startDate: moment(new Date(new Date().setMonth(new Date().getMonth() - 1))).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        cashierIds: function () {
            var list = cookies['filter_dt_' + documentType + '_' + user.id] != undefined ? cookies['filter_dt_' + documentType + '_' + user.id] : [];
            var ids = "";
            for (var index = 0; index < list.length; index++) {
                if (ids != "") {
                    ids += ",";
                }
                ids += list[index];
            }
            return ids;
        }()
    };

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initDocument = {
        id: undefined,
        type: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        time: moment(new Date()).format("HH:mm"),
        serviceDate: "",
        dateOfServicesRendered: "",
        dueDate: moment(new Date()).format("YYYY-MM-DD"),
        customer: null,
        customerId: null,
        discountProcent: "",
        discountAmount: "",
        note: "",
        note2: "",
        warehouseId: null,
        warehouse: null,
        warehouse2Id: null,
        warehouse2: null,
        otherDocumentNumber: "",
        documentPosition: [],
        stornoDocument: null,
        stornoDocumentId: null,
        paymentDeadlineNumDays: "8",
        valid: ""
    };

    for (var x = 0; x < 5; x++) {
        initDocument.documentPosition.push({
            item: null,
            vatRateId: 1,
            quantity: 0,
            discount: 0,
            itemAmountWithoutVat: 0,
            totalAmountWithoutVat: 0,
            totalAmountWithVat: 0,
            grossWeight: 0,
            isCustomItem: false,
            itemTitle: "",
            unitId: null,
            length: 0,
            radius: 0,
            cubic: 0
        });
    }

    const translate = {
        header: intl.formatMessage({ id: "CREDIT.HEADER" }),
        newHeader: intl.formatMessage({ id: "CREDIT.NEW.HEADER" }),
        editHeader: intl.formatMessage({ id: "CREDIT.EDIT.HEADER" }),
        copyHeader: intl.formatMessage({ id: "CREDIT.COPY.HEADER" }),
        viewHeader: intl.formatMessage({ id: "CREDIT.VIEW.HEADER" }),
        historyHeader: intl.formatMessage({ id: "CREDIT.HISTORY.HEADER" })
    };

    const gridColumnsVisible = {
        id: true,
        fiscalCashierBusinessUnitTitle: true,
        talkingNumber: true,
        type: false,
        documentTypeTitle: false,
        customerName: true,
        dateTime: true,
        creatorId: false,
        creatorTitle: true,
        amountForPayment: true,
        endId: false,
        endTalkingNumber: false,
        documentStornoId: false,
        documentStornoTalkingNumber: true,
        payments: false,
        print: true,
        history: true
    };

    const formFieldsVisible = {
        date: true,
        time: false,
        serviceDate: false,
        dateOfServicesRendered: false,
        dueDate: false,
        customer: true,
        supplier: false,
        discountProcent: true,
        discountAmount: false,
        note: false,
        warehouseId: true,
        warehouse2Id: false,
        otherDocumentNumber: false,
        paymentDeadlineNumDays: false,
        //result fields
        amountForPaymentBase: true,
        amountVat: true,
        amountForPayment: true,
        amountDiscount: true,
    };

    const formTranslate = {
        date: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
        time: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
        serviceDate: intl.formatMessage({ id: "AUTH.GENERAL.SERVICE_DATE" }),
        dateOfServicesRendered: intl.formatMessage({ id: "AUTH.GENERAL.SERVICE_DATE" }),
        dueDate: intl.formatMessage({ id: "AUTH.GENERAL.DUE_DATE" }),
        customer: intl.formatMessage({ id: "AUTH.GENERAL.BUYER" }),
        supplier: intl.formatMessage({ id: "AUTH.GENERAL.SUPPLIER" }),
        discountProcent: intl.formatMessage({ id: "AUTH.GENERAL.DISCOUNT_PROCENT" }),
        discountAmount: intl.formatMessage({ id: "AUTH.GENERAL.DISCOUNT_AMOUNT" }),
        note: intl.formatMessage({ id: "AUTH.GENERAL.NOTE" }),
        warehouseId: intl.formatMessage({ id: "AUTH.GENERAL.WAREHOUSE" }),
        warehouse2Id: intl.formatMessage({ id: "AUTH.GENERAL.WAREHOUSE2" }),
        //result fields
        amountForPaymentBase: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_FOR_PAYMENT_BASE" }),
        amountVat: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_VAT" }),
        amountForPayment: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_FOR_PAYMENT" }),
        amountDiscount: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_DISCOUNT" }),
        amountOfPayment: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_OF_PAYMENT" }),
        otherDocumentNumber: intl.formatMessage({ id: "AUTH.GENERAL.OTHER_DOCUMENT_NUMBER" }),
        paymentDeadlineNumDays: intl.formatMessage({ id: "AUTH.GENERAL.PAYMENT_DEADLINE_NUM_DAYS" })
    };

    const gridPositionColumnsVisible = {
        itemGoods: false,
        itemAll: true,
        vat: true,
        quantity: true,
        discount: true,
        itemAmountWithoutVat: true,
        totalAmountWithoutVat: true,
        totalAmountWithVat: true,
        grossWeight: false,
        stock: false,
        isCustomItem: true,
        cubic: user.organizationCubic
    };

    const gridPositionColumnsTranslate = {
        item: intl.formatMessage({ id: "AUTH.GENERAL.ITEM" }),
        vat: intl.formatMessage({ id: "AUTH.GENERAL.VAT_RATE" }),
        quantity: intl.formatMessage({ id: "AUTH.GENERAL.QUANTITY" }),
        discount: intl.formatMessage({ id: "AUTH.GENERAL.DISCOUNT_PROCENT" }),
        itemAmountWithoutVat: intl.formatMessage({ id: "AUTH.GENERAL.ITEM_AMOUNT_WITHOUT_VAT" }),
        totalAmountWithoutVat: intl.formatMessage({ id: "AUTH.GENERAL.TOTAL_AMOUNT_WITHOUT_VAT" }),
        totalAmountWithVat: intl.formatMessage({ id: "AUTH.GENERAL.TOTAL_AMOUNT_WITH_VAT" }),
        grossWeight: intl.formatMessage({ id: "AUTH.GENERAL.GROSS_WEIGHT" }),
        stock: intl.formatMessage({ id: "AUTH.GENERAL.STOCK" }),
        isCustomItem: intl.formatMessage({ id: "AUTH.GENERAL.IS_CUSTOM_ITEM" }),
        length: intl.formatMessage({ id: "AUTH.GENERAL.LENGTH" }),
        radius: intl.formatMessage({ id: "AUTH.GENERAL.RADIUS" }),
        cubic: intl.formatMessage({ id: "AUTH.GENERAL.CUBIC" })
    };

    const deleteFromTranslate = {
        delete: intl.formatMessage({ id: "CREDIT.DELETE" }),
        areYouSure: intl.formatMessage({ id: "CREDIT.ARE_YOU_SURE_TO_PERMANTLY_DELETE_THIS_ITEM" }),
        ItemAreDeleting: intl.formatMessage({ id: "CREDIT.ITEM_ARE_DELETING" }),
        cancel: intl.formatMessage({ id: "TAKEOVER.CANCEL" }),
        storno: intl.formatMessage({ id: "TAKEOVER.STORNO" })
    };

    const value = {
        documentType,
        translate,
        gridColumnsVisible,
        formFieldsVisible,
        gridPositionColumnsVisible,
        gridPositionColumnsTranslate,
        deleteFromTranslate,
        formTranslate,
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initDocument,
        readOnly: true,
        newDocumentButtonClick: takeoversUIEvents.newDocumentButtonClick,
        openEditDocumentDialog: takeoversUIEvents.openEditDocumentDialog,
        openDeleteDocumentDialog: takeoversUIEvents.openDeleteDocumentDialog,
        printDocumentsDialog: takeoversUIEvents.printDocumentsDialog,
        printDocumentDialog: takeoversUIEvents.printDocumentDialog,
        printStornoDocumentsDialog: takeoversUIEvents.printStornoDocumentsDialog,
        copyDocumentButtonClick: takeoversUIEvents.copyDocumentButtonClick,
        historyDocumentDialog: takeoversUIEvents.historyDocumentDialog
    };

  return (
      <DocumentsUIProvider value={value}>
          <DocumentsLoadingDialog />
          <Route path="/sales/credits/new">
        {({ history, match }) => (
                  <DocumentEditDialog
            show={match != null}
            onHide={() => {
                history.push("/sales/credits");
            }}
          />
        )}
      </Route>
          <Route path="/sales/credits/:id/edit">
        {({ history, match }) => (
                  <DocumentEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/sales/credits");
            }}
          />
        )}
          </Route>
          <Route path="/sales/credits/:id/copy">
              {({ history, match }) => (
                  <DocumentEditDialog
                      show={match != null}
                      id={match && match.params.id}
                      copy={true}
                      onHide={() => {
                          history.push("/sales/credits");
                      }}
                  />
              )}
          </Route>
          <Route path="/sales/credits/printCredits">
        {({ history, match }) => (
            <DocumentsPrintDialog
                show={match != null}
                onHide={() => {
                    history.push("/sales/credits");
                }}
            />
        )}
          </Route>

          <Route path="/sales/credits/:id/printCredit">
              {({ history, match }) => (
                  <DocumentsPrintDialog
                      id={match && match.params.id}
                      show={match != null}
                      onHide={() => {
                          history.push("/sales/credits");
                      }}
                  />
              )}
          </Route>
          <Route path="/sales/credits/:id/printStornoCredits">
              {({ history, match }) => (
                  <DocumentsPrintStornoDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/sales/credits");
                      }}
                  />
              )}
          </Route>
          <Route path="/sales/credits/:id/delete">
        {({ history, match }) => (
        <DocumentDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/sales/credits");
            }}
          />
        )}
          </Route>

          <Route path="/sales/credits/send">
              {({ history, match }) => (
                  <DocumentEditDialog
                      show={match != null}
                      sendToDocument={true}
                      sendToDocumentType={7}
                      id={location.state != undefined && location.state.ids != undefined && location.state.ids.length > 0 ? location.state.ids : undefined}
                      onHide={() => {
                          history.push("/sales/credits");
                      }}
                  />
              )}
          </Route>
          <Route path="/sales/credits/:id/history">
              {({ history, match }) => (
                  <DocumentHistoryDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/sales/credits");
                      }}
                  />
              )}
          </Route>
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false);}} />
          <DocumentsCard />
      </DocumentsUIProvider>
  );
}
