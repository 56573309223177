/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/items/itemsActions";
import { useJigsawsUIContext } from "./JigsawsUIContext";
import { FormattedMessage } from "react-intl";

export function JigsawDeleteDialog() {
  // Jigsaws UI Context
  const jigsawsUIContext = useJigsawsUIContext();
  const jigsawsUIProps = useMemo(() => {
    return {
      id: jigsawsUIContext.selectedId,
      setIds: jigsawsUIContext.setIds,
      showDeleteJigsawDialog: jigsawsUIContext.showDeleteJigsawDialog,
      closeDeleteJigsawDialog: jigsawsUIContext.closeDeleteJigsawDialog,
    };
  }, [jigsawsUIContext]);

  // Jigsaws Redux state
  const dispatch = useDispatch();
    const { jigsaws, jigsawsRemove } = useSelector(
        (state) => ({
            jigsaws: state.items.jigsaws,
            jigsawsRemove: state.items.jigsawsRemove
        }),
        shallowEqual
    );

  // if !id we should close modal
  useEffect(() => {
    if (!jigsawsUIProps.id) {
      jigsawsUIProps.closeDeleteJigsawDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jigsawsUIProps.id]);

    const deleteJigsaw = () => {
        var el = jigsaws.find(el => el.id == jigsawsUIProps.id);
        var list = jigsaws.filter(x => x.id !== el.id);
        dispatch(actions.setJigsaws(list));
        if (el.id > 0) {
            var listRemove = [...jigsawsRemove];
            listRemove.push(el);
            dispatch(actions.setJigsawsRemove(listRemove));
        }
        jigsawsUIProps.closeDeleteJigsawDialog();
    };

  return (
    <Modal
      show={jigsawsUIProps.showDeleteJigsawDialog}
      onHide={jigsawsUIProps.closeDeleteJigsawDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
                  <FormattedMessage id="AUTH.JIGSAW.REMOVE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <span><FormattedMessage id="AUTH.JIGSAW.ARE_YOU_SURE_TO_REMOVE" /></span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={jigsawsUIProps.closeDeleteJigsawDialog}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="AUTH.GENERAL.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteJigsaw}
            className="btn btn-primary btn-elevate"
          >
            <FormattedMessage id="AUTH.GENERAL.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
