import * as requestFromServer from "./importCrud";
import { importSlice, callTypes } from "./importSlice";

const { actions } = importSlice;

export const fetchWarehouses = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getWarehouses()
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.warehousesFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find warehouses";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
