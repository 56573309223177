import React, { useEffect, useMemo, useCallback, useState} from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import { DocumentHistoryDialogHeader } from "./DocumentHistoryDialogHeader";
import { DocumentHistoryTable } from "./DocumentHistoryTable";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { isEqual, isFunction } from "lodash";

export function DocumentHistoryDialog({ id, show, onHide }) {
    const initialFilter = {
        id: id,
        query: "",
        sortOrder: "desc", // asc||desc
        sortField: "createdDate",
        pageNumber: 1,
        pageSize: 25
    };
    const [queryParams, setQueryParamsBase] = useState(initialFilter);

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

  // Office users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        initDocument: documentsUIContext.initDocument
    };
    }, [documentsUIContext]);


  // Users Redux state
  const dispatch = useDispatch();
    const { actionsLoading, documentForEdit } = useSelector(
    (state) => ({
            actionsLoading: state.documents.actionsLoading,
            documentForEdit: state.documents.documentForEdit
    }),
    shallowEqual
  );

    useEffect(() => {
        if (!show) {
            return;
        }
        
        dispatch(actions.fetchDocument(id));
        queryParams.id = id;
        dispatch(actions.fetchHistory(queryParams));
    }, [id, show, dispatch]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
          <DocumentHistoryDialogHeader id={id} document={documentForEdit || documentsUIProps.initDocument}/>
          <DocumentHistoryTable onHide={onHide} setQueryParams={setQueryParams} queryParams={queryParams} />
    </Modal>
  );
}
