import axios from "axios";
import { convertToDateTime } from "../../../../util/Util";

// Method from server should return QueryResultsModel(cashiers: any[], totalsCount: number)
// cashiers => filtered/sorted result
export function findCashiers(queryParams) {
    return axios.post("/api/cashier/find", queryParams );
}

// CREATE =>  POST: add a new cashier to the server
export function createCashier(cashier) {
    return axios.post("/api/cashier", cashier );
}

export function getCashierById(cashierId) {
    return axios.get("/api/cashier/" + cashierId );
}

// UPDATE => PUT: update the cashier on the server
export function updateCashier(cashier) {
    return axios.put("/api/cashier/" + cashier.id, cashier);
}

// DELETE => delete the cashier from the server
export function deleteCashier(cashierId) {
    return axios.delete("/api/cashier/" + cashierId );
}

// DELETE Cashiers by ids
export function deleteCashiers(ids) {
    return axios.post("/api/cashier/deleteCashiers", { ids });
}

export function checkIfCashierExist(id, businessPremiseId, cashierId) {
    return axios.post("/api/cashier/checkIfCashierExist", {
        id: id == undefined ? -1 : id,
        businessPremiseId: businessPremiseId,
        cashierId: cashierId
    });
}

export function registerCashierById(cashierId) {
    return axios.get("/api/cashier/register/" + cashierId);
}

export function unregisterCashierById(cashierId) {
    return axios.get("/api/cashier/unregister/" + cashierId);
}

export function deconnectCashierById(cashierId) {
    return axios.get("/api/cashier/deconnect/" + cashierId);
}
