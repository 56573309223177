import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { UsersPage } from "./users/UsersPage";
import { OfficeUsersPage } from "./officeUsers/OfficeUsersPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function UserPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
            {
                /* Redirect from eCommerce root URL to /customers */
                <Redirect
                exact={true}
                from="/users"
                to="/users/cashier"
                />
                }
                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledBlagajniskihUporabnikov, UserFunctionalityE.UrediBlagajniskeUporabnike]) && (
                    <ContentRoute path="/users/cashier" component={UsersPage} />

                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledPisarniskihUporabnikov, UserFunctionalityE.UrediPisarniskeUporabnike]) && (
                    <ContentRoute path="/users/office" component={OfficeUsersPage} />

                )}

            </Switch>
        </Suspense>
    );
}
