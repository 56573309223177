// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InputCount from '../../../../../components/InputCount'
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import { onlyDigits } from "../../../../../util/Util";

export function OrganizationEditForm({
  saveOrganization,
    organization,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [init, setInit] = useState(false);
    const [cashierUserCount, setCashierUserCount] = useState(organization != undefined && organization != null ? organization.cashierUserCount : 1);
    const [officeUserCount, setOfficeUserCount] = useState(organization != undefined && organization != null ? organization.officeUserCount : 1);
    const [cashierCount, setCashierCount] = useState(organization != undefined && organization != null ? organization.cashierCount : 1);
    useEffect(() => {
        setCashierUserCount(organization != undefined && organization != null ? organization.cashierUserCount : 1);
        setOfficeUserCount(organization != undefined && organization != null ? organization.officeUserCount : 1);
        setCashierCount(organization != undefined && organization != null ? organization.cashierCount : 1);
    }, [user, organization]);
    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.Organizacije]);


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={organization}
        validateOnChange={false}
        onSubmit={(values) => {
            var obj = {
                id: values.id,
                appModuleSales: values.appModuleSales,
                appModulePurchase: values.appModulePurchase,
                appModuleSetting: values.appModuleSetting,
                appModuleAdministration: values.appModuleAdministration,
                officeUserCount: officeUserCount,
                cashierUserCount: cashierUserCount,
                cashierCount: cashierCount,
                active: values.active,
                confirmed: values.confirmed,
                //not save
                name: values.name,
                taxNumber: values.taxNumber,
                taxDuty: values.taxDuty,
                address: values.address,
                postCode: values.postCode,
                postTitle: values.postTitle,
                invoiceSendToMail: values.invoiceSendToMail,
                readOnly: values.readOnly,
                cubic: values.cubic,
                contractId: values.contractId,
                notSentToFurs: values.notSentToFurs
            };
            saveOrganization(obj);
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
            )}
            {!actionsLoading && (
                <Form className="form form-label-right">
                    <div className="form-group row">
                        {/* Login */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                            <Field
                                disabled={true}
                                name="name"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* First Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.TAXCODE" /></label>
                            <Field
                                disabled={true}
                                name="taxNumber"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TAXCODE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* Last Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.REGISTRATION_NUMBER" /></label>
                            <Field
                                disabled={true}
                                name="registrationNumber"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.REGISTRATION_NUMBER' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        {/* Login */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.ADDRESS" /></label>
                            <Field
                                disabled={true}
                                name="address"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* First Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.TOWN" /></label>
                            <Field
                                disabled={true}
                                name="town"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TOWN' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* Last Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.TAX_DUTY" /></label>
                            <br />
                            <Field
                                disabled={true}
                                className="PrivateSwitchBase-input-93"
                                name="taxDuty"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TAX_DUTY' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        {/* Login */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.POST" /></label>
                            <Field
                                disabled={true}
                                name="postCode"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.POST' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* First Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.POST" /></label>
                            <Field
                                disabled={true}
                                name="postTitle"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.POST' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                    </div>
                    <hr />
                    <h3>
                        {values.invoiceSendToMail && (
                            <FormattedMessage id="ORGANIZATION.INVOICE.MAIL" />
                        )}
                        {!values.invoiceSendToMail && (
                            <FormattedMessage id="ORGANIZATION.INVOICE.POST" />
                        )}

                    </h3>

                    {values.invoiceSendToMail && (
                        <div className="form-group row">
                            {/* Login */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.EMAIL" /></label>
                                <Field
                                    disabled={true}
                                    name="invoiceEmail"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>

                        </div>
                    )}

                    {!values.invoiceSendToMail && (
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.ADDRESS" /></label>
                                <Field
                                    disabled={true}
                                    name="invoiceAddress"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>

                            <div className="col-lg-4">
                                <label><FormattedMessage id="ORGANIZATION.INVOICE.POST" /></label>
                                <Field
                                    disabled={true}
                                    name="invoicePostCode"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'ORGANIZATION.INVOICE.POST' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>

                            <div className="col-lg-4">
                                <label><FormattedMessage id="ORGANIZATION.INVOICE.POST" /></label>
                                <Field
                                    disabled={true}
                                    name="invoicePostTitle"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'ORGANIZATION.INVOICE.POST' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>

                        </div>
                    )}

                    <hr />
                    <h3>
                        <FormattedMessage id="ORGANIZATION.MODULES" />
                    </h3>
                    <hr />
                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_ADMINISTRATION" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="appModuleAdministration"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_ADMINISTRATION' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_PURCHASE" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="appModulePurchase"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_PURCHASE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_SALES" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="appModuleSales"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_SALES' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                    </div>

                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_SETTING" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="appModuleSetting"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_SETTING' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                                <FormattedMessage id="PACKAGE.NUMBER_OF_OFFICE_USERS" />
                            </label>

                            <div className="col-lg-6 col-xl-6">

                                <InputCount
                                    value={officeUserCount}
                                    minValue={values.minOfficeUserCount}
                                    maxValue={200}
                                    disabled={readOnly}
                                    onChange={(event, val) => {
                                        setOfficeUserCount(val);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="PACKAGE.NUMBER_OF_CASHIER_USERS" />
                            </label>
                            <div className="col-lg-6 col-xl-6">
                                <InputCount
                                    value={cashierUserCount}
                                    minValue={values.minCashierUserCount}
                                    maxValue={100}
                                    disabled={readOnly}
                                    onChange={(event, val) => {
                                        setCashierUserCount(val);
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                                              <FormattedMessage id="PACKAGE.NUMBER_OF_CASHIERS" />
                            </label>

                            <div className="col-lg-6 col-xl-6">

                                <InputCount
                                    value={cashierCount}
                                    minValue={values.minCashierCount}
                                    maxValue={200}
                                    disabled={readOnly}
                                    onChange={(event, val) => {
                                        setCashierCount(val);
                                    }}
                                />
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_CUBIC" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="cubic"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_CUBIC' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ADMIN.APP_MODULE_NOT_SENT_TO_FURS" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="notSentToFurs"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ADMIN.APP_MODULE_NOT_SENT_TO_FURS' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                    </div>
                    <hr />
                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="active"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.ACTIVE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ECOMMERCE.COMMON.CONFIRMED" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="confirmed"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CONFIRMED' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.READ_ONLY" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                name="readOnly"
                                type="checkbox"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.READ_ONLY' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                    </div>

                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ECOMMERCE.COMMON.CONTRACT_ID" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                name="contractId"
                                component={Input}
                                onKeyPress={onlyDigits}
                                placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.CONTRACT_ID' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                    </div>

                </Form>
            )}
              
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
            <> </>
            {!actionsLoading && (
                <CheckPermissions
                    organizationFunctionalities={[UserFunctionalityE.Organizacije]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
    </>
  );
}
