// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, useIntl } from "react-intl";

export default function AutocompleteCustomer(props) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState(props.value);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await fetch('/api/customer/all');
            const result = await response.json();

            if (active) {
                setOptions(Object.keys(result.entities).map((key) => result.entities[key]));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    return (
        <>
            <Autocomplete
                open={open}
                disabled={props.readOnly}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={props.value}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name + "," + option.taxNumber}
                renderOption={(option) => <span><b>{option.taxNumber}</b> {option.name}</span>}
                options={options}
                loading={loading}
                onChange={(event, value) => { setValue(value); props.onChange(value); }}
                onInputChange={(event, value) => { setInputValue(value); }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </>
    );
}
