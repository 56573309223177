import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";
import moment from 'moment';

const { actions } = dashboardSlice;

export const fetchTrafficToday = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getTrafficToday()
        .then(response => {
            const trafficToday = response.data.model;
            dispatch(actions.trafficTodayFetched({ trafficToday: trafficToday }));
        })
        .catch(error => {
            error.clientMessage = "Can't find traffic today";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};


export const fetchTrafficCurrentMonth = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getTrafficCurrentMonth()
        .then(response => {
            const trafficCurrentMonth = response.data.model;
            dispatch(actions.trafficCurrentMonthFetched({ trafficCurrentMonth: trafficCurrentMonth }));
        })
        .catch(error => {
            error.clientMessage = "Can't find traffic current month";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchTrafficLast12Months = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getTrafficLast12Months()
        .then(response => {
            const trafficLast12Months = response.data.model;
            dispatch(actions.trafficLast12MonthsFetched({ trafficLast12Months: trafficLast12Months }));
        })
        .catch(error => {
            error.clientMessage = "Can't find traffic last 12 months";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchSalesPurchaseByYears = (year) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getSalesPurchaseByYears(year)
        .then(response => {
            const salesPurchaseByYears = response.data.entities;
            dispatch(actions.salesPurchaseByYearsFetched({ salesPurchaseByYears: salesPurchaseByYears }));
        })
        .catch(error => {
            error.clientMessage = "Can't find sales purchase by years";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchSalesByYears = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getSalesByYears()
        .then(response => {
            const salesByYears = response.data.entities;
            dispatch(actions.salesByYearsFetched({ salesByYears: salesByYears }));
        })
        .catch(error => {
            error.clientMessage = "Can't find sales by years";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};