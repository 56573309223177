import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CashierUserRolesPage } from "./userRoles/CashierUserRolesPage";
import { OfficeUserRolesPage } from "./userRoles/OfficeUserRolesPage";
import { CodeListsPage } from "./codeLists/CodeListsPage";
import { ChangePasswordPage } from "./changePassword/ChangePasswordPage";
import { ExportPage } from "./export/ExportPage";
import { YourProfilePage } from "./yourProfile/YourProfilePage";
import YourCompanyPage from "./yourCompany/YourCompanyPage";
import { CashiersPage } from "./cashiers/CashiersPage";
import { AppPage } from "./app/AppPage";
import ImportPage from "./import/ImportPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function UserRolePage() {
    const { user } = useSelector(state => state.auth);

    return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
            {
              /* Redirect from eCommerce root URL to /customers */
              <Redirect
                exact={true}
                from="/settings"
                to="/settings/profile"
              />
            }

            <ContentRoute path="/settings/profile" component={YourProfilePage} />

            <ContentRoute path="/settings/app" component={AppPage} />

            {hasRoleForFunctionality(user, [UserFunctionalityE.PregledVlogZaBlagajniskePravice, UserFunctionalityE.UrediVlogeZaBlagajniskePravice]) && (
                <ContentRoute path="/settings/roles_cashier" component={CashierUserRolesPage} />
            )}
  
            {hasRoleForFunctionality(user, [UserFunctionalityE.PregledVlogZaPisarniskePravice, UserFunctionalityE.UrediVlogeZaPisarniskePravice]) && (
                <ContentRoute path="/settings/roles_office" component={OfficeUserRolesPage} />
            )}

            {hasRoleForFunctionality(user, [UserFunctionalityE.PregledSifrantov, UserFunctionalityE.UrediSifrante]) && (
                <ContentRoute path="/settings/codelist" component={CodeListsPage} />
            )}

            <ContentRoute path="/settings/change_password" component={ChangePasswordPage} />

            {hasRoleForFunctionality(user, [UserFunctionalityE.PregledVasegaPodjetja, UserFunctionalityE.UrediVasePodjetje]) && (
                <ContentRoute path="/settings/company" component={YourCompanyPage} />
            )}

            {hasRoleForFunctionality(user, [UserFunctionalityE.PregledDavcnihBlagajn, UserFunctionalityE.UrediDavcneBlagajne]) && (
                <ContentRoute path="/settings/cashiers" component={CashiersPage} />
            )}

            {hasRoleForFunctionality(user, [UserFunctionalityE.UrediIzdelke, UserFunctionalityE.UrediInventurnePopise]) && (
                <ContentRoute path="/settings/import" component={ImportPage} />
                )}

            {hasRoleForFunctionality(user, [UserFunctionalityE.Izvozi]) && (
                <ContentRoute path="/settings/export" component={ExportPage} />
            )}


      </Switch>
    </Suspense>
  );
}
