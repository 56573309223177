import axios from "axios";

export function checkIfCashierUserNameIsFree(applicationUserId, userName) {
    applicationUserId = (applicationUserId == undefined || applicationUserId == null) ? -1 : applicationUserId;
    return axios.post("/api/user/CheckIfCashierUserNameIsFree", { applicationUserId, userName });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
    return axios.post("/api/user/find", queryParams );
}

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
    return axios.post("/api/user", user );
}

export function getUserById(userId) {
    return axios.get("/api/user/" + userId );
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
    return axios.put("/api/user/" + user.id, user);
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
    return axios.delete("/api/user/" + userId );
}

// DELETE Users by ids
export function deleteUsers(ids) {
    return axios.post("/api/user/deleteUsers", { ids });
}

export function getUserRoles() {
    return axios.get("/api/userRole/cashier");
}

export function findCashiers() {
    var queryParams = {
        pageNumber: 1,
        pageSize: 10000,
        query: "",
        sortField: "businessPremiseTitle",
        sortOrder: "asc",
        status: 1

    };
    return axios.post("/api/cashier/find", queryParams);
}