// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export function CodeListEditForm({
    codelistSchemaId,
    saveCodeList,
    codeList,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();


    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediSifrante]);

    // Validation schema
    const CodeListEditSchema = Yup.object().shape({
        code: Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        name: Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
    });

  return (
    <>
      <Formik
        enableReinitialize={true}
            initialValues={codeList}
            validationSchema={CodeListEditSchema}
              onSubmit={(values) => {
                var obj = {
                    active: values.active,
                    code: values.code,
                    codelistSchemaId: codelistSchemaId,
                    description: values.description,
                    id: values.id,
                    name: values.name
                };
                  saveCodeList(obj);
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
            )}
            {!actionsLoading && (
                <Form className="form form-label-right">
                    <div className="form-group row">

                        <div className="col-lg-4">
                            <label><FormattedMessage id="CODE" /></label>
                            <Field
                                disabled={readOnly}
                                name="code"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'CODE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                            <Field
                                disabled={readOnly}
                                name="name"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" /></label>
                            <br />
                            <Field
                                disabled={readOnly}
                                className="PrivateSwitchBase-input-93"
                                type="checkbox"
                                name="active"
                            />

                        </div>

                    </div>

                    <div className="form-group row">

                        <div className="col-lg-12">
                            <label><FormattedMessage id="AUTH.INPUT.DESCRIPTION" /></label>
                            <Field
                                disabled={readOnly}
                                name="description"
                                className="form-control"
                                component="textarea"
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.DESCRIPTION' })}
                            />
                        </div>


                    </div>

                </Form>
            )}

            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
                <> </>
            {!actionsLoading && (
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediSifrante]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
            </Modal.Footer>
          </>
        )}
          </Formik>
    </>
  );
}
