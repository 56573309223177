/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel`}>
                <a href="mailto:podpora@mikropos.si?Subject=Pomo�">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span> <FormattedMessage id="SUPPORT_MAIL" /></span>
                    
                </a>
            </li>
                   
            <li className={`menu-item menu-item-rel`}>
                <a href="tel:08-33-00000">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span> <FormattedMessage id="HEADER_HELP_PHONE" /></span>
                </a>
            </li>
            <li className={`menu-item menu-item-rel`}>
                <a target="_blank" href="https://skupinacomp.freshdesk.com/support/solutions/35000141021">
                    <i className="fa fa-medkit" aria-hidden="true"></i>
                    <span> <FormattedMessage id="INSTRUCTION" /></span>
                </a>
            </li>
            <li className={`menu-item menu-item-rel`}>
                <a target="_blank" href="https://get.teamviewer.com/mikroposqs">
                    <img alt="" src={toAbsoluteUrl("/media/my/menuico-tview.png")} />
                </a>
            </li>
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
