/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../_redux/dashboards/dashboardActions"
import { Chart } from "react-google-charts";
import Select5YearBack from '../../../../components/Select5YearBack';


export function SalesByYearsWidget({ className }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);

    useEffect(() => { }, [user]);

    const { currentState } = useSelector(
        (state) => ({
            currentState: state.dashboard
        }),
        shallowEqual
    );
    const { salesByYears, listLoading } = currentState;

    function getChartData(list) {
        if (list == null) {
            return null;
        }
        var result = [];
        for (var index = 0; index < list.length; index++) {
            for (var y = 0; y < list[index].length; y++) {
                if (y == 0) {
                    result.push([]);
                }
                var data = list[index][y];
                if (index > 0 && y > 0) {
                    result[index].push(parseFloat(data.replace(".", "").replace(",", ".")));
                } else {
                    result[index].push(data);
                }
                
            }
        }

        return result;
    }

    return (
        <div className={`card card-custom ${className}`} style={{ height: "440px" }}>
            <div className="card-body">
                
                <div className="row">
                    <div className="col">
                        <h5 className="text-uppercase text-muted mb-0 card-title"><FormattedMessage id="SALES_BY_YEARS" /></h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {listLoading && (
                            <div className="overlay-layer bg-transparent">
                                <div className="spinner spinner-lg spinner-success" />
                            </div>
                        )}
                        {!listLoading && (
                            <>
                                <Chart
                                    width={550}
                                    height={390}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={getChartData(salesByYears)}
                                    options={{
                                        chartArea: { width: '65%' }

                                    }}
                                    legendToggle
                                />
                            </>
                        )}
                    </div>
                </div>
                        
                
            </div>
        </div>
    );
}

