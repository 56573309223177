import * as requestFromServer from "./itemGroupsCrud";
import { itemGroupsSlice, callTypes } from "./itemGroupsSlice";

const {actions} = itemGroupsSlice;

export const fetchItemGroups = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findItemGroups(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.itemGroupsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find itemGroups";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchItemGroup = id => dispatch => {
  if (!id) {
    return dispatch(actions.itemGroupFetched({ itemGroupForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getItemGroupById(id)
      .then(response => {
      const itemGroup = response.data.model;
      dispatch(actions.itemGroupFetched({ itemGroupForEdit: itemGroup }));
    })
    .catch(error => {
      error.clientMessage = "Can't find itemGroup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteItemGroup = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItemGroup(id)
    .then(response => {
      dispatch(actions.itemGroupDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete itemGroup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createItemGroup = itemGroupForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createItemGroup(itemGroupForCreation)
      .then(response => {
        const { model } = response.data;
        dispatch(actions.itemGroupCreated({ model }));
    })
    .catch(error => {
      error.clientMessage = "Can't create itemGroup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateItemGroup = itemGroup => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateItemGroup(itemGroup)
    .then(() => {
      dispatch(actions.itemGroupUpdated({ itemGroup }));
    })
    .catch(error => {
      error.clientMessage = "Can't update itemGroup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteItemGroups = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItemGroups(ids)
    .then(() => {
      dispatch(actions.itemGroupsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete itemGroups";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};