import React, { useMemo } from "react";
import { useItemsUIContext } from "../ItemsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function ItemsGrouping() {
  const intl = useIntl();

  // Items UI Context
  const itemsUIContext = useItemsUIContext();
  const itemsUIProps = useMemo(() => {
    return {
        ids: itemsUIContext.ids,
        setIds: itemsUIContext.setIds,
        openItemStockMovementDialog: itemsUIContext.openItemStockMovementDialog
    };
  }, [itemsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{itemsUIProps.ids.length}</b>
                </span>
              </label>
                      </div>
                      {itemsUIProps.ids.length == 1 &&
                          <div>
                              <button
                                  type="button"
                                  className="btn btn-light-primary font-weight-bolder font-size-sm"
                              onClick={itemsUIProps.openItemStockMovementDialog}
                              >
                                  <i className="fa fa-print"></i><FormattedMessage id="ITEM.STOCK.MOVEMENT" />
                              </button>
                          </div>
                      
                      }
          </div>
        </div>
      </div>
    </div>
  );
}
