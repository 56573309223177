import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual, isFunction } from "lodash";
import moment from 'moment'
import { useCookies } from "react-cookie";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Formik } from "formik";
import MultiSelectCashier from '../../../../components/MultiSelectCashier';
import DateRange2 from '../../../../components/DateRange2';
import PdfViewer from "../../../../components/PdfViewer"

export function ReportVatCalculatorPage({ history }) {
    const intl = useIntl();
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());

    const applyFilter = (values) => {



    };

  return (
      <>
          <Card>
              <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.REPORT_VAT_CALCULATOR" })} >
                  <CardHeaderToolbar>
                  </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                  <Formik
                      initialValues={{
                          status: "",
                          type: "",
                          searchText: "",
                      }}
                      onSubmit={(values) => {
                          applyFilter(values);
                      }}
                  >
                      {({
                          values,
                          handleSubmit,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                      }) => (
                              <form onSubmit={handleSubmit} className="form form-label-right">
                                  <div className="form-group row">
                                      <div className="col-lg-4">
                                          <DateRange2
                                              startDate={startDate}
                                              endDate={endDate}
                                              onChange={(startDate, endDate) => {
                                                  setStartDate(startDate);
                                                  setEndDate(endDate);
                                                  handleSubmit();
                                              }}
                                          />
                                          <small className="form-text text-muted">
                                              <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.DATES" />
                                          </small>
                                      </div>

                                  </div>
                              </form>
                          )}
                  </Formik>

                  <PdfViewer
                      url={'../../../API/OfficeReport/ReportVatCalculator?start=' + moment(startDate).format("YYYY-MM-DD") + '&end=' + moment(endDate).format("YYYY-MM-DD") }
                  />

              </CardBody>
          </Card>
          </>
  );
}
