import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useEndsUIContext } from "../EndsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import moment from 'moment';
import MultiSelectCashier from '../../../../../components/MultiSelectCashier';
import DateRange2 from '../../../../../components/DateRange2';

const prepareFilter = (queryParams, values, fromDt, toDt, selectedCashiers) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };
    newQueryParams.startDate = moment(fromDt).format("YYYY-MM-DD");
    newQueryParams.endDate = moment(toDt).format("YYYY-MM-DD");
    newQueryParams.query = searchText;
    var ids = "";
    for (var index = 0; index < selectedCashiers.length; index++) {
        if (ids != "") {
            ids += ",";
        }
        ids += selectedCashiers[index];
    }
    newQueryParams.cashierIds = ids;
    return newQueryParams;
};

export function EndsFilter({ listLoading }) {
    const intl = useIntl();
    const [cookies, setCookie] = useCookies();
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { cashiers } = currentState;
    const { user } = useSelector(state => state.auth);

    const [selectedCashiers, setSelectedCashiers] = useState(cookies['filter_dt_ends' + '_' + user.id] != undefined ? getSelectCashiers(cookies['filter_dt_ends' + '_' + user.id]) : []);
  // Ends UI Context
    const endsUIContext = useEndsUIContext();
  const endsUIProps = useMemo(() => {
    return {
        queryParams: endsUIContext.queryParams,
        setQueryParams: endsUIContext.setQueryParams,
    };
  }, [endsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
      const newQueryParams = prepareFilter(endsUIProps.queryParams, values, startDate, endDate, selectedCashiers);
    if (!isEqual(newQueryParams, endsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      endsUIProps.setQueryParams(newQueryParams);
    }
    };

    function getSelectCashiers(selectCashiers) {
        var list = [];
        for (var index = 0; index < selectCashiers.length; index++) {
            for (var x = 0; x < cashiers.length; x++) {
                if (cashiers[x].id == selectCashiers[index]) {
                    list.push(selectCashiers[index]);
                    break;
                }
            }

        }
        return list;
    }

  return (
    <>
      <Formik
        initialValues={{
            status: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
                <div className="col-lg-4">
                    <MultiSelectCashier
                        value={selectedCashiers}
                        onChange={(val) => {
                            var current = new Date();
                            var nextYear = new Date();
                            nextYear.setFullYear(current.getFullYear() + 5);

                            setCookie('filter_dt_ends' + '_' + user.id, val, {
                                path: '/',
                                expires: nextYear });
                            setSelectedCashiers(val);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                        <b><FormattedMessage id="AUTH.GENERAL.CHOOSE" /></b> <FormattedMessage id="AUTH.GENERAL.FISCAL_CASHIER2" />
                    </small>
                </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.SEARCH"
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                </small>
                              </div>
                              <div className="col-lg-4">
                                  <DateRange2
                                      startDate={startDate}
                                      endDate={endDate}
                                      onChange={(startDate, endDate) => {
                                          setStartDate(startDate);
                                          setEndDate(endDate);
                                          handleSubmit();
                                      }}
                                  />
                                  <small className="form-text text-muted">
                                      <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.DATES" />
                                  </small>
                              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
