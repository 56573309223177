import axios from "axios";

export function getTrafficToday() {
    return axios.get("/api/document/trafficToday");
}

export function getTrafficCurrentMonth() {
    return axios.get("/api/document/trafficCurrentMonth");
}

export function getTrafficLast12Months() {
    return axios.get("/api/document/trafficLast12Months");
}

export function getSalesPurchaseByYears(year) {
    return axios.get("/api/document/salesPurchaseByYears/" + year);
}

export function getSalesByYears() {
    return axios.get("/api/document/salesByYears");
}
