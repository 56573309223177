import {createSlice} from "@reduxjs/toolkit";

const initialCashiersState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    cashierForEdit: undefined,
    pricelistForEdit: undefined,
    pricelists: undefined,
    pricelistsRemove: undefined,
    lastError: null,
    currentVatRateId: null,
    jigsawForEdit: undefined,
    jigsaws: undefined,
    jigsawsRemove: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const cashiersSlice = createSlice({
  name: "cashiers",
  initialState: initialCashiersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCashierById
    cashierFetched: (state, action) => {
      state.actionsLoading = false;
      state.cashierForEdit = action.payload.cashierForEdit;
      state.error = null;
    },
    // findCashiers
    cashiersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCashier
    cashierCreated: (state, action) => {
        state.actionsLoading = false;
      state.error = null;
        state.entities.push(action.payload.model);
        state.totalCount++;
    },
    // updateCashier
    cashierUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.cashier.id) {
          return action.payload.cashier;
        }
        return entity;
      });
    },
    // deleteCashier
    cashierDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCashiers
    cashiersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
