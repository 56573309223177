import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/itemGroups/itemGroupsActions";
import { ItemGroupEditDialogHeader } from "./ItemGroupEditDialogHeader";
import { ItemGroupEditForm } from "./ItemGroupEditForm";
import { useItemGroupsUIContext } from "../ItemGroupsUIContext";

export function ItemGroupEditDialog({ id, show, onHide }) {
  // ItemGroups UI Context
    const itemGroupsUIContext = useItemGroupsUIContext();
  const itemGroupsUIProps = useMemo(() => {
    return {
        initItemGroup: itemGroupsUIContext.initItemGroup,
    };
  }, [itemGroupsUIContext]);

  // ItemGroups Redux state
  const dispatch = useDispatch();
    const { actionsLoading, itemGroupForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.itemGroups.actionsLoading,
    itemGroupForEdit: state.itemGroups.itemGroupForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(actions.fetchItemGroup(id));
  }, [id, dispatch]);

  // server request for saving itemGroup
  const saveItemGroup = (itemGroup) => {
    if (!id) {
      // server request for creating itemGroup
      dispatch(actions.createItemGroup(itemGroup)).then(() => onHide());
    } else {
      // server request for updating itemGroup
      dispatch(actions.updateItemGroup(itemGroup)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="mEditItemGroup"
    >
        <ItemGroupEditDialogHeader id={id} />
          <ItemGroupEditForm
        saveItemGroup={saveItemGroup}
        actionsLoading={actionsLoading}
              itemGroup={itemGroupForEdit || itemGroupsUIProps.initItemGroup}
        onHide={onHide}
      />
    </Modal>
  );
}
