// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { checkIfCashierUserNameIsFree } from "../../../_redux/users/usersCrud";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export function UserEditForm({
  userRoles,
  saveUser,
  cashierUser,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [isForeignOperator, setIsForeignOperator] = useState(cashierUser.foreignOperator);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [init, setInit] = useState(false);
    
    useEffect(() => {
        if (cashierUser.id > 0 && !init) {
            setIsForeignOperator(cashierUser.foreignOperator);
            setInit(true);
        }
    });
    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediBlagajniskeUporabnike]);
    // Validation schema
    const UserEditSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        userName: Yup.string()
            .min(1, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_1_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.CASHIER_USERNAME_ONLY_DIGIT' }), value => {
                return new Promise((resolve, reject) => {
                    if (/^[0-9]{1,50}$/.test(value)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            })
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.CASHIER_USERNAME_EXISTS_YET' }), value => {
                return new Promise((resolve, reject) => {
                    if (/^[0-9]{1,50}$/.test(value)) {

                        checkIfCashierUserNameIsFree(cashierUser.id, value)
                            .then(({ data }) => {
                                if (!data.didError) {
                                    resolve(data.model);
                                } else {
                                    setErrorMessage(data.message);
                                    setErrorOpen(true);
                                    resolve(false);
                                }
                            })
                            .catch((e) => {
                                resolve(false);
                            });
                    } else {
                        resolve(false);
                    }
                })
            }),
        taxNumber: Yup.string().test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.INVALID_TAXNUMBER' }), value => {
            return new Promise((resolve, reject) => {
                if (isForeignOperator) {
                    resolve(true);
                } else {
                    if (/^[0-9]{8}$/.test(value)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            })
        }),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' }))
        
    });





  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={cashierUser}
        validateOnChange={false}
        validationSchema={UserEditSchema}
              onSubmit={(values) => {
                  var obj = {
                      email: values.email,
                      firstName: values.firstName,
                      foreignOperator: values.foreignOperator,
                      id: values.id,
                      lastName: values.lastName,
                      phone: values.phone,
                      taxNumber: values.taxNumber,
                      userName: values.userName,
                      applicationUserFiscalCashierBusinessUnitUserRole: values.applicationUserFiscalCashierBusinessUnitUserRole
                  }

                  saveUser(obj);
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
                )}
                {!actionsLoading && (
                    <Form className="form form-label-right">
                        <div className="form-group row">
                            {/* Login */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.ID" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="userName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.ID' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            {/* First Name */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.FULLNAME" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="firstName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.FULLNAME' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            {/* Last Name */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.LASTNAME" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="lastName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.LASTNAME' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.PHONE" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="phone"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PHONE' })}
                                    label=""
                                    customFeedbackLabel=" "

                                />
                            </div>
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.EMAIL" /></label>
                                <Field
                                    disabled={readOnly}
                                    type="email"
                                    name="email"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>
                        <hr />
                            <table className="table">
                                <thead>
                                    <tr>
                                              <th style={{width: "50%"}} scope="col"><FormattedMessage id="AUTH.GENERAL.CASHIERS" /></th>
                                              <th style={{ width: "50%" }} scope="col"><FormattedMessage id="SETTING.USER_ROLE" /></th>
                                    </tr>
                                </thead>
                                      <tbody>
                                          <FieldArray
                                              name="applicationUserFiscalCashierBusinessUnitUserRole"
                                              render={arrayHelpers => (
                                                  <>
                                                      {values.applicationUserFiscalCashierBusinessUnitUserRole.map((cashier, index) => (
                                                          <tr key={'tr_' + cashier.id}>
                                                              <td>{cashier.fiscalCashierBusinessUnit.businessPremiseTitle}</td>
                                                              <td>
                                                                  <Field
                                                                      disabled={readOnly}
                                                                      className={`form-control form-control-solid`}
                                                                      component="select"
                                                                      name={`applicationUserFiscalCashierBusinessUnitUserRole.${index}.userRoleId`}>
                                                                      {userRoles.map(option => (
                                                                          <option key={option.id} value={option.id}>
                                                                              {option.title}
                                                                          </option>
                                                                      ))}
                                                                  </Field>
                                                              </td>
                                                          </tr>
                                                      ))}
                                                  </>
                                              )}
                                          />
                                </tbody>
                            </table>

                        <hr />
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.FOREIGN_OPERATOR" />
                                    <br />
                                    <br />
                                    <Field
                                        disabled={readOnly}
                                        className="PrivateSwitchBase-input-93"
                                        type="checkbox"
                                        name="foreignOperator"
                                        onClick={(e) => {
                                            setIsForeignOperator(e.target.value != "true");
                                        }}
                                    />

                                </label>
                            </div>
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.TAXCODE_OPERATOR" /></label>
                                <Field
                                    name="taxNumber"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TAXCODE_OPERATOR' })}
                                    label=""
                                    disabled={isForeignOperator || readOnly}
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>

                    </Form>
                )}

            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
            <> </>
            {!actionsLoading && (
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediBlagajniskeUporabnike]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
    </>
  );
}
