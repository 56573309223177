import React, { useEffect, Suspense, lazy } from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashboard/pages/DashboardPage";
import { CheckAxiosRequests } from "../app/CheckAxiosRequests"
import { useDispatch } from "react-redux";
import * as actions from "./../_redux/cache/cacheActions";

import UserPage from "./modules/User/pages/UserPage"
import SettingPage from "./modules/Setting/pages/SettingPage"
import AdminPage from "./modules/Admin/pages/AdminPage"
import CustomerPage from "./modules/Customer/pages/CustomerPage"
import ItemPage from "./modules/Item/pages/ItemPage"
import PurchasePage from "./modules/Purchase/pages/PurchasePage"
import SalesPage from "./modules/Sales/pages/SalesPage"
import ReportPage from "./modules/Report/pages/ReportPage"

//const UserPage = lazy(() =>
//  import("./modules/User/pages/UserPage")
//);
//const SettingPage = lazy(() =>
//    import("./modules/Setting/pages/SettingPage")
//);

//const AdminPage = lazy(() =>
//    import("./modules/Admin/pages/AdminPage")
//);

//const CustomerPage = lazy(() =>
//    import("./modules/Customer/pages/CustomerPage")
//);

//const ItemPage = lazy(() =>
//    import("./modules/Item/pages/ItemPage")
//);

//const PurchasePage = lazy(() =>
//    import("./modules/Purchase/pages/PurchasePage")
//);

//const SalesPage = lazy(() =>
//    import("./modules/Sales/pages/SalesPage")
//);

//const ReportPage = lazy(() =>
//    import("./modules/Report/pages/ReportPage")
//);

export default function BasePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchInit());
     }, []) // [] - is required if you need only one call
     //https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <Route path="/users" component={UserPage} />
                <Route path="/settings" component={SettingPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/customers" component={CustomerPage} />
                <Route path="/items" component={ItemPage} />
                <Route path="/itemGroups" component={ItemPage} />
                <Route path="/purchase" component={PurchasePage} />
                <Route path="/sales" component={SalesPage} />
                <Route path="/reports" component={ReportPage} />
                
                <Redirect to="error/error-v1"/>
            </Switch>
            <CheckAxiosRequests />
        </Suspense>
    );
}
