import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { getExportOrganization } from "../_redux/exportCrud";
import { login } from "../_redux/exportCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  password: "",
};

function Export(props) {
  const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");


    useEffect(() => {
        getExportOrganization(props.match.params.accessKey)
            .then(({ data: { model } }) => {
                if (model == "") {
                    props.history.push('/auth/login')
                } else {
                    setName(model);
                }
                disableLoading();
        })
        .catch(() => {
            disableLoading();
            
        });
    }, []);

  const ExportSchema = Yup.object().shape({
    password: Yup.string()
          .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
        .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
    
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
      validationSchema: ExportSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
        enableLoading();
        login(props.match.params.accessKey, values.password)
        .then(({ data: { model } }) => {
            disableLoading();
            setSubmitting(false);
            if (model == true) {
                window.location = "/api/export/DownloadExportDataPublic?accessKey=" + props.match.params.accessKey + "&password=" + values.password;
            } else {
                setStatus(
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN_EXPORT",
                    })
                );
            } 
        })
        .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN_EXPORT",
                })
            );
        });
    },
  });

    return (
    <div>
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.EXPORT.TITLE1" /> - {name}
        </h3>
        <p className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.GENERAL.ENTER_YOUR_PASSWORD" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
                <FormattedMessage id="AUTH.EXPORT.TITLE" />
            </div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD' })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
          <span>
            <FormattedMessage id="AUTH.DOWNLOAD.BUTTON" />
          </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
            </div>
            </div>
  );
}

export default injectIntl(connect()(Export));
