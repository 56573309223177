import React, { useState } from 'react';

export default function PdfViewer(props) {
    const [loading, setLoading] = useState(true);



    return (
        <>
            {loading && (
                <div className="row">
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-2">
                        <br/>
                        <div className="overlay-layer bg-transparent">
                            <div className="spinner spinner-lg spinner-success" />
                        </div> 
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>
            )}
            <iframe
                style={{ width: "100%", height: loading ? "0px" : "720px", border: "0px" }}
                src={props.url}
                type='application/pdf'
                title='title'
                onLoad={() => { setLoading(false); }}
            />
        </>
    );
}
