import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { deconnectCashierById } from "../../../_redux/cashiers/cashiersCrud";
import InfoPopup from "../../../../Helpers/InfoPopup"

export function CashierDeconnectDialog({ id, show, onHide }) {
    // Cashiers UI Context
    const intl = useIntl();
    const [infoMessage, setInfoMessage] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);
    const [actionsLoading, setActionsLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const cashiersUIContext = useCashiersUIContext();
    const cashiersUIProps = useMemo(() => {
        return {
            initCashier: cashiersUIContext.initCashier,
            setIds: cashiersUIContext.setIds,
            queryParams: cashiersUIContext.queryParams
        };
    }, [cashiersUIContext]);

    // Cashiers Redux state
    const dispatch = useDispatch();
    const { cashierForEdit } = useSelector(
        (state) => ({
            cashierForEdit: state.cashiers.cashierForEdit
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(actions.fetchCashier(id));
    }, [id, dispatch]);

    function tick() {
        dispatch(actions.fetchCashier(id));
    }

    useEffect(() => {
        if (show) {
            var id = setInterval(() => tick(), 4000);
            setTimer(id);
        } else {
            clearInterval(timer);
        }
    }, [show]);


    function deconnectDevice() {
        setActionsLoading(true);
        deconnectCashierById(cashierForEdit.id)
            .then(({ data }) => {
                onHide();
                setInfoMessage(intl.formatMessage({ id: 'DECONNECT.DEVICE.SUCCESS' }));
                setInfoOpen(true);
                setActionsLoading(false);

                cashiersUIProps.setIds([]);
                dispatch(actions.fetchCashiers(cashiersUIProps.queryParams));
            }).catch((e) => {
                setActionsLoading(false);
            });
    }

    return (
      <>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                        <FormattedMessage id="DECONNECT_DEVICE" />
            </Modal.Title>
          </Modal.Header>
                <Modal.Body>

                    {actionsLoading && (
                        <div className="overlay-layer bg-transparent">
                            <div className="spinner spinner-lg spinner-success" />
                        </div>
                    )}

                    {!actionsLoading && cashierForEdit != undefined && cashierForEdit.deviceKey != null && (
                        <>
                            <span>
                                <FormattedMessage id="CASHIER.DECONNECT.TEXT" />
                            </span>
                            <br />
                            <br />
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <label><FormattedMessage id="AUTH.INPUT.DEVICE_TITLE" /></label>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className="form-control"
                                        value={cashierForEdit.deviceTitle}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label><FormattedMessage id="AUTH.INPUT.DEVICE_KEY" /></label>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className="form-control"
                                        value={cashierForEdit.deviceKey}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <label><FormattedMessage id="AUTH.INPUT.DEVICE_IP_ADDRESS" /></label>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className="form-control"
                                        value={cashierForEdit.deviceIpAddress}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label><FormattedMessage id="AUTH.INPUT.LAST_DEVICE_SYNCH" /></label>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className="form-control"
                                        value={cashierForEdit.lastDeviceSynch}
                                    />
                                </div>
                            </div>
                            
                        </>
                    )}

          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
                      >
                    <FormattedMessage id="AUTH.GENERAL.CLOSE" />
              </button>
            <> </>
                {cashierForEdit != undefined && !actionsLoading && (
                <button
                    type="button"
                    onClick={deconnectDevice}
                    className="btn btn-primary btn-elevate"
                >
                    <FormattedMessage id="DECONNECT_DEVICE" />
                </button>
                )}
            </div>
          </Modal.Footer>
        </Modal>
        <InfoPopup message={infoMessage} open={infoOpen} onClose={() => setInfoOpen(false)} />
    </>
  );
}
