import axios from "axios";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUserRoles(queryParams) {
    return axios.post("/api/userRole/find", queryParams );
}

// CREATE =>  POST: add a new user to the server
export function createUserRole(userRole) {
    return axios.post("/api/userRole", userRole );
}

export function getUserRoleById(userRoleId) {
    return axios.get("/api/userRole/" + userRoleId );
}

// UPDATE => PUT: update the user on the server
export function updateUserRole(userRole) {
    return axios.put("/api/userRole/" + userRole.id, userRole);
}

// DELETE => delete the user from the server
export function deleteUserRole(userRoleId) {
    return axios.delete("/api/userRole/" + userRoleId );
}

export function getAllUserFunctionalities() {
    return axios.get("/api/userRole/userFunctionalities");
}
