// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, InputGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InfoPopup from "../../../../Helpers/InfoPopup"
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import { SketchPicker } from 'react-color'
import { onlyDigits } from "../../../../../util/Util";

export function ItemGroupEditForm({
  saveItemGroup,
  itemGroup,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [background, setBackground] = useState(itemGroup != undefined ? itemGroup.background : "#ffffff");
    const [displayBackgroundPicker, setDisplayBackgroundPicker] = useState(false);
    const [foreground, setForeground] = useState(itemGroup != undefined ? itemGroup.foreground : "#ffffff");
    const [displayForegroundPicker, setDisplayForegroundPicker] = useState(false);

    useEffect(() => {
        setBackground(itemGroup != undefined ? itemGroup.background : "#ffffff");
        setForeground(itemGroup != undefined ? itemGroup.foreground : "#ffffff");
    }, [user, itemGroup]);

    useEffect(() => {

    });

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediSkupineIzdelkov]);

    // Validation schema
    const ItemGroupEditSchema = Yup.object().shape({
        title: Yup.string()
            .max(250, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_250_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        fontSize: Yup.number()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'FONT_SIZE.MORE_THEN_6' }), value => {
                return new Promise((resolve, reject) => {
                    var num = parseFloat(value);
                    resolve(num >= 7);
                })
            })
    });

  return (
    <>
          <Formik
        enableReinitialize={true}
        initialValues={itemGroup}
        validateOnChange={false}
              validationSchema={ItemGroupEditSchema}
              onSubmit={(values) => {
                  var obj = {
                      id: values.id,
                      title: values.title,
                      background: background,
                      foreground: foreground,
                      fontSize: values.fontSize,
                      order: values.order,
                      active: values.active,
                      appearInCashier: values.appearInCashier
                  };
                  saveItemGroup(obj);
              }
        }
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
                          )}

                {!actionsLoading && (
                    <Form className="form form-label-right">

                        <div className="form-group row">
                            {/* Login */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="title"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            {/* Background */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.BACKGROUND" /></label>
                                <br />
                                <div className="form-control"
                                    style={{
                                        padding: '5px',
                                        background: '#fff',
                                        borderRadius: '2px',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { if (readOnly) return; setDisplayBackgroundPicker(!displayBackgroundPicker); }}>
                                    <div style={{ width: '100%', height: '100%', background: `${background}` }} />
                                </div>
                                {displayBackgroundPicker ? <div style={{ position: 'absolute', zIndex: '2' }}>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }}
                                        onClick={() => { setDisplayBackgroundPicker(false); }} />
                                    <SketchPicker color={background} onChange={(color) => { setBackground(color.hex); }} />
                                </div> : null}
                            </div>
                            {/* Foreground */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.FOREGROUND" /></label>
                                <br />
                                <div className="form-control"
                                    style={{
                                        padding: '5px',
                                        background: '#fff',
                                        borderRadius: '2px',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { if (readOnly) return; setDisplayForegroundPicker(!displayForegroundPicker); }}>
                                    <div style={{ width: '100%', height: '100%', background: `${foreground}` }} />
                                </div>
                                {displayForegroundPicker ? <div style={{ position: 'absolute', zIndex: '2' }}>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px'
                                        }}
                                        onClick={() => { setDisplayForegroundPicker(false); }} />
                                    <SketchPicker color={foreground} onChange={(color) => { setForeground(color.hex); }} />
                                </div> : null}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.FONT_SIZE" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="fontSize"
                                    type="number"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.FONT_SIZE' })}
                                    label=""
                                    customFeedbackLabel=" "
                                    onKeyPress={onlyDigits}
                                />
                            </div>

                            <div className="col-lg-4" >
                                    <label><FormattedMessage id="ECOMMERCE.COMMON.APPEAR_IN_CASHIER" />
                                    <br />
                                    <Field
                                        disabled={readOnly}
                                        className="PrivateSwitchBase-input-93"
                                        type="checkbox"
                                        name="appearInCashier"
                                    />
                                </label>
                                      </div>

                                      <div className="col-lg-4" >
                                          <label><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" />
                                              <br />
                                              <Field
                                                  disabled={readOnly}
                                                  className="PrivateSwitchBase-input-93"
                                                  type="checkbox"
                                                  name="active"
                                              />
                                          </label>
                                      </div>

                        </div>

                    </Form>         
                )}
            
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
                <> </>
                {!actionsLoading && (
                    <CheckPermissions
                        userFunctionalities={[UserFunctionalityE.UrediSkupineIzdelkov]}>
                        <button
                            type="submit"
                            onClick={() => handleSubmit()}
                            className="btn btn-primary btn-elevate"
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE" />
                        </button>
                    </CheckPermissions>
                )}
                          
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
