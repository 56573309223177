import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function OrganizationEditDialogHeader({ id }) {
  const intl = useIntl();
  // Organizations Redux state
  const { organizationForEdit, actionsLoading } = useSelector(
    (state) => ({
      organizationForEdit: state.organizations.organizationForEdit,
      actionsLoading: state.organizations.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
    useEffect(() => {
      let _title = id ? "" : intl.formatMessage({ id: "AUTH.ORGANIZATION.NEW" }) ;
        if (organizationForEdit && id) {
            _title = intl.formatMessage({ id: "AUTH.ORGANIZATION.EDIT" }) + ` '${organizationForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [organizationForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
