import axios from "axios";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDocuments(queryParams) {
    return axios.post("/api/document/find", queryParams );
}

// CREATE =>  POST: add a new user to the server
export function createDocument(document) {
    return axios.post("/api/document", document );
}

export function getDocumentById(documentId) {
    return axios.get("/api/document/" + documentId );
}
export function getDocumentByIds(ids, type) {
    return axios.post("/api/document/send", { ids: ids, type: type});
}

// UPDATE => PUT: update the user on the server
export function updateDocument(document) {
    return axios.put("/api/document/" + document.id, document);
}

// DELETE => delete the user from the server
export function deleteDocument(documentId) {
    return axios.delete("/api/document/" + documentId );
}

export function checkIfOtherDocumentNumberYetExist(id, number, documentType) {
    return axios.post("/api/document/checkIfOtherDocumentNumberYetExist", {
        id: id == undefined ? -1 : id,
        number: number,
        documentType: documentType
    });
}

export function getStockByItemId(id, warehouse) {
    var warehouseQuery = "";
    if (warehouse != undefined && warehouse != null) {
        warehouseQuery = "?warehouseId=" + warehouse.id;
    }
    return axios.get("/api/item/getStockByItemId/" + id + warehouseQuery);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findHistory(queryParams) {
    return axios.post("/api/document/history", queryParams);
}

export function checkIfCanCreateERacun(ids) {
    return axios.post("/api/document/checkIfCanCreateERacun", { ids });
}