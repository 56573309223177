import React, { useMemo, useState } from "react";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { checkIfCanCreateERacun } from "../../../_redux/documents/documentsCrud"
import InfoPopup from "../../../../Helpers/InfoPopup"

export function DocumentsGrouping() {
  const intl = useIntl();
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);


  // Office Users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        ids: documentsUIContext.ids,
        setIds: documentsUIContext.setIds,
        copyDocumentButtonClick: documentsUIContext.copyDocumentButtonClick,
        printDocumentsDialog: documentsUIContext.printDocumentsDialog,
        sendDocumentsToDeliveryNoteDialog: documentsUIContext.sendDocumentsToDeliveryNoteDialog,
        sendDocumentsToDeliveryNoteInvoiceDialog: documentsUIContext.sendDocumentsToDeliveryNoteInvoiceDialog,
        createEInvoiceButtonClick: documentsUIContext.createEInvoiceButtonClick,
        createESlogButtonClick: documentsUIContext.createESlogButtonClick,
        readOnly: documentsUIContext.readOnly,
        documentType: documentsUIContext.documentType
    };
    }, [documentsUIContext]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.documents }),
        shallowEqual
    );
    const { entities } = currentState;

    function checkIfDocumentsFromSameCustomerAndLinkedDocuments() {
        if (documentsUIProps.ids.length == 0) {
            return true;
        }
        var customerId = null;
        for (var index = 0; index < entities.length; ++index) {
            var row = entities[index];

            if (isSelectedId(row.id)) {
                if (customerId == null) {
                    customerId = row.customerId;
                }

                if (customerId != row.customerId) {
                    return false;
                }
                if (row.linkedDocumentIds != null ||
                    row.documentStornoId > 0) {
                    return false;
                }
            }
        }
        return true;
    }

    function isSelectedId(id) {
        for (var index = 0; index < documentsUIProps.ids.length; ++index) {
            var rowId = documentsUIProps.ids[index];
            if (id == rowId) {
                return true;
            }
        }
        return false;
    }

    function createEInvoiceButtonClick(ids) {
        checkIfCanCreateERacun(ids).then(({ data }) => {
            if (data.model.success) {
                var txtIds = "";
                for (var index = 0; index < ids.length; index++) {
                    if (txtIds == "") {
                        txtIds = ids[index];
                    } else {
                        txtIds += "," + ids[index];
                    }
                }
                window.location = "/api/document/DownloadERacun/" + txtIds;
            } else {
                setMessage(data.model.message);
                setOpen(true);
            }
        });
    }

    function createESlogButtonClick(ids){
        checkIfCanCreateERacun(ids).then(({ data }) => {
            if (data.model.success) {
                var txtIds = "";
                for (var index = 0; index < ids.length; index++) {
                    if (txtIds == "") {
                        txtIds = ids[index];
                    } else {
                        txtIds += "," + ids[index];
                    }
                }
                window.location = "/api/document/DownloadESlog/" + txtIds;
            } else {
                setMessage(data.model.message);
                setOpen(true);
            }
        });
    }

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{documentsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
                      <div>
                          {documentsUIProps.ids.length == 1 && !documentsUIProps.readOnly && (
                              <button
                                  type="button"
                                  className="btn btn-light-primary font-weight-bolder font-size-sm"
                                  onClick={() => documentsUIProps.copyDocumentButtonClick(documentsUIProps.ids)}
                              >
                                  <i className="fa fa-copy"></i><FormattedMessage id="COPY" />
                              </button>
                            )}
                           &nbsp;
                          <button
                              type="button"
                              className="btn btn-light-primary font-weight-bolder font-size-sm"
                              onClick={documentsUIProps.printDocumentsDialog}
                          >
                              <i className="fa fa-print"></i><FormattedMessage id="PRINT" />
                          </button>

                          {!documentsUIProps.readOnly && documentsUIProps.documentType == 11 && checkIfDocumentsFromSameCustomerAndLinkedDocuments() && (
                              <>
                                  &nbsp;
                                  <button
                                      type="button"
                                      className="btn btn-light-primary font-weight-bolder font-size-sm"
                                      onClick={documentsUIProps.sendDocumentsToDeliveryNoteDialog}
                                  >
                                      <i className="fa fa-clipboard"></i><FormattedMessage id="SEND_TO_DELIVERY_NOTE" />
                                  </button> 
                              </>
                          )}


                          {!documentsUIProps.readOnly &&
                            (documentsUIProps.documentType == 1) &&
                              checkIfDocumentsFromSameCustomerAndLinkedDocuments()
                              && (
                              <>
                                    &nbsp;
                                    <button
                                      type="button"
                                      className="btn btn-light-primary font-weight-bolder font-size-sm"
                                      onClick={documentsUIProps.sendDocumentsToDeliveryNoteInvoiceDialog}
                                    >
                                        <i className="fa fa-file-invoice"></i><FormattedMessage id="SEND_TO_DELIVERY_NOTE_INVOICE" />
                                    </button> 
                              </>
                              )}

                          {(documentsUIProps.documentType == 3 ||
                              documentsUIProps.documentType == 5 ||
                              documentsUIProps.documentType == 7
                              ) &&
                              documentsUIProps.ids.length > 0
                              && (
                                  <>
                                        &nbsp;
                                        <button
                                            type="button"
                                            className="btn btn-light-primary font-weight-bolder font-size-sm"
                                      onClick={() => createEInvoiceButtonClick(documentsUIProps.ids)}
                                        >
                                                <i className="fa fa-file-invoice"></i><FormattedMessage id="CREATE_E_INVOICE" />
                                        </button>
                                        &nbsp;
                                        <button
                                          type="button"
                                          className="btn btn-light-primary font-weight-bolder font-size-sm"
                                          onClick={() => createESlogButtonClick(documentsUIProps.ids)}
                                      >
                                          <i className="fa fa-receipt"></i><FormattedMessage id="CREATE_E_SLOG" />
                                      </button>
                                  </>
                              )}

            </div>
          </div>
        </div>
        </div>
        {/* end::Form */}
        <InfoPopup message={message} open={open} onClose={() => setOpen(false)} />
    </div>
  );
}
