import {createSlice} from "@reduxjs/toolkit";

const initialUserRolesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  userRoleForEdit: undefined,
    userFunctionalities: undefined,
    lastError: null,
    deleteResult : null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const userRolesSlice = createSlice({
    name: "userRoles",
    initialState: initialUserRolesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
        state.error = null;
        state.deleteResult = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUserRoleById
    userRoleFetched: (state, action) => {
        state.actionsLoading = false;
        state.userRoleForEdit = action.payload.userRoleForEdit;
        state.error = null;
    },
    // userFunctionalities
    userFunctionalitiesFetched: (state, action) => {
        state.actionsLoading = false;
        state.userFunctionalities = action.payload.userFunctionalities;
        state.error = null;
    },
    // findUserRoles
    userRolesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUserRole
    userRoleCreated: (state, action) => {
      state.actionsLoading = false;
        state.error = null;
      state.entities.push(action.payload.model);
    },
    // updateUserRole
    userRoleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.userRole.id) {
              return action.payload.userRole;
        }
        return entity;
      });
    },
    // deleteUserRole
    userRoleDeleted: (state, action) => {
      state.error = null;
        state.actionsLoading = false;
        state.deleteResult = action.payload.model;
        if (action.payload.model == true) {
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        }
    },
  }
});
