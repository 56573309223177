import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import { initialFilter } from "./UserRolesUIHelpers";

const UserRolesUIContext = createContext();

export function useUserRolesUIContext() {
    return useContext(UserRolesUIContext);
}

export const UserRolesUIConsumer = UserRolesUIContext.Consumer;

export function UserRolesUIProvider({ type, userRolesUIEvents, children }) {
  initialFilter.type = type; 
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initUserRole = {
        id: undefined,
        title: "",
        type: type
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initUserRole,
      newUserRoleButtonClick: userRolesUIEvents.newUserRoleButtonClick,
      openEditUserRoleDialog: userRolesUIEvents.openEditUserRoleDialog,
      openDeleteUserRoleDialog: userRolesUIEvents.openDeleteUserRoleDialog,
      openDeleteUserRolesDialog: userRolesUIEvents.openDeleteUserRolesDialog
  };

    return <UserRolesUIContext.Provider value={value}>{children}</UserRolesUIContext.Provider>;
}