import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage } from "react-intl";
import PdfViewer from "../../../../../components/PdfViewer"

export function CashierPrintDialog({ id, show, onHide }) {
  // Office users UI Context
    const cashiersUIContext = useCashiersUIContext();
    const cashiersUIProps = useMemo(() => {
    return {
        queryParams: cashiersUIContext.queryParams,
    };
    }, [cashiersUIContext]);

  // Office users Redux state
  const dispatch = useDispatch();
  

  // looking for loading/dispatch
  useEffect(() => {}, [dispatch]);

  return (
    <Modal
      show={show}
          onHide={onHide}
          size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="PREVIEW" />
        </Modal.Title>
      </Modal.Header>
          <Modal.Body>
            {id > 0 && (
                <PdfViewer
                    url={'../../../../API/Cashier/Report/' + id}
                /> 
            )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
                  >
                      <FormattedMessage id="AUTH.GENERAL.CLOSE" />
          </button>
          
        </div>
      </Modal.Footer>
    </Modal>
  );
}
