import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { UserRolesFilter } from "./userRoles-filter/UserRolesFilter";
import { UserRolesTable } from "./userRoles-table/UserRolesTable";
import { UserRolesGrouping } from "./userRoles-grouping/UserRolesGrouping";
import { useUserRolesUIContext } from "./UserRolesUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function OfficeUserRolesCard() {
    const intl = useIntl();
    const userRolesUIContext = useUserRolesUIContext();
    const userRolesUIProps = useMemo(() => {
    return {
        ids: userRolesUIContext.ids,
        newUserRoleButtonClick: userRolesUIContext.newUserRoleButtonClick,
    };
    }, [userRolesUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.OFFICE_USER_ROLES" } )} >
              <CardHeaderToolbar>
                  <CheckPermissions
                      userFunctionalities={[UserFunctionalityE.UrediVlogeZaPisarniskePravice]}>
                      <button
                          type="button"
                          className="btn btn-primary"
                          onClick={userRolesUIProps.newUserRoleButtonClick}
                      >
                          <FormattedMessage id="SETTING.USER_ROLE.NEW" />
                      </button>
                  </CheckPermissions>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UserRolesFilter />
        {userRolesUIProps.ids.length > 0 && <UserRolesGrouping />}
              <UserRolesTable type={2} />
      </CardBody>
    </Card>
  );
}
