// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import * as actions from "../../../_redux/pricelists/pricelistsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { EffectiveDateFromColumnFormatter } from "./column-formatters/EffectiveDateFromColumnFormatter";
import { MoneyColumnFormatter } from "./column-formatters/MoneyColumnFormatter";
import * as uiHelpers from "./PricelistsUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { usePricelistsUIContext } from "./PricelistsUIContext";
import { useIntl } from "react-intl";


export function PricelistsTable() {
    const intl = useIntl();

  // Pricelists UI Context
  const pricelistsUIContext = usePricelistsUIContext();
  const pricelistsUIProps = useMemo(() => {
    return {
      openEditPricelistDialog: pricelistsUIContext.openEditPricelistDialog,
        openDeletePricelistDialog: pricelistsUIContext.openDeletePricelistDialog,
        readOnly: pricelistsUIContext.readOnly
    };
  }, [pricelistsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
      (state) => ({ currentState: state.items }),
    shallowEqual
  );
    const { pricelists } = currentState;


  const columns = [
    {
        dataField: "effectiveDateFrom",
          text: intl.formatMessage({ id: "AUTH.INPUT.PRICE_DATE" }),
        sort: false,
          formatter: EffectiveDateFromColumnFormatter
      },
      {
          dataField: "priceWithoutVat",
          text: intl.formatMessage({ id: "AUTH.INPUT.PRICE_WITHOUT_VAT" }),
          sort: false,
          formatter: MoneyColumnFormatter
      },
      {
          dataField: "priceWithVat",
          text: intl.formatMessage({ id: "AUTH.INPUT.PRICE_WITH_VAT" }),
          sort: false,
          formatter: MoneyColumnFormatter
      },
      {
          hidden: pricelistsUIProps.readOnly,
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditPricelistDialog: pricelistsUIProps.openEditPricelistDialog,
        openDeletePricelistDialog: pricelistsUIProps.openDeletePricelistDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                      data={pricelists === null ? [] : pricelists}
                columns={columns}
              >
                      <PleaseWaitMessage entities={pricelists} />
                      <NoRecordsFoundMessage entities={pricelists} />
              </BootstrapTable>

    </>
  );
}
