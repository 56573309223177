import React, { useState } from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog/UsersLoadingDialog";
import { UserEditDialog } from "./user-edit-dialog/UserEditDialog";
import { UserDeleteDialog } from "./user-delete-dialog/UserDeleteDialog";
import { UsersDeleteDialog } from "./users-delete-dialog/UsersDeleteDialog";
import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./UsersCard";
import { shallowEqual, useSelector } from "react-redux";
import InfoPopup from "../../../Helpers/InfoPopup"
import { useIntl } from "react-intl";

export function UsersPage({ history }) {
    const intl = useIntl();
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const { user } = useSelector(state => state.auth);
    const { currentState } = useSelector(
        (state) => ({ currentState: state.users }),
        shallowEqual
    );
    const { totalCount } = currentState;


  const usersUIEvents = {
      newUserButtonClick: () => {
          if (totalCount < user.organizationCashierUserCount) {
              history.push("/users/cashier/new");
          } else {
              setOpen(true);
              setMessage(intl.formatMessage({
                  id: "GENERAL.CAN_NOT_ADDED_USERS",
              }));
          }
    },
    openEditUserDialog: (id) => {
        history.push(`/users/cashier/${id}/edit`);
    },
    openDeleteUserDialog: (id) => {
        history.push(`/users/cashier/${id}/delete`);
    },
    openDeleteUsersDialog: () => {
        history.push(`/users/cashier/deleteUsers`);
    }
  }

  return (
      <UsersUIProvider usersUIEvents={usersUIEvents}>
        <UsersLoadingDialog />
          <Route path="/users/cashier/new">
        {({ history, match }) => (
           <UserEditDialog
            show={match != null}
            onHide={() => {
                history.push("/users/cashier");
            }}
          />
        )}
      </Route>
          <Route path="/users/cashier/:id/edit">
        {({ history, match }) => (
            <UserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/users/cashier");
            }}
          />
        )}
      </Route>
          <Route path="/users/cashier/deleteUsers">
        {({ history, match }) => (
            <UsersDeleteDialog
            show={match != null}
            onHide={() => {
                history.push("/users/cashier");
            }}
          />
        )}
      </Route>
          <Route path="/users/cashier/:id/delete">
        {({ history, match }) => (
            <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/users/cashier");
            }}
          />
        )}
      </Route>
          
        <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
      <UsersCard />
      </UsersUIProvider>
  );
}
