// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";

export function StatusColumnFormatter(
  cellContent,
  row,
  rowIndex
) {
  return (
      <>
          {cellContent == 1 && <span class="badge badge-primary"><FormattedMessage id="AUTH.GENERAL.EXPORT_STATUS_WORKING" /></span>}
          {cellContent == 2 && <span class="badge badge-success"><FormattedMessage id="AUTH.GENERAL.EXPORT_STATUS_DONE" /></span>}
          {cellContent == 3 && <span class="badge badge-danger"><FormattedMessage id="AUTH.GENERAL.EXPORT_STATUS_ERROR" /></span>}
    </>
  );
}
