import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { OffersPage } from "./offers/OffersPage";
import { DeliveryNotesPage } from "./deliveryNotes/DeliveryNotesPage";
import { InvoicesPage } from "./invoices/InvoicesPage";
import { CreditsPage } from "./credits/CreditsPage";
import { ExpensesPage } from "./expenses/ExpensesPage";
import { ReceiptsPage } from "./receipts/ReceiptsPage";
import { DeliveryNoteInvoicesPage } from "./deliveryNoteInvoices/DeliveryNoteInvoicesPage";
import { EndsPage } from "./ends/EndsPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function SalesPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect
                        exact={true}
                        from="/sales"
                        to="/sales/offers"
                    />
                }
                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledPonudb, UserFunctionalityE.UrediPonudbe]) && (
                    <ContentRoute path="/sales/offers" component={OffersPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobavnic, UserFunctionalityE.UrediDobavnice]) && (
                    <ContentRoute path="/sales/delivery_notes" component={DeliveryNotesPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledRacunov]) && (
                    <ContentRoute path="/sales/invoices" component={InvoicesPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobropisovOffice]) && (
                    <ContentRoute path="/sales/credits" component={CreditsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledIzdatkovOffice]) && (
                    <ContentRoute path="/sales/expenses" component={ExpensesPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledPrejemkovOffice]) && (
                    <ContentRoute path="/sales/receipts" component={ReceiptsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobavniceRacuni, UserFunctionalityE.UrediDobavniceRacune]) && (
                    <ContentRoute path="/sales/delivery_note_invoices" component={DeliveryNoteInvoicesPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledZakljuckov]) && (
                    <ContentRoute path="/sales/ends" component={EndsPage} />
                )}
                
            </Switch>
        </Suspense>
    );
}
