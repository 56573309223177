// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CashiersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import InfoPopup from "../../../../Helpers/InfoPopup"

export function CashiersTable() {
    const intl = useIntl();
    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
  // Cashiers UI Context
  const cashiersUIContext = useCashiersUIContext();
  const cashiersUIProps = useMemo(() => {
    return {
        ids: cashiersUIContext.ids,
        setIds: cashiersUIContext.setIds,
        queryParams: cashiersUIContext.queryParams,
        setQueryParams: cashiersUIContext.setQueryParams,
        openEditCashierDialog: cashiersUIContext.openEditCashierDialog,
        openDeleteCashierDialog: cashiersUIContext.openDeleteCashierDialog,
        printInternAktDialog: cashiersUIContext.printInternAktDialog,
        registerDialog: cashiersUIContext.registerDialog,
        unregisterDialog: cashiersUIContext.unregisterDialog,
        openConnectDialog: cashiersUIContext.openConnectDialog,
        openDeconnectDialog: cashiersUIContext.openDeconnectDialog
    };
  }, [cashiersUIContext]);

  // Getting curret state of cashiers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.cashiers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Cashiers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    cashiersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCashiers(cashiersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashiersUIProps.queryParams, dispatch]);

  // Table columns
    const columns = [
        {
          dataField: "id",
          text: "#",
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
        },
        {
            dataField: "businessPremiseTitle",
            text: intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_PREMISE" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "businessPremiseId",
            text: intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_PREMISE_ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "cashierId",
            text: intl.formatMessage({ id: "AUTH.GENERAL.CASHIER_ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "premiseType",
            text: intl.formatMessage({ id: "AUTH.GENERAL.INTERNI_AKT" }),
            formatter: columnFormatters.InternAktColumnFormatter,
            formatExtraData: {
                printInternAktDialog: cashiersUIProps.printInternAktDialog
            },
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                user: user,
                openEditCashierDialog: cashiersUIProps.openEditCashierDialog,
                openDeleteCashierDialog: cashiersUIProps.openDeleteCashierDialog,
                registerDialog: cashiersUIProps.registerDialog,
                unregisterDialog: cashiersUIProps.unregisterDialog,
                openConnectDialog: cashiersUIProps.openConnectDialog,
                openDeconnectDialog: cashiersUIProps.openDeconnectDialog
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );


  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: cashiersUIProps.queryParams.pageSize,
      page: cashiersUIProps.queryParams.pageNumber,
      paginationTotalRenderer: customTotal,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  cashiersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
        </PaginationProvider>
        <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
