import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { FormattedMessage, useIntl } from "react-intl";

const prepareFilter = (queryParams, values) => {
    const { searchText, status } = values;
    const newQueryParams = { ...queryParams };
  
    newQueryParams.query = searchText;
    newQueryParams.status = status !== "" ? + status : undefined;
    return newQueryParams;
};

export function CustomersFilter({ listLoading }) {
    const intl = useIntl();

  // Customers UI Context
    const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
        queryParams: customersUIContext.queryParams,
        setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
            status: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
        <div className="form-group row">
            <div className="col-lg-2">
                <select
                    className="form-control"
                    name="status"
                    onChange={(e) => {
                        setFieldValue("status", e.target.value);
                        handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.status}
                >
                    <option value="1">{intl.formatMessage({ id: "ECOMMERCE.COMMON.ACTIVE" })}</option>
                    <option value="2">{intl.formatMessage({ id: "ECOMMERCE.COMMON.INACTIVE" })}</option>
                </select>
                <small className="form-text text-muted">
                    <b><FormattedMessage id="ECOMMERCE.COMMON.FILTER" /></b> <FormattedMessage id="ECOMMERCE.COMMON.BY_STATUS" />
                </small>
            </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.SEARCH"
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
