import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    listLoading: false,
    actionsLoading: false,
    trafficToday: null,
    trafficCurrentMonth: null,
    trafficLast12MonthsAmount: null,
    salesPurchaseByYears: null,
    salesByYears: null,
    lastError: null,
    deleteResult: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialDashboardState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.deleteResult = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        trafficTodayFetched: (state, action) => {
            state.actionsLoading = false;
            state.trafficToday = action.payload.trafficToday;
            state.error = null;
        },
        trafficCurrentMonthFetched: (state, action) => {
            state.actionsLoading = false;
            state.trafficCurrentMonth = action.payload.trafficCurrentMonth;
            state.error = null;
        },
        trafficLast12MonthsFetched: (state, action) => {
            state.actionsLoading = false;
            state.trafficLast12Months = action.payload.trafficLast12Months;
            state.error = null;
        },
        salesPurchaseByYearsFetched: (state, action) => {
            state.actionsLoading = false;
            state.salesPurchaseByYears = action.payload.salesPurchaseByYears;
            state.error = null;
        },
        salesByYearsFetched: (state, action) => {
            state.actionsLoading = false;
            state.salesByYears = action.payload.salesByYears;
            state.error = null;
        }
    }
});
