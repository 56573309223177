import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage } from "react-intl";
import PdfViewer from "../../../../../components/PdfViewer"

export function DocumentsPrintDialog({ show, id, onHide }) {
  // Office users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        ids: documentsUIContext.ids,
        setIds: documentsUIContext.setIds,
        queryParams: documentsUIContext.queryParams,
    };
    }, [documentsUIContext]);

  // Office users Redux state
  const dispatch = useDispatch();
  

  // if office users weren't selected we should close modal
  useEffect(() => {
      if (!id|| !documentsUIProps.ids || documentsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsUIProps.ids]);

  // looking for loading/dispatch
  useEffect(() => {}, [dispatch]);

  return (
    <Modal
      show={show}
          onHide={onHide}
          size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="PREVIEW" />
        </Modal.Title>
      </Modal.Header>
          <Modal.Body>
              {id != undefined && id != null && (
                  <PdfViewer
                      url={'../../../API/Document/Report?ids=' + id}
                  />
                  
              )}  

              {(id == undefined || id == null) && documentsUIProps.ids != undefined && documentsUIProps.ids != null && documentsUIProps.ids.length > 0 && (
                  <PdfViewer
                      url={'../../API/Document/Report?ids=' + documentsUIProps.ids}
                  />
                )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
                  >
                      <FormattedMessage id="AUTH.GENERAL.CLOSE" />
          </button>
          
        </div>
      </Modal.Footer>
    </Modal>
  );
}
