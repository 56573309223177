import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import QRCode from "qrcode.react";


export function CashierConnectDialog({ id, show, onHide }) {
    // Cashiers UI Context
    const [timer, setTimer] = useState(null);
    const cashiersUIContext = useCashiersUIContext();
    const cashiersUIProps = useMemo(() => {
        return {
            initCashier: cashiersUIContext.initCashier,
            setIds: cashiersUIContext.setIds,
            queryParams: cashiersUIContext.queryParams
        };
    }, [cashiersUIContext]);

    // Cashiers Redux state
    const dispatch = useDispatch();
    const { actionsLoading, cashierForEdit} = useSelector(
            (state) => ({
                actionsLoading: state.cashiers.actionsLoading,
                cashierForEdit: state.cashiers.cashierForEdit,
            }),
            shallowEqual
        );

    useEffect(() => {
        dispatch(actions.fetchCashier(id));
    }, [id, dispatch]);

    function tick() {
        dispatch(actions.fetchCashier(id));
    }

    useEffect(() => {
        if (show) {
            var id = setInterval(() => tick(), 4000);
            setTimer(id);
        } else {
            clearInterval(timer);
        }
    }, [show]);

    function onHideRefresh() {
        onHide();
        cashiersUIProps.setIds([]);
        dispatch(actions.fetchCashiers(cashiersUIProps.queryParams));
    }

    return (
      <>
        <Modal
          show={show}
        onHide={onHideRefresh}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                <FormattedMessage id="CONNECT_DEVICE" />
            </Modal.Title>
          </Modal.Header>
                <Modal.Body>
                    {cashierForEdit != undefined && cashierForEdit.deviceKey == null && (
                        <>
                            <span>
                                <FormattedMessage id="CASHIER.CONNECT.TEXT" />
                            </span>
                            <br/>
                            <br />
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <label><FormattedMessage id="AUTH.INPUT.KEY" /></label>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className="form-control"
                                        value={cashierForEdit.publicKey}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    <QRCode value={cashierForEdit.publicKey} />
                                </div>

                            </div>
                        </>
                    )}


                    {cashierForEdit != undefined && cashierForEdit.deviceKey != null && (
                        <>
                            <span>
                                <FormattedMessage id="CASHIER.CONNECT.SUCCESS" />
                            </span>
                        </>
                        )}
                
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHideRefresh}
                className="btn btn-light btn-elevate"
                      >
                    <FormattedMessage id="AUTH.GENERAL.CLOSE" />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
    </>
  );
}
