import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { usersSlice } from "../app/modules/User/_redux/users/usersSlice";
import { officeUsersSlice } from "../app/modules/User/_redux/officeUsers/officeUsersSlice";
import { userRolesSlice } from "../app/modules/Setting/_redux/userRoles/userRolesSlice";
import { codeListsSlice } from "../app/modules/Setting/_redux/codeLists/codeListsSlice";
import { customersSlice } from "../app/modules/Customer/_redux/customers/customersSlice";
import { yourCompanySlice } from "../app/modules/Setting/_redux/yourCompany/yourCompanySlice";
import { yourProfileSlice } from "../app/modules/Setting/_redux/yourProfile/yourProfileSlice";
import { organizationsSlice } from "../app/modules/Admin/_redux/organizations/organizationsSlice";
import { activeUsersSlice } from "../app/modules/Admin/_redux/activeUsers/activeUsersSlice";
import { itemGroupsSlice } from "../app/modules/Item/_redux/itemGroups/itemGroupsSlice";
import { itemsSlice } from "../app/modules/Item/_redux/items/itemsSlice";
import { endsSlice } from "../app/modules/Sales/_redux/ends/endsSlice";
import { cashiersSlice } from "../app/modules/Setting/_redux/cashiers/cashiersSlice";
import { cacheSlice } from "../_redux/cache/cacheSlice";
import { documentsSlice } from "../app/modules/Document/_redux/documents/documentsSlice";
import { importSlice } from "../app/modules/Setting/_redux/import/importSlice";
import { exportSlice } from "../app/modules/Setting/_redux/export/exportSlice";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboards/dashboardSlice";


export const rootReducer = combineReducers({
    auth: auth.reducer,
    users: usersSlice.reducer,
    officeUsers: officeUsersSlice.reducer,
    userRoles: userRolesSlice.reducer,
    organization: yourCompanySlice.reducer,
    codeLists: codeListsSlice.reducer,
    organizations: organizationsSlice.reducer,
    activeUsers: activeUsersSlice.reducer,
    customers: customersSlice.reducer,
    itemGroups: itemGroupsSlice.reducer,
    items: itemsSlice.reducer,
    profile: yourProfileSlice.reducer,
    cache: cacheSlice.reducer,
    documents: documentsSlice.reducer,
    cashiers: cashiersSlice.reducer,
    ends: endsSlice.reducer,
    import: importSlice.reducer,
    export: exportSlice.reducer,
    dashboard: dashboardSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
