// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { shallowEqual,  useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


export default function SelectDocumentType(props) {
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { documentTypes } = currentState;

    return (
        <>
            <Select
                disabled={props.readOnly}
                variant="outlined"
                className={`form-control form-control-solid }`}
                value={props.value}
                onChange={(event) => { props.onChange(event.target.value); }}
            >
                {documentTypes != undefined && documentTypes.map(option => (
                    <MenuItem key={option.id} value={option.id} >
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
