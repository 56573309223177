/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../app/enum/userFunctionalityE"
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
    const intl = useIntl();
    const [reports, setReports] = useState(officeReports == undefined ? [] : officeReports);
  const location = useLocation();
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { officeReports } = currentState;

    useEffect(() => {
        setReports(officeReports == undefined ? [] : officeReports)
    }, [officeReports]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
    };

    const getReport = () => {
        if (officeReports == undefined) {
            return [];
        }

        return officeReports
    };
    

    const searchReports = (query) => {
        if (query == null || query == undefined || query == "") {
            setReports(getReport(""));
        }
        var reports = getReport("");
        var list = [];
        for (var x = 0; x < reports.length; x++) {
            var report = reports[x];
            if (report.title.toLowerCase().includes(query.toLowerCase())) {
                list.push(report);
            }
        }
        setReports(list);
    };
    

  return (
    <>
        {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledPrometaZaDanes, UserFunctionalityE.PregledPrometaZaTekociMesec,
                  UserFunctionalityE.PregledPrometaVZadnjih12MesecihBrezDdv, UserFunctionalityE.PregledProdajePoLetih,
                  UserFunctionalityE.PregledProdajeNabavePoLetih]}
              >
                    {/*begi::DashBoard*/}
                    <li
                        className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/dashboard">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                            </span>
                            <span className="menu-text">
                                <FormattedMessage id="AUTH.GENERAL.DASHBOARD" />
                            </span>
                        </NavLink>
                    </li>
                    {/*end::DashBoard*/}
                </CheckPermissions>

              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledStrank, UserFunctionalityE.UrediStranke]}
              >
                  {/*begi::Customer*/}
                  <li
                      className={`menu-item ${getMenuItemActive("/customers", false)}`}
                      aria-haspopup="true"
                  >
                      <NavLink className="menu-link" to="/customers">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag1.svg")} />
                          </span>
                          <span className="menu-text">
                              <FormattedMessage id="AUTH.GENERAL.CUSTOMERS" />
                          </span>
                      </NavLink>
                  </li>
                  {/*end::DashBoard*/}
              </CheckPermissions>

              {/* ITEMS */}
              {/*begin::1 Level*/}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledSkupinIzdelkov, UserFunctionalityE.UrediSkupineIzdelkov,
                      UserFunctionalityE.PregledIzdelkov, UserFunctionalityE.UrediIzdelke]}>
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/itemGroup", true
                      )} ${getMenuItemActive(
                          "/item", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle nItemsHeader" to="/itemGroup">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Food/Coffee1.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ITEMS" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledSkupinIzdelkov, UserFunctionalityE.UrediSkupineIzdelkov]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/itemGroups"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link nItemsGroups"
                                          to="/itemGroups">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ITEM_GROUPS" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>
                              <CheckPermissions userFunctionalities={[UserFunctionalityE.PregledIzdelkov, UserFunctionalityE.UrediIzdelke]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/items"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link nItems"
                                          to="/items">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ITEMS" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>
                          </ul>
                      </div>
                  </li>
                  {/*end::ITEMS*/}
              </CheckPermissions>


              {/*start::SALES*/}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledPonudb, UserFunctionalityE.UrediPonudbe,
                      UserFunctionalityE.PregledDobavnic, UserFunctionalityE.UrediDobavnice,
                      UserFunctionalityE.PregledRacunov, UserFunctionalityE.UrediRacune]}
              >
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/sales", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/google-material">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Euro.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.SALES" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">


                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledDobavniceRacuni, UserFunctionalityE.UrediDobavniceRacune]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/delivery_note_invoices"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/delivery_note_invoices">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.DELIVERY_NOTE_INVOICES" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledPonudb, UserFunctionalityE.UrediPonudbe]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/offers"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/offers">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.OFFERS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledDobavnic, UserFunctionalityE.UrediDobavnice]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/delivery_notes"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/delivery_notes">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.DELIVERY_NOTES" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledRacunov]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/invoices"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/invoices">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.INVOICES" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledDobropisovOffice]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/credits"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/credits">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CREDITS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledIzdatkovOffice]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/expenses"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/expenses">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.EXPENSES" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledPrejemkovOffice]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/receipts"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/receipts">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.RECEIPTS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledZakljuckov]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/sales/ends"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/sales/ends">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ENDS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                          </ul>
                      </div>
                  </li>
              </CheckPermissions>
              {/*end::SALES*/}



              {/*start::PURCHASE*/}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledPrevzemnihListov, UserFunctionalityE.UrediPrevzemneListe,
                      UserFunctionalityE.PregledInventurnihPopisov, UserFunctionalityE.UrediInventurnePopise,
                      UserFunctionalityE.PregledOdpisov, UserFunctionalityE.UrediOdpise,
                      UserFunctionalityE.PregledMedskladiscnihPrenosov, UserFunctionalityE.UrediMedskladiscnePrenose,
                      UserFunctionalityE.PregledLastnihPorab, UserFunctionalityE.UrediLastnePorabe,
                      UserFunctionalityE.PregledVracil, UserFunctionalityE.UrediVracila,
                      UserFunctionalityE.PregledProizvodnihNalogov, UserFunctionalityE.UrediProizvodneNaloge]}
              >
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/purchase", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/google-material">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.PURCHASE" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledPrevzemnihListov, UserFunctionalityE.UrediPrevzemneListe]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/takeovers"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/takeovers">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.TAKE_OVERS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>
                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledInventurnihPopisov, UserFunctionalityE.UrediInventurnePopise]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/inventories"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/inventories">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.INVENTORIES" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledOdpisov, UserFunctionalityE.UrediOdpise]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/writeoffs"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/writeoffs">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.WRITEOFFS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledMedskladiscnihPrenosov, UserFunctionalityE.UrediMedskladiscnePrenose]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/internalStockTransfers"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/internalStockTransfers">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.INTERNAL_STOCK_TRANSFERS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledLastnihPorab, UserFunctionalityE.UrediLastnePorabe]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/ownConsumptions"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/ownConsumptions">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.OWN_CONSUMPTIONS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledVracil, UserFunctionalityE.UrediVracila]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/refunds"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/refunds">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.REFUNDS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledProizvodnihNalogov, UserFunctionalityE.UrediProizvodneNaloge]}>
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/purchase/productionOrders"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/purchase/productionOrders">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.PRODUCTION_ORDERS" /></span>
                                      </NavLink>
                                  </li>
                              </CheckPermissions>

                          </ul>
                      </div>
                  </li>
              </CheckPermissions>
              {/*end::PURCHASE*/}

              {/* REPORTS */}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PorociloPoDnevih,
                      UserFunctionalityE.PorocilaPoSkupinahArtiklov,
                      UserFunctionalityE.PorociloPoArtiklih,
                      UserFunctionalityE.PorociloPoUporabnikih,
                      UserFunctionalityE.PorociloPoDnevihInUrah,
                      UserFunctionalityE.PorociloPoDobavnicahPoDnevih,
                      UserFunctionalityE.PorociloPoNacinihPlacilaPoDnevih,
                      UserFunctionalityE.PorociloPrometaPoUrah,
                      UserFunctionalityE.PorociloZalogaNaDan,
                      UserFunctionalityE.PorociloNabaveArtiklovPoDobaviteljih,
                      UserFunctionalityE.PorociloProdajePoUporabnikihPoArtiklih,
                      UserFunctionalityE.PorociloProdajaNabavaInMarza,
                      UserFunctionalityE.PorociloPoStrankah,
                      UserFunctionalityE.PorociloArtiklovVSestavnicah,
                      UserFunctionalityE.PorociloPorabeArtiklovPoNC,
                      UserFunctionalityE.PorociloZalogaNaDanPOS,
                      UserFunctionalityE.PorociloProdajePoMizah,
                      UserFunctionalityE.PorociloProdajePoDobaviteljih,
                      UserFunctionalityE.PorociloZalogeNaDanZNCinPC,
                      UserFunctionalityE.PorociloObdelavePoMizah,
                      UserFunctionalityE.PorociloPoDokumentih,
                      UserFunctionalityE.PorociloPoUporabnikihPoNacinuPlacila,
                      UserFunctionalityE.TdrPorocilo,
                      UserFunctionalityE.PorociloMarzeArtiklovPoSestavnicahGAST,
                      UserFunctionalityE.RacuniStornacijeSPopusti,
                      UserFunctionalityE.RacuniStornoVezanoNaRacun,
                      UserFunctionalityE.PorociloProdajePoUporabnikihPoSkupinah,
                      UserFunctionalityE.ObracunLastnePorabe,
                      UserFunctionalityE.PrimerjavaProdajePoLetihInMesecih,
                      UserFunctionalityE.PorociloPorabeArtiklov,
                      UserFunctionalityE.PrviZadnjiIzdanRacunUporabnika,
                      UserFunctionalityE.TrenutnoVeljavenCenik,
                      UserFunctionalityE.PorociloGastZaNezavezance,
                      UserFunctionalityE.PorociloNVzalogeNaDanPoFIFO,
                      UserFunctionalityE.ProdajaPoIzbranemArtiklu,
                      UserFunctionalityE.RvcPoSkupinahArtiklov,
                      UserFunctionalityE.OcenaDdvja,
                      UserFunctionalityE.ProdaniArtikliPoUrah,
                      UserFunctionalityE.IzdaneDobavnicePoKupcih]}>
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/google-material", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/google-material">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.REPORTS" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <li className="menu-item" >
                                  <div className="input-group rounded menu-link">
                                      <input type="search"
                                          className="form-control ds-input"
                                          placeholder={intl.formatMessage({
                                              id: "AUTH.GENERAL.SEARCH_REPORTS",
                                          })}
                                          aria-label={intl.formatMessage({
                                              id: "AUTH.GENERAL.SEARCH_REPORTS",
                                          })}
                                          aria-describedby="search-addon"
                                          onChange={(e) => {
                                              searchReports(e.target.value);
                                          }}

                                      />
                                  </div>
                              </li>
                                  
                              {reports.map(option => (
                                    <CheckPermissions key={"report_" + option.id}
                                        userFunctionalities={[option.id]}>
                                        {/*begin::3 Level*/}
                                        <li
                                            className={`menu-item  ${getMenuItemActive(
                                                option.url
                                            )}`}
                                            aria-haspopup="true"
                                        >
                                            <NavLink className="menu-link"
                                                to={option.url}>
                                                <i className="menu-bullet menu-bullet-dot">
                                                    <span />
                                                </i>
                                                <span className="menu-text">{option.title}</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </CheckPermissions>
                              ))}
                              {reports.length == 0 && <li className="menu-item" >
                                  <span className=" menu-link menu-text" ><FormattedMessage id="AUTH.GENERAL.NO_DATA" /></span>
                              </li>}  
                          </ul>
                      </div>
                  </li>
                  {/*end::REPORTS*/}
              </CheckPermissions>

                 {/* USERS */}
                    {/*begin::1 Level*/}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledBlagajniskihUporabnikov, UserFunctionalityE.UrediBlagajniskeUporabnike,
                  UserFunctionalityE.PregledPisarniskihUporabnikov, UserFunctionalityE.UrediPisarniskeUporabnike]}>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/users", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                    <NavLink className="menu-link menu-toggle" to="/google-material">
                    <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                    </span>
                        <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.USERS" /></span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <CheckPermissions
                                userFunctionalities={[UserFunctionalityE.PregledBlagajniskihUporabnikov, UserFunctionalityE.UrediBlagajniskeUporabnike]}>
                                {/*begin::3 Level*/}
                                <li
                                    className={`menu-item  ${getMenuItemActive(
                                        "/users/cashier"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link"
                                        to="/users/cashier">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CASHIER" /></span>
                                    </NavLink>
                                </li>
                                {/*end::3 Level*/}
                            </CheckPermissions>
                            <CheckPermissions userFunctionalities={[UserFunctionalityE.PregledPisarniskihUporabnikov, UserFunctionalityE.UrediPisarniskeUporabnike]}>
                                {/*begin::3 Level*/}
                                <li
                                    className={`menu-item  ${getMenuItemActive(
                                        "/users/office"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link"
                                        to="/users/office">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.OFFICE" /></span>
                                    </NavLink>
                                </li>
                                {/*end::3 Level*/}
                            </CheckPermissions>
                        </ul>
                    </div>
                    </li>
                  {/*end::USERS*/}
              </CheckPermissions>

              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledVlogZaBlagajniskePravice, UserFunctionalityE.UrediVlogeZaBlagajniskePravice,
                      UserFunctionalityE.PregledVlogZaPisarniskePravice, UserFunctionalityE.UrediVlogeZaPisarniskePravice,
                      UserFunctionalityE.UrediSifrante, UserFunctionalityE.UrediIzdelke, UserFunctionalityE.UrediInventurnePopise]}>
                  {/* SETTINGS */}
                  {/*begin::1 Level*/}
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/settings", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle nSettings" to="/google-material">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.SETTINGS" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <li
                                  className={`menu-item  ${getMenuItemActive(
                                      "/settings/profile"
                                  )}`}
                                  aria-haspopup="true"
                              >
                                  <NavLink className="menu-link"
                                      to="/settings/profile">
                                      <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                      </i>
                                      <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.YOUR_PROFIL" /></span>
                                  </NavLink>
                              </li>
                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledVasegaPodjetja, UserFunctionalityE.UrediVasePodjetje]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/settings/company"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link nYourCompany"
                                          to="/settings/company">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.YOUR_COMPANY" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>


                            <CheckPermissions
                                    userFunctionalities={[UserFunctionalityE.PregledVlogZaBlagajniskePravice, UserFunctionalityE.UrediVlogeZaBlagajniskePravice]}>
                                {/*begin::3 Level*/}
                                <li
                                    className={`menu-item  ${getMenuItemActive(
                                        "/settings/roles_cashier"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link"
                                        to="/settings/roles_cashier">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CASHIER_USER_ROLES" /></span>
                                    </NavLink>
                                </li>
                                    {/*end::3 Level*/}
                            </CheckPermissions>

                            <CheckPermissions
                                userFunctionalities={[UserFunctionalityE.PregledVlogZaPisarniskePravice, UserFunctionalityE.UrediVlogeZaPisarniskePravice]}>
                                {/*begin::3 Level*/}
                                <li
                                    className={`menu-item  ${getMenuItemActive(
                                        "/settings/roles_office"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link"
                                        to="/settings/roles_office">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.OFFICE_USER_ROLES" /></span>
                                    </NavLink>
                                </li>
                                {/*end::3 Level*/}
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledDavcnihBlagajn, UserFunctionalityE.UrediDavcneBlagajne]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item nCashiers ${getMenuItemActive(
                                          "/settings/cashiers"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/settings/cashiers">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CASHIERS" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.PregledSifrantov, UserFunctionalityE.UrediSifrante]}>
                                {/*begin::3 Level*/}
                                <li
                                    className={`menu-item  ${getMenuItemActive(
                                        "/settings/codelist"
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link"
                                          to="/settings/codelist">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CODELISTS" /></span>
                                    </NavLink>
                                </li>
                                {/*end::3 Level*/}
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.Izvozi]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/settings/export"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/settings/export">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.EXPORT" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>

                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.UrediIzdelke, UserFunctionalityE.UrediInventurnePopise]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/settings/import"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/settings/import">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.IMPORT" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>

                              <li
                                  className={`menu-item  ${getMenuItemActive(
                                      "/settings/change_password"
                                  )}`}
                                  aria-haspopup="true"
                              >
                                  <NavLink className="menu-link"
                                      to="/settings/change_password">
                                      <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                      </i>
                                      <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.CHANGE_PASSWORD" /></span>
                                  </NavLink>
                              </li>


                              <li
                                  className={`menu-item  ${getMenuItemActive(
                                      "/settings/app"
                                  )}`}
                                  aria-haspopup="true"
                              >
                                  <NavLink className="menu-link"
                                      to="/settings/app">
                                      <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                      </i>
                                      <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.APPLICATION" /></span>
                                  </NavLink>
                              </li>
                          </ul>
                      </div>
                  </li>
                  {/*end::SETTINGS*/}
              </CheckPermissions>

              {/* ADMINISTRATION */}
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.AktivniUporabniki, UserFunctionalityE.Organizacije]}>
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/google-material", true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                      <NavLink className="menu-link menu-toggle" to="/google-material">
                          <span className="svg-icon menu-icon">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-protected.svg")} />
                          </span>
                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ADMINISTRATION" /></span>
                          <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                              <CheckPermissions
                                  userFunctionalities={[UserFunctionalityE.AktivniUporabniki]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/admin/active_users"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/admin/active_users">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ACTIVE_USER" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>
                              <CheckPermissions userFunctionalities={[UserFunctionalityE.Organizacije]}>
                                  {/*begin::3 Level*/}
                                  <li
                                      className={`menu-item  ${getMenuItemActive(
                                          "/admin/organizations"
                                      )}`}
                                      aria-haspopup="true"
                                  >
                                      <NavLink className="menu-link"
                                          to="/admin/organizations">
                                          <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                          </i>
                                          <span className="menu-text"><FormattedMessage id="AUTH.GENERAL.ORGANIZATION" /></span>
                                      </NavLink>
                                  </li>
                                  {/*end::3 Level*/}
                              </CheckPermissions>
                          </ul>
                      </div>
                  </li>
                  {/*end::ADMINISTRATION*/}
              </CheckPermissions>

          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
