export function onlyDigits(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d/.test(keyValue)) {
        event.preventDefault();
    }
}

export function decimal(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d|,|-/.test(keyValue)) {
        event.preventDefault();
    }
}

export function convertToDateTime(dt) {
    return (dt.getMonth() + 1) + "/" + dt.getDate() + "/" + dt.getFullYear();
}

export function onlyPositiveDecimal(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d|,/.test(keyValue)) {
        event.preventDefault();
    }
}
