import React, { useMemo } from "react";
import { PricelistsTable } from "./PricelistsTable";
import { PricelistDeleteDialog } from "./PricelistDeleteDialog";
import { PricelistEditDialog } from "./pricelist-edit-dialog/PricelistEditDialog";
import { usePricelistsUIContext } from "./PricelistsUIContext";

export function Pricelists() {
  // Pricelists UI Context
  const pricelistsUIContext = usePricelistsUIContext();
  const pricelistsUIProps = useMemo(() => {
    return { ids: pricelistsUIContext.ids };
  }, [pricelistsUIContext]);
  return (
      <>
          <PricelistDeleteDialog />
          <PricelistEditDialog />
          <hr />
          <PricelistsTable />
      </>
    );
}
