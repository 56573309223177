// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, InputGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InfoPopup from "../../../../Helpers/InfoPopup"
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import { onlyDigits, onlyPositiveDecimal } from "../../../../../util/Util";
import { checkIfCashierExist } from "../../../_redux/cashiers/cashiersCrud";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { useDispatch } from "react-redux";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AutocompletePost from '../../../../../components/AutocompletePost'
import AutocompleteWarehouseWithoutConsignment from "../../../../../components/AutocompleteWarehouseWithoutConsignment"

export function CashierEditForm({
  saveCashier,
  cashier,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [isRealEstateBP, setIsRealEstateBP] = useState([cashier != undefined ? cashier.isRealEstateBP : false]);
    const [post, setPost] = useState(cashier != undefined && cashier.realEstateAddressPostalCode != null ?
        { code: cashier.realEstateAddressPostalCode, title: cashier.realEstateAddressCity } : null);
    const [warehouse, setWarehouse] = useState(cashier != undefined ? cashier.warehouse : null);
    const [errorWarehouse, setErrorWarehouse] = useState("");
    const [errorPost, setErrorPost] = useState("");
    const [businessPremiseId, setBusinessPremiseId] = useState(cashier != undefined ? cashier.businessPremiseId : "");
    const [cashierId, setCashierId] = useState(cashier != undefined ? cashier.cashierId : "");

    const dispatch = useDispatch();
    useEffect(() => {
        setIsRealEstateBP([cashier != undefined ? cashier.isRealEstateBP : false]);
        setPost(cashier != undefined && cashier.realEstateAddressPostalCode != null ?
            { code: cashier.realEstateAddressPostalCode, title: cashier.realEstateAddressCity } : null);
        setWarehouse(cashier != undefined ? cashier.warehouse : null);
        setBusinessPremiseId(cashier != undefined ? cashier.businessPremiseId : null);
        setCashierId(cashier != undefined ? cashier.cashierId : null);
    }, [user, cashier]);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediDavcneBlagajne]) || cashier.isRegistered;

    // Validation schema
    const CashierEditSchema = Yup.object().shape({
        businessPremiseTitle: Yup.string()
            .max(250, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_250_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        businessPremiseId: Yup.string()
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_20_SYMBOLS' }), value => {
                return new Promise((resolve, reject) => {
                    resolve(businessPremiseId != null && businessPremiseId != undefined && businessPremiseId.length < 21);
                })
            })
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    resolve(businessPremiseId != null && businessPremiseId != undefined && businessPremiseId.trim().length > 0);
                })
            })
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.ONLY_CHAR' }), value => {
                return new Promise((resolve, reject) => {
                    resolve(new RegExp(/^[A-Za-z0-9 ]+$/).test(businessPremiseId));
                })
            })
            .test('validator', intl.formatMessage({ id: 'CASHIER.GENERAL.CASHIER_EXISTS_YET' }), value => {
                return validCheckIfCashierExist();
            }),
        cashierId: Yup.string()
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_20_SYMBOLS' }), value => {
                return new Promise((resolve, reject) => {
                    resolve(cashierId != null && cashierId != undefined && cashierId.length < 21);
                })
            })
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    resolve(cashierId != null && cashierId != undefined && cashierId.trim().length > 0);
                })
            })
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.ONLY_CHAR' }), value => {
                return new Promise((resolve, reject) => {
                    resolve( new RegExp(/^[A-Za-z0-9 ]+$/).test(cashierId));
                })
            })
            .test('validator', intl.formatMessage({ id: 'CASHIER.GENERAL.CASHIER_EXISTS_YET' }), value => {
                return validCheckIfCashierExist();
            }),
        realEstatePropertyCadastralNumber: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        realEstatePropertyBuildingNumber: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        realEstatePropertyBuildingSectionNumber: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        realEstateAddressStreet: Yup.string()
            .max(100, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_100_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        realEstateAddressHouseNumber: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        realEstateAddressHouseNumberAdditional: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' })),
        realEstateAddressCommunity: Yup.string()
            .max(100, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_100_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
                return new Promise((resolve, reject) => {
                    if (isRealEstateBP[0] && (value == undefined || value == "" || value == null || value.trim() == "")) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
            }),
        valid: Yup.string()
            .test('validator', "Error", value => {
                return new Promise((resolve, reject) => {
                    resolve(validateForm());
                })
            })
        
    });

    function validCheckIfCashierExist() {
        return new Promise((resolve, reject) => {
            if (businessPremiseId != undefined && businessPremiseId != "" && businessPremiseId != null &&
                cashierId != undefined && cashierId != "" && cashierId != null) {
                checkIfCashierExist(cashier.id, businessPremiseId, cashierId)
                    .then(({ data }) => {
                        if (!data.didError) {
                            resolve(!data.model);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((e) => {
                        resolve(false);
                    });
            } else {
                resolve(true);
            }
        });
    }

    function handleChangeIsRealEstateBP(event, value) {
        var res = event.currentTarget.value == "true";
        setIsRealEstateBP([res]);
    }

    function validateForm() {
        var res = true;
        if (isRealEstateBP[0]) {
            if (post == undefined) {
                setErrorPost(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
                res = false;
            } else {
                setErrorPost("");
            }
        } else {
            setErrorPost("");
        }
        if (warehouse == undefined) {
            setErrorWarehouse(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorWarehouse("");
        }

        return res;
    }

  return (
    <>
        <Formik
            enableReinitialize={true}
            initialValues={cashier}
            validateOnChange={false}
            validationSchema={CashierEditSchema}
              onSubmit={(values) => {
                  var obj = {
                      id: values.id,
                      businessPremiseTitle: values.businessPremiseTitle,
                      businessPremiseId: businessPremiseId,
                      cashierId: cashierId,
                      isRealEstateBP: isRealEstateBP[0],
                      realEstatePropertyCadastralNumber: values.realEstatePropertyCadastralNumber,
                      realEstatePropertyBuildingNumber: values.realEstatePropertyBuildingNumber,
                      realEstatePropertyBuildingSectionNumber: values.realEstatePropertyBuildingSectionNumber,
                      realEstateAddressStreet: values.realEstateAddressStreet,
                      realEstateAddressHouseNumber: values.realEstateAddressHouseNumber,
                      realEstateAddressHouseNumberAdditional: values.realEstateAddressHouseNumberAdditional,
                      realEstateAddressCommunity: values.realEstateAddressCommunity,
                      realEstateAddressCity: post != null ? post.title : "",
                      realEstateAddressPostalCode: post != null ?  post.code : "",
                      premiseType: values.premiseType,
                      warehouse: warehouse,
                      warehouseId: warehouse.id,
                  };
                saveCashier(obj);
            }
        }
      >
        {({ handleSubmit, errors, values }) => (
                  <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                          )}

                          {!actionsLoading && (
                              <Form className="form form-label-right">

                                  <div style={{ display: "none" }} className="form-group col-lg-4">
                                      <Field
                                          disabled={readOnly}
                                          name="valid"
                                          component={Input}
                                          label=""
                                          customFeedbackLabel=" "
                                      />
                                  </div>
                                  <div className="form-group row">

                                      <div className="col-lg-12">
                                          <label><FormattedMessage id="AUTH.INPUT.BUSINESS_PREMISE" /></label>
                                          <Field
                                              disabled={readOnly}
                                              name="businessPremiseTitle"
                                              component={Input}
                                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.BUSINESS_PREMISE' })}
                                              label=""
                                              customFeedbackLabel=" "
                                          />
                                      </div>

                                  </div>

                                  <div className="form-group row">
                                      
                                      <div className="col-lg-4">
                                          <label><FormattedMessage id="AUTH.INPUT.BUSINESS_PREMISE_ID" /></label>
                                          <Field
                                              disabled={readOnly}
                                              name="businessPremiseId"
                                              component={Input}
                                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.BUSINESS_PREMISE_ID' })}
                                              label=""
                                              customFeedbackLabel=" "
                                              value={businessPremiseId}
                                              onChange={(e) => {
                                                  setBusinessPremiseId(e.target.value);
                                              }}
                                          />
                                      </div>
                                      
                                      <div className="col-lg-4">
                                          <label><FormattedMessage id="AUTH.GENERAL.CASHIER_ID" /></label>
                                          <Field
                                              disabled={readOnly}
                                              name="cashierId"
                                              component={Input}
                                              placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.CASHIER_ID' })}
                                              label=""
                                              customFeedbackLabel=" "
                                              value={cashierId}
                                              onChange={(e) => {
                                                  setCashierId(e.target.value);
                                              }}
                                          />
                                      </div>

                                      <div className="col-lg-4">
                                          <label><FormattedMessage id="AUTH.GENERAL.WAREHOUSE" /></label>
                                          <AutocompleteWarehouseWithoutConsignment
                                              readOnly={readOnly}
                                              name="warehouseId"
                                              value={warehouse}
                                              onChange={(val) => {
                                                  setWarehouse(val);
                                              }}
                                          />
                                          {errorWarehouse.length > 0 ? (
                                              <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{errorWarehouse}</div>
                                              </div>
                                          ) : null}
                                      </div>
                                      

                                  </div>

                                  <hr />

                                  <div className="form-group row">

                                      <div className="col-lg-4">
                                      </div>

                                      <div className="col-lg-4">

                                          {!readOnly && (
                                              <ToggleButtonGroup
                                                  type="checkbox"
                                                  value={isRealEstateBP}
                                                  onChange={handleChangeIsRealEstateBP}
                                              >
                                                  <ToggleButton value={true}><FormattedMessage id="AUTH.GENERAL.REAL_ESTATE" /></ToggleButton>
                                                  <ToggleButton value={false}><FormattedMessage id="AUTH.GENERAL.MOVABLE_PROPERTY" /></ToggleButton>
                                              </ToggleButtonGroup>
                                          )}
                                          
                                          {(readOnly && isRealEstateBP[0]) && (
                                              <h3>
                                                  <FormattedMessage id="AUTH.GENERAL.REAL_ESTATE" />
                                              </h3>
                                          )}

                                          {(readOnly && !isRealEstateBP[0]) && (
                                              <h3>
                                                  <FormattedMessage id="AUTH.GENERAL.MOVABLE_PROPERTY" />
                                              </h3>
                                          )}
                                          
                                      </div>

                                  </div>

                                  <hr />

                                  {isRealEstateBP[0] && (
                                      <>
                                          <div className="form-group row">

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.CADASTRAL_NUMBER" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstatePropertyCadastralNumber"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CADASTRAL_NUMBER' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.BUILDING_NUMBER" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstatePropertyBuildingNumber"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.BUILDING_NUMBER' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.BUILDING_SECTION_NUMBER" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstatePropertyBuildingSectionNumber"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.BUILDING_SECTION_NUMBER' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>
                                          </div>

                                          <div className="form-group row">

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.ADDRESS_STREET" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstateAddressStreet"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS_STREET' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.HOUSE_NUMBER" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstateAddressHouseNumber"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.HOUSE_NUMBER' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.HOUSE_NUMBER_ADDITIONAL" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstateAddressHouseNumberAdditional"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.HOUSE_NUMBER_ADDITIONAL' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>
                                          </div>


                                          <div className="form-group row">

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.ADDRESS_COMMUNITY" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="realEstateAddressCommunity"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS_COMMUNITY' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.GENERAL.POST" /></label>
                                                  <AutocompletePost
                                                      name="postCode"
                                                      value={post}
                                                      readOnly={readOnly}
                                                      onChange={(val) => { setPost(val); }}
                                                  />
                                                  {errorPost.length > 0 ? (
                                                      <div className="fv-plugins-message-container">
                                                          <div className="fv-help-block">{errorPost}</div>
                                                      </div>
                                                  ) : null}
                                              </div>

                                          </div>
                                    </>
                                  )}

                                  {!isRealEstateBP[0] && (
                                      <>
                                          <div className="form-group row">

                                              <div className="col-lg-4">
                                                  <label>
                                                      <Field
                                                          disabled={readOnly}
                                                          name="premiseType"
                                                          type="radio"
                                                          value="1"
                                                      />
                                                      <br/>
                                                      <FormattedMessage id="AUTH.INPUT.PREMISE_TYPE_A" />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4">
                                                  <label>
                                                      <Field
                                                          disabled={readOnly}
                                                          name="premiseType"
                                                          type="radio"
                                                          value="2"
                                                      />
                                                      <br />
                                                      <FormattedMessage id="AUTH.INPUT.PREMISE_TYPE_B" />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4">
                                                  <label>
                                                      <Field
                                                          disabled={readOnly}
                                                          name="premiseType"
                                                          type="radio"
                                                          value="3"
                                                      />
                                                      <br />
                                                      <FormattedMessage id="AUTH.INPUT.PREMISE_TYPE_C" />
                                                  </label>
                                              </div>


                                            </div>
                                        </>
                                    )}

                              </Form>
                              )}
                        
                        </Modal.Body>
                      
                        
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
                          >
                    {!readOnly && (
                        <FormattedMessage id="AUTH.GENERAL.CANCEL" />
                    )}
                    {readOnly && (
                        <FormattedMessage id="AUTH.GENERAL.CLOSE" />
                    )}
                </button>
            <> </>

            {!readOnly && !actionsLoading && (
                <CheckPermissions
                    cashierFunctionalities={[UserFunctionalityE.UrediDavcneBlagajne]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
                          
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
