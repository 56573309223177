import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function ItemEditDialogHeader({ id }) {
  const intl = useIntl();
  // Items Redux state
  const { itemForEdit, actionsLoading } = useSelector(
    (state) => ({
      itemForEdit: state.items.itemForEdit,
      actionsLoading: state.items.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
      let _title = id ? "" : intl.formatMessage({ id: "AUTH.ITEM.NEW" }) ;
    if (itemForEdit && id) {
        _title = intl.formatMessage({ id: "AUTH.ITEM.EDIT" }) + ` '${itemForEdit.title}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [itemForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
