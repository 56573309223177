// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/activeUsers/activeUsersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../ActiveUsersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useActiveUsersUIContext } from "../ActiveUsersUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function ActiveUsersTable() {
    const intl = useIntl();
  // ActiveUsers UI Context
  const activeUsersUIContext = useActiveUsersUIContext();
  const activeUsersUIProps = useMemo(() => {
    return {
        ids: activeUsersUIContext.ids,
        setIds: activeUsersUIContext.setIds,
        queryParams: activeUsersUIContext.queryParams,
        setQueryParams: activeUsersUIContext.setQueryParams,
        openEditActiveUserDialog: activeUsersUIContext.openEditActiveUserDialog
    };
  }, [activeUsersUIContext]);

  // Getting curret state of activeUsers list from store (Redux)
  const { currentState } = useSelector(
      (state) => ({ currentState: state.activeUsers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // ActiveUsers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    activeUsersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchActiveUsers(activeUsersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUsersUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "userName",
        text: intl.formatMessage({ id: "AUTH.INPUT.USERNAME" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "firstName",
        text: intl.formatMessage({ id: "AUTH.GENERAL.FIRSTNAME" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      },
      {
          dataField: "lastName",
          text: intl.formatMessage({ id: "AUTH.GENERAL.LASTNAME" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "phone",
          text: intl.formatMessage({ id: "AUTH.GENERAL.PHONE" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "companyName",
          text: intl.formatMessage({ id: "AUTH.GENERAL.COMPANY" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "taxNumber",
          text: intl.formatMessage({ id: "AUTH.GENERAL.TAXCODE" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
    {
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditActiveUserDialog: activeUsersUIProps.openEditActiveUserDialog
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: activeUsersUIProps.queryParams.pageSize,
      page: activeUsersUIProps.queryParams.pageNumber,
      paginationTotalRenderer: customTotal,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  activeUsersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
