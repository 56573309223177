import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { TakeoversPage } from "./takeovers/TakeoversPage";
import { InventoriesPage } from "./inventories/InventoriesPage";
import { WriteoffsPage } from "./writeoffs/WriteoffsPage";
import { InternalStockTransfersPage } from "./internalStockTransfers/InternalStockTransfersPage";
import { OwnConsumptionsPage } from "./ownConsumptions/OwnConsumptionsPage";
import { RefundsPage } from "./refunds/RefundsPage";
import { ProductionOrdersPage } from "./productionOrders/ProductionOrdersPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function PurchasePage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect
                        exact={true}
                        from="/purchase"
                        to="/purchase/takeovers"
                    />
                }
                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledPrevzemnihListov, UserFunctionalityE.UrediPrevzemneListe]) && (
                    <ContentRoute path="/purchase/takeovers" component={TakeoversPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledInventurnihPopisov, UserFunctionalityE.UrediInventurnePopise]) && (
                    <ContentRoute path="/purchase/inventories" component={InventoriesPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledOdpisov, UserFunctionalityE.UrediOdpise]) && (
                    <ContentRoute path="/purchase/writeoffs" component={WriteoffsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledMedskladiscnihPrenosov, UserFunctionalityE.UrediMedskladiscnePrenose]) && (
                    <ContentRoute path="/purchase/internalStockTransfers" component={InternalStockTransfersPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledLastnihPorab, UserFunctionalityE.UrediLastnePorabe]) && (
                    <ContentRoute path="/purchase/ownConsumptions" component={OwnConsumptionsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledVracil, UserFunctionalityE.UrediVracila]) && (
                    <ContentRoute path="/purchase/refunds" component={RefundsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledProizvodnihNalogov, UserFunctionalityE.UrediProizvodneNaloge]) && (
                    <ContentRoute path="/purchase/productionOrders" component={ProductionOrdersPage} />
                )}
                
            </Switch>
        </Suspense>
    );
}
