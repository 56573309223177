import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import { DocumentEditDialogHeader } from "./DocumentEditDialogHeader";
import { DocumentEditForm } from "./DocumentEditForm";
import { useDocumentsUIContext } from "../DocumentsUIContext";

export function DocumentEditDialog({ id, show, onHide, copy, sendToDocument, sendToDocumentType }) {
  // Office users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        initDocument: documentsUIContext.initDocument,
        formFieldsVisible: documentsUIContext.formFieldsVisible,
        formTranslate: documentsUIContext.formTranslate,
        documentType: documentsUIContext.documentType,
        setIds: documentsUIContext.setIds,
        queryParams: documentsUIContext.queryParams,
        gridPositionColumnsVisible: documentsUIContext.gridPositionColumnsVisible,
        gridPositionColumnsTranslate: documentsUIContext.gridPositionColumnsTranslate,
        readOnly: documentsUIContext.readOnly
    };
    }, [documentsUIContext]);


  // Users Redux state
  const dispatch = useDispatch();
    const { actionsLoading, documentForEdit } = useSelector(
    (state) => ({
            actionsLoading: state.documents.actionsLoading,
            documentForEdit: state.documents.documentForEdit
    }),
    shallowEqual
  );

    useEffect(() => {
        if (!show) {
            return;
        }
        if (sendToDocument) {
            dispatch(actions.fetchSendToDocument(id, sendToDocumentType));
        } else {
            dispatch(actions.fetchDocument(id, copy));
        }
    }, [id, show, dispatch]);

  // server request for saving user
    const saveDocument = (document) => {
    if (!document.id) {
        dispatch(actions.createDocument(document)).then(() => {
            documentsUIProps.setIds([]);
            dispatch(actions.fetchDocuments(documentsUIProps.queryParams));
            // clear selections list
            onHide();
        });
    } else {
        dispatch(actions.updateDocument(document)).then(() => {
            documentsUIProps.setIds([]);
            dispatch(actions.fetchDocuments(documentsUIProps.queryParams));
            // clear selections list
            onHide();
        });
    }


  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
          <DocumentEditDialogHeader id={id} copy={copy} document={documentForEdit || documentsUIProps.initDocument}/>
          <DocumentEditForm
              documentType={documentsUIProps.documentType}
              formFieldsVisible={documentsUIProps.formFieldsVisible}
              formTranslate={documentsUIProps.formTranslate}
              gridPositionColumnsVisible={documentsUIProps.gridPositionColumnsVisible}
              gridPositionColumnsTranslate={documentsUIProps.gridPositionColumnsTranslate}
              saveDocument={saveDocument}
              actionsLoading={actionsLoading}
              document={documentForEdit || documentsUIProps.initDocument}
              onHide={onHide}
              readOnly={documentsUIProps.readOnly}
      />
    </Modal>
  );
}
