import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCodeListsUIContext } from "../CodeListsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as actions from "../../../_redux/codeLists/codeListsActions";


export function CodeListsFilter({ listLoading }) {
    const intl = useIntl();
    const [codelistSchemaId, setCodelistSchemaId] = useState(1);

    const prepareFilter = (queryParams, values) => {
        const newQueryParams = { ...queryParams };

        newQueryParams.id = codelistSchemaId;
        return newQueryParams;
    };

  // CodeLists UI Context
    const codeListsUIContext = useCodeListsUIContext();
    const codeListsUIProps = useMemo(() => {
    return {
        queryParams: codeListsUIContext.queryParams,
        setQueryParams: codeListsUIContext.setQueryParams,
    };
    }, [codeListsUIContext]);

    // Getting curret state of codeLists list from store (Redux)
    const { codelistSchemas } = useSelector(
        (state) => ({
            codelistSchemas: state.codeLists.codelistSchemas
        }),
        shallowEqual
    );

    // CodeLists Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        if (!codelistSchemas) {
            dispatch(actions.fetchCodeListSchemas());
        } 
    }, [dispatch]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
      const newQueryParams = prepareFilter(codeListsUIProps.queryParams, values);
      if (!isEqual(newQueryParams, codeListsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      codeListsUIProps.setQueryParams(newQueryParams);
    }
    };

  return (
    <>
      <Formik
        initialValues={{
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
                <div className="col-lg-4">
                    <Select
                        variant="outlined"
                        name="codelistSchemaId"
                        className="form-control form-control-solid"
                        value={codelistSchemaId}
                        onChange={(e) => {
                            setCodelistSchemaId(e.target.value);
                            handleSubmit();
                        }}
                    >
                        {codelistSchemas && codelistSchemas.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SELECT" /></b> <FormattedMessage id="AUTH.GENERAL.CODELIST" />
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
