import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CustomersPage } from "./customers/CustomersPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function CustomerPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
           
                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledStrank, UserFunctionalityE.UrediStranke]) && (
                    <ContentRoute path="/customers" component={CustomersPage} />
                )}
              
            </Switch>
        </Suspense>
    );
}
