import {createSlice} from "@reduxjs/toolkit";

const initialItemsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    itemForEdit: undefined,
    pricelistForEdit: undefined,
    pricelists: undefined,
    pricelistsRemove: undefined,
    lastError: null,
    currentVatRateId: null,
    jigsawForEdit: undefined,
    jigsaws: undefined,
    jigsawsRemove: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const itemsSlice = createSlice({
  name: "items",
  initialState: initialItemsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getItemById
    itemFetched: (state, action) => {
      state.actionsLoading = false;
      state.itemForEdit = action.payload.itemForEdit;
      state.error = null;
    },
    // findItems
    itemsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createItem
    itemCreated: (state, action) => {
        state.actionsLoading = false;
      state.error = null;
        state.entities.push(action.payload.model);
        state.totalCount++;
    },
    // updateItem
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.item.id) {
          return action.payload.item;
        }
        return entity;
      });
    },
    // deleteItem
    itemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteItems
    itemsDeleted: (state, action) => {
      state.error = null;
        state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    setCurrentVatRateId: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.currentVatRateId = action.payload.currentVatRateId;
    },
    setPricelists: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.pricelists = action.payload.pricelists;
    },
    setPricelistsRemove: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.pricelistsRemove = action.payload.pricelistsRemove;
    },
    setPricelistForEdit: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.pricelistForEdit = action.payload.pricelistForEdit;
    },
    setJigsaws: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.jigsaws = action.payload.jigsaws;
    },
    setJigsawsRemove: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.jigsawsRemove = action.payload.jigsawsRemove;
    },
    setJigsawForEdit: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.jigsawForEdit = action.payload.jigsawForEdit;
    },
  }
});
