import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function CodeListEditDialogHeader({ id }) {
  const intl = useIntl();
  // CodeLists Redux state
    const { codeListForEdit, actionsLoading } = useSelector(
    (state) => ({
            codeListForEdit: state.codeLists.codeListForEdit,
            actionsLoading: state.codeLists.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
      let _title = id ? "" : intl.formatMessage({ id: "SETTING.CODELIST.NEW" }) ;
      if (codeListForEdit && id) {
          _title = intl.formatMessage({ id: "SETTING.CODELIST.EDIT" }) + ` '${codeListForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [codeListForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
