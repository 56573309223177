import axios from "axios";

export function importItemsExcel(data) {
    return axios.post("/api/import/importItemsExcel", data);
}


export function importPricelistsExcel(data) {
    return axios.post("/api/import/importPricelistsExcel", data);
}

export function importStocksExcel(data) {
    return axios.post("/api/import/importStocksExcel", data);
}

export function getWarehouses() {
    return axios.get("/api/warehouse/all");
}
