import * as requestFromServer from "./documentsCrud";
import { documentsSlice, callTypes } from "./documentsSlice";
import moment from 'moment'

const { actions } = documentsSlice;

export const fetchDocuments = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDocuments(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.documentsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find documents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDocument = (id, copy) => dispatch => {
  if (!id) {
    return dispatch(actions.documentFetched({ documentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDocumentById(id)
      .then(response => {
          const document = response.data.model;
          document.valid = "";
          document.discountProcent = document.discountProcent == null ? "" : document.discountProcent;
          document.discountAmount = document.discountAmount == null ? "" : document.discountAmount;
          var date = document.date;
          document.date = date == null ? "" : moment(new Date(date)).format("YYYY-MM-DD");
          document.time = date == null ? "" : moment(new Date(date)).format("HH:mm");
          document.serviceDate = document.serviceDate == null ? "" : moment(new Date(document.serviceDate)).format("YYYY-MM-DD");
          document.dateOfServicesRendered = document.dateOfServicesRendered == null ? "" : moment(new Date(document.dateOfServicesRendered)).format("YYYY-MM-DD");
          document.dueDate = document.dueDate == null ? "" : moment(new Date(document.dueDate)).format("YYYY-MM-DD");

          if (copy) {
              document.id = undefined;
              document.linkedDocument = null;
              document.stornoDocument = null;
              document.stornoDocumentId = null;
              document.otherDocumentNumber = "";
              for (var index = 0; index < document.documentPosition.length; index++) {
                  document.documentPosition[index].id = undefined;
              }
          }

        dispatch(actions.documentFetched({ documentForEdit: document }));
    })
    .catch(error => {
      error.clientMessage = "Can't find document";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSendToDocument = (ids, type) => dispatch => {
    if (!ids || ids.length == 0) {
        return dispatch(actions.documentFetched({ documentForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getDocumentByIds(ids, type)
        .then(response => {
            const document = response.data.model;
            document.valid = "";
            document.discountProcent = document.discountProcent == null ? "" : document.discountProcent;
            document.discountAmount = document.discountAmount == null ? "" : document.discountAmount;
            var date = document.date;
            document.date = date == null ? "" : moment(new Date(date)).format("YYYY-MM-DD");
            document.time = date == null ? "" : moment(new Date(date)).format("HH:mm");
            document.serviceDate = document.serviceDate == null ? "" : moment(new Date(document.serviceDate)).format("YYYY-MM-DD");
            document.dateOfServicesRendered = document.dateOfServicesRendered == null ? "" : moment(new Date(document.dateOfServicesRendered)).format("YYYY-MM-DD");
            document.dueDate = document.dueDate == null ? "" : moment(new Date(document.dueDate)).format("YYYY-MM-DD");
            document.type = type;
            document.id = undefined;
            document.stornoDocument = null;
            document.stornoDocumentId = null;
            document.otherDocumentNumber = "";
            for (var index = 0; index < document.documentPosition.length; index++) {
                document.documentPosition[index].id = undefined;
            }

            dispatch(actions.documentFetched({ documentForEdit: document }));
        })
        .catch(error => {
            error.clientMessage = "Can't find documents";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};


export const deleteDocument = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDocument(id)
    .then(response => {
      dispatch(actions.documentDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete document";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDocument = documentForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .createDocument(documentForCreation)
      .then(response => {
        const { model } = response.data;
        dispatch(actions.documentCreated({ model }));
    })
    .catch(error => {
      error.clientMessage = "Can't create document";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDocument = document => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDocument(document)
    .then(() => {
      dispatch(actions.documentUpdated({ document }));
    })
    .catch(error => {
      error.clientMessage = "Can't update document";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchHistory = queryParams => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findHistory(queryParams)
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.historyFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find history";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

