import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function CheckAxiosRequests() {
    const intl = useIntl();
    const [message, setMessage] = useState("");
    const history = useHistory();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            var showError = false;
                var text = intl.formatMessage({ id: 'AUTH.OPS_CONNECTION_PROBLEM' });
                switch (error.response.status) {
                    case 500:
                        showError = !(error.response.data != undefined && error.response.data.didError == true);
                        break;
                    case 401:
                        history.push("/logout");
                        break;
                    default:
                        showError = true;
                        break;
                }
                if (showError == true) {
                    setMessage(text);
                    setOpen(true);
                }
            // Do something with response error
            return Promise.reject(error);
        });
    })

    function handleClose() {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <FormattedMessage id="AUTH.GENERAL.ERROR" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    <FormattedMessage id="AUTH.GENERAL.CLOSE" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
