import React, { useState, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useDocumentsUIContext } from "../DocumentsUIContext";

export function DocumentHistoryDialogHeader({ id, document }) {
    const documentsUIContext = useDocumentsUIContext();
  // Office users Redux state
    const { documentForEdit, actionsLoading } = useSelector(
    (state) => ({
            documentForEdit: state.documents.documentForEdit,
            actionsLoading: state.documents.actionsLoading,
    }),
    shallowEqual
    );

    const documentsUIProps = useMemo(() => {
        return {
            ids: documentsUIContext.ids,
            newDocumentButtonClick: documentsUIContext.newDocumentButtonClick,
            translate: documentsUIContext.translate,
            readOnly: documentsUIContext.readOnly
        };
    }, [documentsUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
    useEffect(() => {
        let _title = id ? "" : documentsUIProps.translate.newHeader;
        var readOnly = documentsUIProps.readOnly;
        if (document.stornoDocumentId != null) {
            readOnly = true;
        }
        if (documentForEdit && id) {
            _title = documentsUIProps.translate.historyHeader + ` '${documentForEdit.talkingNumber}'`;
        }

    setTitle(_title);
    // eslint-disable-next-line
  }, [documentForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
