import axios from "axios";

export function saveExportData(data) {
    return axios.post("/api/export", data);
}

export function getLastExportData() {
    return axios.get("/api/export/getLastExportData");
}

export function findExports(queryParams) {
    return axios.post("/api/export/find", queryParams);
}
