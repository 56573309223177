import * as requestFromServer from "./endsCrud";
import { endsSlice, callTypes } from "./endsSlice";

const {actions} = endsSlice;

export const fetchEnds = queryParams => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findEnds(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.endsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find ends";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
