import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/officeUsers/officeUsersActions";
import { OfficeUserEditDialogHeader } from "./OfficeUserEditDialogHeader";
import { OfficeUserEditForm } from "./OfficeUserEditForm";
import { useOfficeUsersUIContext } from "../OfficeUsersUIContext";

export function OfficeUserEditDialog({ id, show, onHide }) {
  // Office users UI Context
    const officeUsersUIContext = useOfficeUsersUIContext();
    const officeUsersUIProps = useMemo(() => {
    return {
        initOfficeUser: officeUsersUIContext.initOfficeUser,
    };
    }, [officeUsersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
    const { actionsLoading, officeUserForEdit, userRoles } = useSelector(
    (state) => ({
            actionsLoading: state.officeUsers.actionsLoading,
            officeUserForEdit: state.officeUsers.officeUserForEdit,
            userRoles: state.officeUsers.userRoles
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(actions.fetchOfficeUserRoles(2));
      dispatch(actions.fetchOfficeUser(id));
  }, [id, dispatch]);

  // server request for saving user
    const saveOfficeUser = (officeUser) => {
    if (!id) {
      // server request for creating user
        dispatch(actions.createOfficeUser(officeUser)).then(() => onHide());
    } else {
      // server request for updating officeUser
        dispatch(actions.updateOfficeUser(officeUser)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
        <OfficeUserEditDialogHeader id={id} />
          <OfficeUserEditForm
              userRoles={userRoles}
        saveOfficeUser={saveOfficeUser}
        actionsLoading={actionsLoading}
              officeUser={officeUserForEdit || officeUsersUIProps.initOfficeUser}
        onHide={onHide}
      />
    </Modal>
  );
}
