/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { changePassword } from "../../_redux/changePassword/changePasswordCrud"

export function AppPage(props) {
    const intl = useIntl();
    // Fields
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    useEffect(() => { }, [user]);
   

    return (
        <div className="card card-custom">

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="AUTH.GENERAL.APPLICATION" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="AUTH.GENERAL.APPLICATION_DOWNLOAD" />
          </span>
                </div>
                <div className="card-toolbar">
                    
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    <h1>Navodila za namestitev Android aplikacije</h1>
                    <ul class="list-group">
                        <li class="list-group-item">1. Na Android napravi kliknite <a href="../api/application/download">tukaj</a> in shranite APK datoteko.</li>
                        <li class="list-group-item">2. Zaženite preneseno datoteko <b>com.skupinacomp.pos.mobile.app.apk</b>.</li>
                        <li class="list-group-item">3. Omogoči namestitev aplikacij iz neznanih virov.</li>
                        <li class="list-group-item">4. In nadaljujte namestitev.</li>
                    </ul>
                </div>
            </div>
            {/* end::Form */}


            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    <h1>Navodila za namestitev Android vtičnika aplikacije</h1>
                    <ul class="list-group">
                        <li class="list-group-item">1. Na Android napravi kliknite <a href="../api/application/downloadwrapper">tukaj</a> in shranite APK datoteko.</li>
                        <li class="list-group-item">2. Zaženite preneseno datoteko <b>com.mikropos.wrapper.apk</b>.</li>
                        <li class="list-group-item">3. Omogoči namestitev aplikacij iz neznanih virov.</li>
                        <li class="list-group-item">4. In nadaljujte namestitev.</li>
                        <li class="list-group-item">5. Natolahko uporabite vtičnik v mikroPOS aplikaciji.</li>
                    </ul>
                </div>
            </div>
            {/* end::Form */}
        </div>
    );
}

