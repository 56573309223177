import React from "react";
import { Route } from "react-router-dom";
import { UserRolesLoadingDialog } from "./userRoles-loading-dialog/UserRolesLoadingDialog";
import { UserRoleEditDialog } from "./userRole-edit-dialog/UserRoleEditDialog";
import { UserRoleDeleteDialog } from "./userRole-delete-dialog/UserRoleDeleteDialog";
import { UserRolesUIProvider } from "./UserRolesUIContext";
import { CashierUserRolesCard } from "./CashierUserRolesCard";

export function CashierUserRolesPage({ history }) {
    const type = 1;
    const userRolesUIEvents = {
    newUserRoleButtonClick: () => {
            history.push("/settings/roles_cashier/new");
    },
    openEditUserRoleDialog: (id) => {
        history.push(`/settings/roles_cashier/${id}/edit`);
    },
    openDeleteUserRoleDialog: (id) => {
        history.push(`/settings/roles_cashier/${id}/delete`);
    }
  }

  return (
      <UserRolesUIProvider type={type} userRolesUIEvents={userRolesUIEvents}>
          <UserRolesLoadingDialog />
          <Route path="/settings/roles_cashier/new">
        {({ history, match }) => (
        <UserRoleEditDialog
            type={type}
            show={match != null}
            onHide={() => {
                history.push("/settings/roles_cashier");
            }}
          />
        )}
      </Route>
          <Route path="/settings/roles_cashier/:id/edit">
        {({ history, match }) => (
                  <UserRoleEditDialog
            type={type}
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/settings/roles_cashier");
            }}
          />
        )}
      </Route>
          <Route path="/settings/roles_cashier/:id/delete">
        {({ history, match }) => (
            <UserRoleDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/settings/roles_cashier");
            }}
          />
        )}
      </Route>
          
          
          <CashierUserRolesCard />
      </UserRolesUIProvider>
  );
}
