/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { CheckPermissions } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"

export function Navigation() {
    const user = useSelector(({ auth }) => auth.user, shallowEqual);
    const { organization } = useSelector(
        (state) => ({
            organization: state.organization.organizationForEdit,
        }),
        shallowEqual
    );


    useEffect(() => {
        return () => { };
    }, [user]);

    return (
        <>
            {user && (
                <div
                    className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                    id="kt_company_aside"
                >
                    <div className="card card-custom card-stretch">
                        {/* begin::Body */}
                        <div className="card-body pt-4">
                            {/* begin::Nav */}
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/settings/company/data"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Design/Layers.svg"
                                                    )}
                                                ></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="COMPANY.NAVIGATION.DATA" />
                                        </span>
                                    </NavLink>
                                </div>
                                {user.organizationNotSentToFurs == false && (
                                    <CheckPermissions
                                        userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                                        <div className="navi-item mb-2">
                                            <NavLink
                                                to="/settings/company/furs"
                                                className="navi-link py-4"
                                                activeClassName="active"
                                            >
                                                <span className="navi-icon mr-2">
                                                    <span className="svg-icon">
                                                        <SVG
                                                            src={toAbsoluteUrl(
                                                                "/media/svg/icons/Files/File.svg"
                                                            )}
                                                        ></SVG>{" "}
                                                    </span>
                                                </span>
                                                <span className="navi-text font-size-lg">
                                                    <FormattedMessage id="COMPANY.NAVIGATION.FURS" />
                                                </span>
                                            </NavLink>
                                        </div>
                                    </CheckPermissions>
                                )}
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/settings/company/einvoice"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Text/Article.svg"
                                                    )}
                                                ></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="COMPANY.NAVIGATION.EINVOICE" />
                                        </span>
                                    </NavLink>
                                </div>
                                <CheckPermissions
                                    userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                                    <div className="navi-item mb-2">
                                        <NavLink
                                            to="/settings/company/package"
                                            className="navi-link py-4"
                                            activeClassName="active"
                                        >
                                            <span className="navi-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Code/Compiling.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="navi-text font-size-lg">
                                                <FormattedMessage id="COMPANY.NAVIGATION.PACKAGE" />
                                            </span>

                                        </NavLink>
                                    </div>
                                </CheckPermissions>
                                
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/settings/company/payment"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                                                    )}
                                                ></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="COMPANY.NAVIGATION.PAY" />
                                        </span>
                                    </NavLink>
                                </div>

                                {organization != null && organization.confirmed == true && (
                                    <CheckPermissions
                                        userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                                        <div className="navi-item mb-2">
                                            <NavLink
                                                to="/settings/company/cancel_package"
                                                className="navi-link py-4"
                                                activeClassName="active"
                                            >
                                                <span className="navi-icon mr-2">
                                                    <span className="svg-icon">
                                                        <SVG
                                                            src={toAbsoluteUrl(
                                                                "/media/svg/icons/Code/Error-circle.svg"
                                                            )}
                                                        ></SVG>{" "}
                                                    </span>
                                                </span>
                                                <span className="navi-text font-size-lg">
                                                    <FormattedMessage id="COMPANY.NAVIGATION.CANCEL_PACKAGE" />
                                                </span>

                                            </NavLink>
                                        </div>
                                    </CheckPermissions>
                                    )}
                            </div>
                            {/* end::Nav */}
                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            )}
        </>
    );
}
