import {createSlice} from "@reduxjs/toolkit";

const initialItemGroupsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  itemGroupForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const itemGroupsSlice = createSlice({
  name: "itemGroups",
  initialState: initialItemGroupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getItemGroupById
    itemGroupFetched: (state, action) => {
      state.actionsLoading = false;
      state.itemGroupForEdit = action.payload.itemGroupForEdit;
      state.error = null;
    },
    // findItemGroups
    itemGroupsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createItemGroup
    itemGroupCreated: (state, action) => {
        state.actionsLoading = false;
      state.error = null;
        state.entities.push(action.payload.model);
        state.totalCount++;
    },
    // updateItemGroup
    itemGroupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.itemGroup.id) {
          return action.payload.itemGroup;
        }
        return entity;
      });
    },
    // deleteItemGroup
    itemGroupDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteItemGroups
    itemGroupsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
  }
});
