// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { decimal } from "../../../../../../../../src/app/util/Util";
import moment from 'moment'

export function PricelistEditForm({ savePricelist, pricelist, currentVatRate, readOnly }) {
    const intl = useIntl();

    const [pricelistId, setPricelistId] = useState(pricelist.id);
    const [effectiveDateFrom, setEffectiveDateFrom] = useState(moment(pricelist.effectiveDateFrom).format("YYYY-MM-DD"));
    const [priceWithoutVat, setPriceWithoutVat] = useState(pricelist.priceWithoutVat);
    const [priceWithVat, setPriceWithVat] = useState(pricelist.priceWithVat);

    const [errorEffectiveDateFrom, setErrorEffectiveDateFrom] = useState("");
    const [errorPriceWithoutVat, setErrorPriceWithoutVat] = useState("");
    const [errorPriceWithVat, setErrorPriceWithVat] = useState("");

    const [isValidated, setIsValidated] = useState(false);


    useEffect(() => {
        setPricelistId(pricelist.id);
        setEffectiveDateFrom(moment(pricelist.effectiveDateFrom).format("YYYY-MM-DD"));
        setPriceWithoutVat(pricelist.priceWithoutVat);
        setPriceWithVat(pricelist.priceWithVat);
    }, [pricelist]);

    const getInputClasses = (fieldname) => {
        if (!isValidated) {
            return "";
        }
        switch (fieldname) {
            case "effectiveDateFrom":
                return errorEffectiveDateFrom.length > 0 ? "is-invalid" : "is-valid";
            case "priceWithoutVat":
                return errorPriceWithoutVat.length > 0 ? "is-invalid" : "is-valid";
            case "priceWithVat":
                return errorPriceWithVat.length > 0 ? "is-invalid" : "is-valid";
        }
        return "";
    };

    function release() {
        setPricelistId(undefined);
        setEffectiveDateFrom(moment(new Date()).format("YYYY-MM-DD"));
        setPriceWithoutVat(0);
        setPriceWithVat(0);
        setIsValidated(false);
        setErrorEffectiveDateFrom("");
        setErrorPriceWithoutVat("");
        setErrorPriceWithVat("");
    }

    function save() {
        if (validateForm()) {
            var obj = {
                id: pricelistId,
                priceWithoutVat: priceWithoutVat,
                priceWithVat: priceWithVat,
                effectiveDateFrom: moment(effectiveDateFrom).toDate(),
            };
            savePricelist(obj);
        }
    }

    function validateForm() {
        var res = true;
        if (effectiveDateFrom == undefined) {
            setErrorEffectiveDateFrom(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorEffectiveDateFrom("");
        }
        if (priceWithoutVat === undefined || priceWithoutVat === "" || priceWithoutVat === NaN) {
            setErrorPriceWithoutVat(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorPriceWithoutVat("");
        }

        if (priceWithVat === undefined || priceWithVat === "" || priceWithVat === NaN) {
            setErrorPriceWithVat(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorPriceWithVat("");
        }
        return res;
    }

  return (
    <>
        <div className="form-group row">
            <div className="col-lg-3">
                  <label><FormattedMessage id="AUTH.INPUT.PRICE_DATE" /></label>
                  <input
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PRICE_DATE' })}
                      type="date"
                      disabled={readOnly}
                      value={effectiveDateFrom}
                      className={`form-control ${getInputClasses(
                          "effectiveDateFrom"
                      )}`}
                      name="effectiveDateFrom"
                      onChange={(event, val) => {
                          var dt = new Date(event.target.value);

                          var now = new Date();
                          now.setDate(now.getDate() - 1);

                          if (dt.getTime() > now.getTime()) {
                              setEffectiveDateFrom(event.target.value);
                          }
                      }}
                  />
                  {errorEffectiveDateFrom.length > 0 ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorEffectiveDateFrom}</div>
                      </div>
                  ) : null}
            </div>
            <div className="col-lg-3">
                  <label><FormattedMessage id="AUTH.INPUT.PRICE_WITHOUT_VAT" /></label>
                  <input
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PRICE_WITHOUT_VAT' })}
                      type="number"
                      disabled={readOnly}
                      value={priceWithoutVat}
                      onKeyPress={decimal}
                      className={`form-control ${getInputClasses(
                          "priceWithoutVat"
                      )}`}
                      name="priceWithoutVat"
                      onChange={(event, val) => {
                        setPriceWithoutVat(event.target.value);
                        var proc = (parseFloat('100') + parseFloat(currentVatRate.procent.toString().replace(',', '.'))) / parseFloat('100');
                        var cal = parseFloat(event.target.value.replace(',', '.')) * parseFloat(proc.toString().replace(',', '.'));
                        setPriceWithVat(Math.round(cal * 100000000) / 100000000);
                      }}
                  />
                  {errorPriceWithoutVat.length > 0 ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorPriceWithoutVat}</div>
                      </div>
                  ) : null}
            </div>
            <div className="col-lg-3">
                  <label><FormattedMessage id="AUTH.INPUT.PRICE_WITH_VAT" /></label>

                  <input
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PRICE_WITH_VAT' })}
                      type="number"
                      disabled={readOnly}
                      value={priceWithVat}
                      onKeyPress={decimal}
                      className={`form-control ${getInputClasses(
                          "priceWithVat"
                      )}`}
                      name="priceWithVat"
                      onChange={(event, val) => {
                          setPriceWithVat(event.target.value);
                          var proc = parseFloat('100') + parseFloat(currentVatRate.procent.toString().replace(',', '.'));
                          var cal = (parseFloat(event.target.value.replace(',', '.')) * parseFloat('100')) / parseFloat(proc.toString().replace(',', '.'));
                          setPriceWithoutVat(Math.round(cal * 100000000) / 100000000);
                      }}
                  />
                  {errorPriceWithoutVat.length > 0 ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorPriceWithVat}</div>
                      </div>
                  ) : null}

            </div>

            <div className="col-lg-3">
                <br />
                <button
                    disabled={readOnly}
                    type="button"
                    className="btn btn-danger btn-elevate"
                    onClick={() => release()}
                >
                    <FormattedMessage id="AUTH.INPUT.RELEASE" />
                </button>
                    <> </>
                <button
                    disabled={readOnly}
                    type="button"
                    className="btn btn-primary btn-elevate"
                    onClick={() => save()}
                  >
                      {pricelistId == undefined && <FormattedMessage id="AUTH.GENERAL.ADD" />}
                      {pricelistId != undefined && <FormattedMessage id="AUTH.GENERAL.SAVE" />}
                </button>
            </div>
        </div>
            
    </>
  );
}
