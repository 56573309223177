/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";

const JigsawsUIContext = createContext();
export function useJigsawsUIContext() {
  return useContext(JigsawsUIContext);
}

export const JigsawsUIConsumer = JigsawsUIContext.Consumer;
export function JigsawsUIProvider({ readOnly, children }) {

  const [selectedId, setSelectedId] = useState(null);
  const [showEditJigsawDialog, setShowEditJigsawDialog] = useState(false);
  const initJigsaw = {
    id: undefined,
      quantity: 0,
      item2Id: null,
      item2: null
  };

  const openEditJigsawDialog = id => {
    setSelectedId(id);
    setShowEditJigsawDialog(true);
    setShowDeleteJigsawDialog(false);
  };

  const [showDeleteJigsawDialog, setShowDeleteJigsawDialog] = useState(false);
    const openDeleteJigsawDialog = id => {
    setSelectedId(id);
    setShowDeleteJigsawDialog(true);
    setShowEditJigsawDialog(false);
  };
  const closeDeleteJigsawDialog = () => {
    setSelectedId(undefined);
    setShowDeleteJigsawDialog(false);
  };

  const value = {
    initJigsaw,
    selectedId,
      readOnly,
    openEditJigsawDialog,
    showDeleteJigsawDialog,
    openDeleteJigsawDialog,
    closeDeleteJigsawDialog,
  };
  
  return (
    <JigsawsUIContext.Provider value={value}>
      {children}
    </JigsawsUIContext.Provider>
  );
}
