// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { QuantityColumnFormatter } from "./column-formatters/QuantityColumnFormatter";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useJigsawsUIContext } from "./JigsawsUIContext";
import { useIntl } from "react-intl";


export function JigsawsTable() {
    const intl = useIntl();

  // Jigsaws UI Context
  const jigsawsUIContext = useJigsawsUIContext();
  const jigsawsUIProps = useMemo(() => {
    return {
      openEditJigsawDialog: jigsawsUIContext.openEditJigsawDialog,
        openDeleteJigsawDialog: jigsawsUIContext.openDeleteJigsawDialog,
        readOnly: jigsawsUIContext.readOnly
    };
  }, [jigsawsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
      (state) => ({ currentState: state.items }),
    shallowEqual
  );
const { jigsaws } = currentState;

  const columns = [
    {
        dataField: "item2.title",
          text: intl.formatMessage({ id: "AUTH.GENERAL.ITEM" }),
        sort: false,
      },
      {
          dataField: "quantity",
          text: intl.formatMessage({ id: "AUTH.GENERAL.QUANTITY" }),
          sort: false,
          formatter: QuantityColumnFormatter
      },
      {
          hidden: jigsawsUIProps.readOnly,
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditJigsawDialog: jigsawsUIProps.openEditJigsawDialog,
        openDeleteJigsawDialog: jigsawsUIProps.openDeleteJigsawDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                      data={jigsaws === null ? [] : jigsaws}
                columns={columns}
              >
                      <PleaseWaitMessage entities={jigsaws} />
                      <NoRecordsFoundMessage entities={jigsaws} />
              </BootstrapTable>

    </>
  );
}
