import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CodeListsFilter } from "./codeLists-filter/CodeListsFilter";
import { CodeListsTable } from "./codeLists-table/CodeListsTable";
import { CodeListsGrouping } from "./codeLists-grouping/CodeListsGrouping";
import { useCodeListsUIContext } from "./CodeListsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"


export function CodeListsCard() {
    const intl = useIntl();
    const codeListsUIContext = useCodeListsUIContext();
    const codeListsUIProps = useMemo(() => {
    return {
        ids: codeListsUIContext.ids,
        newCodeListButtonClick: codeListsUIContext.newCodeListButtonClick,
    };
    }, [codeListsUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.CODELISTS" } )} >
              <CardHeaderToolbar>
                  <CheckPermissions
                      userFunctionalities={[UserFunctionalityE.UrediVlogeZaPisarniskePravice]}>
                      <button
                          type="button"
                          className="btn btn-primary"
                          onClick={codeListsUIProps.newCodeListButtonClick}
                      >
                          <FormattedMessage id="SETTING.CODELIST.NEW" />
                      </button>
                  </CheckPermissions>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CodeListsFilter />
        {codeListsUIProps.ids.length > 0 && <CodeListsGrouping />}
              <CodeListsTable type={2} />
      </CardBody>
    </Card>
  );
}
