import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/items/itemsActions";
import { useItemsUIContext } from "../ItemsUIContext";
import { FormattedMessage } from "react-intl";
import PdfViewer from "../../../../../components/PdfViewer"
import { Formik } from "formik";
import DateRange2 from '../../../../../components/DateRange2';
import AutocompleteWarehouse from '../../../../../components/AutocompleteWarehouse';
import moment from 'moment'

export function ItemStockMovementPrintDialog({ show, onHide }) {
    const [warehouse, setWarehouse] = useState(null);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
  // Office users UI Context
    const itemsUIContext = useItemsUIContext();
    const itemsUIProps = useMemo(() => {
        return {
            ids: itemsUIContext.ids,
            setIds: itemsUIContext.setIds,
            queryParams: itemsUIContext.queryParams
        };
    }, [itemsUIContext]);

  // Office users Redux state
  const dispatch = useDispatch();
  

  // if office users weren't selected we should close modal
  useEffect(() => {
      if (!itemsUIProps.ids || itemsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsUIProps.ids]);

  // looking for loading/dispatch
    useEffect(() => { }, [dispatch]);

    const applyFilter = (values) => {



    };

  return (
    <Modal
      show={show}
          onHide={onHide}
          size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="ITEM.STOCK.MOVEMENT" />
        </Modal.Title>
      </Modal.Header>
          <Modal.Body>
              <Formik
                  initialValues={{
                      status: "",
                      type: "",
                      searchText: "",
                  }}
                  onSubmit={(values) => {
                      applyFilter(values);
                  }}
              >
                  {({
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                  }) => (
                      <form onSubmit={handleSubmit} className="form form-label-right">
                          <div className="form-group row">
                              <div className="col-lg-4">
                                  <AutocompleteWarehouse
                                      name="warehouseId"
                                      value={warehouse}
                                      onChange={(val) => {
                                          setWarehouse(val);
                                      }}
                                  />
                                  <small className="form-text text-muted">
                                      <b><FormattedMessage id="AUTH.GENERAL.CHOOSE" /></b> <FormattedMessage id="AUTH.GENERAL.WAREHOUSE_SMALL" />
                                  </small>
                              </div>
                              <div className="col-lg-4">
                                  <DateRange2
                                      startDate={startDate}
                                      endDate={endDate}
                                      onChange={(startDate, endDate) => {
                                          setStartDate(startDate);
                                          setEndDate(endDate);
                                          handleSubmit();
                                      }}
                                  />
                                  <small className="form-text text-muted">
                                      <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.DATES" />
                                  </small>
                              </div>

                          </div>
                      </form>
                  )}
              </Formik>

              { itemsUIProps.ids != undefined && itemsUIProps.ids != null && itemsUIProps.ids.length > 0 && (
                  <PdfViewer
                      url={'../../API/OfficeReport/ReportItemStockMovement?id=' + itemsUIProps.ids[0] + '&start=' + moment(startDate).format("YYYY-MM-DD") + '&end=' + moment(endDate).format("YYYY-MM-DD") + '&warehouseId=' + (warehouse != null ? warehouse.id : '')}
                  />
                )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
                  >
                      <FormattedMessage id="AUTH.GENERAL.CLOSE" />
          </button>
          
        </div>
      </Modal.Footer>
    </Modal>
  );
}
