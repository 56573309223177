import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import { ReportByDayPage } from "./reports/ReportByDayPage";
import { ReportTdrPage } from "./reports/ReportTdrPage";
import { ReportByItemGroupPage } from "./reports/ReportByItemGroupPage";
import { ReportByItemPage } from "./reports/ReportByItemPage";
import { ReportByUserPage } from "./reports/ReportByUserPage";
import { ReportByDayHourPage } from "./reports/ReportByDayHourPage";
import { ReportByDeliveryNotesByDatePage } from "./reports/ReportByDeliveryNotesByDatePage";
import { ReportByPaymentByDatePage } from "./reports/ReportByPaymentByDatePage";
import { ReportTrafficByHourPage } from "./reports/ReportTrafficByHourPage";
import { ReportPurchaseItemBySuppliersPage } from "./reports/ReportPurchaseItemBySuppliersPage";
import { ReportSalesItemByUsersPage } from "./reports/ReportSalesItemByUsersPage";
import { ReportProfitPage } from "./reports/ReportProfitPage";
import { ReportByCustomers } from "./reports/ReportByCustomers";
import { ReportJigsaw } from "./reports/ReportJigsaw";
import { ReportSpentItemsAndPpPage } from "./reports/ReportSpentItemsAndPpPage";
import { ReportSellBySuppliersPage } from "./reports/ReportSellBySuppliersPage";
import { ReportStockItemsPage } from "./reports/ReportStockItemsPage";
import { ReportByDocumentsPage } from "./reports/ReportByDocumentsPage";
import { ReportByUsersByPaymentsPage } from "./reports/ReportByUsersByPaymentsPage";
import { ReportJigsawProfitPage } from "./reports/ReportJigsawProfitPage";
import { ReportSalesByUsersByItemgroupsPage } from "./reports/ReportSalesByUsersByItemgroupsPage";
import { ReportByOwnConsumptionsPage } from "./reports/ReportByOwnConsumptionsPage";
import { ReportSpentItemsPage } from "./reports/ReportSpentItemsPage";
import { ReportFirstLastInvoiceByUsersPage } from "./reports/ReportFirstLastInvoiceByUsersPage";
import { ReportPricelistPage } from "./reports/ReportPricelistPage";
import { ReportProfitJigsawWithVatPage } from "./reports/ReportProfitJigsawWithVatPage";
import { ReportItemStockFifoPage } from "./reports/ReportItemStockFifoPage";
import { ReportSalesItemsByDatePage } from "./reports/ReportSalesItemsByDatePage";
import { ReportRvcByItemGroupsPage } from "./reports/ReportRvcByItemGroupsPage";
import { ReportVatCalculatorPage } from "./reports/ReportVatCalculatorPage";
import { ReportSalesItemsByHourPage } from "./reports/ReportSalesItemsByHourPage";
import { ReportDeliveryNotesByCustomersPage } from "./reports/ReportDeliveryNotesByCustomersPage";

export default function ReportPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    <Redirect
                        exact={true}
                        from="/report"
                        to="/reports/report_by_date"
                    />
                }
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoDnevih]) && (
                    <ContentRoute path="/reports/report_by_date" component={ReportByDayPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.TdrPorocilo]) && (
                    <ContentRoute path="/reports/report_tdr" component={ReportTdrPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorocilaPoSkupinahArtiklov]) && (
                    <ContentRoute path="/reports/report_by_item_groups" component={ReportByItemGroupPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoArtiklih]) && (
                    <ContentRoute path="/reports/report_by_items" component={ReportByItemPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoUporabnikih]) && (
                    <ContentRoute path="/reports/report_by_users" component={ReportByUserPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoDnevihInUrah]) && (
                    <ContentRoute path="/reports/report_by_day_hour" component={ReportByDayHourPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoDobavnicahPoDnevih]) && (
                    <ContentRoute path="/reports/report_by_delivery_notes_by_date" component={ReportByDeliveryNotesByDatePage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoNacinihPlacilaPoDnevih]) && (
                    <ContentRoute path="/reports/report_by_payment_by_date" component={ReportByPaymentByDatePage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPrometaPoUrah]) && (
                    <ContentRoute path="/reports/report_traffic_by_hour" component={ReportTrafficByHourPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloNabaveArtiklovPoDobaviteljih]) && (
                    <ContentRoute path="/reports/report_purchase_item_by_suppliers" component={ReportPurchaseItemBySuppliersPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloProdajePoUporabnikihPoArtiklih]) && (
                    <ContentRoute path="/reports/report_sales_item_by_users" component={ReportSalesItemByUsersPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloProdajaNabavaInMarza]) && (
                    <ContentRoute path="/reports/report_profit" component={ReportProfitPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoStrankah]) && (
                    <ContentRoute path="/reports/report_by_customers" component={ReportByCustomers} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloArtiklovVSestavnicah]) && (
                    <ContentRoute path="/reports/report_jigsaw" component={ReportJigsaw} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPorabeArtiklovPoNC]) && (
                    <ContentRoute path="/reports/report_spent_items_and_pp" component={ReportSpentItemsAndPpPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloProdajePoDobaviteljih]) && (
                    <ContentRoute path="/reports/report_sell_by_suppliers" component={ReportSellBySuppliersPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloZalogeNaDanZNCinPC]) && (
                    <ContentRoute path="/reports/report_stock_items" component={ReportStockItemsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoDokumentih]) && (
                    <ContentRoute path="/reports/report_by_documents" component={ReportByDocumentsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPoDokumentih]) && (
                    <ContentRoute path="/reports/report_by_users_by_payments" component={ReportByUsersByPaymentsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloMarzeArtiklovPoSestavnicahGAST]) && (
                    <ContentRoute path="/reports/report__jigsaw_profit" component={ReportJigsawProfitPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloProdajePoUporabnikihPoSkupinah]) && (
                    <ContentRoute path="/reports/report_sales_by_users_by_itemgroups" component={ReportSalesByUsersByItemgroupsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.ObracunLastnePorabe]) && (
                    <ContentRoute path="/reports/report_by_own_consumptions" component={ReportByOwnConsumptionsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloPorabeArtiklov]) && (
                    <ContentRoute path="/reports/report__spent_items" component={ReportSpentItemsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PrviZadnjiIzdanRacunUporabnika]) && (
                    <ContentRoute path="/reports/report_first_last_invoice_by_users" component={ReportFirstLastInvoiceByUsersPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.TrenutnoVeljavenCenik]) && (
                    <ContentRoute path="/reports/report_pricelist" component={ReportPricelistPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloGastZaNezavezance]) && (
                    <ContentRoute path="/reports/report__profit_jigsaw_with_vat" component={ReportProfitJigsawWithVatPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.PorociloNVzalogeNaDanPoFIFO]) && (
                    <ContentRoute path="/reports/report_item_stock_fifo" component={ReportItemStockFifoPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.ProdajaPoIzbranemArtiklu]) && (
                    <ContentRoute path="/reports/report__sales_items_by_date" component={ReportSalesItemsByDatePage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.RvcPoSkupinahArtiklov]) && (
                    <ContentRoute path="/reports/report_rvc_by_item_groups" component={ReportRvcByItemGroupsPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.OcenaDdvja]) && (
                    <ContentRoute path="/reports/report_vat_calculator" component={ReportVatCalculatorPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.ProdaniArtikliPoUrah]) && (
                    <ContentRoute path="/reports/report_sales_items_by_hour" component={ReportSalesItemsByHourPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.IzdaneDobavnicePoKupcih]) && (
                    <ContentRoute path="/reports/report_delivery_notes_by_customers" component={ReportDeliveryNotesByCustomersPage} />
                )}
            </Switch>
        </Suspense>
    );
}
