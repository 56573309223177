// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { checkIfOfficeUserNameIsFree } from "../../../_redux/officeUsers/officeUsersCrud";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export function OfficeUserEditForm({
  userRoles,
  saveOfficeUser,
  officeUser,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [isForeignOperator, setIsForeignOperator] = useState(officeUser.foreignOperator);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [init, setInit] = useState(false);
    const [userRoleId, setUserRoleId] = useState(officeUser.userRoleId > 0 ? officeUser.userRoleId : userRoles[0].id);
    
    useEffect(() => {
        if (officeUser.id > 0 && !init) {
            setIsForeignOperator(officeUser.foreignOperator);
            setUserRoleId(officeUser.userRoleId);
            setInit(true);
        }
    });
    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediPisarniskeUporabnike]);
    var password = undefined;
    var changepassword = undefined;
    if (officeUser.id == undefined) {
        password = Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
            .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }));
        changepassword = Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH' })
                ),
            });
    }

    // Validation schema
    const OfficeUserEditSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        userName: Yup.string()
            .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.USERNAME_EXISTS_YET' }), value => {
                return new Promise((resolve, reject) => {
                    checkIfOfficeUserNameIsFree(officeUser.id, value)
                    .then(({ data }) => {
                        if (!data.didError) {
                            resolve(data.model);
                        } else {
                            setErrorMessage(data.message);
                            setErrorOpen(true);
                        }
                    })
                    .catch((e) => {
                        resolve(false);
                    });
                })
            }),
        taxNumber: Yup.string().test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.INVALID_TAXNUMBER' }), value => {
            return new Promise((resolve, reject) => {
                if (isForeignOperator) {
                    resolve(true);
                } else {
                    if (/^[0-9]{8}$/.test(value)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            })
        }),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' })),
        userRoleId: Yup.string().test('validator', intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }), value => {
            return new Promise((resolve, reject) => {
                resolve(value != undefined && value != null && value != "");
            })
        }),
        password: password,
        changepassword: changepassword
    });

    const getInputClasses = (errors) => {
        if (errors == undefined) {
            return "";
        }
        if (errors) {
            return "is-invalid";
        }
        if (!errors) {
            return "is-valid";
        }
        return "";
    };

    function handleChange(event) {
        setUserRoleId(event.target.value);
    }

  return (
    <>
          <Formik
        enableReinitialize={true}
              initialValues={officeUser}
              validateOnChange={false}
        validationSchema={OfficeUserEditSchema}
              onSubmit={(values) => {
                  var obj = {
                      email: values.email,
                      firstName: values.firstName,
                      foreignOperator: values.foreignOperator,
                      id: values.id,
                      lastName: values.lastName,
                      phone: values.phone,
                      taxNumber: values.taxNumber,
                      userName: values.userName,
                      userRoleId: userRoleId,
                      password: values.password,
                  }
                  saveOfficeUser(obj);
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
                )}
                {!actionsLoading && (
                    <Form className="form form-label-right">
                        <div className="form-group row">
                            {/* Login */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.USERNAME" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="userName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.USERNAME' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            {/* First Name */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.INPUT.FULLNAME" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="firstName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.FULLNAME' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            {/* Last Name */}
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.LASTNAME" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="lastName"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.LASTNAME' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.PHONE" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="phone"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PHONE' })}
                                    label=""
                                    customFeedbackLabel=" "

                                />
                            </div>
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.EMAIL" /></label>
                                <Field
                                    disabled={readOnly}
                                    type="email"
                                    name="email"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                            <div className="col-lg-4">
                                <label><FormattedMessage id="SETTING.USER_ROLE" /></label>
                                <br />
                                <Select
                                    disabled={readOnly}
                                    variant="outlined"
                                    name="userRoleId"
                                    className={`form-control form-control-solid ${getInputClasses(errors.userRoleId)}`}
                                    value={userRoleId}
                                    onChange={handleChange}
                                >
                                    {userRoles.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.userRoleId ? (
                                    <div className="">{errors.userRoleId}</div>
                                ) : null}
                            </div>
                        </div>
                        {officeUser.id == undefined && (
                            <>
                                <hr />
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <label><FormattedMessage id="AUTH.INPUT.PASSWORD" /></label>
                                        <Field
                                            disabled={readOnly}
                                            name="password"
                                            type="password"
                                            component={Input}
                                            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                                            label=""
                                            customFeedbackLabel=" "
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label><FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" /></label>
                                        <Field
                                            disabled={readOnly}
                                            name="changepassword"
                                            type="password"
                                            component={Input}
                                            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                                            label=""
                                            customFeedbackLabel=" "
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <hr />
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.FOREIGN_OPERATOR" />
                                    <br />
                                    <br />
                                    <Field
                                        disabled={readOnly}
                                        className="PrivateSwitchBase-input-93"
                                        type="checkbox"
                                        name="foreignOperator"
                                        onClick={(e) => {
                                            setIsForeignOperator(e.target.value != "true");
                                        }}
                                    />

                                </label>
                            </div>
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.TAXCODE_OPERATOR" /></label>
                                <Field
                                    name="taxNumber"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TAXCODE_OPERATOR' })}
                                    label=""
                                    disabled={isForeignOperator || readOnly}
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>

                    </Form>
                )}
              
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
                <> </>
                {!actionsLoading && (
                    <CheckPermissions
                        userFunctionalities={[UserFunctionalityE.UrediPisarniskeUporabnike]}>
                        <button
                            type="submit"
                            onClick={() => handleSubmit()}
                            className="btn btn-primary btn-elevate"
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE" />
                        </button>
                    </CheckPermissions>
                )}
              
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
    </>
  );
}
