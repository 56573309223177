/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { CheckPermissions } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"

export function Navigation() {
    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    useEffect(() => {
        return () => { };
    }, [user]);

    return (
        <>
            {user && (
                <div
                    className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                    id="kt_profile_aside"
                >
                    <div className="card card-custom card-stretch">
                        {/* begin::Body */}
                        <div className="card-body pt-4">
                            {/* begin::Nav */}
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <CheckPermissions
                                    userFunctionalities={[UserFunctionalityE.UrediIzdelke]}>
                                    <div className="navi-item mb-2">
                                        <NavLink
                                            to="/settings/import/iitems"
                                            className="navi-link py-4"
                                            activeClassName="active"
                                        >
                                            <span className="navi-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Food/Coffee1.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="navi-text font-size-lg">
                                                <FormattedMessage id="IMPORT.NAVIGATION.ITEMS" />
                                            </span>
                                        </NavLink>
                                    </div>
                                </CheckPermissions>
                                <CheckPermissions
                                    userFunctionalities={[UserFunctionalityE.UrediIzdelke]}>
                                    <div className="navi-item mb-2">
                                        <NavLink
                                            to="/settings/import/ipricelists"
                                            className="navi-link py-4"
                                            activeClassName="active"
                                        >
                                            <span className="navi-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Shopping/Price1.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="navi-text font-size-lg">
                                                <FormattedMessage id="IMPORT.NAVIGATION.PRICES" />
                                            </span>
                                        </NavLink>
                                    </div>
                                </CheckPermissions>
                                <CheckPermissions
                                    userFunctionalities={[UserFunctionalityE.UrediInventurnePopise]}>
                                    <div className="navi-item mb-2">
                                        <NavLink
                                            to="/settings/import/stocks"
                                            className="navi-link py-4"
                                            activeClassName="active"
                                        >
                                            <span className="navi-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/shopping/Cart2.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="navi-text font-size-lg">
                                                <FormattedMessage id="IMPORT.NAVIGATION.STCOKS" />
                                            </span>
                                        </NavLink>
                                    </div>
                                </CheckPermissions>
                                
                            </div>
                            {/* end::Nav */}
                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            )}
        </>
    );
}
