/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../_redux/dashboards/dashboardActions"

export function TodayTrafficWidget({ className }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);

    useEffect(() => { }, [user]);

    const { currentState } = useSelector(
        (state) => ({
            currentState: state.dashboard
        }),
        shallowEqual
    );
    const { trafficToday, listLoading } = currentState;

    return (
        <div className={`card card-custom ${className}`} style={{ height: "130px" }}>
            <div className="card-body">
                {listLoading && (
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                    </div>
                )}
                {!listLoading && (
                    <>
                        <div className="row">
                            <div className="col">
                                <h5 className="text-uppercase text-muted mb-0 card-title"><FormattedMessage id="TRAFFIC_FOR_TODAY" /></h5>
                                <span className="h2 font-weight-bold mb-0">{trafficToday && trafficToday.trafficTodayAmount} <FormattedMessage id="CURRENCY_E" /></span>
                            </div>
                            <div className="col-auto col">
                                <span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
                                    <span className="symbol-label">
                                        <i className="fa fa-sun"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <p className="mt-3 mb-0 text-muted text-sm">
                            <span className={`mr-2 ${trafficToday != null && trafficToday.todayIsUp ? 'text-success' : 'text-danger'}`}>

                                {trafficToday && trafficToday.todayIsUp && (
                                    <i className="fa fa-arrow-up"></i>
                                )}
                                {trafficToday && !trafficToday.todayIsUp && (
                                    <i className="fa fa-arrow-down"></i>
                                )}

                                {trafficToday && trafficToday.diffTodayYesterday}%</span>
                            <span className="text-nowrap"><FormattedMessage id="SINCE_YESTERDAY" /></span>
                        </p>
                    </>
                )}
                
            </div>
        </div>
    );
}

