export const UserFunctionalityE = Object.freeze(
    {
        "PregledBlagajniskihUporabnikov" : 11,
        "UrediBlagajniskeUporabnike": 12,
        "PregledPisarniskihUporabnikov": 13,
        "UrediPisarniskeUporabnike": 14,
        "PregledVlogZaBlagajniskePravice": 15,
        "UrediVlogeZaBlagajniskePravice": 16,
        "PregledVlogZaPisarniskePravice": 17,
        "UrediVlogeZaPisarniskePravice": 18,
        "PregledSifrantov": 19,
        "UrediSifrante": 20,
        "PregledVasegaPodjetja": 21,
        "UrediVasePodjetje": 22,
        "AktivniUporabniki": 23,
        "Organizacije": 24,
        "PregledStrank": 25,
        "UrediStranke": 26,
        "PregledSkupinIzdelkov": 27,
        "UrediSkupineIzdelkov": 28,
        "PregledIzdelkov": 29,
        "UrediIzdelke": 30,
        "PregledPrevzemnihListov": 31,
        "UrediPrevzemneListe": 32,
        "PregledInventurnihPopisov": 33,
        "UrediInventurnePopise": 34,
        "PregledOdpisov": 35,
        "UrediOdpise": 36,
        "PregledMedskladiscnihPrenosov": 37,
        "UrediMedskladiscnePrenose": 38,
        "PregledLastnihPorab": 39,
        "UrediLastnePorabe": 40,
        "PregledVracil": 41,
        "UrediVracila": 42,
        "PregledProizvodnihNalogov": 43,
        "UrediProizvodneNaloge": 44,
        "PregledDavcnihBlagajn": 45,
        "UrediDavcneBlagajne": 46,
        "PregledPonudb": 47,
        "UrediPonudbe": 48,
        "PregledDobavnic": 49,
        "UrediDobavnice": 50,
        "PregledRacunov": 51,
        //"UrediRacune": 52
        "PregledDobavniceRacuni": 69,
        "UrediDobavniceRacune": 70,
        "PregledDobropisovOffice": 71,
        "PregledIzdatkovOffice": 72,
        "PregledPrejemkovOffice": 73,
        "PregledZakljuckov": 74,
        "Izvozi": 75,
        "PregledPrometaZaDanes": 76,
        "PregledPrometaZaTekociMesec": 77,
        "PregledPrometaVZadnjih12MesecihBrezDdv": 78,
        "PregledProdajePoLetih": 79,
        "PregledProdajeNabavePoLetih": 80,
        "PregledGibanjeZaloge": 81,
        "PregledPrometaStrank": 82,
        "PregledSeznamovPrevzemov": 83,

        //REPORTS
        "PorociloPoDnevih": 300,
        "PorocilaPoSkupinahArtiklov": 301,
        "PorociloPoArtiklih": 302,
        "PorociloPoUporabnikih": 303,
        "PorociloPoDnevihInUrah": 304,
        "PorociloPoDobavnicahPoDnevih": 305,
        "PorociloPoNacinihPlacilaPoDnevih": 306,
        "PorociloPrometaPoUrah": 307,
        "PorociloZalogaNaDan": 308,
        "PorociloNabaveArtiklovPoDobaviteljih": 309,
        "PorociloProdajePoUporabnikihPoArtiklih": 310,
        "PorociloProdajaNabavaInMarza": 311,
        "PorociloPoStrankah": 312,
        "PorociloArtiklovVSestavnicah": 313,
        "PorociloPorabeArtiklovPoNC": 314,
        "PorociloZalogaNaDanPOS": 315,
        "PorociloProdajePoMizah": 316,
        "PorociloProdajePoDobaviteljih": 317,
        "PorociloZalogeNaDanZNCinPC": 318,
        "PorociloObdelavePoMizah": 319,
        "PorociloPoDokumentih": 320,
        "PorociloPoUporabnikihPoNacinuPlacila": 321,
        "TdrPorocilo": 322,
        "PorociloMarzeArtiklovPoSestavnicahGAST": 323,
        "RacuniStornacijeSPopusti": 324,
        "RacuniStornoVezanoNaRacun": 325,
        "PorociloProdajePoUporabnikihPoSkupinah": 326,
        "ObracunLastnePorabe": 327,
        "PrimerjavaProdajePoLetihInMesecih": 328,
        "PorociloPorabeArtiklov": 329,
        "PrviZadnjiIzdanRacunUporabnika": 330,
        "TrenutnoVeljavenCenik": 331,
        "PorociloGastZaNezavezance": 332,
        "PorociloNVzalogeNaDanPoFIFO": 333,
        "ProdajaPoIzbranemArtiklu": 334,
        "RvcPoSkupinahArtiklov": 335,
        "OcenaDdvja": 336,
        "ProdaniArtikliPoUrah": 337,
        "IzdaneDobavnicePoKupcih": 338
    });
