import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ItemGroupsFilter } from "./itemGroups-filter/ItemGroupsFilter";
import { ItemGroupsTable } from "./itemGroups-table/ItemGroupsTable";
import { ItemGroupsGrouping } from "./itemGroups-grouping/ItemGroupsGrouping";
import { useItemGroupsUIContext } from "./ItemGroupsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function ItemGroupsCard() {
    const intl = useIntl();
    const itemGroupsUIContext = useItemGroupsUIContext();
  const itemGroupsUIProps = useMemo(() => {
    return {
        ids: itemGroupsUIContext.ids,
        newItemGroupButtonClick: itemGroupsUIContext.newItemGroupButtonClick,
    };
  }, [itemGroupsUIContext]);

    return (
        <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.ITEM_GROUPS" } )} >
                <CardHeaderToolbar className="chtItemGroups">
                  <CheckPermissions
                        userFunctionalities={[UserFunctionalityE.UrediSkupineIzdelkov]}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={itemGroupsUIProps.newItemGroupButtonClick}
                    >
                          <FormattedMessage id="AUTH.ITEM_GROUP.NEW" />
                    </button>
                </CheckPermissions>
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ItemGroupsFilter />
        {itemGroupsUIProps.ids.length > 0 && <ItemGroupsGrouping />}
        <ItemGroupsTable />
      </CardBody>
    </Card>
  );
}
