import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { EndsFilter } from "./ends-filter/EndsFilter";
import { EndsTable } from "./ends-table/EndsTable";
import { EndsGrouping } from "./ends-grouping/EndsGrouping";
import { useEndsUIContext } from "./EndsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function EndsCard() {
    const intl = useIntl();
    const endsUIContext = useEndsUIContext();
  const endsUIProps = useMemo(() => {
    return {
        ids: endsUIContext.ids,
        newEndButtonClick: endsUIContext.newEndButtonClick,
    };
  }, [endsUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.ENDS" } )} >
            <CardHeaderToolbar>
                  
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <EndsFilter />
        {endsUIProps.ids.length > 0 && <EndsGrouping />}
        <EndsTable />
      </CardBody>
    </Card>
  );
}
