import React, { createContext, useContext, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import {isEqual, isFunction} from "lodash";
import moment from 'moment'
import { shallowEqual, useSelector } from "react-redux";

const EndsUIContext = createContext();

export function useEndsUIContext() {
    return useContext(EndsUIContext);
}

export const EndsUIConsumer = EndsUIContext.Consumer;

export function EndsUIProvider({ endsUIEvents, children }) {
    const [cookies, setCookie] = useCookies();
    const { user } = useSelector(state => state.auth);

    const initialFilter = {
        status: 1,
        query: "",
        sortOrder: "asc", // asc||desc
        sortField: "id",
        pageNumber: 1,
        pageSize: 25,
        startDate: moment(new Date(new Date().setMonth(new Date().getMonth() - 1))).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        cashierIds: function () {
            var list = cookies['filter_dt_ends' + '_' + user.id] != undefined ? cookies['filter_dt_ends' + '_' + user.id] : [];
            var ids = "";
            for (var index = 0; index < list.length; index++) {
                if (ids != "") {
                    ids += ",";
                }
                ids += list[index];
            }
            return ids;
        }()
    };



  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initEnd = {
        id: undefined,
        taxDuty: false,
        taxNumber: "",
        name: "",
        address: "",
        postCode: null,
        postTitle: null,
        isForeigner: false,
        useWholesalePricelist: false,
        withoutVatExport: false,
        discount: 0,
        paymentDeadline: 8,
        isSupplier: false,
        active: true,
        bankBicCode: "",
        bankIbanCode: "",
        countryId: null
    };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initEnd,
      printDocumentsDialog: endsUIEvents.printDocumentsDialog,
      printDocumentDialog: endsUIEvents.printDocumentDialog
  };

    return <EndsUIContext.Provider value={value}>{children}</EndsUIContext.Provider>;
}