import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Input,
} from "../../../../../../_metronic/_partials/controls";

const prepareFilter = (queryParams, values) => {

    const { searchText } = values;
    const newQueryParams = { ...queryParams };
  
    newQueryParams.query = searchText;
    return newQueryParams;
};

export function CashiersFilter({ listLoading }) {
    const intl = useIntl();

  // Cashiers UI Context
    const cashiersUIContext = useCashiersUIContext();
  const cashiersUIProps = useMemo(() => {
    return {
        queryParams: cashiersUIContext.queryParams,
        setQueryParams: cashiersUIContext.setQueryParams,
    };
  }, [cashiersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(cashiersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, cashiersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      cashiersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
        <div className="form-group row">
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.SEARCH"
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
