import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./CashiersUIHelpers";

const CashiersUIContext = createContext();

export function useCashiersUIContext() {
    return useContext(CashiersUIContext);
}

export const CashiersUIConsumer = CashiersUIContext.Consumer;

export function CashiersUIProvider({cashiersUIEvents, children}) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
        if (isFunction(nextQueryParams)) {
            nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
            return prevQueryParams;
        }

        return nextQueryParams;
    });
    }, []);

    const initCashier = {
        id: undefined,
        businessPremiseTitle: "",
        businessPremiseId: "",
        cashierId: "",
        isRealEstateBP: true,
        realEstatePropertyCadastralNumber: "",
        realEstatePropertyBuildingNumber: "",
        realEstatePropertyBuildingSectionNumber: "",
        realEstateAddressStreet: "",
        realEstateAddressHouseNumber: "",
        realEstateAddressHouseNumberAdditional: "",
        realEstateAddressCommunity: "",
        realEstateAddressCity: "",
        realEstateAddressPostalCode: "",
        premiseType: "1",
        isRegistered: false,
        warehouse: null,
        warehouseId: null,
        valid: ""
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initCashier,
        newCashierButtonClick: cashiersUIEvents.newCashierButtonClick,
        openEditCashierDialog: cashiersUIEvents.openEditCashierDialog,
        openDeleteCashierDialog: cashiersUIEvents.openDeleteCashierDialog,
        openDeleteCashiersDialog: cashiersUIEvents.openDeleteCashiersDialog,
        printInternAktDialog: cashiersUIEvents.printInternAktDialog,
        registerDialog: cashiersUIEvents.registerDialog,
        unregisterDialog: cashiersUIEvents.unregisterDialog,
        openConnectDialog: cashiersUIEvents.openConnectDialog,
        openDeconnectDialog: cashiersUIEvents.openDeconnectDialog
    };

    return <CashiersUIContext.Provider value={value}>{children}</CashiersUIContext.Provider>;
}