import axios from "axios";

export function getOrganization() {
    return axios.get("/api/organization");
}

// UPDATE => PUT: update the user on the server
export function updateOrganization(organization) {
    return axios.put("/api/organization/yourOrganization", organization);
}

export function updateLogoOrganization(data) {
    return axios.post("/api/organization/logo", data);
}

export function updateFiscalDigitalCertificate(data) {
    return axios.post("/api/fiscalDigitalCertificate", data);
}

export function getFiscalDigitalCertificate() {
    return axios.get("/api/fiscalDigitalCertificate");
}