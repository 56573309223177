import { createSlice } from "@reduxjs/toolkit";

const initialExportState = {
    listLoading: false,
    actionsLoading: false,
    exportDataForEdit: null,
    totalCount: 0,
    entities: null,
    lastError: null,
    deleteResult: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const exportSlice = createSlice({
    name: "export",
    initialState: initialExportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.deleteResult = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        lastExportDataFetched: (state, action) => {
            state.actionsLoading = false;
            state.exportDataForEdit = action.payload.exportDataForEdit;
            state.error = null;
        },
        // expertsFetched
        expertsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        }
    }
});
