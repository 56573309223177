import { createSlice } from "@reduxjs/toolkit";


const initialOrganizationState = {
    listLoading: false,
    actionsLoading: false,
    organizationForEdit: undefined,
    fiscalDigitalCertificateForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const yourCompanySlice = createSlice({
    name: "organization",
    initialState: initialOrganizationState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.deleteResult = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        organizationFetched: (state, action) => {
            state.actionsLoading = false;
            state.organizationForEdit = action.payload.organizationForEdit;
            state.error = null;
        },
        organizationUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.organizationForEdit = action.payload.organization;
        },
        fiscalDigitalCertificateFetched: (state, action) => {
            state.actionsLoading = false;
            state.fiscalDigitalCertificateForEdit = action.payload.fiscalDigitalCertificateForEdit;
            state.error = null;
        }
    }
});
