/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";

const PricelistsUIContext = createContext();
export function usePricelistsUIContext() {
  return useContext(PricelistsUIContext);
}

export const PricelistsUIConsumer = PricelistsUIContext.Consumer;
export function PricelistsUIProvider({ readOnly, children }) {

  const [selectedId, setSelectedId] = useState(null);
  const [showEditPricelistDialog, setShowEditPricelistDialog] = useState(false);
  const initPricelist = {
    id: undefined,
    priceWithoutVat: 0,
    priceWithVat: 0,
      effectiveDateFrom: new Date()
  };


  const openEditPricelistDialog = id => {
    setSelectedId(id);
    setShowEditPricelistDialog(true);
    setShowDeletePricelistDialog(false);
  };

  const [showDeletePricelistDialog, setShowDeletePricelistDialog] = useState(false);
    const openDeletePricelistDialog = id => {
    setSelectedId(id);
    setShowDeletePricelistDialog(true);
    setShowEditPricelistDialog(false);
  };
  const closeDeletePricelistDialog = () => {
    setSelectedId(undefined);
    setShowDeletePricelistDialog(false);
  };

  const value = {
    initPricelist,
    selectedId,
      readOnly,
    openEditPricelistDialog,
    showDeletePricelistDialog,
    openDeletePricelistDialog,
    closeDeletePricelistDialog,
  };
  
  return (
    <PricelistsUIContext.Provider value={value}>
      {children}
    </PricelistsUIContext.Provider>
  );
}
