/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"
import AutocompletePost from '../../../../components/AutocompletePost'
import { updateLogoOrganization } from "../../_redux/yourCompany/yourCompanyCrud";

export default function CompanyDetails(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [logoUniqueId, setLogoUniqueId] = useState("");
    const [removeLogo, setRemoveLogo] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { organization, error } = useSelector(
        (state) => ({
            organization: state.organization.organizationForEdit,
            error: state.organization.error
        }),
        shallowEqual
    );

    const [post, setPost] = useState(organization != undefined && organization.postCode != null ?
        { code: organization.postCode, title: organization.postTitle } : null);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediVasePodjetje]);

    useEffect(() => {
        setPost(organization != undefined && organization.postCode != null ?
            { code: organization.postCode, title: organization.postTitle } : null);
    }, [user, organization]);
    // Methods
    const saveOrganization = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);

        var newLogoUniqueId = values.logoUniqueId;
        if (removeLogo == true) {
            newLogoUniqueId = "";
        } else if (logoUniqueId != "" && newLogoUniqueId != logoUniqueId) {
            newLogoUniqueId = logoUniqueId;
        }
        
        var obj = {
            address: values.address,
            appModulePurchase: values.appModulePurchase,
            appModuleSales: values.appModuleSales,
            description: values.description,
            id: values.id,
            name: values.name,
            postCode: post.code,
            postTitle: post.title,
            registrationNumber: values.registrationNumber,
            taxDuty: values.taxDuty,
            taxNumber: values.taxNumber,
            town: values.town,
            bankName: values.bankName,
            bankIban: values.bankIban,
            bankBic: values.bankBic,
            invoiceSendToMail: values.invoiceSendToMail,
            invoiceEmail: values.invoiceEmail,
            invoiceAddress: values.invoiceAddress,
            invoicePostCode: values.invoicePostCode,
            invoicePostTitle: values.invoicePostTitle,
            logoUniqueId: newLogoUniqueId,
            officeUserCount: values.officeUserCount,
            cashierUserCount: values.cashierUserCount,
            cashierCount: values.cashierCount,
            minOfficeUserCount: values.minOfficeUserCount,
            minCashierUserCount: values.minCashierUserCount,
            minCashierCount: values.minCashierCount,
            deactiveReason:  values.deactiveReason,
            deactiveDate: values.deactiveDate,
            confirmed: values.confirmed,
            contractExpirationDate: values.contractExpirationDate
        };
      
        dispatch(actions.updateOrganization(obj)).then(() => {
            setIsError(error != null);
            setIsSuccess(error == null);
            setLoading(false);
            setSubmitting(false);
            setRemoveLogo(false);
            setLogoUniqueId("");
        });
    };
    // UI Helpers
    const initialValues = {
        address: organization != undefined && organization != null ? organization.address : "",
        appModulePurchase: organization && organization != null != undefined ? organization.appModulePurchase : false,
        appModuleSales: organization && organization != null != undefined ? organization.appModuleSales : false,
        description: organization && organization != null != undefined ? organization.description : "",
        id: organization && organization != null != undefined ? organization.id : -1,
        name: organization && organization != null != undefined ? organization.name : "",
        postCode: organization && organization != null != undefined ? organization.postCode : 0,
        postTitle: organization && organization != null != undefined ? organization.postTitle : "",
        registrationNumber: organization && organization != null != undefined ? organization.registrationNumber : "",
        taxDuty: organization != undefined && organization != null ? organization.taxDuty : false,
        taxNumber: organization != undefined && organization != null ? organization.taxNumber : "",
        town: organization != undefined && organization != null ? organization.town : "",
        bankName: organization != undefined && organization != null && organization.bankName != null ? organization.bankName : "",
        bankIban: organization != undefined && organization != null && organization.bankIban != null ? organization.bankIban : "",
        bankBic: organization != undefined && organization != null && organization.bankBic != null ? organization.bankBic : "",
        invoiceSendToMail: organization && organization != null != undefined ? organization.invoiceSendToMail : false,
        invoiceEmail: organization && organization != null != undefined ? organization.invoiceEmail : "",
        invoiceAddress: organization && organization != null != undefined ? organization.invoiceAddress : "",
        invoicePostCode: organization && organization != null != undefined ? organization.invoicePostCode : 0,
        invoicePostTitle: organization && organization != null != undefined ? organization.invoicePostTitle : "",
        logoUniqueId: organization && organization != null != undefined ? organization.logoUniqueId : "",
        officeUserCount: organization != undefined && organization != null ? organization.officeUserCount : 1,
        cashierUserCount: organization != undefined && organization != null ? organization.cashierUserCount : 1,
        cashierCount: organization != undefined && organization != null ? organization.cashierCount : 1,
        minOfficeUserCount: organization != undefined && organization != null ? organization.minOfficeUserCount : 1,
        minCashierUserCount: organization != undefined && organization != null ? organization.minCashierUserCount : 1,
        minCashierCount: organization != undefined && organization != null ? organization.minCashierCount : 1,
        deactiveReason: organization != undefined && organization != null && organization.deactiveReason != null ? organization.deactiveReason : "",
        deactiveDate: organization != undefined && organization != null && organization.deactiveDate != null ? organization.deactiveDate : "",
        confirmed: organization && organization != null != undefined ? organization.confirmed : false,
        contractExpirationDate: organization != undefined && organization != null && organization.contractExpirationDate != null ? organization.contractExpirationDate : null
    };

    const Schema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        town: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const getLogo = () => {
        if (removeLogo) {
            return "none"
        }
        return "url(/api/organization/logo?uniqueId=" + logoUniqueId + "&" + new Date().getTime() + ")";
    };
    const onClickRemoveLogo = () => {
        setLogoUniqueId("");
        setRemoveLogo(true);
    };

    const onChangeLogo = event => {
        const formData = new FormData();
        formData.append(
            "logo",
            event.target.files[0],
            event.target.files[0].name
        );
        updateLogoOrganization(formData).then((req) => {
            if (!req.data.didError) {
                setLogoUniqueId(req.data.model);
                setRemoveLogo(false);
            } else {
                setLogoUniqueId("");
            }
        }).catch(() => {
            setLogoUniqueId("");
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveOrganization(values, setStatus, setSubmitting);
        },
        onReset: () => {
            setIsError(false);
            setIsSuccess(false);
            setLogoUniqueId("");
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="COMPANY.NAVIGATION.DATA" />
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="COMPANY.NAVIGATION.DATA_2" />
          </span>
                </div>

                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || post == null || (formik.touched && !formik.isValid)
                            }
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
                {formik.isSubmitting}
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="reset"
                        >
                            <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                        </button>
                    </div>
                </CheckPermissions>

            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_COMPANY_DATA" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_COMPANY_DATA" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.TAXCODE" />
                        </label>
                        
                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={true}
                                type="text"
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TAXCODE' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "taxNumber"
                                )}`}
                                name="taxNumber"
                                {...formik.getFieldProps("taxNumber")}
                            />
                            {formik.touched.taxNumber &&
                                formik.errors.taxNumber ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.taxNumber}
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.COMPANY_TITLE" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                disabled={readOnly}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.COMPANY_TITLE' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "name"
                                )}`}
                                name="name"
                                {...formik.getFieldProps("name")}
                            />
                            {formik.touched.name &&
                                formik.errors.name ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.name}
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.COMPANY_TITLE_LONG" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                disabled={readOnly}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.COMPANY_TITLE_LONG' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "description"
                                )}`}
                                name="description"
                                {...formik.getFieldProps("description")}
                            />
                            {formik.touched.description &&
                                formik.errors.description ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.description}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.ADDRESS" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                disabled={readOnly}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "address"
                                )}`}
                                name="address"
                                {...formik.getFieldProps("address")}
                            />
                            {formik.touched.address &&
                                formik.errors.address ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.address}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.POST" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <AutocompletePost
                                name="postCode"
                                value={post}
                                onChange={(val) => { setPost(val); }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.TOWN" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                disabled={readOnly}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TOWN' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "town"
                                )}`}
                                name="town"
                                {...formik.getFieldProps("town")}
                            />
                            {formik.touched.town &&
                                formik.errors.town ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.town}
                                    </div>
                                ) : null}
                        </div>
                    </div>


                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.INPUT.TAX_DUTY" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={readOnly}
                                type="checkbox"
                                name="taxDuty"
                                checked={formik.values.taxDuty}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                            <FormattedMessage id="AUTH.INPUT.LOGO" />
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <div
                                className="image-input image-input-outline"
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/users/blank.png"
                                    )}`,
                                }}
                            >
                                <div
                                    className="image-input-wrapper"
                                    style={{ backgroundImage: `${getLogo()}` }}
                                />
                                <label
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                    <input
                                        type="file"
                                        onChange={onChangeLogo}
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </label>
                                <span
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="cancel"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                <span
                                    onClick={onClickRemoveLogo}
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                    data-toggle="tooltip"
                                    title=""
                                >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            </div>
                            <span className="form-text text-muted">
                                <FormattedMessage id="IMAGE.ALLOWED_FILES" />
                            </span>
                        </div>
                    </div>

                </div>

            </div>
            {/* end::Form */}
        </form>
    );
}

