// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { decimal } from "../../../../../../../../src/app/util/Util";
import AutocompleteItemGoods from "../../../../../../components/AutocompleteItemGoods"

export function JigsawEditForm({ saveJigsaw, jigsaw, currentVatRate, readOnly }) {
    const intl = useIntl();

    const [jigsawId, setJigsawId] = useState(jigsaw.id);
    const [item2, setItem2] = useState(jigsaw.item2);
    const [quantity, setQuantity] = useState(jigsaw.quantity);
    const [errorItem2, setErrorItem2] = useState("");
    const [errorQuantity, setErrorQuantity] = useState("");
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        setJigsawId(jigsaw.id);
        setItem2(jigsaw.item2);
        setQuantity(jigsaw.quantity);
    }, [jigsaw]);

    const getInputClasses = (fieldname) => {
        if (!isValidated) {
            return "";
        }
        switch (fieldname) {
            case "item2":
                return errorItem2.length > 0 ? "is-invalid" : "is-valid";
            case "quantity":
                return errorQuantity.length > 0 ? "is-invalid" : "is-valid";
        }
        return "";
    };

    function release() {
        setJigsawId(undefined);
        setItem2(null);
        setQuantity(0);
        setIsValidated(false);
        setErrorItem2("");
        setErrorQuantity("");
    }

    function save() {
        if (validateForm()) {
            var obj = {
                id: jigsawId,
                quantity: quantity,
                item2Id: item2.id,
                item2: item2
            };
            saveJigsaw(obj);
        }
    }

    function validateForm() {
        var res = true;
        if (item2 == undefined) {
            setErrorItem2(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorItem2("");
        }
        if (quantity === undefined || quantity === "" || quantity === NaN) {
            setErrorQuantity(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
            res = false;
        } else {
            setErrorQuantity("");
        }
        
        return res;
    }

  return (
    <>
        <div className="form-group row">
            <div className="col-lg-3">
                  <label><FormattedMessage id="AUTH.GENERAL.ITEM" /></label>
                  <AutocompleteItemGoods
                      readOnly={readOnly}
                      name="item2"
                      value={item2}
                      onChange={(val) => {
                          setItem2(val);
                      }}
                  />
                  {errorItem2.length > 0 ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorItem2}</div>
                      </div>
                  ) : null}
            </div>
            <div className="col-lg-3">
                  <label><FormattedMessage id="AUTH.GENERAL.QUANTITY" /></label>
                  <input
                      placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.QUANTITY' })}
                      type="number"
                      disabled={readOnly}
                      value={quantity}
                      onKeyPress={decimal}
                      className={`form-control ${getInputClasses(
                          "quantity"
                      )}`}
                      name="quantity"
                      onChange={(event, val) => {
                          setQuantity(event.target.value);
                      }}
                  />
                  {errorQuantity.length > 0 ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorQuantity}</div>
                      </div>
                  ) : null}
            </div>

            <div className="col-lg-3">
                <br />
                <button
                    disabled={readOnly}
                    type="button"
                    className="btn btn-danger btn-elevate"
                    onClick={() => release()}
                >
                    <FormattedMessage id="AUTH.INPUT.RELEASE" />
                </button>
                    <> </>
                <button
                    disabled={readOnly}
                    type="button"
                    className="btn btn-primary btn-elevate"
                    onClick={() => save()}
                  >
                      {jigsawId == undefined && <FormattedMessage id="AUTH.GENERAL.ADD" />}
                      {jigsawId != undefined && <FormattedMessage id="AUTH.GENERAL.SAVE" />}
                </button>
            </div>
        </div>
            
    </>
  );
}
