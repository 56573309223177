import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/codeLists/codeListsActions";
import { CodeListEditDialogHeader } from "./CodeListEditDialogHeader";
import { CodeListEditForm } from "./CodeListEditForm";
import { useCodeListsUIContext } from "../CodeListsUIContext";

export function CodeListEditDialog({id, show, onHide }) {
  // CodeLists UI Context
    const codeListsUIContext = useCodeListsUIContext();
    const codeListsUIProps = useMemo(() => {
    return {
        initCodeList: codeListsUIContext.initCodeList,
        queryParams: codeListsUIContext.queryParams
    };
    }, [codeListsUIContext]);
  // CodeLists Redux state
    const dispatch = useDispatch();
    const { actionsLoading, codeListForEdit } = useSelector(
    (state) => ({
            actionsLoading: state.codeLists.actionsLoading,
            codeListForEdit: state.codeLists.codeListForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
      // server call for getting CodeList by id
      dispatch(actions.fetchCodeList(id));
      
      
  }, [id, dispatch]);

 

  // server request for saving codeList
    const saveCodeList = (codeList) => {
    if (!id) {
      // server request for creating codeList
        dispatch(actions.createCodeList(codeList)).then(() => onHide());
    } else {
      // server request for updating codeList
        dispatch(actions.updateCodeList(codeList)).then(() => onHide());
    }
  };
    
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      >
          <CodeListEditDialogHeader id={id} />
          <CodeListEditForm
              codelistSchemaId={codeListsUIProps.queryParams.id }
              saveCodeList={saveCodeList}
        actionsLoading={actionsLoading}
              codeList={codeListForEdit || codeListsUIProps.initCodeList}
        onHide={onHide}
      />
    </Modal>
  );
}
