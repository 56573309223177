import React, { useState } from "react";
import { Route } from "react-router-dom";
import { OfficeUsersLoadingDialog } from "./officeUsers-loading-dialog/OfficeUsersLoadingDialog";
import { OfficeUserEditDialog } from "./officeUser-edit-dialog/OfficeUserEditDialog";
import { OfficeUserDeleteDialog } from "./officeUser-delete-dialog/OfficeUserDeleteDialog";
import { OfficeUsersDeleteDialog } from "./officeUsers-delete-dialog/OfficeUsersDeleteDialog";
import { OfficeUsersUIProvider } from "./OfficeUsersUIContext";
import { OfficeUsersCard } from "./OfficeUsersCard";
import { shallowEqual, useSelector } from "react-redux";
import InfoPopup from "../../../Helpers/InfoPopup"
import {  useIntl } from "react-intl";


export function OfficeUsersPage({ history }) {
    const intl = useIntl();
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const { user } = useSelector(state => state.auth);
    const { currentState } = useSelector(
        (state) => ({ currentState: state.officeUsers }),
        shallowEqual
    );
    const { totalCount } = currentState;

  const officeUsersUIEvents = {
     newOfficeUserButtonClick: () => {
          if (totalCount < user.organizationOfficeUserCount) {
              history.push("/users/office/new");
          } else {
              setOpen(true);
              setMessage(intl.formatMessage({
                  id: "GENERAL.CAN_NOT_ADDED_USERS",
              }));
          }
    },
    openEditOfficeUserDialog: (id) => {
        history.push(`/users/office/${id}/edit`);
    },
    openDeleteOfficeUserDialog: (id) => {
        history.push(`/users/office/${id}/delete`);
    },
    openDeleteOfficeUsersDialog: () => {
        history.push(`/users/office/deleteUsers`);
    }
  }

  return (
      <OfficeUsersUIProvider officeUsersUIEvents={officeUsersUIEvents}>
          <OfficeUsersLoadingDialog />
          <Route path="/users/office/new">
        {({ history, match }) => (
                  <OfficeUserEditDialog
            show={match != null}
            onHide={() => {
                history.push("/users/office");
            }}
          />
        )}
      </Route>
          <Route path="/users/office/:id/edit">
        {({ history, match }) => (
          <OfficeUserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/users/office");
            }}
          />
        )}
      </Route>
          <Route path="/users/office/deleteUsers">
        {({ history, match }) => (
          <OfficeUsersDeleteDialog
            show={match != null}
            onHide={() => {
                history.push("/users/office");
            }}
          />
        )}
      </Route>
          <Route path="/users/office/:id/delete">
        {({ history, match }) => (
          <OfficeUserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/users/office");
            }}
          />
        )}
      </Route>
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false);}} />
          <OfficeUsersCard />
      </OfficeUsersUIProvider>
  );
}
