// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, InputGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InfoPopup from "../../../../Helpers/InfoPopup"
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import { SketchPicker } from 'react-color'
import { onlyDigits, onlyPositiveDecimal } from "../../../../../util/Util";
import SelectVat from "../../../../../components/SelectVat"
import SelectItemType from "../../../../../components/SelectItemType"
import SelectUnit from "../../../../../components/SelectUnit"
import AutocompleteItemGroup from "../../../../../components/AutocompleteItemGroup"
import { checkIfEanCodeExist } from "../../../_redux/items/itemsCrud";
import { PricelistsUIProvider } from "../item-pricelist/PricelistsUIContext";
import { Pricelists } from "../item-pricelist/Pricelists";
import * as actions from "../../../_redux/items/itemsActions";
import { useDispatch } from "react-redux";
import { JigsawsUIProvider } from "../item-jigsaw/JigsawsUIContext";
import { Jigsaws } from "../item-jigsaw/Jigsaws";


export function ItemEditForm({
  saveItem,
  item,
  actionsLoading,
  onHide,
}) {
    const [tab, setTab] = useState("details");
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [background, setBackground] = useState(item != undefined ? item.background : "#ffffff");
    const [displayBackgroundPicker, setDisplayBackgroundPicker] = useState(false);
    const [foreground, setForeground] = useState(item != undefined ? item.foreground : "#ffffff");
    const [displayForegroundPicker, setDisplayForegroundPicker] = useState(false);
    const [vatRateId, setVatRateId] = useState(item != undefined ? item.vatRateId : 1);
    const [itemTypeId, setItemTypeId] = useState(item != undefined ? item.itemTypeId : 1);
    const [unitId, setUnitId] = useState(item != undefined ? item.unitId : null);
    const [itemGroup, setItemGroup] = useState(item != undefined ? item.itemGroup : null);

    const dispatch = useDispatch();
    useEffect(() => {
        setBackground(item != undefined ? item.background : "#ffffff");
        setForeground(item != undefined ? item.foreground : "#ffffff");
        setItemGroup(item != undefined ? item.itemGroup : null);
        setVatRateId(item != undefined ? item.vatRateId : 1);
        setItemTypeId(item != undefined ? item.itemTypeId : 1);
        setUnitId(item != undefined ? item.unitId : null);
        dispatch(actions.setCurrentVatRateId(item != undefined ? item.vatRateId : 1));
    }, [user, item]);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediSkupineIzdelkov]);

    // Validation schema
    const ItemEditSchema = Yup.object().shape({
        title: Yup.string()
            .max(250, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_250_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        fontSize: Yup.number()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'FONT_SIZE.MORE_THEN_6' }), value => {
                return new Promise((resolve, reject) => {
                    var num = parseFloat(value);
                    resolve(num >= 7);
                })
            }),
        supplierNumber: Yup.string()
            .max(200, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_200_SYMBOLS' })),
        maxDiscount: Yup.number()
            .test('validator', intl.formatMessage({ id: 'MAX_DISCOUNT.MORE_0_TO_100' }), value => {
                return new Promise((resolve, reject) => {
                    if (value == undefined) {
                        resolve(true);
                    }
                    var num = parseFloat(value);
                    resolve(num >= 0 && num <= 100);
                })
            }),
        eanCode: Yup.string()
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_30_SYMBOLS' }))
            .test('validator', intl.formatMessage({ id: 'ITEM.GENERAL.ITEM_EAN_CODE_EXISTS_YET' }), value => {
                return new Promise((resolve, reject) => {
                    if (value != undefined && value != "" && value != null) {
                        checkIfEanCodeExist(item.id, value)
                            .then(({ data }) => {
                                if (!data.didError) {
                                    resolve(!data.model);
                                } else {
                                    resolve(false);
                                }
                            })
                            .catch((e) => {
                                resolve(false);
                            });
                    } else {
                        resolve(true);
                    }
                })
            }),
    });

    function validateForm(values) {
        if (itemGroup != null) {
            return true;
        } else {
            setMessage(intl.formatMessage({ id: 'AUTH.INFO_SELECT_ITEM_GROUP' }));
            setOpen(true);
            return false;
        }
    }

  return (
    <>
          <Formik
        enableReinitialize={true}
        initialValues={item}
        validateOnChange={false}
              validationSchema={ItemEditSchema}
              onSubmit={(values) => {
                  if (validateForm(values)) {
                      var obj = {
                          id: values.id,
                          title: values.title,
                          description: values.description,
                          eanCode: values.eanCode,
                          supplierNumber: values.supplierNumber,
                          allowedDiscount: values.allowedDiscount,
                          appearInCashier: values.appearInCashier,
                          isTdr: values.isTdr,
                          isConsignmentStock: values.isConsignmentStock,
                          exemptForVat: values.exemptForVat,
                          background: background,
                          foreground: foreground,
                          fontSize: values.fontSize,
                          order: values.order,
                          active: values.active,
                          maxDiscount: values.maxDiscount,
                          grossWeightPerUnit: values.grossWeightPerUnit,
                          itemGroupId: itemGroup.id,
                          itemGroup: itemGroup,
                          vatRateId: vatRateId,
                          unitId: unitId,
                          itemTypeId: itemTypeId,
                          printer1: values.printer1,
                          printer2: values.printer2,
                          printer3: values.printer3
                      };
                      saveItem(obj);
                  }
              }
        }
      >
        {({ handleSubmit, errors, values }) => (
                  <>
                      {!actionsLoading && (
                          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                              <li className="nav-item" onClick={() => setTab("details")}>
                                  <a
                                      style={{ margin: "0px 0px 0px 5px" }}
                                      className={`nav-link ${tab === "details" && "active"}`}
                                      data-toggle="tab"
                                      role="tab"
                                      aria-selected={(tab === "details").toString()}
                                  >
                                      <FormattedMessage id="TAB.DETAILS" />
                                  </a>
                              </li>
                              <li className="nav-item" onClick={() => setTab("price")}>
                                  <a
                                      className={`nav-link ${tab === "price" && "active"}`}
                                      data-toggle="tab"
                                      role="button"
                                      aria-selected={(tab === "price").toString()}
                                  >
                                      <FormattedMessage id="TAB.PRICE" />
                                  </a>
                              </li>
                              {itemTypeId == 2 && (
                                  <li className="nav-item" onClick={() => setTab("jigsaw")}>
                                      <a
                                          className={`nav-link ${tab === "jigsaw" && "active"}`}
                                          data-toggle="tab"
                                          role="tab"
                                          aria-selected={(tab === "jigsaw").toString()}
                                      >
                                          <FormattedMessage id="TAB.JIGSAW" />
                                      </a>
                                  </li>
                              )}
                              <li className="nav-item" onClick={() => setTab("shape")}>
                                  <a
                                      className={`nav-link ${tab === "shape" && "active"}`}
                                      data-toggle="tab"
                                      role="tab"
                                      aria-selected={(tab === "shape").toString()}
                                  >
                                      <FormattedMessage id="TAB.SHAPE" />
                                  </a>
                              </li>
                          </ul>
                      )}
                      
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                          )}

                          {!actionsLoading && (
                              <Form className="form form-label-right">
                                  {tab === "details" && (
                                      <>
                                          <div className="form-group row">
                                              <div className="col-lg-8">
                                                  <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="title"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.EAN_CODE" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="eanCode"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EAN_CODE' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>
                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-12">
                                                  <label><FormattedMessage id="AUTH.INPUT.DESCRIPTION" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="description"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.DESCRIPTION' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>

                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.GENERAL.VAT_RATE" /></label>
                                                  <SelectVat
                                                      readOnly={readOnly}
                                                      name="vatRateId"
                                                      value={vatRateId}
                                                      onChange={(val) => { setVatRateId(val); dispatch(actions.setCurrentVatRateId(val)); }}
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.GENERAL.TYPE" /></label>
                                                  <SelectItemType
                                                      readOnly={readOnly}
                                                      name="itemTypeId"
                                                      value={itemTypeId}
                                                      onChange={(val) => { setItemTypeId(val); }}
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.GENERAL.UNIT" /></label>
                                                  <SelectUnit
                                                      readOnly={readOnly}
                                                      name="unitId"
                                                      value={unitId}
                                                      onChange={(val) => { setUnitId(val); }}
                                                  />
                                              </div>

                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.GENERAL.ITEM_GROUP" /></label>

                                                  <AutocompleteItemGroup
                                                      readOnly={readOnly}
                                                      name="itemGroupId"
                                                      value={itemGroup}
                                                      onChange={(val) => {
                                                          setItemGroup(val);
                                                          setBackground(val.background);
                                                          setForeground(val.foreground);
                                                      }}
                                                  />

                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.SUPPLIER_NUMBER" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="supplierNumber"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.SUPPLIER_NUMBER' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                  />
                                              </div>

                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.MAX_DISCOUNT" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      type="number"
                                                      name="maxDiscount"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.MAX_DISCOUNT' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>

                                          </div>
                                          <div className="form-group row">

                                              {itemTypeId == 1 && (
                                                  <div className="col-lg-4">
                                                      <label><FormattedMessage id="AUTH.INPUT.GROSS_WEIGHT_PER_UNIT" /></label>
                                                      <Field
                                                          disabled={readOnly}
                                                          type="number"
                                                          name="grossWeightPerUnit"
                                                          component={Input}
                                                          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.GROSS_WEIGHT_PER_UNIT' })}
                                                          label=""
                                                          customFeedbackLabel=" "
                                                          onKeyPress={onlyPositiveDecimal}
                                                      />
                                                  </div>
                                              )}

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.ALLOWED_DISCOUNT" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="allowedDiscount"
                                                      />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.APPEAR_IN_CASHIER" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="appearInCashier"
                                                      />
                                                  </label>
                                              </div>

                                          </div>
                                          <div className="form-group row">

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.IS_TDR" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="isTdr"
                                                      />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.IS_CONSIGNMENT_STOCK" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="isConsignmentStock"
                                                      />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.EXEMPT_FOR_VAT" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="exemptForVat"
                                                      />
                                                  </label>
                                              </div>

                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="active"
                                                      />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.PRINTER1" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="printer1"
                                                      />
                                                  </label>
                                              </div>

                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.PRINTER2" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="printer2"
                                                      />
                                                  </label>
                                              </div>

                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-4" >
                                                  <label><FormattedMessage id="ECOMMERCE.COMMON.PRINTER3" />
                                                      <br />
                                                      <Field
                                                          disabled={readOnly}
                                                          className="PrivateSwitchBase-input-93"
                                                          type="checkbox"
                                                          name="printer3"
                                                      />
                                                  </label>
                                              </div>
                                          </div>
                                      </>
                                  )}

                                  {tab === "price" && (
                                      <PricelistsUIProvider readOnly={readOnly}>
                                          <Pricelists />
                                      </PricelistsUIProvider>
                                  )}

                                  {tab === "jigsaw" && (
                                      <JigsawsUIProvider readOnly={readOnly}>
                                          <Jigsaws />
                                      </JigsawsUIProvider>
                                  )}

                                  {tab === "shape" && (
                                      <>
                                          <div className="form-group row">
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.BACKGROUND" /></label>
                                                  <br />
                                                  <div className="form-control"
                                                      style={{
                                                          padding: '5px',
                                                          background: '#fff',
                                                          borderRadius: '2px',
                                                          display: 'inline-block',
                                                          cursor: 'pointer',
                                                      }}
                                                      onClick={() => { if (readOnly) return; setDisplayBackgroundPicker(!displayBackgroundPicker); }}>
                                                      <div style={{ width: '100%', height: '100%', background: `${background}` }} />
                                                  </div>
                                                  {displayBackgroundPicker ? <div style={{ position: 'absolute', zIndex: '2' }}>
                                                      <div
                                                          style={{
                                                              position: 'fixed',
                                                              top: '0px',
                                                              right: '0px',
                                                              bottom: '0px',
                                                              left: '0px'
                                                          }}
                                                          onClick={() => { setDisplayBackgroundPicker(false); }} />
                                                      <SketchPicker color={background} onChange={(color) => { setBackground(color.hex); }} />
                                                  </div> : null}
                                              </div>
                                              {/* Foreground */}
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.FOREGROUND" /></label>
                                                  <br />
                                                  <div className="form-control"
                                                      style={{
                                                          padding: '5px',
                                                          background: '#fff',
                                                          borderRadius: '2px',
                                                          display: 'inline-block',
                                                          cursor: 'pointer',
                                                      }}
                                                      onClick={() => { if (readOnly) return; setDisplayForegroundPicker(!displayForegroundPicker); }}>
                                                      <div style={{ width: '100%', height: '100%', background: `${foreground}` }} />
                                                  </div>
                                                  {displayForegroundPicker ? <div style={{ position: 'absolute', zIndex: '2' }}>
                                                      <div
                                                          style={{
                                                              position: 'fixed',
                                                              top: '0px',
                                                              right: '0px',
                                                              bottom: '0px',
                                                              left: '0px'
                                                          }}
                                                          onClick={() => { setDisplayForegroundPicker(false); }} />
                                                      <SketchPicker color={foreground} onChange={(color) => { setForeground(color.hex); }} />
                                                  </div> : null}
                                              </div>

                                          </div>

                                          <div className="form-group row">
                                              <div className="col-lg-4">
                                                  <label><FormattedMessage id="AUTH.INPUT.FONT_SIZE" /></label>
                                                  <Field
                                                      disabled={readOnly}
                                                      name="fontSize"
                                                      type="number"
                                                      component={Input}
                                                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.FONT_SIZE' })}
                                                      label=""
                                                      customFeedbackLabel=" "
                                                      onKeyPress={onlyDigits}
                                                  />
                                              </div>


                                          </div>

                                      </>
                                  )}
                              </Form>
                              )}
                        
                        </Modal.Body>
                      
                        
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
            <> </>

            {!actionsLoading && (
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediSkupineIzdelkov]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
                          
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
