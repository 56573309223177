import * as requestFromServer from "./itemsCrud";
import { itemsSlice, callTypes } from "./itemsSlice";

const {actions} = itemsSlice;

export const fetchItems = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findItems(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.itemsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find items";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchItem = id => dispatch => {
    if (!id) {
        dispatch(actions.setPricelists({ pricelists: [] }));
        dispatch(actions.setPricelistsRemove({ pricelistsRemove: [] }));
        dispatch(actions.setJigsaws({ jigsaws: [] }));
        dispatch(actions.setJigsawsRemove({ jigsawsRemove: [] }));
        return dispatch(actions.itemFetched({ itemForEdit: undefined }));
      }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getItemById(id)
      .then(response => {
          response.data.model.maxDiscount = response.data.model.maxDiscount == null ? "" : response.data.model.maxDiscount;
          response.data.model.grossWeightPerUnit = response.data.model.grossWeightPerUnit == null ? "" : response.data.model.grossWeightPerUnit;
        const item = response.data.model;
          dispatch(actions.itemFetched({ itemForEdit: item }));
          var pricelists = [];
          item.pricelist.forEach(el => {
              var pricelist = { ...el };
              pricelist.effectiveDateFrom = new Date(el.effectiveDateFrom);
              pricelists.push(pricelist);

          });
          dispatch(actions.setPricelists({ pricelists: pricelists }));
          dispatch(actions.setPricelistsRemove({ pricelistsRemove: [] }));
          dispatch(actions.setJigsaws({ jigsaws: [...item.jigsaws1] }));
          dispatch(actions.setJigsawsRemove({ jigsawsRemove: [] }));
    })
    .catch(error => {
      error.clientMessage = "Can't find item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteItem = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItem(id)
    .then(response => {
      dispatch(actions.itemDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createItem = itemForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createItem(itemForCreation)
      .then(response => {
        const { model } = response.data;
        dispatch(actions.itemCreated({ model }));
    })
    .catch(error => {
      error.clientMessage = "Can't create item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateItem = item => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateItem(item)
    .then(() => {
      dispatch(actions.itemUpdated({ item }));
    })
    .catch(error => {
      error.clientMessage = "Can't update item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteItems = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItems(ids)
    .then(() => {
      dispatch(actions.itemsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete items";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setCurrentVatRateId = id => dispatch => {
    if (!id) {
        dispatch(actions.setCurrentVatRateId({ setCurrentVatRateId: undefined }));
    }
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setCurrentVatRateId({ currentVatRateId: id }));
};

export const setPricelists = pricelists => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setPricelists({ pricelists: pricelists }));
};

export const setPricelistsRemove = pricelists => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setPricelistsRemove({ pricelistsRemove: pricelists }));
};

export const setPricelistForEdit = pricelist => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setPricelistForEdit({ pricelistForEdit: pricelist }));
};

export const setJigsaws = jigsaws => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setJigsaws({ jigsaws: jigsaws }));
};

export const setJigsawsRemove = jigsaws => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setJigsawsRemove({ jigsawsRemove: jigsaws }));
};

export const setJigsawForEdit = jigsaw => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.setJigsawForEdit({ jigsawForEdit: jigsaw }));
};