// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/codeLists/codeListsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CodeListsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCodeListsUIContext } from "../CodeListsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function CodeListsTable() {
    const intl = useIntl();
  // Users UI Context
    const codeListsUIContext = useCodeListsUIContext();
    const codeListsUIProps = useMemo(() => {
    return {
        ids: codeListsUIContext.ids,
        setIds: codeListsUIContext.setIds,
        queryParams: codeListsUIContext.queryParams,
        setQueryParams: codeListsUIContext.setQueryParams,
        openEditCodeListDialog: codeListsUIContext.openEditCodeListDialog
    };
    }, [codeListsUIContext]);

  // Getting curret state of codeLists list from store (Redux)
    const { currentState } = useSelector(
      (state) => ({
          currentState: state.codeLists
      }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // CodeLists Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    codeListsUIProps.setIds([]);
    // server call by queryParams
      dispatch(actions.fetchCodeLists(codeListsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeListsUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "code",
      text: intl.formatMessage({ id: "CODE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      },
      {
          dataField: "name",
          text: intl.formatMessage({ id: "AUTH.INPUT.TITLE" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "active",
          text: intl.formatMessage({ id: "ECOMMERCE.COMMON.ACTIVE" }),
          formatter: columnFormatters.ActiveColumnFormatter,
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
    {
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
        formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
          openEditCodeListDialog: codeListsUIProps.openEditCodeListDialog
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: codeListsUIProps.queryParams.pageSize,
      page: codeListsUIProps.queryParams.pageNumber,
      paginationTotalRenderer: customTotal,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  codeListsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
