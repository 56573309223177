import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual, isFunction } from "lodash";
import moment from 'moment'
import { useCookies } from "react-cookie";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import PdfViewer from "../../../../components/PdfViewer"

export function ReportPricelistPage({ history }) {
    const intl = useIntl();

  return (
      <>
          <Card>
              <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.REPORT_PRICELIST" })} >
                  <CardHeaderToolbar>
                  </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                  <PdfViewer
                      url={'../../../API/OfficeReport/ReportPricelist' }
                  />

              </CardBody>
          </Card>
          </>
  );
}
