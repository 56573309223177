import * as requestFromServer from "./yourCompanyCrud";
import { yourCompanySlice, callTypes } from "./yourCompanySlice";

const { actions } = yourCompanySlice;

export const fetchOrganization = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getOrganization()
        .then(response => {
            const organization = response.data.model;
            dispatch(actions.organizationFetched({ organizationForEdit: organization }));
        })
        .catch(error => {
            error.clientMessage = "Can't find organization";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const updateOrganization = organization => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateOrganization(organization)
        .then(() => {
            dispatch(actions.organizationUpdated({ organization }));
        })
        .catch(error => {
            error.clientMessage = "Can't update organization";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchFiscalDigitalCertificate = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getFiscalDigitalCertificate()
        .then(response => {
            const fiscalDigitalCertificate = response.data.model.name == null ? null : response.data.model;
            dispatch(actions.fiscalDigitalCertificateFetched({ fiscalDigitalCertificateForEdit: fiscalDigitalCertificate }));
        })
        .catch(error => {
            error.clientMessage = "Can't find fiscal digital Certificate";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const updateFiscalDigitalCertificate = fiscalDigitalCertificate => dispatch => {
    dispatch(actions.fiscalDigitalCertificateFetched({ fiscalDigitalCertificateForEdit: fiscalDigitalCertificate }));
};
