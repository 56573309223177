import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/items/itemsActions";
import { ItemEditDialogHeader } from "./ItemEditDialogHeader";
import { ItemEditForm } from "./ItemEditForm";
import { useItemsUIContext } from "../ItemsUIContext";

export function ItemEditDialog({ id, show, onHide }) {
    // Items UI Context
    const itemsUIContext = useItemsUIContext();
  const itemsUIProps = useMemo(() => {
    return {
        initItem: itemsUIContext.initItem,
    };
  }, [itemsUIContext]);

  // Items Redux state
  const dispatch = useDispatch();
    const { actionsLoading, itemForEdit, pricelists, pricelistsRemove,
        jigsaws, jigsawsRemove } = useSelector(
    (state) => ({
      actionsLoading: state.items.actionsLoading,
        itemForEdit: state.items.itemForEdit,
        pricelists: state.items.pricelists,
        pricelistsRemove: state.items.pricelistsRemove,
        jigsaws: state.items.jigsaws,
        jigsawsRemove: state.items.jigsawsRemove
    }),
    shallowEqual
  );

    useEffect(() => {
      dispatch(actions.fetchItem(id));
  }, [id, dispatch]);

// server request for saving item
    const saveItem = (item) => {

    item.pricelist = [...pricelists];
    item.pricelistRemove = [...pricelistsRemove];
    item.jigsaws1 = [...jigsaws];
    item.jigsawsRemove = [...jigsawsRemove];

    if (!id) {
        // server request for creating item
        dispatch(actions.createItem(item)).then(() => { dispatch(actions.fetchItem(null)); onHide(); });
    } else {
        // server request for updating item
        dispatch(actions.updateItem(item)).then(() => { dispatch(actions.fetchItem(null)); onHide(); });
    }
};

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="mEditItem"
    >
        <ItemEditDialogHeader id={id} />

        
          
              <ItemEditForm
                  saveItem={saveItem}
                  actionsLoading={actionsLoading}
                  item={itemForEdit || itemsUIProps.initItem}
                  onHide={onHide}
              />
          


    </Modal>
  );
}
