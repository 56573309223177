/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"
import Switch from '@material-ui/core/Switch';
import InputCount from '../../../../components/InputCount'

export default function CompanyPackage(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { organization, error } = useSelector(
        (state) => ({
            organization: state.organization.organizationForEdit,
            error: state.organization.error
        }),
        shallowEqual
    );
    const [cashierUserCount, setCashierUserCount] = useState(organization != undefined && organization != null ? organization.cashierUserCount : 1);
    const [officeUserCount, setOfficeUserCount] = useState(organization != undefined && organization != null ? organization.officeUserCount : 1);
    const [cashierCount, setCashierCount] = useState(organization != undefined && organization != null ? organization.cashierCount : 1);
    const [appModulePurchase, setAppModulePurchase] = useState(organization && organization != null != undefined ? organization.appModulePurchase : false);
    const [cubic, setCubic] = useState(organization && organization != null != undefined ? organization.cubic : false);
    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediVasePodjetje]);

    useEffect(() => {
        setCashierUserCount(organization != undefined && organization != null ? organization.cashierUserCount : 1);
        setOfficeUserCount(organization != undefined && organization != null ? organization.officeUserCount : 1);
        setCashierCount(organization != undefined && organization != null ? organization.cashierCount : 1);
        setAppModulePurchase(organization && organization != null != undefined ? organization.appModulePurchase : false);
        setCubic(organization && organization != null != undefined ? organization.cubic : false);
    }, [user, organization]);
    // Methods
    const saveOrganization = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);

        var obj = {
            address: values.address,
            appModulePurchase: appModulePurchase,
            appModuleSales: values.appModuleSales,
            description: values.description,
            id: values.id,
            name: values.name,
            postCode: values.postCode,
            postTitle: values.postTitle,
            registrationNumber: values.registrationNumber,
            taxDuty: values.taxDuty,
            taxNumber: values.taxNumber,
            town: values.town,
            bankName: values.bankName,
            bankIban: values.bankIban,
            bankBic: values.bankBic,
            cubic: cubic,
            invoiceSendToMail: values.invoiceSendToMail,
            invoiceEmail: values.invoiceEmail,
            invoiceAddress: values.invoiceAddress,
            invoicePostCode: values.invoicePostCode,
            invoicePostTitle: values.invoicePostTitle,
            logoUniqueId: values.logoUniqueId,
            officeUserCount: officeUserCount,
            cashierUserCount: cashierUserCount,
            cashierCount: cashierCount,
            minOfficeUserCount: values.minOfficeUserCount,
            minCashierUserCount: values.minCashierUserCount,
            minCashierCount: values.minCashierCount,
            deactiveReason: values.deactiveReason,
            deactiveDate: values.deactiveDate,
            confirmed: values.confirmed,
            contractExpirationDate: values.contractExpirationDate
        };
        
        dispatch(actions.updateOrganization(obj)).then(() => {
            setIsError(error != null);
            setIsSuccess(error == null);
            setLoading(false);
            setSubmitting(false);
        });
    };
    // UI Helpers
    const initialValues = {
        address: organization != undefined && organization != null ? organization.address : "",
        appModulePurchase: organization && organization != null != undefined ? organization.appModulePurchase : false,
        appModuleSales: organization && organization != null != undefined ? organization.appModuleSales : false,
        cubic: organization && organization != null != undefined ? organization.cubic : false,
        description: organization && organization != null != undefined ? organization.description : "",
        id: organization && organization != null != undefined ? organization.id : -1,
        name: organization && organization != null != undefined ? organization.name : "",
        postCode: organization && organization != null != undefined ? organization.postCode : 0,
        postTitle: organization && organization != null != undefined ? organization.postTitle : "",
        registrationNumber: organization && organization != null != undefined ? organization.registrationNumber : "",
        taxDuty: organization != undefined && organization != null ? organization.taxDuty : false,
        taxNumber: organization != undefined && organization != null ? organization.taxNumber : "",
        town: organization != undefined && organization != null ? organization.town : "",
        bankName: organization != undefined && organization != null && organization.bankName != null ? organization.bankName : "",
        bankIban: organization != undefined && organization != null && organization.bankIban != null ? organization.bankIban : "",
        bankBic: organization != undefined && organization != null && organization.bankBic != null ? organization.bankBic : "",
        invoiceSendToMail: organization && organization != null != undefined ? organization.invoiceSendToMail : false,
        invoiceEmail: organization && organization != null != undefined ? organization.invoiceEmail : "",
        invoiceAddress: organization && organization != null != undefined ? organization.invoiceAddress : "",
        invoicePostCode: organization && organization != null != undefined ? organization.invoicePostCode : 0,
        invoicePostTitle: organization && organization != null != undefined ? organization.invoicePostTitle : "",
        logoUniqueId: organization && organization != null != undefined ? organization.logoUniqueId : "",
        officeUserCount: organization != undefined && organization != null ? organization.officeUserCount : 1,
        cashierUserCount: organization != undefined && organization != null ? organization.cashierUserCount : 1,
        cashierCount: organization != undefined && organization != null ? organization.cashierCount : 1,
        minOfficeUserCount: organization != undefined && organization != null ? organization.minOfficeUserCount : 1,
        minCashierUserCount: organization != undefined && organization != null ? organization.minCashierUserCount : 1,
        minCashierCount: organization != undefined && organization != null ? organization.minCashierCount : 1,
        deactiveReason: organization != undefined && organization != null && organization.deactiveReason != null ? organization.deactiveReason : "",
        deactiveDate: organization != undefined && organization != null && organization.deactiveDate != null ? organization.deactiveDate : "",
        contractExpirationDate: organization != undefined && organization != null && organization.contractExpirationDate != null ? organization.contractExpirationDate : null,
        confirmed: organization != undefined && organization != null ? organization.confirmed : false
    };

    const Schema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        town: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveOrganization(values, setStatus, setSubmitting);
        },
        onReset: (values) => {
            setIsError(false);
            setIsSuccess(false);
            setCashierUserCount(values.cashierUserCount);
            setOfficeUserCount(values.officeUserCount);
            setAppModulePurchase(values.appModulePurchase);
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="COMPANY.NAVIGATION.PACKAGE" />
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="COMPANY.NAVIGATION.PACKAGE_2" />
          </span>
                </div>

                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
                {formik.isSubmitting}
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="reset"
                        >
                            <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                        </button>
                    </div>
                </CheckPermissions>

            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_PACKAGE_DATA" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_PACKAGE_DATA" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="PACKAGE.PURCHASE" />
                        </label>
                        
                        <div className="col-lg-9 col-xl-6">

                            <Switch
                                checked={appModulePurchase}
                                onChange={() => { setAppModulePurchase(!appModulePurchase); }}
                                value={true}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                disabled={readOnly}
                            />


                        </div>
                    </div>

                    <div className="form-group row"
                        style={{ display: "none" }}
                    >
                        <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="ADMIN.APP_MODULE_CUBIC" />
                        </label>

                        <div className="col-lg-9 col-xl-6">

                            <Switch
                                checked={cubic}
                                onChange={() => { setCubic(!cubic); }}
                                value={true}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                disabled={readOnly}
                            />


                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="PACKAGE.NUMBER_OF_CASHIERS" />
                        </label>

                        <div className="col-lg-6 col-xl-6">

                            <InputCount
                                value={cashierCount}
                                minValue={formik.values.minCashierCount}
                                maxValue={100}
                                disabled={readOnly}
                                onChange={(event, val) => {
                                    setCashierCount(val);
                                }}
                            />

                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="PACKAGE.NUMBER_OF_OFFICE_USERS" />
                        </label>

                        <div className="col-lg-6 col-xl-6">
                           
                            <InputCount
                                value={officeUserCount}
                                minValue={formik.values.minOfficeUserCount}
                                maxValue={200}
                                disabled={readOnly}
                                onChange={(event, val) => {
                                    setOfficeUserCount(val);
                                }}
                            />
                 
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-4 col-lg-4 col-form-label text-alert">
                            <FormattedMessage id="PACKAGE.NUMBER_OF_CASHIER_USERS" />
                        </label>

                        <div className="col-lg-6 col-xl-6">

                            <InputCount
                                value={cashierUserCount}
                                minValue={formik.values.minCashierUserCount}
                                maxValue={100}
                                disabled={readOnly}
                                onChange={(event, val) => {
                                    setCashierUserCount(val);
                                }}
                            />

                        </div>
                    </div>
                    


                </div>

            </div>
            {/* end::Form */}
        </form>
    );
}

