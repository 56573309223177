import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function UserRoleEditDialogHeader({ id }) {
  const intl = useIntl();
  // UserRoles Redux state
    const { userRoleForEdit, actionsLoading } = useSelector(
    (state) => ({
            userRoleForEdit: state.userRoles.userRoleForEdit,
            actionsLoading: state.userRoles.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
      let _title = id ? "" : intl.formatMessage({ id: "SETTING.USER_ROLE.NEW" }) ;
      if (userRoleForEdit && id) {
          _title = intl.formatMessage({ id: "SETTING.USER_ROLE.EDIT" }) + ` '${userRoleForEdit.title}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [userRoleForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
