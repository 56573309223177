// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, InputGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPopup from "../../../../Helpers/ErrorPopup"
import InfoPopup from "../../../../Helpers/InfoPopup"
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AutocompletePost from '../../../../../components/AutocompletePost'
import AutocompleteCountry from '../../../../../components/AutocompleteCountry'
import { findByTaxCode, checkIfCustomerYetExist } from "../../../_redux/customers/customersCrud";
import { onlyDigits, decimal } from "../../../../../util/Util";

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
    const { user } = useSelector(state => state.auth);
    const intl = useIntl();
    const [isForeigner, setIsForeigner] = useState([customer != undefined ? customer.isForeigner : false]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoadingAjpes, setIsLoadingAjpes] = useState(false);
    const ref = useRef(null);

    const [post, setPost] = useState(customer != undefined && customer.postCode != null ?
        { code: customer.postCode, title: customer.postTitle } : null);
    const [country, setCountry] = useState(customer != undefined && customer.countryId != null ?
        { id: customer.countryId, title: customer.countryTitle, isoCode: customer.countryIsoCode } : null);

    useEffect(() => {
        setPost(customer != undefined && customer.postCode != null ?
            { code: customer.postCode, title: customer.postTitle } : null);
        setCountry(customer != undefined && customer.countryId != null ?
            { id: customer.countryId, title: customer.countryTitle, isoCode: customer.countryIsoCode } : null);
        setIsForeigner([customer != undefined ? customer.isForeigner : false]);
    }, [user, customer]);

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediStranke]);

    // Validation schema
    const CustomerEditSchema = Yup.object().shape({
        name: Yup.string()
            .max(250, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_250_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        address: Yup.string()
            .max(250, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_250_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        registrationNumber: Yup.string()
            .max(10, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_10_SYMBOLS' })).nullable(),
        taxNumber: Yup.string()
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'AJPES.GENERAL.COMPANY_EXISTS_YET' }), value => {
                return new Promise((resolve, reject) => {
                    if (!isForeigner[0] && value != undefined && value != "" && value != null) {
                        checkIfCustomerYetExist(customer.id, value)
                            .then(({ data }) => {
                                if (!data.didError) {
                                    resolve(!data.model);
                                } else {
                                    resolve(false);
                                }
                            })
                            .catch((e) => {
                                resolve(false);
                            });
                    } else {
                        resolve(true);
                    }
                })
            }),
        discount: Yup.number()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'DISCOUNT.BETWEEM_0_AND_100' }), value => {
                return new Promise((resolve, reject) => {
                    var num = parseFloat(value);
                    resolve(num >= 0 && num <= 100);
                })
            })
        ,
        paymentDeadline: Yup.number()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({ id: 'PAYMENT_DEADLINE.MORE_THEN_0' }), value => {
                return new Promise((resolve, reject) => {
                    var num = parseFloat(value);
                    resolve(num >= 0);
                })
            })
    });

    function handleChangeIsForeigner(event, value) {
        var res = event.currentTarget.value == "true";
        setIsForeigner([res]);
    }

    function validateForm(values) {
        if (isForeigner[0]) {
            if (country != null) {
                return true;
            } else {
                setMessage(intl.formatMessage({ id: 'AUTH.INFO_SELECT_COUNTRY' }));
                setOpen(true);
                return false;
            }
        } else {
            
            if (post != null) {
                return true;
            } else {
                setMessage(intl.formatMessage({ id: 'AUTH.INFO_SELECT_POST' }));
                setOpen(true);
                return false;
            }
        }
    }

    
    function handlerAjpesClick(e) {
        var taxNumber = ref.current.values.taxNumber;
        if (taxNumber == "") {
            return;
        }
        setIsLoadingAjpes(true);
        findByTaxCode(taxNumber)
            .then(({ data }) => {
                if (data.model.id > -1) {
                    ref.current.setFieldValue("name", data.model.name);
                    ref.current.setFieldValue("taxDuty", data.model.taxDuty);
                    ref.current.setFieldValue("taxNumber", data.model.taxNumber);
                    ref.current.setFieldValue("address", data.model.address);
                    ref.current.setFieldValue("active", true);
                    ref.current.setFieldValue("isSupplier", true);
                    setPost({ code: data.model.postCode, title: data.model.postTitle });
                } else {
                    setMessage(intl.formatMessage({ id: 'AUTH.AJPES_NOT_EXISTS_COMPANY' }));
                    setOpen(true);
                }
                setIsLoadingAjpes(false);
            })
            .catch((e) => {
                setErrorMessage(intl.formatMessage({ id: 'AUTH.OPS_CONNECTION_PROBLEM' }));
                setErrorOpen(true);
                setIsLoadingAjpes(false);
            });

    }

  return (
    <>
          <Formik
              innerRef={ref}
        enableReinitialize={true}
        initialValues={customer}
        validateOnChange={false}
              validationSchema={CustomerEditSchema}
              onSubmit={(values) => {
                  if (validateForm(values)) {
                      var obj = {
                          taxDuty: values.taxDuty,
                          taxNumber: values.taxNumber,
                          id: values.id,
                          name: values.name,
                          address: values.address,
                          postCode: post != null ? post.code : null,
                          postTitle: post != null ? post.title : null,
                          isForeigner: isForeigner[0],
                          useWholesalePricelist: values.useWholesalePricelist,
                          withoutVatExport: values.withoutVatExport,
                          discount: values.discount,
                          paymentDeadline: values.paymentDeadline,
                          isSupplier: values.isSupplier,
                          active: values.active,
                          bankBicCode: values.bankBicCode,
                          bankIbanCode: values.bankIbanCode,
                          countryId: country != null ? country.id : null,
                          registrationNumber: values.registrationNumber
                      };
                      saveCustomer(obj);
                  }
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
                )}
            {!actionsLoading && (
                <Form className="form form-label-right">

                    <div className="form-group row">
                        <div className="col-lg-3 col-xl-3">
                        </div>
                        <div className="col-lg-6 col-xl-6">

                            <ToggleButtonGroup
                                type="checkbox"
                                value={isForeigner}
                                onChange={handleChangeIsForeigner}
                            >
                                <ToggleButton value={false}><FormattedMessage id="AUTH.GENERAL.HOST" /></ToggleButton>
                                <ToggleButton value={true}><FormattedMessage id="AUTH.GENERAL.FOREIGNER" /></ToggleButton>
                            </ToggleButtonGroup>

                        </div>
                        <div className="col-lg-3 col-xl-3">
                        </div>
                    </div>
                    <hr />

                    <div className="form-group row">
                        {/* Login */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.TITLE" /></label>
                            <Field
                                disabled={readOnly}
                                name="name"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.TITLE' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {/* First Name */}
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.INPUT.ADDRESS" /></label>
                            <Field
                                disabled={readOnly}
                                name="address"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.INPUT.ADDRESS' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        {!isForeigner[0] && (
                            <div className="col-lg-4">
                                <label>
                                    <FormattedMessage id="AUTH.GENERAL.POST" />
                                </label>

                                <AutocompletePost
                                    name="postCode"
                                    value={post}
                                    onChange={(val) => { setPost(val); }}
                                />
                            </div>
                        )}
                        {isForeigner[0] && (
                            <div className="col-lg-4">
                                <label>
                                    <FormattedMessage id="AUTH.GENERAL.COUNTRY" />
                                </label>

                                <AutocompleteCountry
                                    name="countryId"
                                    value={country}
                                    onChange={(val) => { setCountry(val); }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="form-group row">
                        {!isForeigner[0] && (
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.ID_FOR_TAX" /></label>
                                {values.id == undefined && (
                                    <InputGroup>
                                        <Field
                                            disabled={readOnly}
                                            name="taxNumber"
                                            component={Input}
                                            placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.ID_FOR_TAX' })}
                                            label=""
                                            customFeedbackLabel=" "
                                        />
                                        <InputGroup.Append>
                                            <Button
                                                disabled={isLoadingAjpes}
                                                variant="outline-secondary"
                                                onClick={handlerAjpesClick} >
                                                {isLoadingAjpes && (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                <FormattedMessage id="AUTH.GENERAL.AJPES" />

                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                )}
                                {values.id != undefined && (
                                    <Field
                                        disabled={readOnly}
                                        name="taxNumber"
                                        disabled={true}
                                        component={Input}
                                        placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.ID_FOR_TAX' })}
                                        label=""
                                        customFeedbackLabel=" "
                                    />
                                )}
                            </div>
                        )}

                        {isForeigner[0] && (
                            <div className="col-lg-4">
                                <label><FormattedMessage id="AUTH.GENERAL.LABEL" /></label>
                                <Field
                                    disabled={readOnly}
                                    name="taxNumber"
                                    component={Input}
                                    placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.LABEL' })}
                                    label=""
                                    customFeedbackLabel=" "
                                />

                            </div>
                        )}

                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.PAYMENT_DEADLINE_DAYS" /></label>
                            <Field
                                disabled={readOnly}
                                type="number"
                                name="paymentDeadline"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PAYMENT_DEADLINE_DAYS' })}
                                label=""
                                onKeyPress={onlyDigits}
                            />
                        </div>
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.DISCOUNT" /></label>
                            <Field
                                disabled={readOnly}
                                type="number"
                                name="discount"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.DISCOUNT' })}
                                label=""
                                customFeedbackLabel=" "
                                onKeyPress={decimal}
                            />
                        </div>

                    </div>

                    <hr />
                    <div className="form-group row">
                        <div className="col-lg-4" >
                            <label><FormattedMessage id="ECOMMERCE.COMMON.TAX_DUTY" />
                                <br />
                                <Field
                                    disabled={readOnly}
                                    className="PrivateSwitchBase-input-93"
                                    type="checkbox"
                                    name="taxDuty"
                                />
                            </label>
                        </div>
                        <div className="col-lg-4" >
                            <label><FormattedMessage id="ECOMMERCE.COMMON.SUPPLIER" />
                                <br />
                                <Field
                                    disabled={readOnly}
                                    className="PrivateSwitchBase-input-93"
                                    type="checkbox"
                                    name="isSupplier"
                                />
                            </label>
                        </div>
                        <div className="col-lg-4" >
                            <label><FormattedMessage id="ECOMMERCE.COMMON.ACTIVE" />
                                <br />
                                <Field
                                    disabled={readOnly}
                                    className="PrivateSwitchBase-input-93"
                                    type="checkbox"
                                    name="active"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4" >
                            <label><FormattedMessage id="ECOMMERCE.COMMON.WITHOUT_VAT" />
                                <br />
                                <Field
                                    disabled={readOnly}
                                    className="PrivateSwitchBase-input-93"
                                    type="checkbox"
                                    name="withoutVatExport"
                                />
                            </label>
                        </div>
                        <div className="col-lg-4" >
                            <label><FormattedMessage id="ECOMMERCE.COMMON.USE_WHOLESALE_PRICELIST" />
                                <br />
                                <Field
                                    disabled={readOnly}
                                    className="PrivateSwitchBase-input-93"
                                    type="checkbox"
                                    name="useWholesalePricelist"
                                />
                            </label>
                        </div>
                    </div>
                    <hr />
                    <h3><FormattedMessage id="ECOMMERCE.COMMON.DATA_E_INVOICE" /></h3>
                    <hr />
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.BIC" /></label>
                            <Field
                                disabled={readOnly}
                                name="bankBicCode"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.BIC' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                        
                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.BANK_IBAN" /></label>
                            <Field
                                disabled={readOnly}
                                name="bankIbanCode"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.BANK_IBAN' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        <div className="col-lg-4">
                            <label><FormattedMessage id="AUTH.GENERAL.REGISTRATION_NUMBER1" /></label>
                            <Field
                                disabled={readOnly}
                                name="registrationNumber"
                                component={Input}
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.REGISTRATION_NUMBER1' })}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>
                    </div>
                </Form>
            )}
            
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
            <> </>
            {!actionsLoading && (
                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediStranke]}>
                    <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE" />
                    </button>
                </CheckPermissions>
            )}
                          
            </Modal.Footer>
          </>
        )}
          </Formik>
          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
