import {createSlice} from "@reduxjs/toolkit";

const initialCacheState = {
    listLoading: false,
    actionsLoading: false,
    vatRates: undefined,
    itemTypes: undefined,
    units: undefined,
    officeReports: undefined,
    initStepTutorial: undefined,
    documentTypes: undefined,
    lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const cacheSlice = createSlice({
    name: "cache",
    initialState: initialCacheState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
        state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
      allVatRatesFetched: (state, action) => {
        state.actionsLoading = false;
        state.vatRates = action.payload;
        state.error = null;
      },
      allItemTypesFetched: (state, action) => {
          state.actionsLoading = false;
          state.itemTypes = action.payload;
          state.error = null;
      },
      allUnitsFetched: (state, action) => {
          state.actionsLoading = false;
          state.units = action.payload;
          state.error = null;
      },
      allCashiersFetched: (state, action) => {
          state.actionsLoading = false;
          state.cashiers = action.payload;
          state.error = null;
      },
      allOfficeReportsFetched: (state, action) => {
          state.actionsLoading = false;
          state.officeReports = action.payload;
          state.error = null;
      },
      initStepTutorialsFetched: (state, action) => {
          state.actionsLoading = false;
          state.initStepTutorial = action.payload;
          state.error = null;
      },
      allDocumentTypesFetched: (state, action) => {
          state.actionsLoading = false;
          state.documentTypes = action.payload;
          state.error = null;
      }
  }
});
