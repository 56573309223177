import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ItemsFilter } from "./items-filter/ItemsFilter";
import { ItemsTable } from "./items-table/ItemsTable";
import { ItemsGrouping } from "./items-grouping/ItemsGrouping";
import { useItemsUIContext } from "./ItemsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function ItemsCard() {
    const intl = useIntl();
    const itemsUIContext = useItemsUIContext();
  const itemsUIProps = useMemo(() => {
    return {
        ids: itemsUIContext.ids,
        newItemButtonClick: itemsUIContext.newItemButtonClick,
    };
  }, [itemsUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.ITEMS" })} >
              <CardHeaderToolbar className="chtItems">
                  <CheckPermissions
                      userFunctionalities={[UserFunctionalityE.UrediIzdelke]}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={itemsUIProps.newItemButtonClick}
                    >
                          <FormattedMessage id="AUTH.ITEM.NEW" />
                    </button>
                </CheckPermissions>
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
              <ItemsFilter />
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledGibanjeZaloge]}>
                  {itemsUIProps.ids.length > 0 && <ItemsGrouping />}
              </CheckPermissions>
        <ItemsTable />
      </CardBody>
    </Card>
  );
}
