import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, findByTaxCode, checkIfUserNameIsFree, createAccountExistTaxNumber } from "../_redux/authCrud";
import ErrorPopup from "../../Helpers/ErrorPopup"
import { BrowserView, MobileView } from 'react-device-detect';

const initialValues = {
  firstname: "",
  lastname: "",
  taxcode: "",
  phone: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [taxDuty, setTaxDuty] = useState("");
    const [taxNumber, setTaxNumber] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [postCode, setPostCode] = useState("");
    const [postTitle, setPostTitle] = useState("");

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
      .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      lastname: Yup.string()
          .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
          .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
          .required(
              intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
              })
      ),
      taxcode: Yup.string()
          .required()
          .matches(/^[0-9]+$/, intl.formatMessage({ id: 'AUTH.GENERAL.ONLY_DIGIT' }))
          .min(8, intl.formatMessage({ id: 'AUTH.GENERAL.ONLY_8_NUMBER' }))
          .max(8, intl.formatMessage({ id: 'AUTH.GENERAL.ONLY_8_NUMBER' }))
          .required(
              intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
              })
        )
          .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.NOT_FOUND_COMPANY' }), value => {
            return new Promise((resolve, reject) => {
                var taxcode = value;
                if (taxNumber != taxcode) {
                    setTimeout(function () {
                        setTaxDuty("");
                        setTaxNumber("");
                        setName("");
                        setAddress("");
                        setPostCode("");
                        setPostTitle("");
                    }, 0);
                }
                if (taxcode.length >= 8) {
                    findByTaxCode(taxcode)
                        .then(({ data }) => {
                            if (data.model.id > -1) {
                                if (taxDuty != data.model.taxDuty) {
                                    setTaxDuty(data.model.taxDuty);
                                }
                                if (taxNumber != data.model.taxNumber) {
                                    setTaxNumber(data.model.taxNumber);
                                }
                                if (name != data.model.name) {
                                    setName(data.model.name);
                                }
                                if(address != data.model.address) {
                                    setAddress(data.model.address);
                                }
                                if (postCode != data.model.postCode) {
                                    setPostCode(data.model.postCode);
                                }
                                if (postTitle != data.model.postTitle) {
                                    setPostTitle(data.model.postTitle);
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                                setErrorMessage(data.message);
                                setErrorOpen(true);
                            }
                        })
                        .catch((e) => {
                            resolve(false);
                        });
                } else {
                    resolve(false);
                }
            })
          })
          .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.COMPANY_EXISTS_YET' }), value => {
              return new Promise((resolve, reject) => {
                  createAccountExistTaxNumber(formik.values.taxcode)
                      .then(({ data }) => {
                          if (!data.didError) {
                              resolve(!data.model);
                          } else {
                              resolve(false);
                              setErrorMessage(data.message);
                              setErrorOpen(true);
                          }
                      })
                      .catch((e) => {
                          resolve(false);
                      });
              })
          })
      ,
      phone: Yup.string()
          .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
          .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
          .required(
              intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
              })
          ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' }))
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
      .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    username: Yup.string()
        .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
        .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )
        .test('validator', intl.formatMessage({ id: 'AUTH.GENERAL.USERNAME_EXISTS_YET' }), value => {
            return new Promise((resolve, reject) => {
                checkIfUserNameIsFree(formik.values.username)
                .then(({ data }) => {
                    if (!data.didError) {
                        resolve(data.model);
                    } else {
                        resolve(false);
                        setErrorMessage(data.message);
                        setErrorOpen(true);
                    }
                })
                .catch((e) => {
                    resolve(false);
                });
            })
        })
      ,
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
      .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH' })
        ),
      }),
      acceptTerms: Yup.bool().required(
          intl.formatMessage({ id: 'AUTH.GENERAL.YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS' })
    )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
    };

  const formik = useFormik({
    initialValues,
      validationSchema: RegistrationSchema,
      validateOnChange: false,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
        register(values.firstname, values.lastname, values.taxcode, values.phone, values.email, values.username, values.password)
            .then(({ data }) => {
            if (data.didError) {
                setErrorMessage(intl.formatMessage({
                    id: data.errorMessage,
                }));
                setErrorOpen(true);
            } else {
                props.register(data.token);
            }
          disableLoading();
          setSubmitting(false);
        })
            .catch((e) => {
                setSubmitting(false);
                disableLoading();
                if (e != null && e.response != null && e.response.data != null) {
                    setErrorMessage(intl.formatMessage({
                        id: e.response.data.message,
                    }));
                    setErrorOpen(true);
                } else {
                    setStatus(
                        intl.formatMessage({
                            id: "AUTH.VALIDATION.INVALID_LOGIN",
                        })
                    );
                }
        });
    },
  });

    function onTermsAndConditionsClick() {
        window.open('/api/account/TermsAndConditions', '_blank');
    }

    return (
        <div>
            <BrowserView>
                <br />
                <br />
                <br />
                <br />
            </BrowserView>
            {/*end::Content header*/}
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
            <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.GENERAL.ENTER_YOUR_DETAILS_TO_CREATE_YOUR_ACCOUNT" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: TaxCode */}
        <div className="form-group fv-plugins-icon-container">
            <input
                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TAXCODE' })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "taxcode"
                )}`}
                name="taxcode"
                {...formik.getFieldProps("taxcode")}
            />
            {formik.touched.taxcode && formik.errors.taxcode ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.taxcode}</div>
                </div>
            ) : null}
            <div>
                {name}
                <br />
                {address} {postCode} {postTitle}
            </div>
        </div>

        {/* end: TaxCode */}

        {/* begin: Firstname and Lastname */}
        <div className="form-group fv-plugins-icon-container">

            <div className="row">
                <div className="col-sm">
                    <input
                        placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.FIRSTNAME' })}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "firstname"
                        )}`}
                              name="firstname"
                              {...formik.getFieldProps("firstname")}
                    />
                          {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.firstname}</div>
                        </div>
                    ) : null}
                </div>  
                <div className="col-sm">
                    <input
                              placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.LASTNAME' })}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "lastname"
                        )}`}
                              name="lastname"
                              {...formik.getFieldProps("lastname")}
                    />
                          {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.lastname}</div>
                        </div>
                    ) : null}
                </div> 
            </div>
        </div>

        {/* end: Fullname */}


        {/* begin: Phone */}
        <div className="form-group fv-plugins-icon-container">
            <input
                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PHONE' })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "phone"
                )}`}
                name="phone"
                {...formik.getFieldProps("phone")}
            />
                {formik.touched.email && formik.errors.phone ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                </div>
            ) : null}
        </div>
        {/* end: Phone */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL' })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.USERNAME' })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <a
                href="#"
                onClick={onTermsAndConditionsClick}
              className="mr-1"
              rel="noopener noreferrer"
                      >
                <FormattedMessage id="AUTH.VALIDATION.AGREEMENT_REQUIRED" />
            </a>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
                <FormattedMessage id="AUTH.GENERAL.CRATE_ACCOUNT" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      >
                <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
            </button>
          </Link>
        </div>
      </form>

          <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />

            </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
