import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useOrganizationsUIContext } from "../OrganizationsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

const prepareFilter = (queryParams, values) => {
  const { searchText, status } = values;
  const newQueryParams = { ...queryParams };
  
  newQueryParams.query = searchText;
  newQueryParams.status = status !== "" ? +status : undefined;
  return newQueryParams;
};

export function OrganizationsFilter({ listLoading }) {
    const intl = useIntl();

  // Organizations UI Context
    const organizationsUIContext = useOrganizationsUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
        queryParams: organizationsUIContext.queryParams,
        setQueryParams: organizationsUIContext.setQueryParams,
    };
  }, [organizationsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(organizationsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, organizationsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      organizationsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          status: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

                <div className="col-lg-2">
                    <select
                        className="form-control"
                        name="status"
                        placeholder="Filter by Status"
                        onChange={(e) => {
                            setFieldValue("status", e.target.value);
                            handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.status}
                    >
                        <option value="0">{intl.formatMessage({ id: "ECOMMERCE.COMMON.ALL" })}</option>
                        <option value="1">{intl.formatMessage({ id: "ECOMMERCE.COMMON.ACTIVE" })}</option>
                        <option value="2">{intl.formatMessage({ id: "ECOMMERCE.COMMON.INACTIVE" })}</option>
                    </select>
                    <small className="form-text text-muted">
                        <b><FormattedMessage id="ECOMMERCE.COMMON.FILTER" /></b> <FormattedMessage id="ECOMMERCE.COMMON.BY_STATUS" />
                    </small>
                </div>

                <div className="col-lg-4">
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder={intl.formatMessage({
                        id: "AUTH.GENERAL.SEARCH"
                        })}
                        onBlur={handleBlur}
                        value={values.searchText}
                        onChange={(e) => {
                            setFieldValue("searchText", e.target.value);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                        <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                    </small>
                </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
