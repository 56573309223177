import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { useCashiersUIContext } from "../CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { unregisterCashierById } from "../../../_redux/cashiers/cashiersCrud";
import InfoPopup from "../../../../Helpers/InfoPopup"
import ErrorPopup from "../../../../Helpers/ErrorPopup"

export function CashierUnregisterDialog({ id, show, onHide }) {
    const intl = useIntl();
    // Cashiers UI Context
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [openError, setOpenError] = useState(false);
    const cashiersUIContext = useCashiersUIContext();
    const cashiersUIProps = useMemo(() => {
    return {
        setIds: cashiersUIContext.setIds,
        queryParams: cashiersUIContext.queryParams
    };
  }, [cashiersUIContext]);

  // Cashiers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.cashiers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const unregisterCashier = () => {
      unregisterCashierById(id).then(({ data }) => {
          if (!data.didError && data.model) {
              setMessage(intl.formatMessage({ id: "AUTH.UNREGISTER.SUCCESS.FURS" }));
              setOpen(true);
              dispatch(actions.fetchCashiers(cashiersUIProps.queryParams));
              cashiersUIProps.setIds([]);
          }
          else {
              setMessageError(data.errorMessage);
              setOpenError(true);
          }
          onHide();
      });
  };

    return (
      <>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {/*begin::Loading*/}
          {isLoading && <ModalProgressBar />}
          {/*end::Loading*/}
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                      <FormattedMessage id="AUTH.CASHIER.UNREGISTER" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isLoading && (
                      <span><FormattedMessage id="AUTH.CASHIER.ARE_YOU_SURE_TO_UNREGISTER" /></span>
            )}
                  {isLoading && <span><FormattedMessage id="AUTH.GENERAL.CASHIER_ARE_UNREGISTERING" /></span>}
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
                      >
                <FormattedMessage id="AUTH.GENERAL.CANCEL" />
              </button>
              <> </>
              <button
                type="button"
                          onClick={unregisterCashier}
                className="btn btn-primary btn-elevate"
                      >
                    <FormattedMessage id="UNREGISTER" />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
        <ErrorPopup message={messageError} open={openError} onClose={() => { setOpenError(false); }} />
    </>
  );
}
