import * as requestFromServer from "./cashiersCrud";
import { cashiersSlice, callTypes } from "./cashiersSlice";

const {actions} = cashiersSlice;

export const fetchCashiers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCashiers(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.cashiersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find cashiers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCashier = id => dispatch => {
  if (!id) {
    return dispatch(actions.cashierFetched({ cashierForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCashierById(id)
      .then(response => {
          response.data.model.premiseType = response.data.model.premiseType + "";
          response.data.model.valid = "";
        const cashier = response.data.model;
          dispatch(actions.cashierFetched({ cashierForEdit: cashier }));
    })
    .catch(error => {
      error.clientMessage = "Can't find cashier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCashier = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCashier(id)
    .then(response => {
      dispatch(actions.cashierDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete cashier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCashier = cashierForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCashier(cashierForCreation)
      .then(response => {
        const { model } = response.data;
        dispatch(actions.cashierCreated({ model }));
    })
    .catch(error => {
      error.clientMessage = "Can't create cashier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCashier = cashier => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCashier(cashier)
    .then(() => {
      dispatch(actions.cashierUpdated({ cashier }));
    })
    .catch(error => {
      error.clientMessage = "Can't update cashier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCashiers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCashiers(ids)
    .then(() => {
      dispatch(actions.cashiersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete cashiers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

