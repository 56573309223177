// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useState, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/export/exportActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";

export function ExportsTable(refeshCount) {
    const intl = useIntl();
    const defaultSorted = [{ dataField: "id", order: "asc" }];
    const sizePerPageList = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 }
    ];
    const [queryParams, setQueryParams] = useState({
        status: 1,
        query: "",
        sortOrder: "asc", // asc||desc
        sortField: "id",
        pageNumber: 1,
        pageSize: 25
    });

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.export }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(actions.fetchExperts(queryParams));
  }, [dispatch, refeshCount.refeshCount]);

  // Table columns
  const columns = [
    {
      dataField: "id",
          text: "#",
          hidden: true,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "date",
        text: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
        sort: false,
        formatter: columnFormatters.DateColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "creatorId",
        text: intl.formatMessage({ id: "AUTH.GENERAL.CREATOR2" }),
        formatter: columnFormatters.CreatorColumnFormatter,
        sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      },
      {
          dataField: "fromDate",
          text: intl.formatMessage({ id: "AUTH.GENERAL.INTERVAL" }),
          formatter: columnFormatters.IntervalColumnFormatter,
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "email",
          text: intl.formatMessage({ id: "AUTH.INPUT.EMAIL" }),
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "status",
          text: intl.formatMessage({ id: "AUTH.GENERAL.STATUS" }),
          formatter: columnFormatters.StatusColumnFormatter,
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
    {
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
      formatter: columnFormatters.ActionsColumnFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.pageSize,
      page: queryParams.pageNumber,
      paginationTotalRenderer: customTotal,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={defaultSorted}
                onTableChange={getHandlerTableChange(
                  setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
