import * as requestFromServer from "./codeListsCrud";
import { codeListsSlice, callTypes } from "./codeListsSlice";

const { actions } = codeListsSlice;

export const fetchCodeLists = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
      .findCodeLists(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
        dispatch(actions.codeListsFetched({ totalCount, entities }));
    })
    .catch(error => {
        error.clientMessage = "Can't find codeLists";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCodeList = id => dispatch => {
  if (!id) {
      return dispatch(actions.codeListFetched({ codeListForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .getCodeListById(id)
      .then(response => {
          const codeList = response.data.model;
          dispatch(actions.codeListFetched({ codeListForEdit: codeList }));
    })
    .catch(error => {
        error.clientMessage = "Can't find codeList";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCodeList = codeListForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .createCodeList(codeListForCreation)
      .then(response => {
          const { model } = response.data;
        dispatch(actions.codeListCreated({ model }));
    })
    .catch(error => {
        error.clientMessage = "Can't create codeList";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCodeList = codeList => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .updateCodeList(codeList)
    .then(() => {
        dispatch(actions.codeListUpdated({ codeList }));
    })
    .catch(error => {
        error.clientMessage = "Can't update codeList";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCodeListSchemas = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCodeListSchemas()
        .then(response => {
            const codelistSchemas = response.data.entities;
            dispatch(actions.codeListSchemasFetched({ codelistSchemas: codelistSchemas }));
        })
        .catch(error => {
            error.clientMessage = "Can't get codelist schemas";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
