import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function OfficeUserEditDialogHeader({ id }) {
  const intl = useIntl();
  // Office users Redux state
    const { officeUserForEdit, actionsLoading } = useSelector(
    (state) => ({
        officeUserForEdit: state.officeUsers.officeUserForEdit,
        actionsLoading: state.officeUsers.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "AUTH.USER.NEW" }) ;
    if (officeUserForEdit && id) {
        _title = intl.formatMessage({ id: "AUTH.USER.EDIT" }) + ` '${officeUserForEdit.firstName} ${officeUserForEdit.lastName}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [officeUserForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
