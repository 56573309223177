import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cashiers/cashiersActions";
import { CashierEditDialogHeader } from "./CashierEditDialogHeader";
import { CashierEditForm } from "./CashierEditForm";
import { useCashiersUIContext } from "../CashiersUIContext";

export function CashierEditDialog({ id, show, onHide }) {
    // Cashiers UI Context
    const cashiersUIContext = useCashiersUIContext();
  const cashiersUIProps = useMemo(() => {
    return {
        initCashier: cashiersUIContext.initCashier,
    };
  }, [cashiersUIContext]);

  // Cashiers Redux state
  const dispatch = useDispatch();
    const { actionsLoading, cashierForEdit, pricelists, pricelistsRemove,
        jigsaws, jigsawsRemove } = useSelector(
    (state) => ({
        actionsLoading: state.cashiers.actionsLoading,
        cashierForEdit: state.cashiers.cashierForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(actions.fetchCashier(id));
  }, [id, dispatch]);

// server request for saving cashier
const saveCashier = (cashier) => {
    if (!id) {
        // server request for creating cashier
        dispatch(actions.createCashier(cashier)).then(() => onHide());
    } else {
        // server request for updating cashier
        dispatch(actions.updateCashier(cashier)).then(() => onHide());
    }
};

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
          className="mEditCashier"
    >
        <CashierEditDialogHeader id={id} />
        <CashierEditForm
            saveCashier={saveCashier}
            actionsLoading={actionsLoading}
            cashier={cashierForEdit || cashiersUIProps.initCashier}
            onHide={onHide}
        />          
    </Modal>
  );
}
