import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";

export function OrganizationsLoadingDialog() {
  const intl = useIntl();
  // Organizations Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.organizations.listLoading }),
    shallowEqual
  );
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);
    return <LoadingDialog isLoading={isLoading} text={intl.formatMessage({ id: "AUTH.GENERAL.LOADING" })} />;
}
