import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function CustomersCard() {
    const intl = useIntl();
    const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
        ids: customersUIContext.ids,
        newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.CUSTOMERS" } )} >
            <CardHeaderToolbar>
                  <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediStranke]}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={customersUIProps.newCustomerButtonClick}
                    >
                        <FormattedMessage id="AUTH.CUSTOMER.NEW" />
                    </button>
                </CheckPermissions>
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
              <CustomersFilter />
              <CheckPermissions
                  userFunctionalities={[UserFunctionalityE.PregledPrometaStrank, UserFunctionalityE.PregledSeznamovPrevzemov]}>
                  {customersUIProps.ids.length > 0 && <CustomersGrouping />}
              </CheckPermissions>
        <CustomersTable />
      </CardBody>
    </Card>
  );
}
