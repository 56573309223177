import axios from "axios";

export function getProfile() {
    return axios.get("/api/profile");
}

export function updateProfile(profile) {
    return axios.post("/api/profile/", profile);
}

export function updateSignatureProfile(data) {
    return axios.post("/api/profile/signature", data);
}

