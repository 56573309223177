// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useEffect, useState, useRef  } from "react";
import { Steps } from 'intro.js-react';
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { exitInitStepTutorial } from "../../../src/_redux/cache/cacheCrud";
import { useHistory } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';

export default function Intro(props) {
    const stepsRef = useRef();
    const intl = useIntl();
    const history = useHistory();
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { initStepTutorial } = currentState;
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(getInitStep());
    const [options, setOptions] = useState({
        nextLabel: intl.formatMessage({ id: 'AUTH.GENERAL.NEXT' }),
        prevLabel: intl.formatMessage({ id: 'AUTH.GENERAL.PREV' }),
        doneLabel: intl.formatMessage({ id: 'AUTH.GENERAL.DONE' }),
        showBullets: true,
        showProgress: true,
        showStepNumbers: false,
        showButtons: true
    });
    const [steps, setSteps] = useState([
        {
            intro: <div>
                <h1>Dobrodošli v zaledni pisarni mikroPOS</h1>
                <p>V čarovniku vas bomo popeljali skozi stvari, ki jih je potrebno nastaviti v aplikaciji preden lahko pričnete z uporabo.</p>
                <ul>
                    <li>Vnos FURSovega certifikata za davčno potrjevanje računov</li>
                    <li>Vnos izdelkov</li>
                    <li>Registracija blagajne</li>
                    <li>Vnos blagajniškega uporabnika</li>
                    <li>Povezovanje blagajne</li>
                </ul>
            </div>
        },
        {
            element: ".nSettings",
            intro: <div>
                <h1>Vnos certifikata za davčno potrjevanje računov</h1>
                <ul>
                    <li>V meniju izberete Nastavitve \ Vaše podjetje</li>
                    <li>Nato "Certifikat za davčno potrjevanje"</li>
                </ul>
            </div>
        },
        {
            element: ".nYourCompany",
            intro: <div>
                <h1>Vnos certifikata za davčno potrjevanje računov</h1>
                <ul>
                    <li>V meniju izberete Nastavitve \ Vaše podjetje</li>
                    <li>Nato "Certifikat za davčno potrjevanje"</li>
                </ul>
            </div>
        },
        {
            element: "#kt_company_aside",
            intro: <div>
                <h1>Vnos certifikata za davčno potrjevanje računov</h1>
                    <ul>
                        <li>V meniju izberete Nastavitve \ Vaše podjetje</li>
                        <li>Nato "Certifikat za davčno potrjevanje"</li>
                    </ul>
                </div>
        },
        {
            element: "#hCompanyFurs",
            intro: <div>
                <h1>Vnos certifikata za davčno potrjevanje računov</h1>
                <ul>
                    <li>Kliknete na ikono <i className="fa fa-pen icon-sm text-muted"></i></li>
                    <li>in izberete certifikat za davčno potrjevanje tipa: p12</li>
                    <li>Vnesete geslo certifikata</li>
                    <li>Shranite podatke z klikom na gumb "Shrani spremembe"</li>
                </ul>
            </div>
        },
        {
            element: ".nItemsHeader",
            intro: <div>
                <h1>Vnos skupine izdelkov</h1>
                <ul>
                    <li>V meniju izberete Izdelki \ Skupina izdelkov</li>
                </ul>
            </div>
        },
        {
            element: ".nItemsGroups",
            intro: <div>
                <h1>Vnos skupine izdelkov</h1>
                <ul>
                    <li>V meniju izberete Izdelki \ Skupina izdelkov</li>
                </ul>
            </div>
        },
        {
            element: ".chtItemGroups",
            intro: <div>
                <h1>Vnos skupine izdelkov</h1>
                <ul>
                    <li>Za vnos skupine izdelka kliknite na gumb "Nova skupina izdelkov"</li>
                </ul>
            </div>
        },
        {
            element: ".mEditItemGroup",
            intro: <div>
                <h1>Vnos nove skupine izdelkov</h1>
                <ul>
                    <li>Izpolnite polje naziv</li>
                    <li>Ostala polja izpolnite po želji</li>
                    <li>in kliknite shrani</li>
                </ul>
            </div>
        },
        {
            element: ".nItems",
            intro: <div>
                <h1>Vnos izdelkov</h1>
                <ul>
                    <li>V meniju izberete Izdelki \ Izdelki</li>
                </ul>
            </div>
        },
        {
            element: ".chtItems",
            intro: <div>
                <h1>Vnos izdelkov</h1>
                <ul>
                    <li>Za vnos izdelkov kliknite na gumb "Nov izdelek"</li>
                </ul>
            </div>
        },
        {
            element: ".mEditItem",
            intro: <div>
                <h1>Vnos novega izdelka</h1>
                <ul>
                    <li>Izpolnite polje naziv</li>
                    <li>Izpolnite polje skupina artiklov</li>
                    <li>Bodite prividni pri določitvi DDV stopnje izdelka</li>
                    <li>in kliknite shrani</li>
                </ul>
            </div>
        },
        {
            element: ".nCashiers",
            intro: <div>
                <h1>Vnos davčne blagajne</h1>
                <ul>
                    <li>V meniju izberete Nastavitve \ Davčne blagajne</li>
                </ul>
            </div>
        },
        {
            element: ".chtCashiers",
            intro: <div>
                <h1>Vnos davčne blagajne</h1>
                <ul>
                    <li>Za vnos davčne blagajne kliknite na gumb "Nova davčna blagajna"</li>
                </ul>
            </div>
        },
        {
            element: ".mEditCashier",
            intro: <div>
                <h1>Vnos nove davčne blagajne</h1>
                <ul>
                    <li>Izpolnite vsa polja</li>
                    <li>in kliknite shrani</li>
                    <li>Nato je potrebno še registrirati davčno blagajno pri FURSu</li>
                    <li>in nato povezti z vašo napravo</li>
                </ul>
            </div>
        },
        {
            intro: <div>
                <h1>Hvala za vašo pozornost</h1>
                <p>Želimo vam uspešno uporabo sistema mikroPOS.</p>
            </div>
        }
    ]);

    useEffect(() => {
        if (initStepTutorial == undefined) {
            setStepsEnabled(false);
        } else {
            setStepsEnabled(!initStepTutorial);
        }
    }, [initStepTutorial]);


    function getInitStep() {
        var url = window.location.href;
        if (url.toLowerCase().indexOf("/dashboard") > 0) {
            return 0;
        }
        if (url.toLowerCase().indexOf("/settings/company/furs") > 0) {
            return 1;
        }
        if (url.toLowerCase().indexOf("/itemgroups/new") > 0) {
            return 8;
        }
        if (url.toLowerCase().indexOf("/itemgroups") > 0) {
            return 5;
        }
        if (url.toLowerCase().indexOf("/items/new") > 0) {
            return 11;
        }
        if (url.toLowerCase().indexOf("/items") > 0) {
            return 9;
        }
        if (url.toLowerCase().indexOf("/settings/cashiers/new") > 0) {
            return 14;
        }
        if (url.toLowerCase().indexOf("/settings/cashiers") > 0) {
            return 12;
        }
        if (url.toLowerCase().indexOf("/dashboard") > 0) {
            return 15;
        }
        return 0;
    }

    function onExit() {
        exitInitStepTutorial();
    }


    function onBeforeChange(nextStepIndex) {
        if (stepsEnabled == false) {
            return;
        }
        var url = window.location.href;
        switch (nextStepIndex) {
            case 0:
            case 15:
                if (url.toLowerCase().indexOf("/dashboard") < 0) {
                    history.push("/dashboard");
                }
                break;
            case 1:
            case 2:
            case 3:
            case 4:
                if (url.toLowerCase().indexOf("/settings/company/furs") < 0) {
                    history.push("/settings/company/furs");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 5:
            case 6:
            case 7:
                if (url.toLowerCase().indexOf("/itemgroups/new") > 0 || url.toLowerCase().indexOf("/itemgroups") < 0) {
                    history.push("/itemGroups");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 8:
                if (url.toLowerCase().indexOf("/itemgroups/new") < 0) {
                    history.push("/itemGroups/new");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 9:
            case 10:
                if (url.toLowerCase().indexOf("/items/new") > 0 || url.toLowerCase().indexOf("/items") < 0) {
                    history.push("/items");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 11:
                if (url.toLowerCase().indexOf("/items/new") < 0) {
                    history.push("/items/new");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 12:
            case 13:
                if (url.toLowerCase().indexOf("/settings/cashiers") < 0) {
                    history.push("/settings/cashiers");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
            case 14:
                if (url.toLowerCase().indexOf("/settings/cashiers/new") < 0) {
                    history.push("/settings/cashiers/new");
                }
                stepsRef.current.updateStepElement(nextStepIndex);
                break;
        }
    }

    return (
        <>
            <BrowserView>
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={onExit}
                    options={options}
                    onBeforeChange={onBeforeChange}
                    ref={stepsRef}
                />
            </BrowserView>
        </>
    );
}