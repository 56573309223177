import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { DocumentsFilter } from "./documents-filter/DocumentsFilter";
import { DocumentsTable } from "./documents-table/DocumentsTable";
import { DocumentsGrouping } from "./documents-grouping/DocumentsGrouping";
import { useDocumentsUIContext } from "./DocumentsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function DocumentsCard() {
    const intl = useIntl();
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        ids: documentsUIContext.ids,
        newDocumentButtonClick: documentsUIContext.newDocumentButtonClick,
        translate: documentsUIContext.translate,
        readOnly: documentsUIContext.readOnly
    };
  }, [documentsUIContext]);

  return (
    <Card>
          <CardHeader title={documentsUIProps.translate.header} >
              <CardHeaderToolbar>
                {!documentsUIProps.readOnly && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={documentsUIProps.newDocumentButtonClick}
                    >
                        {documentsUIProps.translate.newHeader}
                    </button>
                )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DocumentsFilter />
        {documentsUIProps.ids.length > 0 && <DocumentsGrouping />}
        <DocumentsTable />
      </CardBody>
    </Card>
  );
}
