import axios from "axios";

export function checkIfOfficeUserNameIsFree(applicationUserId, userName) {
    applicationUserId = (applicationUserId == undefined || applicationUserId == null) ? -1 : applicationUserId;
    return axios.post("/api/officeUser/CheckIfOfficeUserNameIsFree", { applicationUserId, userName });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOfficeUsers(queryParams) {
    return axios.post("/api/officeUser/find", queryParams );
}

// CREATE =>  POST: add a new user to the server
export function createOfficeUser(user) {
    return axios.post("/api/officeUser", user );
}

export function getOfficeUserById(userId) {
    return axios.get("/api/officeUser/" + userId );
}

// UPDATE => PUT: update the user on the server
export function updateOfficeUser(user) {
    return axios.put("/api/officeUser/" + user.id, user);
}

// DELETE => delete the user from the server
export function deleteOfficeUser(userId) {
    return axios.delete("/api/officeUser/" + userId );
}

// DELETE Users by ids
export function deleteOfficeUsers(ids) {
    return axios.post("/api/officeUser/deleteUsers", { ids });
}

export function getOfficeUserRoles(type) {
    var queryParams = {
        query: "",
        sortOrder: "asc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10000,
        type: type
    };
    return axios.post("/api/userRole/find", queryParams);
}
