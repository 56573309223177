import React from "react";
import { Route } from "react-router-dom";
import { CodeListsLoadingDialog } from "./codeLists-loading-dialog/CodeListsLoadingDialog";
import { CodeListEditDialog } from "./codeList-edit-dialog/CodeListEditDialog";
import { CodeListsUIProvider } from "./CodeListsUIContext";
import { CodeListsCard } from "./CodeListsCard";

export function CodeListsPage({ history }) {
    const codeListsUIEvents = {
    newCodeListButtonClick: () => {
            history.push("/settings/codelist/new");
    },
    openEditCodeListDialog: (id) => {
        history.push(`/settings/codelist/${id}/edit`);
    }
  }

  return (
      <CodeListsUIProvider codeListsUIEvents={codeListsUIEvents}>
          <CodeListsLoadingDialog />
          <Route path="/settings/codelist/new">
        {({ history, match }) => (
        <CodeListEditDialog
            show={match != null}
            onHide={() => {
                history.push("/settings/codelist");
            }}
          />
        )}
      </Route>
          <Route path="/settings/codelist/:id/edit">
        {({ history, match }) => (
                  <CodeListEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/settings/codelist");
            }}
          />
        )}
      </Route>
          
          
          <CodeListsCard />
      </CodeListsUIProvider>
  );
}
