import React, { useMemo } from "react";
import { useOfficeUsersUIContext } from "../OfficeUsersUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function OfficeUsersGrouping() {
  const intl = useIntl();

  // Office Users UI Context
    const officeUsersUIContext = useOfficeUsersUIContext();
    const officeUsersUIProps = useMemo(() => {
    return {
        ids: officeUsersUIContext.ids,
        setIds: officeUsersUIContext.setIds,
        openDeleteOfficeUsersDialog: officeUsersUIContext.openDeleteOfficeUsersDialog
    };
    }, [officeUsersUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{officeUsersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={officeUsersUIProps.openDeleteOfficeUsersDialog}
              >
                <i className="fa fa-trash"></i><FormattedMessage id="DELETE.ALL" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
