import axios from "axios";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findActiveUsers(queryParams) {
    return axios.post("/api/activeUser/find", queryParams);
}

export function login(reason) {
    return axios.post("/api/activeUser/login/", reason);
}

