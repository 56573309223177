import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/documents/documentsActions";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage } from "react-intl";

export function DocumentDeleteDialog({ id, show, onHide }) {
  // Office users UI Context
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
    return {
        setIds: documentsUIContext.setIds,
        queryParams: documentsUIContext.queryParams,
        deleteFromTranslate: documentsUIContext.deleteFromTranslate
    };
    }, [documentsUIContext]);

  // Office users Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
      (state) => ({ isLoading: state.documents.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteDocument = () => {
    // server request for deleting office user by id
    dispatch(actions.deleteDocument(id)).then(() => {
      // refresh list after deletion
        dispatch(actions.fetchDocuments(documentsUIProps.queryParams));
      // clear selections list
      documentsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            {documentsUIProps.deleteFromTranslate.delete }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
                  <span>{documentsUIProps.deleteFromTranslate.areYouSure}</span>
        )}
              {isLoading && <span>{documentsUIProps.deleteFromTranslate.ItemAreDeleting}</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
                  >
            {documentsUIProps.deleteFromTranslate.cancel}
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteDocument}
            className="btn btn-primary btn-elevate"
                  >
            {documentsUIProps.deleteFromTranslate.storno}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
