import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotPassword } from "../_redux/authCrud";
import ErrorPopup from "../../Helpers/ErrorPopup"

const initialValues = {
  username: "",
};

function ForgotPassword(props) {
  const { intl } = props;
const [isRequested, setIsRequested] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
      username: Yup.string()
        .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
        .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
          forgotPassword(values.username)
        .then(({ data }) => {
            if (data.didError) {
                setErrorMessage(intl.formatMessage({
                    id: data.errorMessage,
                }));
                setErrorOpen(true);
            } else {
                setStatus(
                    intl.formatMessage(
                        { id: "AUTH.FORGOT.SUCCESS_MAIL" }
                    )
                );
            }
            setIsRequested(true);
            setSubmitting(false);
        })
        .catch(() => {
            setIsRequested(false);
            setSubmitting(false);
        });
    },
  });

  return (
      <>
          <div>
              {/*begin::Content header*/}
              < div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10" >
                  <span className="font-weight-bold text-dark-50">
                      <FormattedMessage id="AUTH.GENERAL.DONT_HAVE_AN_ACCOUNT_YET" />
                  </span>
                  <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                      <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                  </Link>
              </div >
              {/*end::Content header*/}
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.FORGOT.FORGOTTEN_PASSWORD" />
            </h3>
            <div className="text-muted font-weight-bold">
                <FormattedMessage id="AUTH.FORGOT.ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.USERNAME' })}
                              type="username"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                              name="username"
                              {...formik.getFieldProps("username")}
              />
                          {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
                >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                </button>
              </Link>
            </div>
            </form>
                  <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
              </div>
              </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
