import React, { useMemo } from "react";
import { useEndsUIContext } from "../EndsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function EndsGrouping() {
  const intl = useIntl();

  // Ends UI Context
  const endsUIContext = useEndsUIContext();
  const endsUIProps = useMemo(() => {
    return {
        ids: endsUIContext.ids,
        setIds: endsUIContext.setIds,
        printDocumentsDialog: endsUIContext.printDocumentsDialog
    };
  }, [endsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                    <FormattedMessage id="SELECTED_RECORDS_COUNT" /> <b>{endsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm"
                    onClick={endsUIProps.printDocumentsDialog}
                >
                    <i className="fa fa-print"></i><FormattedMessage id="PRINT" />
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
