import React, { useEffect, useState }from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../../../_metronic/layout";
import CompanyDetails from "../yourCompany/CompanyDetails";
import CompanyPackage from "../yourCompany/CompanyPackage";
import CompanyFurs from "../yourCompany/CompanyFurs";
import CompanyEInvoice from "../yourCompany/CompanyEInvoice";
import CompanyPayment from "../yourCompany/CompanyPayment";
import CompanyCancelPackage from "../yourCompany/CompanyCancelPackage";
import { Navigation } from "../yourCompany/components/Navigation";
import { useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";
import { hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export default function YourCompanyPage() {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { user } = useSelector(state => state.auth);
    suhbeader.setTitle(intl.formatMessage({ id: 'AUTH.GENERAL.YOUR_COMPANY' }));

    // CodeLists Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchOrganization())
        dispatch(actions.fetchFiscalDigitalCertificate())
    }, [dispatch]);

    return (
        <div className="d-flex flex-row">
            <Navigation></Navigation>
            <div className="flex-row-fluid ml-lg-8">
                <Switch>
                    <Redirect
                        from="/settings/company"
                        exact={true}
                        to="/settings/company/data"
                    />
                   
                    <Route
                        path="/settings/company/data"
                        component={CompanyDetails}
                    />
                   

                    <Route
                        path="/settings/company/einvoice"
                        component={CompanyEInvoice}
                    />
                    <Route
                        path="/settings/company/payment"
                        component={CompanyPayment}
                    />

                    {hasRoleForFunctionality(user, [UserFunctionalityE.UrediVasePodjetje]) && (
                        <>
                            {user.organizationNotSentToFurs == false && (
                                <Route
                                    path="/settings/company/furs"
                                    component={CompanyFurs}
                                />
                            )}
                            <Route
                                path="/settings/company/package"
                                component={CompanyPackage}
                            />
                            <Route
                                path="/settings/company/cancel_package"
                                component={CompanyCancelPackage}
                            />

                        </>
                    )}
                </Switch>
            </div>
        </div>
    );
}
