import React, { useState } from "react";
import { Route } from "react-router-dom";
import { CashiersLoadingDialog } from "./cashiers-loading-dialog/CashiersLoadingDialog";
import { CashierEditDialog } from "./cashier-edit-dialog/CashierEditDialog";
import { CashierDeleteDialog } from "./cashier-delete-dialog/CashierDeleteDialog";
import { CashiersUIProvider } from "./CashiersUIContext";
import { CashiersCard } from "./CashiersCard";
import { CashierPrintDialog } from "./cashier-print-dialog/CashierPrintDialog";
import { CashierRegisterDialog } from "./cashier-register-dialog/CashierRegisterDialog";
import { CashierUnregisterDialog } from "./cashier-unregister-dialog/CashierUnregisterDialog";
import { CashierConnectDialog } from "./cashier-connect-dialog/CashierConnectDialog";
import { CashierDeconnectDialog } from "./cashier-deconnect-dialog/CashierDeconnectDialog";
import { shallowEqual, useSelector } from "react-redux";
import InfoPopup from "../../../Helpers/InfoPopup"
import { useIntl } from "react-intl";

export function CashiersPage({ history }) {
    const intl = useIntl();
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const { user } = useSelector(state => state.auth);
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cashiers }),
        shallowEqual
    );
    const { totalCount } = currentState;


  const cashiersUIEvents = {
    newCashierButtonClick: () => {
        if (totalCount < user.organizationCashierCount) {
            history.push("/settings/cashiers/new");
        } else {
            setOpen(true);
            setMessage(intl.formatMessage({
                id: "GENERAL.CAN_NOT_ADDED_CASHIERS",
            }));
        }
    },
    openEditCashierDialog: (id) => {
        history.push(`/settings/cashiers/${id}/edit`);
    },
    openDeleteCashierDialog: (id) => {
        history.push(`/settings/cashiers/${id}/delete`);
    },
    openDeleteCashiersDialog: () => {
        history.push(`/settings/cashiers/deleteCashiers`);
      },
      printInternAktDialog: (id) => {
          history.push(`/settings/cashiers/${id}/print/`);
      },
      registerDialog: (id) => {
          history.push(`/settings/cashiers/${id}/register`);
      },
      unregisterDialog: (id) => {
          history.push(`/settings/cashiers/${id}/unregister`);
      },
      openConnectDialog: (id) => {
          history.push(`/settings/cashiers/${id}/connect`);
      },
      openDeconnectDialog: (id) => {
          history.push(`/settings/cashiers/${id}/deconnect`);
      }
    }

  return (
      <CashiersUIProvider cashiersUIEvents={cashiersUIEvents}>
        <CashiersLoadingDialog />
          <Route path="/settings/cashiers/new">
        {({ history, match }) => (
           <CashierEditDialog
            show={match != null}
            onHide={() => {
                history.push("/settings/cashiers");
            }}
          />
        )}
      </Route>
          <Route path="/settings/cashiers/:id/edit">
        {({ history, match }) => (
            <CashierEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/settings/cashiers");
            }}
          />
        )}
      </Route>
          <Route path="/settings/cashiers/:id/delete">
        {({ history, match }) => (
            <CashierDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/settings/cashiers");
            }}
          />
        )}
        </Route>
        <Route path="/settings/cashiers/:id/print">
            {({ history, match }) => (
                  <CashierPrintDialog
                    show={match != null}
                    id={match && match.params.id}
                    onHide={() => {
                        history.push("/settings/cashiers");
                    }}
                />
            )
            }
          </Route >
          <Route path="/settings/cashiers/:id/register">
              {({ history, match }) => (
                  <CashierRegisterDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/settings/cashiers");
                      }}
                  />
              )}
          </Route>
          <Route path="/settings/cashiers/:id/unregister">
              {({ history, match }) => (
                  <CashierUnregisterDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/settings/cashiers");
                      }}
                  />
              )}
          </Route>

          <Route path="/settings/cashiers/:id/connect">
              {({ history, match }) => (
                  <CashierConnectDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/settings/cashiers");
                      }}
                  />
              )}
          </Route>

          <Route path="/settings/cashiers/:id/deconnect">
              {({ history, match }) => (
                  <CashierDeconnectDialog
                      show={match != null}
                      id={match && match.params.id}
                      onHide={() => {
                          history.push("/settings/cashiers");
                      }}
                  />
              )}
          </Route>


      <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
      <CashiersCard />
      </CashiersUIProvider>
  );
}
