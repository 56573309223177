// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export default function Select5YearBack(props) {
    var years = [];
    var dt = new Date();
    for (var i = 0; i < 5; i++) {
        years.push(dt.getFullYear());
        dt.setYear(dt.getFullYear() - 1);
    }

    return (
        <>
            <Select
                disabled={props.readOnly}
                variant="outlined"
                className={`form-control form-control-solid }`}
                value={props.value}
                onChange={(event) => { props.onChange(event.target.value); }}
            >
                {years.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
                   
                
            </Select>
        </>
    );
}
