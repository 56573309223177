import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"
import { updateFiscalDigitalCertificate } from "../../_redux/yourCompany/yourCompanyCrud";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";


export default function CompanyFurs(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [certificate, setCertificate] = useState(null);
    const [file, setFile] = useState(undefined);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { fiscalDigitalCertificate } = useSelector(
        (state) => ({
            fiscalDigitalCertificate: state.organization.fiscalDigitalCertificateForEdit
        }),
        shallowEqual
    );
    const [isEdit, setIsEdit] = useState(fiscalDigitalCertificate == null);

    useEffect(() => {
        setIsEdit(fiscalDigitalCertificate == null);
    }, [fiscalDigitalCertificate]);
    // Methods
    const saveFurs = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);
        const formData = new FormData();
        formData.append(
            "certFile",
            certificate,
            certificate.name
        ); 
        formData.append(
            "password",
            values.certPassword,
        );
        updateFiscalDigitalCertificate(formData)
            .then(({ data }) => {
                if (data.didError) {
                    setIsError(true);
                } else {
                    dispatch(actions.updateFiscalDigitalCertificate(data.model));
                    setIsSuccess(true);
                    setIsEdit(false);
                }
                setLoading(false);
                setSubmitting(false);
            })
            .catch((e) => {
                setIsError(true);
                setLoading(false);
                setSubmitting(false);
            });
    };

    const changedCertificate = e => {
        setIsEdit(true);
    };

    const resetFormCert = e => {
        setIsEdit(false);
    };
    // UI Helpers
    const initialValues = {
        certPassword: '',
    };
    const Schema = Yup.object().shape({
        certPassword: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }))
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveFurs(values, setStatus, setSubmitting);
        },
        onReset: (values, { resetForm }) => {
            setIsError(false);
            setIsSuccess(false);
            setCertificate(null);
            resetFormCert();
        },
    });

    return (
    <div id="hCompanyFurs" >
        <form
           
            className="card card-custom card-stretch"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
        >
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="COMPANY.NAVIGATION.FURS" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="COMPANY.NAVIGATION.FURS_2" />
          </span>
                </div>
                {isEdit == true && (
                    <CheckPermissions
                        userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                        <div className="card-toolbar">
                            <button
                                type="submit"
                                className="btn btn-success mr-2"
                                disabled={
                                    formik.isSubmitting || certificate == null || (formik.touched && !formik.isValid)
                                }
                            >
                                <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
                                {formik.isSubmitting}
                            </button>
                            <button
                                className="btn btn-secondary"
                                type="reset"
                            >
                                <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                            </button>
                        </div>
                    </CheckPermissions>
                )}
                
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                {/* begin::Body */}
                <div className="card-body">

                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_FURS" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_FURS" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {( isEdit == true) && (
                        <>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    <FormattedMessage id="COMPANY.NAVIGATION.FURS_2" />
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div
                                        className="image-input image-input-outline"
                                    >
                                        <div
                                            className="image-input-wrapper"
                                            style={{ height: "40px" }}
                                        >
                                            {certificate != null ? certificate.name : ""}
                                        </div>
                                        <label
                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="change"
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <i className="fa fa-pen icon-sm text-muted"></i>
                                            <input
                                                type="file"
                                                value={file}
                                                onChange={(event) => { setCertificate(event.target.files[0]); }}
                                                accept=".p12"
                                            />
                                        </label>
                                        <span
                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="cancel"
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                        </span>
                                        <span
                                            onClick={(event) => { setCertificate(null); setFile(''); }}
                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="remove"
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                        </span>
                                    </div>
                                    <span className="form-text text-muted">
                                        <FormattedMessage id="FURS.GENERAL.ALLOWED_FILES" />
                                    </span>
                                </div>
                            </div>


                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.INPUT.PASSWORD" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="password"
                                        placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                                        className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                            "certPassword"
                                        )}`}
                                        name="certPassword"
                                        {...formik.getFieldProps("certPassword")}
                                    />
                                    {formik.touched.certPassword &&
                                        formik.errors.certPassword ? (
                                            <div className="invalid-feedback">
                                                {formik.errors.certPassword}
                                            </div>
                                        ) : null}
                                </div>
                            </div>
                        </>
                    )}
                    
                    {(fiscalDigitalCertificate != null && isEdit == false) && (
                        <>
                            {new Date(fiscalDigitalCertificate.expiredDate) < new Date() && (
                                <div
                                    className="alert alert-custom alert-light-danger fade show mb-10"
                                    role="alert"
                                >
                                    <div className="alert-icon">
                                        <span className="svg-icon svg-icon-3x svg-icon-danger">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                            ></SVG>{" "}
                                        </span>
                                    </div>
                                    <div className="alert-text font-weight-bold">

                                        <FormattedMessage id="AUTH.GENERAL.ERROR_FURS_EXPIRED" />

                                    </div>
                                </div> 
                            )}

                            <div className="row">
                                <h4><FormattedMessage id="AUTH.GENERAL.CERT_LOADED" /> <a onClick={changedCertificate} href="#"><FormattedMessage id="AUTH.GENERAL.HERE" /></a>.</h4>
                            </div>
                            <hr/>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.INPUT.OWNER" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="text"
                                        disabled={true}
                                        value={fiscalDigitalCertificate.name}
                                        className="form-control form-control-lg form-control-solid mb-2"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.INPUT.SERIAL_NUMBER" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="text"
                                        disabled={true}
                                        value={fiscalDigitalCertificate.number}
                                        className="form-control form-control-lg form-control-solid mb-2"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.INPUT.PUBLISHER" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="text"
                                        disabled={true}
                                        value={fiscalDigitalCertificate.publisherName}
                                        className="form-control form-control-lg form-control-solid mb-2"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    <FormattedMessage id="AUTH.INPUT.EXPIRED_DATE" />
                                </label>

                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="text"
                                        disabled={true}
                                        value={new Date(fiscalDigitalCertificate.expiredDate).getDate() + "." + (new Date(fiscalDigitalCertificate.expiredDate).getMonth() + 1) + "." + new Date(fiscalDigitalCertificate.expiredDate).getFullYear()}
                                        className="form-control form-control-lg form-control-solid mb-2"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                </div>
                {/* end::Body */}
            </div>
            {/* end::Form */}
            </form>
        </div>
    );
}

