import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useItemsUIContext } from "../ItemsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import AutocompleteItemGroup from "../../../../../components/AutocompleteItemGroup"
import {
    Input,
} from "../../../../../../_metronic/_partials/controls";

const prepareFilter = (queryParams, values) => {

    const { searchText, status, itemGroupId } = values;
    const newQueryParams = { ...queryParams };
  
    newQueryParams.query = searchText;
    newQueryParams.status = status !== "" ? + status : undefined;
    newQueryParams.itemGroupId = itemGroupId !== "" ? + itemGroupId : undefined;
    return newQueryParams;
};

export function ItemsFilter({ listLoading }) {
    const intl = useIntl();

  // Items UI Context
    const itemsUIContext = useItemsUIContext();
  const itemsUIProps = useMemo(() => {
    return {
        queryParams: itemsUIContext.queryParams,
        setQueryParams: itemsUIContext.setQueryParams,
    };
  }, [itemsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(itemsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, itemsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      itemsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
                  status: "",
            itemGroupId: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
        <div className="form-group row">
            <div className="col-lg-2">
                <AutocompleteItemGroup
                    name="itemGroupId"
                    onChange={(val) => {
                        if (val == null) {
                            setFieldValue("itemGroupId", "");
                        } else {
                            setFieldValue("itemGroupId", val.id);
                        }
                        handleSubmit();
                    }}
                />     
                <small className="form-text text-muted">
                    <b><FormattedMessage id="ECOMMERCE.COMMON.FILTER" /></b> <FormattedMessage id="ECOMMERCE.COMMON.BY_ITEM_GROUP" />
                </small>
            </div>
            <div className="col-lg-2">
                <select
                    className="form-control"
                    name="status"
                    onChange={(e) => {
                        setFieldValue("status", e.target.value);
                        handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.status}
                >
                    <option value="1">{intl.formatMessage({ id: "ECOMMERCE.COMMON.ACTIVE" })}</option>
                    <option value="2">{intl.formatMessage({ id: "ECOMMERCE.COMMON.INACTIVE" })}</option>
                </select>
                <small className="form-text text-muted">
                    <b><FormattedMessage id="ECOMMERCE.COMMON.FILTER" /></b> <FormattedMessage id="ECOMMERCE.COMMON.BY_STATUS" />
                </small>
            </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.SEARCH"
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                    <b><FormattedMessage id="AUTH.GENERAL.SEARCH" /></b> <FormattedMessage id="AUTH.GENERAL.IN_ALL_FIELDS" />
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
