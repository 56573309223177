import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import moment from 'moment';

export default function DateRange2(props) {
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const [valueTxt, setValueTxt] = useState("");

    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        var txt = "";
        txt = intl.formatMessage({ id: "AUTH.GENERAL.FROM" }) + " " +
            moment(state[0].startDate).format("DD.MM.YYYY") + " " +
            intl.formatMessage({ id: "AUTH.GENERAL.TO" }) + " " +
            moment(state[0].endDate).format("DD.MM.YYYY");
        setValueTxt(txt);
    }, [state]);


    function showDateRange() {
        setState([
            {
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
            }
        ]);
        setVisible(!visible);
    }

    function setToday() {
            setState([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }
            ]);
    }

    function setYesterday() {
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        setState([
            {
                startDate: yesterday,
                endDate: yesterday,
                key: 'selection'
            }
        ]);
    }

    function setLastWeek() {
        var date = new Date();
        var day = date.getDay();
        var prevMonday = new Date();
        if (date.getDay() == 0) {
            prevMonday.setDate(date.getDate() - 7);
        }
        else {
            prevMonday.setDate(date.getDate() - (day - 1));
        }
        setState([
            {
                startDate: prevMonday,
                endDate: date,
                key: 'selection'
            }
        ]);
    }

    function setLastMonth() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setState([
            {
                startDate: firstDay,
                endDate: date,
                key: 'selection'
            }
        ]);
    }

    return (
        <>
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    defaultValue={valueTxt}
                    readOnly={true}
                    onClick={(event) => {
                        showDateRange();
                    }}
                />
                <div className="input-group-prepend">
                    <span className="input-group-text"
                        onClick={(event) => {
                            showDateRange();
                        }}
                    >
                        <i className="ki ki-calendar icon-lg"></i>
                    </span>
                </div>
            </div>
            {visible && (
                <div className="form form-label-right alert alert-light" style={{ position: 'absolute', top: 45, left: 10 }}>

                    <div className="row" >
                        <div className="form-group col-lg-12">
                            <DateRange
                                locale={rdrLocales.sl}
                                editableDateInputs={true}
                                onChange={item => setState([item.selection])}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12">
                            <button
                                type="button"
                                onClick={() => { setToday(); }}
                                className="btn btn-link btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.TODAY" />
                            </button>
                            <> </>
                            <button
                                type="button"
                                onClick={() => { setYesterday(); }}
                                className="btn btn-link btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.YESTERDAY" />
                            </button>
                            <> </>
                            <button
                                type="button"
                                onClick={() => { setLastWeek(); }}
                                className="btn btn-link btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.LAST_WEEK" />
                            </button>
                            <> </>
                            <button
                                type="button"
                                onClick={() => { setLastMonth(); }}
                                className="btn btn-link btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.LAST_MONTH" />
                            </button>

                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6">
                        </div>
                        <div className="form-group col-lg-6">
                            <button
                                type="button"
                                onClick={() => { showDateRange(); }}
                                className="btn btn-light btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.CLOSE" />
                            </button>
                            <> </>
                            <button
                                type="button"
                                onClick={() => {
                                    setVisible(false);
                                    setStartDate(state[0].startDate);
                                    setEndDate(state[0].endDate);
                                    if (props.onChange != null) {
                                        props.onChange(state[0].startDate, state[0].endDate);
                                    }
                                }}
                                className="btn btn-primary btn-elevate"
                            >
                                <FormattedMessage id="AUTH.GENERAL.ACCEPT" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
                
        </>
    );
}
