import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ActiveUsersFilter } from "./activeUsers-filter/ActiveUsersFilter";
import { ActiveUsersTable } from "./activeUsers-table/ActiveUsersTable";
import { ActiveUsersGrouping } from "./activeUsers-grouping/ActiveUsersGrouping";
import { useActiveUsersUIContext } from "./ActiveUsersUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function ActiveUsersCard() {
    const intl = useIntl();
    const activeUsersUIContext = useActiveUsersUIContext();
  const activeUsersUIProps = useMemo(() => {
    return {
        ids: activeUsersUIContext.ids,
        newActiveUserButtonClick: activeUsersUIContext.newActiveUserButtonClick,
    };
  }, [activeUsersUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.USERS" } )} >
            <CardHeaderToolbar>


            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ActiveUsersFilter />
        {activeUsersUIProps.ids.length > 0 && <ActiveUsersGrouping />}
        <ActiveUsersTable />
      </CardBody>
    </Card>
  );
}
