import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ItemsUIHelpers";

const ItemsUIContext = createContext();

export function useItemsUIContext() {
    return useContext(ItemsUIContext);
}

export const ItemsUIConsumer = ItemsUIContext.Consumer;

export function ItemsUIProvider({itemsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initItem = {
        id: undefined,
        title: "",
        description: "",
        eanCode: "",
        supplierNumber: "",
        allowedDiscount: true,
        appearInCashier: true,
        isTdr: false,
        isConsignmentStock: false,
        exemptForVat: false,
        background: "#96BAF6",
        foreground: "#EFDCDC",
        fontSize: 14,
        order: -1,
        maxDiscount: "",
        grossWeightPerUnit: "",
        itemGroup: null,
        itemGroupId: undefined,
        vatRateId: 1,
        unitId: null,
        itemTypeId: 1,
        active: true,
        printer1: true,
        printer2: false,
        printer3: false,
        pricelist: [],
        jigsaws1: []
    };
  
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initItem,
      newItemButtonClick: itemsUIEvents.newItemButtonClick,
      openEditItemDialog: itemsUIEvents.openEditItemDialog,
      openDeleteItemDialog: itemsUIEvents.openDeleteItemDialog,
      openItemStockMovementDialog: itemsUIEvents.openItemStockMovementDialog,
      openUpItem: itemsUIEvents.openUpItem,
      openDownItem: itemsUIEvents.openDownItem
  };

    return <ItemsUIContext.Provider value={value}>{children}</ItemsUIContext.Provider>;
}