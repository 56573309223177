import axios from "axios";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrganizations(queryParams) {
    return axios.post("/api/organization/find", queryParams);
}

export function getOrganizationById(id) {
    return axios.get("/api/organization/" + id);
}

// UPDATE => PUT: update the user on the server
export function updateOrganization(organization) {
    return axios.put("/api/organization/" + organization.id, organization);
}

