// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { decimal, onlyDigits } from "../../../../../util/Util";
import AutocompleteSupplier from "../../../../../components/AutocompleteSupplier"
import AutocompleteWarehouseWithoutConsignment from "../../../../../components/AutocompleteWarehouseWithoutConsignment"
import AutocompleteCustomer from "../../../../../components/AutocompleteCustomer"
import { checkIfOtherDocumentNumberYetExist } from "../../../_redux/documents/documentsCrud";
import { DocumentPositionGrid } from "./DocumentPositionGrid";
import InfoPopup from "../../../../Helpers/InfoPopup"

export function DocumentEditForm({
  documentType,
  formFieldsVisible,
    formTranslate,
    gridPositionColumnsVisible,
    gridPositionColumnsTranslate,
  saveDocument,
  document,
  actionsLoading,
    onHide,
    readOnly
}) {
    const intl = useIntl();
    const [customer, setCustomer] = useState(document != undefined ? document.customer : null);
    const [warehouse, setWarehouse] = useState(document != undefined ? document.warehouse : null);
    const [warehouse2, setWarehouse2] = useState(document != undefined ? document.warehouse2 : null);
    const [errorCustomer, setErrorCustomer] = useState("");
    const [errorSupplier, setErrorSupplier] = useState("");
    const [errorWarehouseId, setErrorWarehouseId] = useState("");
    const [errorWarehouse2Id, setErrorWarehouse2Id] = useState("");
    const [discountProcent, setDiscountProcent] = useState(document != undefined ? document.discountProcent : "");
    const [discountAmount, setDiscountAmount] = useState(document != undefined ? document.discountAmount : "");
    const [recalculate, doRecalculate] = useState(0);
    const [save, doSave] = useState(0);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCustomer(document != undefined ? document.customer : null);
        setWarehouse(document != undefined ? document.warehouse : null);
        setWarehouse2(document != undefined ? document.warehouse2 : null);
        setDiscountProcent(document != undefined ? document.discountProcent : "");
        setDiscountAmount(document != undefined ? document.discountAmount : "");
    }, [document]);

    useEffect(() => {
        validateForm();
    }, [warehouse, warehouse2, customer]);

    if (document.stornoDocumentId != null) {
        readOnly = true;
    }

    if (document.linkedDocument2 != null &&
        document.linkedDocument2.length > 0) {
        readOnly = true;
    }

    var objValid = {};

    if (formFieldsVisible.date) {
        objValid.date = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.time) {
        objValid.time = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.serviceDate) {
        objValid.serviceDate = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.dateOfServicesRendered) {
        objValid.dateOfServicesRendered = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.dueDate) {
        objValid.dueDate = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.paymentDeadlineNumDays) {
        objValid.paymentDeadlineNumDays = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }));
    }
    if (formFieldsVisible.otherDocumentNumber) {
        objValid.otherDocumentNumber = Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .test('validator', intl.formatMessage({
                id: "AUTH.VALIDATION.DOCUMENT.EXIST_OTHER_DOC_NUM",
            }), value => {
                return new Promise((resolve, reject) => {
                    checkIfOtherDocumentNumberYetExist(document.id, value, documentType)
                        .then(({ data }) => {
                            if (!data.didError) {
                                resolve(!data.model);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch((e) => {
                            resolve(false);
                        });
                })
            });
    }
    objValid.valid = Yup.string()
        .test('validator', "Error", value => {
            return new Promise((resolve, reject) => {
                resolve(validateForm());
            })
        });

    // Validation schema
    const DocumentEditSchema = Yup.object().shape(objValid);

    function validateForm() {
        var res = true;
        if (formFieldsVisible.customer) {
            if (customer == undefined) {
                setErrorCustomer(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
                res = false;
            } else {
                setErrorCustomer("");
            }
        }
        if (formFieldsVisible.supplier) {
            if (customer == undefined) {
                setErrorSupplier(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
                res = false;
            } else {
                setErrorSupplier("");
            }
        }
        if (formFieldsVisible.warehouseId) {
            if (warehouse == undefined) {
                setErrorWarehouseId(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
                res = false;
            } else {
                setErrorWarehouseId("");
            }
        }
        if (formFieldsVisible.warehouse2Id) {
            if (warehouse2 == undefined) {
                setErrorWarehouse2Id(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }));
                res = false;
            } else if (warehouse != undefined && warehouse.id == warehouse2.id) {
                setErrorWarehouse2Id(intl.formatMessage({ id: 'WAREHOUSE.VALIDATION.SAME' }));
                res = false;
            }
            else {
                setErrorWarehouse2Id("");
            }
        }

        return res;
    }

    var documentPosition = null;
    function saveDocumentPositions(documentPositions) {
        documentPosition = documentPositions;
    }

    function getDiscount() {
        return {
            discountProcent: discountProcent,
            discountAmount: discountAmount
        };
    }

  return (
    <>
          <Formik
        enableReinitialize={true}
              initialValues={document}
              validateOnChange={false}
        validationSchema={DocumentEditSchema}
              onSubmit={(values) => {
                  if (documentPosition.length > 0) {
                      var date = values.date;
                      if (formFieldsVisible.time) {
                          date = date + " " + values.time + ":00";
                      }
                      var obj = {
                          id: values.id,
                          type: documentType,
                          date: date,
                          serviceDate: values.serviceDate,
                          dateOfServicesRendered: values.dateOfServicesRendered,
                          dueDate: values.dueDate,
                          customer: customer,
                          customerId: customer != null ? customer.id : -1,
                          discountProcent: discountProcent,
                          discountAmount: discountAmount,
                          note: values.note,
                          note2: values.note2,
                          warehouse: warehouse,
                          warehouseId: warehouse != null ? warehouse.id : -1,
                          warehouse2: warehouse2,
                          warehouse2Id: warehouse2 != null ? warehouse2.id : -1,
                          otherDocumentNumber: values.otherDocumentNumber,
                          paymentDeadlineNumDays: values.paymentDeadlineNumDays,
                          documentPosition: documentPosition,
                          linkedDocument: values.linkedDocument
                      }
                      saveDocument(obj);
                  } else {
                      setMessage(intl.formatMessage({ id: 'AUTH.DOCUMENT_POSITIONS.ZERO' }));
                      setOpen(true);
                  }
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}

            {!actionsLoading && (
                <Form className="form form-label-right">
                    <div className="row">

                        <div style={{ display: "none" }} className="form-group col-lg-4">
                            <Field
                                disabled={readOnly}
                                name="valid"
                                component={Input}
                                label=""
                                customFeedbackLabel=" "
                            />
                        </div>

                        {formFieldsVisible.date && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.date}</label>
                                <Field
                                    disabled={readOnly}
                                    name="date"
                                    type="date"
                                    component={Input}
                                    placeholder={formTranslate.date}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.time && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.time}</label>
                                <Field
                                    disabled={readOnly}
                                    name="time"
                                    type="time"
                                    component={Input}
                                    placeholder={formTranslate.date}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.serviceDate && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.serviceDate}</label>
                                <Field
                                    disabled={readOnly}
                                    name="serviceDate"
                                    type="date"
                                    component={Input}
                                    placeholder={formTranslate.serviceDate}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.dateOfServicesRendered && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.dateOfServicesRendered}</label>
                                <Field
                                    disabled={readOnly}
                                    name="dateOfServicesRendered"
                                    type="date"
                                    component={Input}
                                    placeholder={formTranslate.dateOfServicesRendered}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.dueDate && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.dueDate}</label>
                                <Field
                                    disabled={readOnly}
                                    name="dueDate"
                                    type="date"
                                    component={Input}
                                    placeholder={formTranslate.dueDate}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.paymentDeadlineNumDays && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.paymentDeadlineNumDays}</label>
                                <Field
                                    disabled={readOnly}
                                    name="paymentDeadlineNumDays"
                                    type="number"
                                    onKeyPress={onlyDigits}
                                    component={Input}
                                    placeholder={formTranslate.paymentDeadlineNumDays}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}
                                      

                        {formFieldsVisible.customer && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.customer}</label>
                                <AutocompleteCustomer
                                    readOnly={readOnly}
                                    name="customerId"
                                    value={customer}
                                    onChange={(val) => {
                                        setCustomer(val);
                                    }}
                                />
                                {errorCustomer.length > 0 ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errorCustomer}</div>
                                    </div>
                                ) : null}
                            </div>
                        )}

                        {formFieldsVisible.supplier && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.supplier}</label>
                                <AutocompleteSupplier
                                    readOnly={readOnly}
                                    name="customerId"
                                    value={customer}
                                    onChange={(val) => {
                                        setCustomer(val);
                                    }}
                                />
                                {errorSupplier.length > 0 ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errorSupplier}</div>
                                    </div>
                                ) : null}
                            </div>
                        )}

                        {formFieldsVisible.otherDocumentNumber && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.otherDocumentNumber}</label>
                                <Field
                                    disabled={readOnly}
                                    name="otherDocumentNumber"
                                    component={Input}
                                    placeholder={formTranslate.otherDocumentNumber}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        )}

                        {formFieldsVisible.discountProcent && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.discountProcent}</label>
                                <Field
                                    disabled={readOnly || discountAmount != ""}
                                    name="discountProcent"
                                    type="number"
                                    onKeyPress={decimal}
                                    component={Input}
                                    placeholder={formTranslate.discountProcent}
                                    label=""
                                    customFeedbackLabel=" "
                                    value={discountProcent}
                                    onChange={(event) => {
                                        setDiscountProcent(event.target.value);
                                        setDiscountAmount("");
                                        doRecalculate(prev => prev + 1);
                                    }}
                                />
                            </div>
                        )}

                        {formFieldsVisible.discountAmount && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.discountAmount}</label>
                                <Field
                                    disabled={readOnly || discountProcent != ""}
                                    name="discountAmount"
                                    type="number"
                                    onKeyPress={decimal}
                                    component={Input}
                                    placeholder={formTranslate.discountAmount}
                                    label=""
                                    customFeedbackLabel=" "
                                    value={discountAmount}
                                    onChange={(event) => {
                                        setDiscountProcent("");
                                        setDiscountAmount(event.target.value);
                                        doRecalculate(prev => prev + 1);
                                    }}
                                />
                            </div>
                        )}

                        {formFieldsVisible.warehouseId && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.warehouseId}</label>
                                <AutocompleteWarehouseWithoutConsignment
                                    readOnly={readOnly}
                                    name="warehouseId"
                                    value={warehouse}
                                    onChange={(val) => {
                                        setWarehouse(val);
                                    }}
                                />
                                {errorWarehouseId.length > 0 ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errorWarehouseId}</div>
                                    </div>
                                ) : null}
                            </div>
                        )}

                        {formFieldsVisible.warehouse2Id && (
                            <div className="form-group col-lg-4">
                                <label>{formTranslate.warehouse2Id}</label>
                                <AutocompleteWarehouseWithoutConsignment
                                    readOnly={readOnly}
                                    name="warehouse2Id"
                                    value={warehouse2}
                                    onChange={(val) => {
                                        setWarehouse2(val);
                                    }}
                                />
                                {errorWarehouse2Id.length > 0 ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errorWarehouse2Id}</div>
                                    </div>
                                ) : null}
                            </div>
                        )}


                    </div>

                    {formFieldsVisible.note && (
                        <div className="form-group row">

                            <div className="col-lg-12">
                                <label>{formTranslate.note}</label>
                                <Field
                                    disabled={readOnly}
                                    name="note"
                                    component={Input}
                                    placeholder={formTranslate.note}
                                    label=""
                                    customFeedbackLabel=" "
                                />
                            </div>
                        </div>
                    )}
                    <DocumentPositionGrid
                        warehouse={warehouse}
                        readOnly={readOnly}
                        documentType={documentType}
                        documentPositions={document.documentPosition}
                        gridPositionColumnsVisible={gridPositionColumnsVisible}
                        gridPositionColumnsTranslate={gridPositionColumnsTranslate}
                        formFieldsVisible={formFieldsVisible}
                        saveDocumentPositions={saveDocumentPositions}
                        getDiscount={getDiscount}
                        recalculate={recalculate}
                        save={save}
                    />
                </Form>            
                              
                )}
            </Modal.Body>
            <Modal.Footer>
               
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
                          >
                {!readOnly && (
                    <FormattedMessage id="AUTH.GENERAL.CANCEL" />
                )}
                {readOnly && (
                    <FormattedMessage id="AUTH.GENERAL.CLOSE" />
                )}
              </button>
                <> </>
            {!actionsLoading && !readOnly && (
                <button
                    type="submit"
                    onClick={() => {
                        doSave(prev => prev + 1);
                        handleSubmit();
                    }}
                    className="btn btn-primary btn-elevate"
                >
                    <FormattedMessage id="AUTH.GENERAL.SAVE" />
                </button>
            )}
                
              
            </Modal.Footer>
          </>
        )}
          </Formik>
          <InfoPopup message={message} open={open} onClose={() => { setOpen(false); }} />
    </>
  );
}
