/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { changePassword } from "../../_redux/changePassword/changePasswordCrud"

export function ChangePasswordPage(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    useEffect(() => { }, [user]);
    // Methods
    const saveUser = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);
        const updatedUser = {
            id: user.id,
            currentPassword: values.currentPassword,
            password: values.password
        };

        changePassword(updatedUser).then(({ data }) => {
            if (data.didError) {
                setIsError(true);
            } else {
                setIsSuccess(data.model);
                setIsError(!data.model);
            }
            setLoading(false);
            setSubmitting(false);
        })
        .catch((e) => {
            setLoading(false);
            setSubmitting(false);
            setIsError(true);
        });
    };
    // UI Helpers
    const initialValues = {
        currentPassword: "",
        password: "",
        cPassword: "",
    };
    const Schema = Yup.object().shape({
        currentPassword: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
            password: Yup.string()
                .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
                .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
                .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        cPassword: Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH' })
                ),
            }),
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveUser(values, setStatus, setSubmitting);
            resetForm(initialValues);
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit}  onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="AUTH.GENERAL.CHANGE_PASSWORD" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="AUTH.GENERAL.CHANGE_YOUR_PASSWORD" />
          </span>
                </div>
                <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting || (formik.touched && !formik.isValid)
                        }
                    >
                        <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
            {formik.isSubmitting}
                    </button>
                    <button
                        className="btn btn-secondary"
                        type="reset"
                    >
                        <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_CHANGE_PASSWORD" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_CHANGE_PASSWORD" />

              </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.CURRENT_PASSWORD" />
            </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.CURRENT_PASSWORD" })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "currentPassword"
                                )}`}
                                name="currentPassword"
                                {...formik.getFieldProps("currentPassword")}
                            />
                            {formik.touched.currentPassword &&
                                formik.errors.currentPassword ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.currentPassword}
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.NEW_PASSWORD" />
            </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.NEW_PASSWORD" })}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.CONFIRM_PASSWORD" />
            </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.CONFIRM_PASSWORD" })}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "cPassword"
                                )}`}
                                name="cPassword"
                                {...formik.getFieldProps("cPassword")}
                            />
                            {formik.touched.cPassword && formik.errors.cPassword ? (
                                <div className="invalid-feedback">
                                    {formik.errors.cPassword}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}

