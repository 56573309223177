import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { JigsawEditForm } from "./JigsawEditForm";
import { useJigsawsUIContext } from "../JigsawsUIContext";
import * as actions from "../../../../_redux/items/itemsActions";

export function JigsawEditDialog() {
  // Jigsaws UI Context
  const jigsawsUIContext = useJigsawsUIContext();
  const jigsawsUIProps = useMemo(() => {
    return {
      id: jigsawsUIContext.selectedId,
      showEditJigsawDialog: jigsawsUIContext.showEditJigsawDialog,
        initJigsaw: jigsawsUIContext.initJigsaw,
        readOnly: jigsawsUIContext.readOnly
    };
  }, [jigsawsUIContext]);

  // Jigsaws Redux state
  const dispatch = useDispatch();
    const { jigsawForEdit, currentVatRate, jigsaws } = useSelector(
    (state) => ({
        jigsawForEdit: state.items.jigsawForEdit,
        currentVatRate: state.cache.vatRates.find(el => el.id == state.items.currentVatRateId),
        jigsaws: state.items.jigsaws,
        jigsawsRemove: state.items.jigsawsRemove
    }),
        shallowEqual
    );

    // if !id we should close modal
    useEffect(() => {
        if (!jigsawsUIProps.showEditJigsawDialog && !jigsawsUIProps.id) {

        }
        var el = jigsaws.find(el => el.id == jigsawsUIProps.id);
        dispatch(actions.setJigsawForEdit(el));
    }, [jigsawsUIProps.id]);

    const saveJigsaw = (jigsaw) => {
        var list = [...jigsaws];
        if (!jigsaw.id) {
            var newId = 0;
            list.forEach(el => {
                if (el.id < newId) {
                    newId = el.id;
                }
            });
            newId--;
            jigsaw.id = newId;
            list.push(jigsaw);
        } else {
            var index = list.findIndex(el => el.id == jigsaw.id);
            list[index] = jigsaw;
            dispatch(actions.setJigsawForEdit(undefined));
        }
        dispatch(actions.setJigsaws(list));
    };
    
  return (
      <JigsawEditForm
          saveJigsaw={saveJigsaw}
          jigsaw={jigsawForEdit || jigsawsUIProps.initJigsaw}
          currentVatRate={currentVatRate}
          readOnly={jigsawsUIProps.readOnly}
      />
  );
}
