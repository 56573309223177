/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { importItemsExcel } from "../../_redux/import/importCrud";
import { importPricelistsExcel } from "../../_redux/import/importCrud";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ImportIPricelists(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);

    useEffect(() => {
       
    }, [user]);

    const onChangeImportData = event => {
        setIsSuccess(false);
        setIsError(false);
        setLoading(true);
        const formData = new FormData();
        formData.append(
            "data",
            event.target.files[0],
            event.target.files[0].name
        );
        event.target.value = null;
        importPricelistsExcel(formData).then((req) => {
            if (!req.data.didError) {
                setIsSuccess(true);
            } else {
                setIsError(true);
                setError(req.errorMessage);
            }
            setLoading(false);
        }).catch((e) => {
            setIsError(true);
            setError(e.response.data.errorMessage);
            setLoading(false);
        });
    }

    return (
        <form className="card card-custom" >
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="IMPORT.NAVIGATION.PRICES" />
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="IMPORT.NAVIGATION.PRICES_2" />
          </span>
                </div>

            <div className="card-toolbar">
            </div>

            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_IMPORT_DATA" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                { error}

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <h4><FormattedMessage id="IMPORT.PRICELIST.0" /></h4>
                            <h4><FormattedMessage id="IMPORT.PRICELIST.1" /></h4>
                            <ol>
                                <li><FormattedMessage id="IMPORT.PRICELIST.2" /></li>
                                <li><a href="/api/import/downloadImportExcelTemplatePricelists"><FormattedMessage id="IMPORT.PRICELIST.3" /></a><FormattedMessage id="IMPORT.PRICELIST.4" /></li>
                                <li><FormattedMessage id="IMPORT.PRICELIST.5" />  <a href="https://skupinacomp.freshdesk.com/support/solutions/articles/35000192893-uvoz-cenika/" target="_blank"><FormattedMessage id="IMPORT.PRICELIST.5_1" /></a>).</li>
                                <li><FormattedMessage id="IMPORT.PRICELIST.6" /></li>
                                <li><FormattedMessage id="IMPORT.PRICELIST.7" /></li>
                            </ol>
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                            <FormattedMessage id="AUTH.INPUT.IMPORT" />
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            {!loading ? <input
                                type="file"
                                onChange={onChangeImportData}
                                accept=".xlsx"
                            /> : null}
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {!loading ? <span className="form-text text-muted">
                                <FormattedMessage id="IMPORT.ALLOWED_FILES" />
                            </span> : null}
                            
                        </div>
                    </div>

                </div>

            </div>
            {/* end::Form */}
        </form>
    );
}

