import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/userRoles/userRolesActions";
import { useUserRolesUIContext } from "../UserRolesUIContext";
import { FormattedMessage } from "react-intl";

export function UserRoleDeleteDialog({ id, show, onHide }) {
  // UserRoles UI Context
    const userRolesUIContext = useUserRolesUIContext();
    const userRolesUIProps = useMemo(() => {
    return {
        setIds: userRolesUIContext.setIds,
        queryParams: userRolesUIContext.queryParams
    };
    }, [userRolesUIContext]);

  // UserRoles Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
      (state) => ({ isLoading: state.userRoles.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

    const deleteUserRole = () => {
    // server request for deleting userRole by id
        dispatch(actions.deleteUserRole(id)).then(() => {
      // refresh list after deletion
            dispatch(actions.fetchUserRoles(userRolesUIProps.queryParams));
      // clear selections list
      userRolesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="SETTING.USER_ROLE.DELETE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
                  <span><FormattedMessage id="SETTING.USER_ROLE.ARE_YOU_SURE_TO_PERMANTLY_DELETE_THIS_USER_ROLE" /></span>
        )}
              {isLoading && <span><FormattedMessage id="SETTING.GENERAL.USER_ROLE_ARE_DELETING" /></span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
                  >
            <FormattedMessage id="AUTH.GENERAL.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
                      onClick={deleteUserRole}
            className="btn btn-primary btn-elevate"
                  >
            <FormattedMessage id="AUTH.GENERAL.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
