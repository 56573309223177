// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../DocumentsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function DocumentHistoryTable({ onHide, setQueryParams, queryParams }) {
    const intl = useIntl();

    // Getting curret state of office users list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.documents }),
        shallowEqual
    );
    const { historyTotalCount, historyEntities, listLoading } = currentState;

  // Office Users Redux state
  const dispatch = useDispatch();
    useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchHistory(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, dispatch]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
      totalSize: historyTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: queryParams.pageSize,
      page: queryParams.pageNumber,
      paginationTotalRenderer: customTotal
  };
  return (
      <>
            <Modal.Body className="overlay overlay-block cursor-default">
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={listLoading}
                                paginationProps={paginationProps}
                            >
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    bordered={false}
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={historyEntities === null ? [] : historyEntities}
                                    columns={[
                                        {
                                            dataField: "id",
                                            text: "#",
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "index",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.INDEX" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "columnName",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.COLUMN_NAME" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "originalValue",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.ORIGINAL_VALUE" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "newValue",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.NEW_VALUE" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "firstName",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.FULL_NAME" }),
                                            formatter: columnFormatters.UserColumnFormatter,
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "createdDate",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
                                            formatter: columnFormatters.DateColumnFormatter,
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "zoi",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.ZOI" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "eorDate",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.EOR_DATE" }),
                                            formatter: columnFormatters.DateColumnFormatter,
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        },
                                        {
                                            dataField: "eor",
                                            text: intl.formatMessage({ id: "AUTH.GENERAL.EOR" }),
                                            sort: true,
                                            sortCaret: sortCaret,
                                            headerSortingClasses,
                                        }
                                    ]}
                                    defaultSorted={uiHelpers.defaultSorted}
                                    onTableChange={getHandlerTableChange(
                                        setQueryParams
                                    )}
                                    {...paginationTableProps}
                                >
                                    <PleaseWaitMessage entities={historyEntities} />
                                    <NoRecordsFoundMessage entities={historyEntities} />
                                </BootstrapTable>
                            </Pagination>
                        );
                    }}
                </PaginationProvider>


            </Modal.Body>
            <Modal.Footer>

                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    <FormattedMessage id="AUTH.GENERAL.CLOSE" />
                </button>

            </Modal.Footer>
    </>
  );
}
