// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../DocumentsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function DocumentsTable() {
    const intl = useIntl();
  // Office users UI Context
  const documentsUIContext = useDocumentsUIContext();
  const documentsUIProps = useMemo(() => {
    return {
        ids: documentsUIContext.ids,
        setIds: documentsUIContext.setIds,
        queryParams: documentsUIContext.queryParams,
        setQueryParams: documentsUIContext.setQueryParams,
        openEditDocumentDialog: documentsUIContext.openEditDocumentDialog,
        openDeleteDocumentDialog: documentsUIContext.openDeleteDocumentDialog,
        printStornoDocumentsDialog: documentsUIContext.printStornoDocumentsDialog,
        printDocumentsDialog: documentsUIContext.printDocumentsDialog,
        printDocumentDialog: documentsUIContext.printDocumentDialog,
        historyDocumentDialog: documentsUIContext.historyDocumentDialog,
        gridColumnsVisible: documentsUIContext.gridColumnsVisible,
        readOnly: documentsUIContext.readOnly,
    };
  }, [documentsUIContext]);

  // Getting curret state of office users list from store (Redux)
  const { currentState } = useSelector(
      (state) => ({ currentState: state.documents }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Office Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    documentsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchDocuments(documentsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsUIProps.queryParams, dispatch]);

  // Table columns
    var columns = [];
    if (documentsUIProps.gridColumnsVisible.id) {
        columns.push({
            dataField: "id",
            text: "#",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }

    if (documentsUIProps.gridColumnsVisible.fiscalCashierBusinessUnitTitle) {
        columns.push({
            dataField: "fiscalCashierBusinessUnitTitle",
            text: intl.formatMessage({ id: "AUTH.GENERAL.FISCAL_CASHIER" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }

    if (documentsUIProps.gridColumnsVisible.talkingNumber) {
        columns.push({
            dataField: "talkingNumber",
            text: intl.formatMessage({ id: "AUTH.GENERAL.TALKING_NUMBER" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.type) {
        columns.push({
            dataField: "type",
            text: intl.formatMessage({ id: "AUTH.GENERAL.TYPE" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.documentTypeTitle) {
        columns.push({
            dataField: "documentTypeTitle",
            text: intl.formatMessage({ id: "AUTH.GENERAL.DOCUMENT_TYPE.TITLE" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.customerName) {
        columns.push({
            dataField: "customerName",
            text: intl.formatMessage({ id: "AUTH.GENERAL.CUSTOMER" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }

    if (documentsUIProps.gridColumnsVisible.linkedDocumentIds) {
        columns.push({
            dataField: "linkedDocumentIds",
            formatter: columnFormatters.LinkedDocumentColumnFormatter,
            formatExtraData: {
                printStornoDocumentsDialog: documentsUIProps.printStornoDocumentsDialog
            },
            text: intl.formatMessage({ id: "AUTH.GENERAL.LINKED_DOCUMENT" }),
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.dateTime) {
        columns.push({
            dataField: "date",
            formatter: columnFormatters.DateTimeColumnFormatter,
            text: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }

    if (documentsUIProps.gridColumnsVisible.date) {
        columns.push({
            dataField: "date",
            formatter: columnFormatters.DateColumnFormatter,
            text: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.creatorId) {
        columns.push({
            dataField: "creatorId",
            text: intl.formatMessage({ id: "AUTH.GENERAL.CREATOR.ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.creatorTitle) {
        columns.push({
            dataField: "creatorTitle",
            text: intl.formatMessage({ id: "AUTH.GENERAL.CREATOR" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.amountForPayment) {
        columns.push({
            dataField: "amountForPayment",
            formatter: columnFormatters.AmountForPaymentColumnFormatter,
            text: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_FOR_PAYMENT" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.endId) {
        columns.push({
            dataField: "endId",
            text: intl.formatMessage({ id: "AUTH.GENERAL.END.ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.endTalkingNumber) {
        columns.push({
            dataField: "endTalkingNumber",
            text: intl.formatMessage({ id: "AUTH.GENERAL.END.TALKING_NUMBER" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.documentStornoId) {
        columns.push({
            dataField: "documentStornoId",
            text: intl.formatMessage({ id: "AUTH.GENERAL.DOCUMENT_STORNO.ID" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.documentStornoTalkingNumber) {
        columns.push({
            dataField: "documentStornoTalkingNumber",
            text: intl.formatMessage({ id: "AUTH.GENERAL.DOCUMENT_STORNO.TALKING_NUMBER" }),
            formatter: columnFormatters.StornoTalkingNumberColumnFormatter,
            formatExtraData: {
                printStornoDocumentsDialog: documentsUIProps.printStornoDocumentsDialog
            },
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    if (documentsUIProps.gridColumnsVisible.payments) {
        columns.push({
            dataField: "payments",
            text: intl.formatMessage({ id: "AUTH.GENERAL.DOCUMENT_STORNO.PAYMENTS" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        });
    }
    columns.push({
        dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
        formatter: columnFormatters.ActionsColumnFormatter,
        formatExtraData: {
            openEditDocumentDialog: documentsUIProps.openEditDocumentDialog,
            openDeleteDocumentDialog: documentsUIProps.openDeleteDocumentDialog,
            printDocumentDialog: documentsUIProps.printDocumentDialog,
            historyDocumentDialog: documentsUIProps.historyDocumentDialog,
            readOnly: documentsUIProps.readOnly,
            print: documentsUIProps.gridColumnsVisible.print,
            history: documentsUIProps.gridColumnsVisible.history
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    });

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: documentsUIProps.queryParams.pageSize,
      page: documentsUIProps.queryParams.pageNumber,
      paginationTotalRenderer: customTotal
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                      keyField="id"

                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  documentsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                    entities,
                    ids: documentsUIProps.ids,
                    setIds: documentsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
