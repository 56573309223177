import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { OfficeUsersFilter } from "./officeUsers-filter/OfficeUsersFilter";
import { OfficeUsersTable } from "./officeUsers-table/OfficeUsersTable";
import { OfficeUsersGrouping } from "./officeUsers-grouping/OfficeUsersGrouping";
import { useOfficeUsersUIContext } from "./OfficeUsersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function OfficeUsersCard() {
    const intl = useIntl();
    const officeUsersUIContext = useOfficeUsersUIContext();
    const officeUsersUIProps = useMemo(() => {
    return {
        ids: officeUsersUIContext.ids,
        newOfficeUserButtonClick: officeUsersUIContext.newOfficeUserButtonClick,
    };
  }, [officeUsersUIContext]);

  return (
    <Card>
        <CardHeader title={intl.formatMessage({ id: "AUTH.USER.LIST.OFFICE" } )} >
        <CardHeaderToolbar>
            <CheckPermissions
                userFunctionalities={[UserFunctionalityE.UrediPisarniskeUporabnike]}>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={officeUsersUIProps.newOfficeUserButtonClick}
                >
                    <FormattedMessage id="AUTH.USER.NEW" />
                </button>
            </CheckPermissions>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <OfficeUsersFilter />
        {officeUsersUIProps.ids.length > 0 && <OfficeUsersGrouping />}
        <OfficeUsersTable />
      </CardBody>
    </Card>
  );
}
