import axios from "axios";
import { convertToDateTime } from "../../../../util/Util";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findItems(queryParams) {
    return axios.post("/api/item/find", queryParams );
}

// CREATE =>  POST: add a new item to the server
export function createItem(item) {
    return axios.post("/api/item", item );
}

export function getItemById(itemId) {
    return axios.get("/api/item/" + itemId );
}

// UPDATE => PUT: update the item on the server
export function updateItem(item) {
    var pricelists = [];
    item.pricelist.forEach(el => {
        var obj = { ...el };
        obj.effectiveDateFrom = convertToDateTime(el.effectiveDateFrom);
        pricelists.push(obj);
    });
    item.pricelist = pricelists;
    return axios.put("/api/item/" + item.id, item);
}

// DELETE => delete the item from the server
export function deleteItem(itemId) {
    return axios.delete("/api/item/" + itemId );
}

// DELETE Items by ids
export function deleteItems(ids) {
    return axios.post("/api/item/deleteItems", { ids });
}

export function upItemById(itemId) {
    return axios.get("/api/item/up/" + itemId);
}

export function downItemById(itemId) {
    return axios.get("/api/item/down/" + itemId);
}

export function checkIfEanCodeExist(itemId, eanCode) {
    return axios.post("/api/item/checkIfEanCodeExist", {
        id: itemId == undefined ? -1 : itemId,
        eanCode: eanCode
    });
}
