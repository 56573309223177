import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/activeUsers/activeUsersActions";
import { ActiveUserEditDialogHeader } from "./ActiveUserEditDialogHeader";
import { ActiveUserEditForm } from "./ActiveUserEditForm";
import { useActiveUsersUIContext } from "../ActiveUsersUIContext";
import { useHistory } from "react-router-dom";

export function ActiveUserEditDialog({ id, show, onHide }) {
  // ActiveUsers UI Context
    const activeUsersUIContext = useActiveUsersUIContext();
  const activeUsersUIProps = useMemo(() => {
    return {
        initReason: activeUsersUIContext.initReason,
    };
  }, [activeUsersUIContext]);
    const history = useHistory();

  // ActiveUsers Redux state
  const dispatch = useDispatch();
    const { actionsLoading, token } = useSelector(
    (state) => ({
        actionsLoading: state.activeUsers.actionsLoading,
        token: state.activeUsers.token
    }),
    shallowEqual
    );


  // server request for saving activeUser
    const saveReasonLogin = (reason) => {
        reason.id = id;
        dispatch(actions.login(reason)).then(() => {
            onHide();
            history.push("/relogin");
        });
  };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <ActiveUserEditDialogHeader id={id} />
            <ActiveUserEditForm
                saveReasonLogin={saveReasonLogin}
                actionsLoading={actionsLoading}
                reason={activeUsersUIProps.initReason}
                onHide={onHide}
            />
        </Modal>
    );
}


