import React from "react";
import { useSelector } from "react-redux";
import { Dashboard } from "./dashboards/Dashboard";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../app/enum/userFunctionalityE"
import { Redirect } from "react-router-dom";

export function DashboardPage() {
    const { user } = useSelector(state => state.auth);

    var url = "";
    
    if (!hasRoleForFunctionality(user, [UserFunctionalityE.PregledPrometaZaDanes, UserFunctionalityE.PregledPrometaZaTekociMesec,
        UserFunctionalityE.PregledPrometaVZadnjih12MesecihBrezDdv, UserFunctionalityE.PregledProdajePoLetih,
        UserFunctionalityE.PregledProdajeNabavePoLetih])) {

        if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledStrank, UserFunctionalityE.UrediStranke])) {
            url = "/customers";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledSkupinIzdelkov, UserFunctionalityE.UrediSkupineIzdelkov])) {
            url = "/itemGroups";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledIzdelkov, UserFunctionalityE.UrediIzdelke])) {
            url = "/items";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobavniceRacuni, UserFunctionalityE.UrediDobavniceRacune])) {
            url = "/sales/delivery_note_invoices";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledPonudb, UserFunctionalityE.UrediPonudbe])) {
            url = "/sales/offers";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobavnic, UserFunctionalityE.UrediDobavnice])) {
            url = "/sales/delivery_notes";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledRacunov])) {
            url = "/sales/invoices";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledDobropisovOffice])) {
            url = "/sales/credits";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledIzdatkovOffice])) {
            url = "/sales/expenses";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledPrejemkovOffice])) {
            url = "/sales/receipts";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledZakljuckov])) {
            url = "/sales/ends";
        } else if (hasRoleForFunctionality(user, [UserFunctionalityE.PregledPrevzemnihListov, UserFunctionalityE.UrediPrevzemneListe])) {
            url = "/purchase/takeovers";
        } else {
            url = "/settings/profile";
        }
    }

    return <>
        {url == "" ? <Dashboard /> : <Redirect to={ url } />}
        </>;
}
