import React, { useMemo } from "react";
import { JigsawsTable } from "./JigsawsTable";
import { JigsawDeleteDialog } from "./JigsawDeleteDialog";
import { JigsawEditDialog } from "./jigsaw-edit-dialog/JigsawEditDialog";
import { useJigsawsUIContext } from "./JigsawsUIContext";

export function Jigsaws() {
  // Jigsaws UI Context
  const jigsawsUIContext = useJigsawsUIContext();
  const jigsawsUIProps = useMemo(() => {
    return { ids: jigsawsUIContext.ids };
  }, [jigsawsUIContext]);
  
    return (
      <>
        <JigsawDeleteDialog />
        <JigsawEditDialog />
          <hr />
          <JigsawsTable />
      </>
    );
}
