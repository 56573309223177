import * as requestFromServer from "./activeUsersCrud";
import { activeUsersSlice, callTypes } from "./activeUsersSlice";

const { actions } = activeUsersSlice;

export const fetchActiveUsers = queryParams => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findActiveUsers(queryParams)
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.activeUsersFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find activeUsers";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};


export const login = reason => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .login(reason).then(response => {
            const { token } = response.data;
            dispatch(actions.loginFetched({ token }));
        })
        .catch(error => {
            error.clientMessage = "Can't update activeUser";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

