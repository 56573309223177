// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export default function SelectVat(props) {
    const { currentState } = useSelector(
        (state) => ({ currentState: state.cache }),
        shallowEqual
    );
    const { vatRates } = currentState;

    if ((props.value == null || props.value == undefined) && vatRates.length > 0) {
        props.onChange(vatRates[0].id);
    }

    return (
        <>
            <Select
                disabled={props.readOnly}
                variant="outlined"
                className={`form-control form-control-solid }`}
                value={props.value}
                onChange={(event) => { props.onChange(event.target.value); }}
            >
                {vatRates.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
