// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/ends/endsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../EndsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useEndsUIContext } from "../EndsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function EndsTable() {
    const intl = useIntl();
  // Ends UI Context
  const endsUIContext = useEndsUIContext();
  const endsUIProps = useMemo(() => {
    return {
        ids: endsUIContext.ids,
        setIds: endsUIContext.setIds,
        queryParams: endsUIContext.queryParams,
        setQueryParams: endsUIContext.setQueryParams,
        openEditEndDialog: endsUIContext.openEditEndDialog,
        openDeleteEndDialog: endsUIContext.openDeleteEndDialog,
        printDocumentDialog: endsUIContext.printDocumentDialog
    };
  }, [endsUIContext]);

  // Getting curret state of ends list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.ends }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Ends Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    endsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchEnds(endsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endsUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      },
        {
            dataField: "fiscalCashierBusinessUnit.businessPremiseTitle",
          text: intl.formatMessage({ id: "AUTH.GENERAL.FISCAL_CASHIER" }),
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
    {
        dataField: "talkingNumber",
        text: intl.formatMessage({ id: "AUTH.GENERAL.TALKING_NUMBER" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "dateFirstDocument",
        formatter: columnFormatters.DateColumnFormatter,
        text: intl.formatMessage({ id: "AUTH.GENERAL.DATE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      },
      {
          dataField: "dateLastDocument",
          formatter: columnFormatters.FirstLastTalkingNumberFormatter,
          text: intl.formatMessage({ id: "AUTH.GENERAL.TO_FROM_TALKING_NUMBER" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
      {
          dataField: "totalPaymentTypes",
          formatter: columnFormatters.AmountFormatter,
          text: intl.formatMessage({ id: "AUTH.GENERAL.AMOUNT_FOR_PAYMENT" }),
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
    {
      dataField: "action",
        text: intl.formatMessage({ id: "AUTH.GENERAL.ACTIONS" }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
          printDocumentDialog: endsUIProps.printDocumentDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - { to} <FormattedMessage id="FROM" /> {size} <FormattedMessage id="RESULTS" />
        </span>
    );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: endsUIProps.queryParams.pageSize,
      page: endsUIProps.queryParams.pageNumber,
      paginationTotalRenderer: customTotal,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  endsUIProps.setQueryParams
                      )}
                      selectRow={getSelectRow({
                          entities,
                          ids: endsUIProps.ids,
                          setIds: endsUIProps.setIds,
                      })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
