/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { saveExportData } from "../../_redux/export/exportCrud"
import moment from 'moment'
import InfoPopup from "../../../Helpers/InfoPopup"
import * as actions from "../../_redux/export/exportActions"
import { ExportsTable } from "./exports-table/ExportsTable";

export function ExportPage(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [refeshCount, setRefeshCount] = useState(0);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    useEffect(() => { }, [user]);

    useEffect(() => {
        dispatch(actions.fetchLastExportData());

    }, [dispatch]);

    const { currentState } = useSelector(
        (state) => ({
            currentState: state.export
        }),
        shallowEqual
    );
    const { totalCount, entities, listLoading, exportDataForEdit } = currentState;

    // Methods
    const saveExport = (values, setStatus, setSubmitting) => {

        if (!values.dailyReport &&
            !values.stockReport &&
            !values.zReport &&
            !values.deliveryNoteInvoiceReport &&
            !values.xmlExport &&
            !values.documentsFromCashier &&
            !values.documentsFromOffice)
        {
            setLoading(false);
            setSubmitting(false);
            setMessage(intl.formatMessage({ id: 'AUTH.OPS_CAN_NOT_EXPORT' }));
            setOpen(true);
            return;
        }
       
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);

        saveExportData(values).then(({ data }) => {
            if (data.didError) {
                setIsError(true);
            } else {
                setIsSuccess(data.model);
                setIsError(!data.model);
            }
            setLoading(false);
            setSubmitting(false);
            setRefeshCount(refeshCount + 1);
        })
        .catch((e) => {
            setLoading(false);
            setSubmitting(false);
            setIsError(true);
        });
    };

    var date = new Date();
    // UI Helpers
    const initialValues = {
        toDate: moment(date).format("YYYY-MM-DD"),
        fromDate: moment(date.setMonth(date.getMonth() - 1)).format("YYYY-MM-DD"),
        email: "",
        password: "",
        confirmPassword: "",
        dailyReport: true,
        stockReport: false,
        zReport: false,
        deliveryNoteInvoiceReport: false,
        xmlExport: true,
        documentsFromCashier: false,
        documentsFromOffice: false,
        vodKonto1: "",
        vodKonto2: "",
        vodKonto3: "",
        vodKonto4: "",
        vodKonto5: "",
        vodKonto6: "",
        vodKonto7: "",
        vodKonto8: "",
        vodKonto9: "",
        vodKonto10: "",
        vodKonto11: "",
        vodKonto12: "",
        vodUnknownBuyer: "",
        vodCostCenter: ""
    };

    const Schema = Yup.object().shape({
        password: Yup.string()
            .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })),
        confirmPassword: Yup.string()
            .required(intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            }))
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH' })
                ),
            }),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.GENERAL.WRONG_EMAIL_FORMAT' }))
            .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
            .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
        ),
        fromDate: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
        ),
        toDate: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
        ),
        toDate: Yup.date().default(null)
            .when("fromDate",
                (fromDate, yup) => fromDate && yup.min(fromDate, intl.formatMessage({ id: 'AUTH.GENERAL.FROM_DATE_GREATER_THAN_TO_DATE' })))
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    
    const formik = useFormik({
        initialValues: exportDataForEdit || initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveExport(values, setStatus, setSubmitting);
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="AUTH.GENERAL.EXPORT" />
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="AUTH.GENERAL.EXPORT2" />
                    </span>
                </div>
                <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting || (formik.touched && !formik.isValid)
                        }
                    >
                        <FormattedMessage id="AUTH.GENERAL.EXPORT_DATA" />
                        {formik.isSubmitting}
                    </button>
                    <button
                        className="btn btn-secondary"
                        type="reset"
                    >
                        <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_EXPORT" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_EXPORT" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}

                    <div className="form-group row">
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.GENERAL.START" />
                            <input
                                type="date"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.START" })}
                                className={`form-control ${getInputClasses(
                                    "fromDate"
                                )}`}
                                name="fromDate"
                                {...formik.getFieldProps("fromDate")}
                            />
                            {formik.touched.fromDate &&
                                formik.errors.fromDate ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.fromDate}
                                    </div>
                                ) : null}
                        </div>
                      
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.GENERAL.END" />
                            <input
                                type="date"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.END" })}
                                className={`form-control ${getInputClasses(
                                    "toDate"
                                )}`}
                                name="toDate"
                                {...formik.getFieldProps("toDate")}
                            />
                            {formik.touched.toDate &&
                                formik.errors.toDate ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.toDate}
                                    </div>
                                ) : null}
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.EMAIL" />
                            <input
                                type="text"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                                className={`form-control ${getInputClasses(
                                    "email"
                                )}`}
                                name="email"
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email &&
                                formik.errors.email ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.email}
                                    </div>
                                ) : null}
                        </div>

                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.PASSWORD" />
                            <input
                                type="password"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                                className={`form-control ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password &&
                                formik.errors.password ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.password}
                                    </div>
                                ) : null}
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
                            <input
                                type="password"
                                    placeholder={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
                                className={`form-control ${getInputClasses(
                                    "confirmPassword"
                                )}`}
                                name="confirmPassword"
                                {...formik.getFieldProps("confirmPassword")}
                            />
                            {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.confirmPassword}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <hr />
                    <h4><FormattedMessage id="AUTH.GENERAL.ADD_ADDITIONAL_DATA" /></h4>
                    <br />
                    
                    <div className="form-group row">
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.DAILY_REPORT" />
                            <br />
                            <input
                                type="checkbox"
                                checked={formik.values.dailyReport}
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.DAILY_REPORT" })}
                                className={`PrivateSwitchBase-input-93`}
                                name="dailyReport"
                                {...formik.getFieldProps("dailyReport")}
                            />
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.STOCK_REPORT" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.STOCK_REPORT" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "stockReport"
                                )}`}
                                name="stockReport"
                                checked={formik.values.stockReport}
                                {...formik.getFieldProps("stockReport")}
                            />
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.GENERAL.ENDS" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.ENDS" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "zReport"
                                )}`}
                                name="zReport"
                                checked={formik.values.zReport}
                                {...formik.getFieldProps("zReport")}
                            />
                        </div>
                      
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.DELIVERY_NOTE_INVOICE_REPORT" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.DELIVERY_NOTE_INVOICE_REPORT" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "deliveryNoteInvoiceReport"
                                )}`}
                                name="deliveryNoteInvoiceReport"
                                checked={formik.values.deliveryNoteInvoiceReport}
                                {...formik.getFieldProps("deliveryNoteInvoiceReport")}
                            />
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.XML_REPORT" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.XML_REPORT" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "xmlExport"
                                )}`}
                                name="xmlExport"
                                checked={formik.values.xmlExport}
                                {...formik.getFieldProps("xmlExport")}
                            />
                        </div>

                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.GENERAL.DOCUMENTS_FROM_CASHIER" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.GENERAL.DOCUMENTS_FROM_CASHIER" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "documentsFromCashier"
                                )}`}
                                name="documentsFromCashier"
                                checked={formik.values.documentsFromCashier}
                                {...formik.getFieldProps("documentsFromCashier")}
                            />
                        </div>

                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 col-xl-4">
                            <FormattedMessage id="AUTH.INPUT.DOCUMENTS_FROM_OFFICE" />
                            <br />
                            <input
                                type="checkbox"
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.DOCUMENTS_FROM_OFFICE" })}
                                className={`PrivateSwitchBase-input-93 ${getInputClasses(
                                    "documentsFromOffice"
                                )}`}
                                name="documentsFromOffice"
                                checked={formik.values.documentsFromOffice}
                                {...formik.getFieldProps("documentsFromOffice")}
                            />
                        </div>

                    </div>


                    {formik.values.xmlExport && <>
                        <hr />
                        <h4><FormattedMessage id="AUTH.GENERAL.VOD_KONTOS" /></h4>
                        <br />
                        <div className="form-group row">
                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_2" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_2" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto2"
                                    )}`}
                                    name="vodKonto2"
                                    {...formik.getFieldProps("vodKonto2")}
                                />
                                {formik.touched.vodKonto2 &&
                                    formik.errors.vodKonto2 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto2}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_4" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_4" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto4"
                                    )}`}
                                    name="vodKonto4"
                                    {...formik.getFieldProps("vodKonto4")}
                                />
                                {formik.touched.vodKonto4 &&
                                    formik.errors.vodKonto4 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto4}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_3" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_3" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto3"
                                    )}`}
                                    name="vodKonto3"
                                    {...formik.getFieldProps("vodKonto3")}
                                />
                                {formik.touched.vodKonto3 &&
                                    formik.errors.vodKonto3 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto3}
                                        </div>
                                    ) : null}
                            </div>

                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_5" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_5" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto5"
                                    )}`}
                                    name="vodKonto5"
                                    {...formik.getFieldProps("vodKonto5")}
                                />
                                {formik.touched.vodKonto5 &&
                                    formik.errors.vodKonto5 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto5}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_10" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_10" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto10"
                                    )}`}
                                    name="vodKonto10"
                                    {...formik.getFieldProps("vodKonto10")}
                                />
                                {formik.touched.vodKonto10 &&
                                    formik.errors.vodKonto10 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto10}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_11" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_11" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto11"
                                    )}`}
                                    name="vodKonto11"
                                    {...formik.getFieldProps("vodKonto11")}
                                />
                                {formik.touched.vodKonto11 &&
                                    formik.errors.vodKonto11 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto11}
                                        </div>
                                    ) : null}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_1" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_1" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto1"
                                    )}`}
                                    name="vodKonto1"
                                    {...formik.getFieldProps("vodKonto1")}
                                />
                                {formik.touched.vodKonto1 &&
                                    formik.errors.vodKonto1 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto1}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_6" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_6" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto6"
                                    )}`}
                                    name="vodKonto6"
                                    {...formik.getFieldProps("vodKonto6")}
                                />
                                {formik.touched.vodKonto6 &&
                                    formik.errors.vodKonto6 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto6}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_8" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_8" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto8"
                                    )}`}
                                    name="vodKonto8"
                                    {...formik.getFieldProps("vodKonto8")}
                                />
                                {formik.touched.vodKonto8 &&
                                    formik.errors.vodKonto8 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto8}
                                        </div>
                                    ) : null}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_7" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_7" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto7"
                                    )}`}
                                    name="vodKonto7"
                                    {...formik.getFieldProps("vodKonto7")}
                                />
                                {formik.touched.vodKonto7 &&
                                    formik.errors.vodKonto7 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto7}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_9" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_9" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto9"
                                    )}`}
                                    name="vodKonto9"
                                    {...formik.getFieldProps("vodKonto9")}
                                />
                                {formik.touched.vodKonto9 &&
                                    formik.errors.vodKonto9 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto9}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_KONTO_12" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_KONTO_12" })}
                                    className={`form-control ${getInputClasses(
                                        "vodKonto12"
                                    )}`}
                                    name="vodKonto12"
                                    {...formik.getFieldProps("vodKonto12")}
                                />
                                {formik.touched.vodKonto12 &&
                                    formik.errors.vodKonto12 ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodKonto12}
                                        </div>
                                    ) : null}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_UNKNOWN_BUYER" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_UNKNOWN_BUYER" })}
                                    className={`form-control ${getInputClasses(
                                        "vodUnknownBuyer"
                                    )}`}
                                    name="vodUnknownBuyer"
                                    {...formik.getFieldProps("vodUnknownBuyer")}
                                />
                                {formik.touched.vodUnknownBuyer &&
                                    formik.errors.vodUnknownBuyer ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodUnknownBuyer}
                                        </div>
                                    ) : null}
                            </div>

                            <div className="col-lg-4 col-xl-4">
                                <FormattedMessage id="EXPORT.VOD_COST_CENTER" />
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "EXPORT.VOD_COST_CENTER" })}
                                    className={`form-control ${getInputClasses(
                                        "vodCostCenter"
                                    )}`}
                                    name="vodCostCenter"
                                    {...formik.getFieldProps("vodCostCenter")}
                                />
                                {formik.touched.vodCostCenter &&
                                    formik.errors.vodCostCenter ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.vodCostCenter}
                                        </div>
                                    ) : null}
                            </div>

                        </div>
                    </>}

                    <hr />
                    <h4><FormattedMessage id="AUTH.GENERAL.LAST_EXPORT_DATA" /></h4>
                    <br />
                    <div className="form-group row">
                            <div className="col-lg-12 col-xl-12">
                                <button
                                type="button"
                                className="btn btn-primary font-weight-bolder font-size-sm"
                                onClick={(event) => {
                                    setRefeshCount(refeshCount + 1);
                                }}
                                >
                                    <i className="fa fa-sync"></i><FormattedMessage id="AUTH.GENERAL.REFRESH" />
                                </button>
                            </div>
                        </div>

                    <div className="form-group row">
                        <div className="col-lg-12 col-xl-12">
                            <ExportsTable refeshCount={refeshCount} />
                        </div>
                    </div>

                </div>
            </div>
            {/* end::Form */}
            <InfoPopup message={message} open={open} onClose={() => setOpen(false)} />
        </form>
    );
}

