import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/users/usersActions";
import { UserEditDialogHeader } from "./UserEditDialogHeader";
import { UserEditForm } from "./UserEditForm";
import { useUsersUIContext } from "../UsersUIContext";

export function UserEditDialog({ id, show, onHide }) {
  // Users UI Context
    const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
        initUser: usersUIContext.initUser,
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
    const { actionsLoading, userForEdit, userRoles, cashiers } = useSelector(
    (state) => ({
        actionsLoading: state.users.actionsLoading,
        userForEdit: state.users.userForEdit,
        userRoles: state.users.userRoles,
        cashiers: state.users.cashiers
    }),
    shallowEqual
  );

    useEffect(() => {
      dispatch(actions.fetchCashiers());
      dispatch(actions.fetchUserRoles(1));
      dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // server request for saving user
  const saveUser = (user) => {
    if (!id) {
      // server request for creating user
      dispatch(actions.createUser(user)).then(() => onHide());
    } else {
      // server request for updating user
      dispatch(actions.updateUser(user)).then(() => onHide());
    }
    };

    function getUser() {
        if (userForEdit == null || userForEdit == undefined) {
            var user = usersUIProps.initUser;
            if (cashiers != undefined || cashiers != null) {
                user.applicationUserFiscalCashierBusinessUnitUserRole = [];
                for (var x = 0; x < cashiers.length; x++) {
                    var cashier = cashiers[x];

                    var auFcbUr = {};
                    auFcbUr.id = x * -1;
                    auFcbUr.userRole = null;
                    auFcbUr.userRoleId = -1;
                    auFcbUr.fiscalCashierBusinessUnit = cashier;
                    auFcbUr.fiscalCashierBusinessUnitId = cashier.id;

                    user.applicationUserFiscalCashierBusinessUnitUserRole.push(auFcbUr);
                }
            }
            
            return user;
        }
        return userForEdit;
    }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
        <UserEditDialogHeader id={id} />
          <UserEditForm
              cashiers={cashiers}
              userRoles={userRoles}
        saveUser={saveUser}
        actionsLoading={actionsLoading}
              cashierUser={getUser()}
        onHide={onHide}
      />
    </Modal>
  );
}
