import React, { useState } from "react";
import { Route } from "react-router-dom";
import { ItemGroupsLoadingDialog } from "./itemGroups-loading-dialog/ItemGroupsLoadingDialog";
import { ItemGroupEditDialog } from "./itemGroup-edit-dialog/ItemGroupEditDialog";
import { ItemGroupDeleteDialog } from "./itemGroup-delete-dialog/ItemGroupDeleteDialog";
import { ItemGroupsDeleteDialog } from "./itemGroups-delete-dialog/ItemGroupsDeleteDialog";
import { ItemGroupsUIProvider } from "./ItemGroupsUIContext";
import { ItemGroupsCard } from "./ItemGroupsCard";

export function ItemGroupsPage({ history }) {

  const itemGroupsUIEvents = {
    newItemGroupButtonClick: () => {
        history.push("/itemGroups/new");
         
    },
    openEditItemGroupDialog: (id) => {
        history.push(`/itemGroups/${id}/edit`);
    },
    openDeleteItemGroupDialog: (id) => {
        history.push(`/itemGroups/${id}/delete`);
    },
    openDeleteItemGroupsDialog: () => {
        history.push(`/itemGroups/deleteItemGroups`);
      }
  }

  return (
      <ItemGroupsUIProvider itemGroupsUIEvents={itemGroupsUIEvents}>
        <ItemGroupsLoadingDialog />
          <Route path="/itemGroups/new">
        {({ history, match }) => (
           <ItemGroupEditDialog
            show={match != null}
            onHide={() => {
                history.push("/itemGroups");
            }}
          />
        )}
      </Route>
          <Route path="/itemGroups/:id/edit">
        {({ history, match }) => (
            <ItemGroupEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/itemGroups");
            }}
          />
        )}
      </Route>
          <Route path="/itemGroups/deleteItemGroups">
        {({ history, match }) => (
            <ItemGroupsDeleteDialog
            show={match != null}
            onHide={() => {
                history.push("/itemGroups");
            }}
          />
        )}
      </Route>
          <Route path="/itemGroups/:id/delete">
        {({ history, match }) => (
            <ItemGroupDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/itemGroups");
            }}
          />
        )}
      </Route>
          
      <ItemGroupsCard />
      </ItemGroupsUIProvider>
  );
}
