/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/items/itemsActions";
import { usePricelistsUIContext } from "./PricelistsUIContext";
import { FormattedMessage } from "react-intl";

export function PricelistDeleteDialog() {
  // Pricelists UI Context
  const pricelistsUIContext = usePricelistsUIContext();
  const pricelistsUIProps = useMemo(() => {
    return {
      id: pricelistsUIContext.selectedId,
      setIds: pricelistsUIContext.setIds,
      showDeletePricelistDialog: pricelistsUIContext.showDeletePricelistDialog,
      closeDeletePricelistDialog: pricelistsUIContext.closeDeletePricelistDialog,
    };
  }, [pricelistsUIContext]);

  // Pricelists Redux state
  const dispatch = useDispatch();
    const { pricelists, pricelistsRemove } = useSelector(
        (state) => ({
            pricelists: state.items.pricelists,
            pricelistsRemove: state.items.pricelistsRemove
        }),
        shallowEqual
    );

  // if !id we should close modal
  useEffect(() => {
    if (!pricelistsUIProps.id) {
      pricelistsUIProps.closeDeletePricelistDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricelistsUIProps.id]);

    const deletePricelist = () => {
        var el = pricelists.find(el => el.id == pricelistsUIProps.id);
        var list = pricelists.filter(x => x.id !== el.id);
        dispatch(actions.setPricelists(list));
        if (el.id > 0) {
            var listRemove = [...pricelistsRemove];
            listRemove.push(el);
            dispatch(actions.setPricelistsRemove(listRemove));
        }
        pricelistsUIProps.closeDeletePricelistDialog();
    };

  return (
    <Modal
      show={pricelistsUIProps.showDeletePricelistDialog}
      onHide={pricelistsUIProps.closeDeletePricelistDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
                  <FormattedMessage id="AUTH.PRICELIST.REMOVE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <span><FormattedMessage id="AUTH.PRICELIST.ARE_YOU_SURE_TO_REMOVE" /></span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={pricelistsUIProps.closeDeletePricelistDialog}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="AUTH.GENERAL.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deletePricelist}
            className="btn btn-primary btn-elevate"
          >
            <FormattedMessage id="AUTH.GENERAL.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
