import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/organizations/organizationsActions";
import { OrganizationEditDialogHeader } from "./OrganizationEditDialogHeader";
import { OrganizationEditForm } from "./OrganizationEditForm";
import { useOrganizationsUIContext } from "../OrganizationsUIContext";

export function OrganizationEditDialog({ id, show, onHide }) {
  // Organizations UI Context
    const organizationsUIContext = useOrganizationsUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
        initOrganization: organizationsUIContext.initOrganization,
    };
  }, [organizationsUIContext]);

  // Organizations Redux state
  const dispatch = useDispatch();
    const { actionsLoading, organizationForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.organizations.actionsLoading,
        organizationForEdit: state.organizations.organizationForEdit
    }),
    shallowEqual
  );

    useEffect(() => {
      dispatch(actions.fetchOrganization(id));
  }, [id, dispatch]);

  // server request for saving organization
  const saveOrganization = (organization) => {
    if (!id) {
    } else {
      // server request for updating organization
      dispatch(actions.updateOrganization(organization)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
        <OrganizationEditDialogHeader id={id} />
          <OrganizationEditForm
        saveOrganization={saveOrganization}
        actionsLoading={actionsLoading}
        organization={organizationForEdit || organizationsUIProps.initOrganization}
        onHide={onHide}
      />
    </Modal>
  );
}
