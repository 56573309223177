import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CashiersFilter } from "./cashiers-filter/CashiersFilter";
import { CashiersTable } from "./cashiers-table/CashiersTable";
import { CashiersGrouping } from "./cashiers-grouping/CashiersGrouping";
import { useCashiersUIContext } from "./CashiersUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckPermissions } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export function CashiersCard() {
    const intl = useIntl();
    const cashiersUIContext = useCashiersUIContext();
  const cashiersUIProps = useMemo(() => {
    return {
        ids: cashiersUIContext.ids,
        newCashierButtonClick: cashiersUIContext.newCashierButtonClick,
    };
  }, [cashiersUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.CASHIERS" } )} >
              <CardHeaderToolbar className="chtCashiers">
                  <CheckPermissions
                      userFunctionalities={[UserFunctionalityE.UrediDavcneBlagajne]}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={cashiersUIProps.newCashierButtonClick}
                    >
                          <FormattedMessage id="AUTH.CASHIER.NEW" />
                    </button>
                </CheckPermissions>
            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CashiersFilter />
        {cashiersUIProps.ids.length > 0 && <CashiersGrouping />}
        <CashiersTable />
      </CardBody>
    </Card>
  );
}
