import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { OrganizationsPage } from "./organizations/OrganizationsPage";
import { ActiveUsersPage } from "./activeUsers/ActiveUsersPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function AdminPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from eCommerce root URL to /customers */
                    <Redirect
                        exact={true}
                        from="/admin"
                        to="/admin/active_users"
                    />
                }

                {hasRoleForFunctionality(user, [UserFunctionalityE.AktivniUporabniki]) && (
                    <ContentRoute path="/admin/active_users" component={ActiveUsersPage} />
                )}
                {hasRoleForFunctionality(user, [UserFunctionalityE.Organizacije]) && (
                    <ContentRoute path="/admin/organizations" component={OrganizationsPage} />
                )}

            </Switch>
        </Suspense>
    );
}
