import React from "react";
import { useSelector } from "react-redux";


export function hasRoleForFunctionality(user, userFunctionalities) {
    var canRender = false;

    for (var x = 0; x < userFunctionalities.length; x++) {
        var userFunctionality = userFunctionalities[x];

        for (var index = 0; index < user.userRoleForFunctionality.length; index++) {

            if (userFunctionality == user.userRoleForFunctionality[index]) {
                canRender = true;
                break;
            }
        }
        if (canRender) {
            break;
        }
    }

    return canRender;


}


export const CheckPermissions = (props) => {
    /**
    * userPermissions: list of permissions the current user has
    * route: the current route the user is in (route here is a
    *        react-router path)
    * perms: the list of permissions, having any one of which 
    *        should should render the component
    */
    const { user } = useSelector(state => state.auth);
    let { userFunctionalities, children } = props

    // prevent any exceptions from crashing the app
    // this is a crude approach and better approaches should be used
    try {
        var canRender = false;
        if (userFunctionalities == undefined && userFunctionalities == null && user != undefined && user != null) {
            canRender = true;
        } else if (user != undefined && user != null) {
            canRender = hasRoleForFunctionality(user, userFunctionalities);
        }
        
        if (canRender) {
            return (
                <>
                    {children}
                </>
            )
        }
    } catch {
        return null
    }
    return null
}