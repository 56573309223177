import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PricelistEditForm } from "./PricelistEditForm";
import { usePricelistsUIContext } from "../PricelistsUIContext";
import * as actions from "../../../../_redux/items/itemsActions";

export function PricelistEditDialog() {
  // Pricelists UI Context
  const pricelistsUIContext = usePricelistsUIContext();
  const pricelistsUIProps = useMemo(() => {
    return {
      id: pricelistsUIContext.selectedId,
      showEditPricelistDialog: pricelistsUIContext.showEditPricelistDialog,
        initPricelist: pricelistsUIContext.initPricelist,
        readOnly: pricelistsUIContext.readOnly
    };
  }, [pricelistsUIContext]);

  // Pricelists Redux state
  const dispatch = useDispatch();
    const { pricelistForEdit, currentVatRate, pricelists } = useSelector(
    (state) => ({
        pricelistForEdit: state.items.pricelistForEdit,
        currentVatRate: state.cache.vatRates.find(el => el.id == state.items.currentVatRateId),
        pricelists: state.items.pricelists,
        pricelistsRemove: state.items.pricelistsRemove
    }),
        shallowEqual
    );

    // if !id we should close modal
    useEffect(() => {
        if (!pricelistsUIProps.showEditPricelistDialog && !pricelistsUIProps.id) {

        }
        var el = pricelists.find(el => el.id == pricelistsUIProps.id);
        dispatch(actions.setPricelistForEdit(el));
    }, [pricelistsUIProps.id]);

    const savePricelist = (pricelist) => {
        var list = [...pricelists];
        if (!pricelist.id) {
            var newId = 0;
            list.forEach(el => {
                if (el.id < newId) {
                    newId = el.id;
                }
            });
            newId--;
            pricelist.id = newId;
            list.push(pricelist);
        } else {
            var index = list.findIndex(el => el.id == pricelist.id);
            list[index] = pricelist;
            dispatch(actions.setPricelistForEdit(undefined));
        }
        dispatch(actions.setPricelists(list));
    };
    
  return (
      <PricelistEditForm
          savePricelist={savePricelist}
          pricelist={pricelistForEdit || pricelistsUIProps.initPricelist}
          currentVatRate={currentVatRate}
          readOnly={pricelistsUIProps.readOnly}
      />
  );
}
