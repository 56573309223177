import * as requestFromServer from "./organizationsCrud";
import { organizationsSlice, callTypes } from "./organizationsSlice";

const { actions } = organizationsSlice;

export const fetchOrganizations = queryParams => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findOrganizations(queryParams)
        .then(response => {
            const { totalCount, entities } = response.data;
            dispatch(actions.organizationsFetched({ totalCount, entities }));
        })
        .catch(error => {
            error.clientMessage = "Can't find organizations";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchOrganization = id => dispatch => {
    if (!id) {
        return dispatch(actions.organizationFetched({ organizationForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getOrganizationById(id)
        .then(response => {
            const organization = response.data.model;
            dispatch(actions.organizationFetched({ organizationForEdit: organization }));
        })
        .catch(error => {
            error.clientMessage = "Can't find organization";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};


export const updateOrganization = organization => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateOrganization(organization)
        .then(() => {
            dispatch(actions.organizationUpdated({ organization }));
        })
        .catch(error => {
            error.clientMessage = "Can't update organization";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

