import React, { useState } from "react";
import { Route } from "react-router-dom";
import { EndsLoadingDialog } from "./ends-loading-dialog/EndsLoadingDialog";
import { EndsUIProvider } from "./EndsUIContext";
import { EndsCard } from "./EndsCard";
import { EndsPrintDialog } from "./ends-print-dialog/EndsPrintDialog";

export function EndsPage({ history }) {

  const endsUIEvents = {
      printDocumentsDialog: () => {
          history.push(`/sales/ends/printEnds`);
      },
      printDocumentDialog: (id) => {
          history.push(`/sales/ends/${id}/printEnd`);
      }
  }

  return (
    <EndsUIProvider endsUIEvents={endsUIEvents}>
        <EndsLoadingDialog />
          <Route path="/sales/ends/printEnds">
              {({ history, match }) => (
                  <EndsPrintDialog
                      show={match != null}
                      onHide={() => {
                          history.push("/sales/ends");
                      }}
                  />
              )}
          </Route>

          <Route path="/sales/ends/:id/printEnd">
              {({ history, match }) => (
                  <EndsPrintDialog
                      id={match && match.params.id}
                      show={match != null}
                      onHide={() => {
                          history.push("/sales/ends");
                      }}
                  />
              )}
          </Route>
      <EndsCard />
      </EndsUIProvider>
  );
}
