/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../../../Setting/_redux/yourCompany/yourCompanyAction";
import { CheckPermissions, hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export default function CompanyEInvoice(props) {
    const intl = useIntl();
    // Fields
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { organization, error } = useSelector(
        (state) => ({
            organization: state.organization.organizationForEdit,
            error: state.organization.error
        }),
        shallowEqual
    );

    var readOnly = !hasRoleForFunctionality(user, [UserFunctionalityE.UrediVasePodjetje]);

    useEffect(() => { }, [user, organization]);
    // Methods
    const saveOrganization = (values, setStatus, setSubmitting) => {
        setLoading(true);
        setIsError(false);
        setIsSuccess(false);
        
        dispatch(actions.updateOrganization(values)).then(() => {
            setIsError(error != null);
            setIsSuccess(error == null);
            setLoading(false);
            setSubmitting(false);
        });
    };
    // UI Helpers
    const initialValues = {
        address: organization != undefined && organization != null ? organization.address : "",
        appModulePurchase: organization && organization != null != undefined ? organization.appModulePurchase : false,
        appModuleSales: organization && organization != null != undefined ? organization.appModuleSales : false,
        description: organization && organization != null != undefined ? organization.description : "",
        id: organization && organization != null != undefined ? organization.id : -1,
        name: organization && organization != null != undefined ? organization.name : "",
        postCode: organization && organization != null != undefined ? organization.postCode : 0,
        postTitle: organization && organization != null != undefined ? organization.postTitle : "",
        registrationNumber: organization && organization != null != undefined ? organization.registrationNumber : "",
        taxDuty: organization != undefined && organization != null ? organization.taxDuty : false,
        taxNumber: organization != undefined && organization != null ? organization.taxNumber : "",
        town: organization != undefined && organization != null ? organization.town : "",
        bankName: organization != undefined && organization != null && organization.bankName != null ? organization.bankName : "",
        bankIban: organization != undefined && organization != null && organization.bankIban != null ? organization.bankIban : "",
        bankBic: organization != undefined && organization != null && organization.bankBic != null ? organization.bankBic : "",
        invoiceSendToMail: organization && organization != null != undefined ? organization.invoiceSendToMail : false,
        invoiceEmail: organization && organization != null != undefined ? organization.invoiceEmail : "",
        invoiceAddress: organization && organization != null != undefined ? organization.invoiceAddress : "",
        invoicePostCode: organization && organization != null != undefined ? organization.invoicePostCode : 0,
        invoicePostTitle: organization && organization != null != undefined ? organization.invoicePostTitle : "",
        logoUniqueId: organization && organization != null != undefined ? organization.logoUniqueId : "",
        officeUserCount: organization != undefined && organization != null ? organization.officeUserCount : 1,
        cashierUserCount: organization != undefined && organization != null ? organization.cashierUserCount : 1,
        cashierCount: organization != undefined && organization != null ? organization.cashierCount : 1,
        minOfficeUserCount: organization != undefined && organization != null ? organization.minOfficeUserCount : 1,
        minCashierUserCount: organization != undefined && organization != null ? organization.minCashierUserCount : 1,
        minCashierCount: organization != undefined && organization != null ? organization.minCashierCount : 1,
        deactiveReason: organization != undefined && organization != null && organization.deactiveReason != null ? organization.deactiveReason : "",
        deactiveDate: organization != undefined && organization != null && organization.deactiveDate != null ? organization.deactiveDate : "",
        contractExpirationDate: organization != undefined && organization != null && organization.contractExpirationDate != null ? organization.contractExpirationDate : null,
        confirmed: organization != undefined && organization != null ? organization.confirmed : false
    };

    const Schema = Yup.object().shape({
        registrationNumber: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        bankName: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        bankIban: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        bankBic: Yup.string().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            saveOrganization(values, setStatus, setSubmitting);
        },
        onReset: () => {
            setIsError(false);
            setIsSuccess(false);
        }
    });

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        <FormattedMessage id="COMPANY.NAVIGATION.EINVOICE" />
          </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        <FormattedMessage id="COMPANY.NAVIGATION.EINVOICE_2" />
          </span>
                </div>

                <CheckPermissions
                    userFunctionalities={[UserFunctionalityE.UrediVasePodjetje]}>
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            <FormattedMessage id="AUTH.GENERAL.SAVE_CHANGES" />
                {formik.isSubmitting}
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="reset"
                        >
                            <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                        </button>
                    </div>
                </CheckPermissions>

            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isSuccess && (
                        <div
                            className="alert alert-custom alert-success fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-info">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                <FormattedMessage id="AUTH.GENERAL.SUCCESS_COMPANY_DATA" />
                            </div>
                            <div className="alert-close" onClick={() => setIsSuccess(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}

                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">

                                <FormattedMessage id="AUTH.GENERAL.ERROR_COMPANY_DATA" />

                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.REGISTRATION_NUMBER" />
                        </label>
                        
                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={readOnly}
                                type="text"
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.REGISTRATION_NUMBER' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "registrationNumber"
                                )}`}
                                name="registrationNumber"
                                {...formik.getFieldProps("registrationNumber")}
                            />
                            {formik.touched.registrationNumber &&
                                formik.errors.registrationNumber ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.registrationNumber}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.BANK_NAME" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={readOnly}
                                type="text"
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.BANK_NAME' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "bankName"
                                )}`}
                                name="bankName"
                                {...formik.getFieldProps("bankName")}
                            />
                            {formik.touched.bankName &&
                                formik.errors.bankName ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.bankName}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.BANK_IBAN" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={readOnly}
                                type="text"
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.BANK_IBAN' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "bankIban"
                                )}`}
                                name="bankIban"
                                {...formik.getFieldProps("bankIban")}
                            />
                            {formik.touched.bankIban &&
                                formik.errors.bankIban ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.bankIban}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <FormattedMessage id="AUTH.GENERAL.BIC" />
                        </label>

                        <div className="col-lg-9 col-xl-6">
                            <input
                                disabled={readOnly}
                                type="text"
                                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.BIC' })}
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "bankBic"
                                )}`}
                                name="bankBic"
                                {...formik.getFieldProps("bankBic")}
                            />
                            {formik.touched.bankBic &&
                                formik.errors.bankBic ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.bankBic}
                                    </div>
                                ) : null}
                        </div>
                    </div>

                </div>

            </div>
            {/* end::Form */}
        </form>
    );
}

