import axios from "axios";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findItemGroups(queryParams) {
    return axios.post("/api/itemGroup/find", queryParams );
}

// CREATE =>  POST: add a new itemGroup to the server
export function createItemGroup(itemGroup) {
    return axios.post("/api/itemGroup", itemGroup );
}

export function getItemGroupById(itemGroupId) {
    return axios.get("/api/itemGroup/" + itemGroupId );
}

// UPDATE => PUT: update the itemGroup on the server
export function updateItemGroup(itemGroup) {
    return axios.put("/api/itemGroup/" + itemGroup.id, itemGroup);
}

// DELETE => delete the itemGroup from the server
export function deleteItemGroup(itemGroupId) {
    return axios.delete("/api/itemGroup/" + itemGroupId );
}

// DELETE ItemGroups by ids
export function deleteItemGroups(ids) {
    return axios.post("/api/itemGroup/deleteItemGroups", { ids });
}

export function upItemGroupById(itemGroupId) {
    return axios.get("/api/itemGroup/up/" + itemGroupId);
}

export function downItemGroupById(itemGroupId) {
    return axios.get("/api/itemGroup/down/" + itemGroupId);
}