import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ItemGroupsPage } from "./itemGroups/ItemGroupsPage";
import { ItemsPage } from "./items/ItemsPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { hasRoleForFunctionality } from "../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../enum/userFunctionalityE"
import { useSelector } from "react-redux";

export default function ItemPage() {
    const { user } = useSelector(state => state.auth);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledSkupinIzdelkov, UserFunctionalityE.UrediSkupineIzdelkov]) && (
                    <ContentRoute path="/itemGroups" component={ItemGroupsPage} />
                )}

                {hasRoleForFunctionality(user, [UserFunctionalityE.PregledIzdelkov, UserFunctionalityE.UrediIzdelke]) && (
                    <ContentRoute path="/items" component={ItemsPage} />
                )}
              
            </Switch>
        </Suspense>
    );
}
