/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Export from "./Export";
import ResetPassword from "./ResetPassword";
import Registration from "./Registration";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { BrowserView, MobileView } from 'react-device-detect';

export function AuthPage() {
  const intl = useIntl();

  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-1.jpg")})`
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5">
                  <img
                      alt="Logo"
                      className="max-h-70px"
                                  src={toAbsoluteUrl("/media/logos/mikropos-logo2.png")}
                  />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <h3 className="font-size-h1 mb-5 text-white">
                    <FormattedMessage id="AUTH.GENERAL.WELCOME" />
                  </h3>
                  <p className="font-weight-lighter text-white opacity-80">
                    <FormattedMessage id="AUTH.GENERAL.WELCOME_TEXT" />
                  </p>
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                                  {intl.formatMessage({
                                      id: "AUTH.GENERAL.FOOTER"
                                  }, { year: new Date().getFullYear() })}
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">

              {/* begin::Content body */}
                      <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

                          <BrowserView>
                              <div style={{ height: 50 }} className="position-absolute top-0 left-0 text-left mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 header-menu header-menu-mobile" >
                                  <ul className={`menu-nav`}>
                                      <li className={`menu-item menu-item-rel`}>
                                          <a href="mailto:podpora@mikropos.si?Subject=Pomo�">
                                              <i className="fa fa-envelope" aria-hidden="true"></i>
                                              <span> <FormattedMessage id="SUPPORT_MAIL" /></span>

                                          </a>
                                      </li>

                                      <li className={`menu-item menu-item-rel`}>
                                          <a href="tel:08-33-00000">
                                              <i className="fa fa-phone" aria-hidden="true"></i>
                                              <span> <FormattedMessage id="HEADER_HELP_PHONE" /></span>
                                          </a>
                                      </li>
                                      <li className={`menu-item menu-item-rel`}>
                                          <a target="_blank" href="https://skupinacomp.freshdesk.com/support/solutions/35000141021">
                                              <i className="fa fa-medkit" aria-hidden="true"></i>
                                              <span> <FormattedMessage id="INSTRUCTION" /></span>
                                          </a>
                                      </li>
                                      <li className={`menu-item menu-item-rel`}>
                                          <a target="_blank" href="https://get.teamviewer.com/mikroposqs">
                                              <img alt="" src={toAbsoluteUrl("/media/my/menuico-tview.png")} />
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </BrowserView>


                <Switch>
                <ContentRoute path="/auth/login" component={Login}/>
                <ContentRoute path="/auth/registration" component={Registration}/>
                <ContentRoute path="/auth/export/:accessKey" component={Export} />
                <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                              />
                <ContentRoute
                    path="/auth/reset-password"
                    component={ResetPassword}
                />

                <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect to="/auth/login"/>
              </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div
                  className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                              {intl.formatMessage({
                                  id: "AUTH.GENERAL.FOOTER"
                              }, { year: new Date().getFullYear() })}
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
