import React, { useState } from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";
import { CustomerTrafficPrintDialog } from "./customer-traffic-print-dialog/CustomerTrafficPrintDialog";
import { CustomerTakeoversPrintDialog } from "./customer-takeovers-print-dialog/CustomerTakeoversPrintDialog";


export function CustomersPage({ history }) {

  const customersUIEvents = {
    newCustomerButtonClick: () => {
        history.push("/customers/new");
         
    },
    openEditCustomerDialog: (id) => {
        history.push(`/customers/${id}/edit`);
    },
    openDeleteCustomerDialog: (id) => {
        history.push(`/customers/${id}/delete`);
    },
    openDeleteCustomersDialog: () => {
        history.push(`/customers/deleteCustomers`);
    },
    openCustomerTraffictDialog: () => {
        history.push(`/customers/printCustomerTraffic`);
    },
    openCustomerTakeoversDialog: () => {
        history.push(`/customers/printCustomerTakeovers`);
    },
  }

  return (
      <CustomersUIProvider customersUIEvents={customersUIEvents}>
        <CustomersLoadingDialog />
          <Route path="/customers/new">
        {({ history, match }) => (
           <CustomerEditDialog
            show={match != null}
            onHide={() => {
                history.push("/customers");
            }}
          />
        )}
      </Route>
          <Route path="/customers/:id/edit">
        {({ history, match }) => (
            <CustomerEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/customers");
            }}
          />
        )}
      </Route>
          <Route path="/customers/deleteCustomers">
        {({ history, match }) => (
            <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
                history.push("/customers");
            }}
          />
        )}
      </Route>
          <Route path="/customers/:id/delete">
        {({ history, match }) => (
            <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
                history.push("/customers");
            }}
          />
        )}
          </Route>
          <Route path="/customers/printCustomerTraffic">
              {({ history, match }) => (
                  <CustomerTrafficPrintDialog
                      show={match != null}
                      onHide={() => {
                          history.push("/customers");
                      }}
                  />
              )}
          </Route>

          <Route path="/customers/printCustomerTakeovers">
              {({ history, match }) => (
                  <CustomerTakeoversPrintDialog
                      show={match != null}
                      onHide={() => {
                          history.push("/customers");
                      }}
                  />
              )}
          </Route>
          
      <CustomersCard />
      </CustomersUIProvider>
  );
}
