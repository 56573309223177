import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./OrganizationsUIHelpers";

const OrganizationsUIContext = createContext();

export function useOrganizationsUIContext() {
    return useContext(OrganizationsUIContext);
}

export const OrganizationsUIConsumer = OrganizationsUIContext.Consumer;

export function OrganizationsUIProvider({organizationsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initOrganization = {
        id:-1,
        appModuleSales: false,
        appModulePurchase: false,
        appModuleSetting: false,
        appModuleAdministration: false,
        officeUserCount: 0,
        cashierUserCount: 0,
        cashierCount: 0,
        active: false,
        confirmed: false,
        //not save
        name: '',
        town: '',
        taxNumber: '',
        taxDuty: false,
        address: '',
        postCode: '',
        postTitle: '',
        registrationNumber: '',
        invoiceSendToMail: false,
        readOnly: false,
        contractId: '',
        invoiceAddress: '',
        invoicePostCode: '',
        invoicePostTitle:''
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initOrganization,
      newOrganizationButtonClick: organizationsUIEvents.newOrganizationButtonClick,
      openEditOrganizationDialog: organizationsUIEvents.openEditOrganizationDialog
  };

    return <OrganizationsUIContext.Provider value={value}>{children}</OrganizationsUIContext.Provider>;
}