import React, { useEffect, useState }from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../../../_metronic/layout";
import ImportItems from "../import/ImportItems";
import ImportStocks from "../import/ImportStocks";
import ImportIPricelists from "../import/ImportIPricelists";
import { Navigation } from "../import/components/Navigation";
import { useIntl } from "react-intl";
import { hasRoleForFunctionality } from "../../../../../_metronic/layout/_core/CheckPermissions"
import { UserFunctionalityE } from "../../../../enum/userFunctionalityE"

export default function ImportPage() {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { user } = useSelector(state => state.auth);
    suhbeader.setTitle(intl.formatMessage({ id: 'AUTH.GENERAL.YOUR_COMPANY' }));

    // CodeLists Redux state
    const dispatch = useDispatch();
    useEffect(() => {


    }, [dispatch]);

    return (
        <div className="d-flex flex-row">
            <Navigation></Navigation>
            <div className="flex-row-fluid ml-lg-8">
                <Switch>
                    <Redirect
                        from="/settings/import"
                        exact={true}
                        to="/settings/import/iitems"
                    />

                    {hasRoleForFunctionality(user, [UserFunctionalityE.UrediInventurnePopise]) && (
                            <Route
                                path="/settings/import/stocks"
                                component={ImportStocks}
                            />
                    )}
                   
                    {hasRoleForFunctionality(user, [UserFunctionalityE.UrediIzdelke]) && (
                        <Route
                            path="/settings/import/iitems"
                                component={ImportItems}
                        />
                    )}

                    {hasRoleForFunctionality(user, [UserFunctionalityE.UrediIzdelke]) && (
                        <Route
                            path="/settings/import/ipricelists"
                            component={ImportIPricelists}
                        />
                    )}
                </Switch>
            </div>
        </div>
    );
}
