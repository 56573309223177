import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { OrganizationsFilter } from "./organizations-filter/OrganizationsFilter";
import { OrganizationsTable } from "./organizations-table/OrganizationsTable";
import { OrganizationsGrouping } from "./organizations-grouping/OrganizationsGrouping";
import { useOrganizationsUIContext } from "./OrganizationsUIContext";
import { FormattedMessage, useIntl } from "react-intl";

export function OrganizationsCard() {
    const intl = useIntl();
    const organizationsUIContext = useOrganizationsUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
        ids: organizationsUIContext.ids,
        newOrganizationButtonClick: organizationsUIContext.newOrganizationButtonClick,
    };
  }, [organizationsUIContext]);

  return (
    <Card>
          <CardHeader title={intl.formatMessage({ id: "AUTH.GENERAL.ORGANIZATION" } )} >
            <CardHeaderToolbar>


            </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <OrganizationsFilter />
        {organizationsUIProps.ids.length > 0 && <OrganizationsGrouping />}
        <OrganizationsTable />
      </CardBody>
    </Card>
  );
}
