import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./CustomersUIHelpers";

const CustomersUIContext = createContext();

export function useCustomersUIContext() {
    return useContext(CustomersUIContext);
}

export const CustomersUIConsumer = CustomersUIContext.Consumer;

export function CustomersUIProvider({customersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

    const initCustomer = {
        id: undefined,
        taxDuty: false,
        taxNumber: "",
        name: "",
        address: "",
        postCode: null,
        postTitle: null,
        isForeigner: false,
        useWholesalePricelist: false,
        withoutVatExport: false,
        discount: 0,
        paymentDeadline: 8,
        isSupplier: false,
        active: true,
        bankBicCode: "",
        bankIbanCode: "",
        countryId: null
    };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
      initCustomer,
      newCustomerButtonClick: customersUIEvents.newCustomerButtonClick,
      openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
      openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
      openCustomerTraffictDialog: customersUIEvents.openCustomerTraffictDialog,
      openCustomerTakeoversDialog: customersUIEvents.openCustomerTakeoversDialog
  };

    return <CustomersUIContext.Provider value={value}>{children}</CustomersUIContext.Provider>;
}