import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import ErrorPopup from "../../Helpers/ErrorPopup"
import UrlParams from "../../../util/UrlParams"

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    username: "",
    password: "",
    confirmPassword: ""
};

function ResetPassword(props) {
  const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [alertClassName, setAlertClassName] = useState("mb-10 alert alert-custom alert-light-danger alert-dismissible");
    const [showForm, setShowForm] = useState(true);

    const ResetPasswordSchema = Yup.object().shape({
        username: Yup.string()
          .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
          .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
          .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.MINIMUM_3_SYMBOLS' }))
        .max(50, intl.formatMessage({ id: 'AUTH.GENERAL.MAXIMUM_50_SYMBOLS' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
    confirmPassword: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )
        .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH' })
            ),
        })
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
      validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
        enableLoading();
        let params = new UrlParams(window.location.search);
        let id = params.get('id');
        let code = params.get('code');

        resetPassword(id, code, values.username, values.password)
        .then(({ data }) => {
            disableLoading();
            if (data.didError) {
                setErrorMessage(intl.formatMessage({ id: 'AUTH.OPS_CONNECTION_PROBLEM' }));
                setErrorOpen(true);
            } else {
                if (data.model) {
                    setShowForm(false);
                    setAlertClassName("mb-10 alert alert-custom alert-success alert-dismissible");
                    setStatus(
                    intl.formatMessage(
                        { id: "AUTH.RESETPASSWORD.SUCCESS" }
                    ));
                } else {
                    setSubmitting(false);
                    setShowForm(true);
                    setStatus(
                    intl.formatMessage(
                        { id: "AUTH.RESETPASSWORD.UNSUCCESS" }
                    ));
                }
            }
          })
            .catch(() => {
                disableLoading();
                setSubmitting(false);
          });
    },
  });

    return (
        <div>
            {/*begin::Content header*/}
            < div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10" >
                <span className="font-weight-bold text-dark-50">
                    <FormattedMessage id="AUTH.GENERAL.DONT_HAVE_AN_ACCOUNT_YET" />
                </span>
                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                    <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                </Link>
            </div >
            {/*end::Content header*/}
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.RESETPASSWORD.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
                  <FormattedMessage id="AUTH.RESETPASSWORD.ENTER_YOUR_USERNAME_AND_PASSWORD" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status && (
                  <div className={alertClassName} >
                    <div className="alert-text font-weight-bold">
                        {formik.status}
                    </div>
                </div>
            )}

        {showForm && (
                  <div>
                      <div className="form-group fv-plugins-icon-container">
                          <input
                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.USERNAME' })}
                              type="username"
                              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                  "username"
                              )}`}
                              name="username"
                              {...formik.getFieldProps("username")}
                          />
                          {formik.touched.username && formik.errors.username ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.username}</div>
                              </div>
                          ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                          <input
                              placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD' })}
                              type="password"
                              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                  "password"
                              )}`}
                              name="password"
                              {...formik.getFieldProps("password")}
                          />
                          {formik.touched.password && formik.errors.password ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.password}</div>
                              </div>
                          ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                          <input
                              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                              type="password"
                              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                  "confirmPassword"
                              )}`}
                              name="confirmPassword"
                              {...formik.getFieldProps("confirmPassword")}
                          />
                          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                              </div>
                          ) : null}
                      </div>

                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                          <button
                              type="submit"
                              disabled={
                                  formik.isSubmitting ||
                                  !formik.isValid
                              }
                              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                          >
                              <span>
                                  <FormattedMessage id="AUTH.RESETPASSWORD.BUTTON" />
                              </span>
                              {loading && <span className="ml-3 spinner spinner-white"></span>}
                          </button>
                      </div>   
                  </div>   
            )}

        
          </form>

          <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login">
              <FormattedMessage id="AUTH.GENERAL.LOGIN" />
          </Link>
        {/*end::Form*/}
                <ErrorPopup message={errorMessage} open={errorOpen} onClose={() => setErrorOpen(false)} />
            </div>
            </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
