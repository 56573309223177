import React, {createContext, useContext} from "react";

const DocumentsUIContext = createContext();

export function useDocumentsUIContext() {
    return useContext(DocumentsUIContext);
}

export const DocumentsUIConsumer = DocumentsUIContext.Consumer;

export function DocumentsUIProvider({ value, children }) {
    return <DocumentsUIContext.Provider value={value}>{children}</DocumentsUIContext.Provider>;
}