/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as actions from "../../../Setting/_redux/yourProfile/yourProfileAction";
import { YourProfileForm } from "./YourProfileForm"



export function YourProfilePage() {
    // Fields
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user, shallowEqual);

    // CodeLists Redux state
    useEffect(() => {
        setLoading(true);
        dispatch(actions.fetchProfile()).then(() => {
            setLoading(false);
        });
    }, [dispatch]);


    useEffect(() => {
    }, [user]);

    return (
        <>
            {loading && <ModalProgressBar />}
            <YourProfileForm />
        </>
    );
}

